// import 'babel-polyfill';
import 'react-app-polyfill/stable';
import "regenerator-runtime/runtime";

import React from 'react';
// PMD 15/01/24 Upgrade to React18.2.0
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from "react-router-dom-v5-compat";
import { CookiesProvider } from 'react-cookie';
// import { createStore, applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { Router } from "react-router-dom";
import { store, history } from "./store";


// import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';

// import { createBrowserHistory } from 'history'
// import rootReducer from './reducers';

// PMD 05/08/22 B2C Implementation
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import App from './components/App';

import * as serviceWorker from './serviceWorker';

// const history = createBrowserHistory();

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


/*
const store = createStore(
  // connectRouter(history)(rootReducer),
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk)
  )
)
*/

// PMD 05/08/22 B2C Implementation logging level
// msalConfig.system = {
//   loggerOptions: {
//     loggerCallback: LogLevel.Error
//   }
// }
// Instantiate a PublicClientApplication object and initialize it 
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <CookiesProvider>
      <Router history={history}>
        <BrowserRouter>
          <CompatRouter>
            <App instance={msalInstance} />
          </CompatRouter>
        </BrowserRouter>
      </Router>
    </CookiesProvider>
  </Provider>
);

// PMD 15/01/24 Upgrade to React18.2.0
// ReactDOM.render((
//   <Provider store={store}>
//     <CookiesProvider>
//       <ConnectedRouter history={history}>
//         <BrowserRouter>
//           <CompatRouter>
//             {/* PMD 05/08/22 B2C Implementation */}
//             {/* <App /> */}
//             <App instance={msalInstance} />
//           </CompatRouter>
//         </BrowserRouter>

//       </ConnectedRouter>
//     </CookiesProvider>
//   </Provider>
// ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();