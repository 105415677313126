import { LOGOUT, LOAD_COVER, LOAD_SETTINGS, SAVE_SETTINGS, SET_SETTINGS_ERROR } from './types';


import { postRequest, getRequest, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';

export const setSettingsError = (err) => {
  return {
    type: SET_SETTINGS_ERROR,
    payload: err
  };
};


export const loadSettings = () => {

  return (dispatch, getState) => {

    // FIXME: PMD 13/12/21 Check if still required

    if (getState().session.sessionId) {
      dispatch({
        type: LOAD_SETTINGS,
        payload: {}
      });

    }

    // FIXME: PMD 13/12/21 STILL REQUIRED? 
    // if (getState().session.sessionId) {

    //   // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
    //   // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-get-account-details?sessionId=${getState().session.sessionId}`;
    //   const URI = `${config[env].URL}/client-portal-get-account-details?sessionId=${getState().session.sessionId}`;

    //   const credentials = 'omit';
    //   // const headers = { appkey: process.env.REACT_APP_APP_KEY };

    //   //      return getRequest(URI, credentials, headers)

    //   const headers = {};

    //   return getRequest(URI, credentials, headers)
    //     .then(apiParse)
    //     .then(response => {

    //       dispatch({
    //         type: LOAD_SETTINGS,
    //         payload: response
    //       });

    //     })
    //     .catch(err => {

    //       dispatch({
    //         type: SET_SETTINGS_ERROR,
    //         payload: "error loading settings"
    //       });

    //     });
    // } else {
    //   //no secure id
    //   dispatch({
    //     type: LOAD_SETTINGS,
    //     payload: {
    //       organisationName: null,
    //       departmentName: null,
    //       spotliteCertificateEmailAddress: null,
    //       // ICOReferenceNumber: null,
    //       correspondenceAddressLine1: null,
    //       correspondenceAddressLine2: null,
    //       correspondenceAddressCity: null,
    //       correspondenceAddressState: null,
    //       correspondenceAddressPostalCode: null
    //     }
    //   });
    // }
  }
};

// PMD 14/12/21 No longer called
// export const saveSettings = ({ departmentName, spotliteCertificateEmailAddress, clientEmailDownload, /* ICOReferenceNumber, */ applicantEmailDownload, DefaultAccountNameForUsers, address }) => {
//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-post-account-details`;
//       const URI = `${config[env].URL}/client-portal-post-account-details`;
//       const options = {
//         sessionId: getState().session.sessionId,
//         departmentName: departmentName,
//         spotliteCertificateEmailAddress: spotliteCertificateEmailAddress,
//         clientEmailDownload: clientEmailDownload,
//         // ICOReferenceNumber: ICOReferenceNumber,
//         applicantEmailDownload: applicantEmailDownload,
//         defaultAccountNameForUsers: DefaultAccountNameForUsers,
//         address: address
//       };

//       const credentials = 'omit';
//       // const headers = { appkey: process.env.REACT_APP_APP_KEY };

//       // return postRequest(URI, options, credentials, headers)
//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {

//           dispatch({
//             type: SAVE_SETTINGS,
//             payload: response
//           });

//         })
//         .catch(err => {

//           dispatch({
//             type: SET_SETTINGS_ERROR,
//             payload: "error saving settings"
//           });

//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: SAVE_SETTINGS,
//         payload: {}
//       });
//     }
//   }
// };

export const loadCover = () => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-get-cover-page?sessionId=${getState().session.sessionId}`;
      const URI = `${config[env].URL}/client-portal-get-cover-page?sessionId=${getState().session.sessionId}`;

      const credentials = 'omit';
      // const headers = { appkey: process.env.REACT_APP_APP_KEY };

      // return getRequest(URI, credentials, headers)

      const headers = {};

      return getRequest(URI, credentials, headers)
        .then(apiParse)
        .then(response => {

          dispatch({
            type: LOAD_COVER,
            payload: response
          });

        })
        .catch(err => {
          // PMD 20/05/22 Add generic error handler
          errorHandler(err)

          dispatch({
            type: SET_SETTINGS_ERROR,
            payload: "error loading certificate cover"
          });

        });
    } else {
      //no secure id
      dispatch({
        type: LOAD_COVER,
        payload: ''
      });
    }
  }
};


export const saveCover = (coverItem) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-post-cover-page`;
      const URI = `${config[env].URL}/client-portal-post-cover-page`;

      const options = {
        sessionId: getState().session.sessionId,
        spotliteCertificateCoverPage: coverItem
      };
      const credentials = 'omit';
      // const headers = { appkey: process.env.REACT_APP_APP_KEY };

      // return postRequest(URI, options, credentials, headers)
      return postRequest(URI, options, credentials)
        .then(apiParse)
        .then(response => {

          dispatch({
            type: LOAD_COVER,
            payload: { spotliteCertificateCoverPage: coverItem }
          });

        })
        .catch(err => {
          // PMD 20/05/22 Add generic error handler
          errorHandler(err)
          dispatch({
            type: SET_SETTINGS_ERROR,
            payload: "error saving certificate cover"
          });

        });
    } else {
      //no secure id
      dispatch({
        type: LOAD_COVER,
        payload: ''
      });
    }
  }
};

export const deleteAccount = () => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-close-account`;
      const URI = `${config[env].URL}/client-portal-close-account`;

      const options = {
        sessionId: getState().session.sessionId,
      };
      const credentials = 'omit';
      // const headers = { appkey: process.env.REACT_APP_APP_KEY };

      // return postRequest(URI, options, credentials, headers)
      return postRequest(URI, options, credentials)
        .then(apiParse)
        .then(response => {

          dispatch({
            type: LOGOUT
          });

        })
        .catch(err => {
          // PMD 20/05/22 Add generic error handler
          errorHandler(err)
          dispatch({
            type: SET_SETTINGS_ERROR,
            payload: err
          });

        });
    } else {
      //no secure id
      dispatch({
        type: LOGOUT
      });
    }
  }
};
