import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Headline extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string,
		className: PropTypes.string,
		type: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	render() {
		const {
			componentType,
			className,
			type,
			children,
			...others
		} = this.props;
		const Element = componentType;
		const typeClass = type ? `headline--${type}` : '';
		const classNames = `headline ${typeClass} ${(className) ? className : ''}`;

		return (
            <Element className={classNames} {...others}>
				{children}
            </Element>
		);
	}
}
