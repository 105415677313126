import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import fetch from 'isomorphic-fetch';

import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Modalstyle.css';

import './WebPageDisplay.css';

import config from '../../../config';
import Loader from '../../Loader/Loader';
// import './DisplayWebPage.css';
const env = process.env.NODE_ENV || 'development';

function WebPageAsPDFDisplay() {
    const [ url, setUrl ] = useState('');
    const [ pdfUrl, setPdfUrl ] = useState('');
    const [ pdfKey, setPdfKey ] = useState(0);
    const [ comment, setComment ] = useState(''); 
    const [ browseHistory, setbrowseHistory ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { sessionId } = useSelector(state => state.session);
    const { selectedApplicationTask } = useSelector(state => state.applications);
    const { ApplicationTasks } = useSelector(state => state.applications.applications);

    const appkey = process.env.REACT_APP_APP_KEY;
    const proxyServer = `${config[env].URL}`;
    
    const fetchPdf = async (url) => {
        setLoading(true);
        setError(null);
        let outcomeMessage = null;
        try {
            const queryParams = `?sessionid=${sessionId}&appkey=${appkey}&url=${encodeURIComponent(url)}`;
            // const proxiedUrl = `${proxyServer}/generate-pdf?url=${encodeURIComponent(url)}`;
            const proxiedUrl = `${proxyServer}/generate-webpage-pdf${queryParams}`
            const response = await fetch(proxiedUrl);

            // Check content type to determine if it's a PDF or an error message
            const contentType = response.headers.get('Content-Type');
    
            if (contentType.startsWith('application/pdf')) {
                const blob = await response.blob();
                const pdfBlobUrl = URL.createObjectURL(blob);
                setUrl(url);
                if (!browseHistory.includes(url)) {
                    setbrowseHistory([ ...browseHistory, url ]);
                }
                // Update the key to force re-render
                setPdfKey(prevKey => prevKey + 1);
                setPdfUrl(pdfBlobUrl);
            } else if (contentType.startsWith('application/json')) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An error occurred while generating the PDF.');
            } else {
                throw new Error('Unexpected content type received.');
            }
            // Update the key to force re-render
            setPdfKey(prevKey => prevKey + 1);
            console.log('browseHistory;', browseHistory);
        } 
        catch (err) {
            console.error('Error fetching web page:', err);
            outcomeMessage = `Failed to fetch web page. ${err.message}`
            setError(outcomeMessage);
        }
        finally {
            setLoading(false);
            if (outcomeMessage) {
                confirmAlert({
                    title: 'Search Web Page',
                    message: outcomeMessage,
                    buttons: [{
                        label: 'OK',
                        // onClick: () => resetState()
                        onClick: () => null
                    }]
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await fetchPdf(url);
    };
    
    const handleLinkClick = async (event) => {
        const target = event.target.closest('a');
        if (target && target.href) {
            event.preventDefault();
            const url = target.href;
            // setUrl(url);
            await fetchPdf(url);
        }
    };

    const handlePreviousOrNext = async (event, direction = 'next') => {
        event.preventDefault();
        // alert(`handle${direction}, history ${browseHistory}`);
        // console.log(`handle${direction}, history ${browseHistory}`);

        let currentIndex = browseHistory?.indexOf(url);

        if (currentIndex === -1) {
            // Find out if there is a slash as the last character
            // If '/' then remove last character
            // If no '/' then append '/' as last character and search again
            let alternateUrl = '';
            if (url?.charAt(url?.length - 1) === '/') {
                alternateUrl = url?.slice(0, -1);
            } else {
                alternateUrl = `${url}/`;
            }
            currentIndex = browseHistory?.indexOf(alternateUrl);
        }

        let gotoIndex = 0;
        if (currentIndex === -1) {
            // Set index to first or last element of the array depending on direction
            gotoIndex = direction === 'next' ? browseHistory?.length - 1 : 0;   
        } else {
            gotoIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
            if (gotoIndex >= browseHistory?.length) {
                gotoIndex = 0;
            }
            if (gotoIndex < 0) {
                gotoIndex = browseHistory?.length - 1;
            }
        }
        await fetchPdf(browseHistory[ gotoIndex ]);
    };

    const handleSaveWebpage = async () => {
        // console.log('Comment:', comment);
        // console.log('Chosen Items:', selectedLinks);
        // console.log('selectedApplicationTask:', selectedApplicationTask);
        // console.log('ApplicationTasks:', ApplicationTasks?.[selectedApplicationTask]?.applicationGUID);  
        try {
            if (!comment?.trim()) {
                setComment('');
                return;
            }

            let outcomeMessage  = null;
            try {
                const applicationGUID = ApplicationTasks?.[selectedApplicationTask]?.applicationGUID;
                setLoading(true);
                // Clear any previous errors
                setError(null);

                const options = {
                    sessionId,
                    applicationGUID,
                    comment,
                    webpageURLs: [ url ]
                };
                // console.log('options:', options);

                const response = await fetch(
                    `${proxyServer}/save-webpage`,
                    {
                        method: 'POST',
                        credentials: 'omit',
                        headers: {
                            'Content-Type': 'application/json',
                            appkey: process.env.REACT_APP_APP_KEY
                        },
                        body: JSON.stringify(options)
                    }
                );

                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                outcomeMessage = 'Web page content saved.';
            }
            catch (err) {
                outcomeMessage = `An error occurred whilst saving the web page content, original error:- ${err.message}`;
                throw err;
            }
            finally {
                // Clear the comment and close the modal
                setComment('');
                toggleModal();
                setLoading(false);
                confirmAlert({
                    title: 'Save Web Page',
                    message: outcomeMessage,
                    buttons: [{
                        label: 'OK',
                        // onClick: () => resetState()
                        onClick: () => null
                    }]
                });
            }
        }
        catch (err) {
            setError(err.message);
        }
    };

    const toggleModal = () => {
        setComment('');
        setIsModalOpen(!isModalOpen);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    }

    const handleSave = () => {
        toggleModal();

    };
    
    useEffect(() => {
        return () => {
          // Cleanup previous blob URLs
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);
    
    return (
        <div>
            <div className="flex-container">
            {/*<div style={{ display: 'flex', alignItems: 'center', "marginBottom": "5px"  }}> */}
                {/*
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Enter URL"
                    />
                    <button type="submit">Generate PDF</button>
                </form>
                */}
                <button 
                    className="button-small-top-left-margin"
                    onClick={ (e) => handlePreviousOrNext(e, 'previous') }
                    disabled={ !Array.isArray(browseHistory) || !browseHistory.length || loading }
                >
                    &lt;
                </button>
                <button 
                    className="button-small-top-left-right-margin"
                    onClick={ (e) => handlePreviousOrNext(e, 'next') }
                    disabled={ !Array.isArray(browseHistory) || !browseHistory.length || loading }
                >
                    &gt;
                </button>
                <fieldset className="form__fieldset__url">
                    <label className="form__label" htmlFor="url">
                        URL or Search Term:
                    <input
                        className="form__fieldset__input__url"
                        type="text"
                        id="url"
                        placeholder="Internet search or type a URL"
                        value={ url }
                        onChange={ (e) => setUrl(e.target.value) }
                    />
                    </label>
                </fieldset>
                <button 
                    className="button button-small-top-left-margin" 
                    onClick={ handleSubmit }
                    disabled={ !url || loading}
                >
                    Search
                </button>
                <button 
                    className="button button-small-top-left-right-margin" 
                    onClick={ handleSave }
                    disabled={ !url || loading }
                >
                    Save
                </button>
            </div>
             {/* Loading spinner or message */}
            <Loader
                className="loader__spinner"
                active={loading}
                activeName="loader--active"
                loaderName="loader"
                spinnerName="loader__spinner"
			/>
                       
            {loading && <div>Getting web page, please wait ...</div>}
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {pdfUrl && !loading && (
                <div style={{ height: '100vh' }} onClick={handleLinkClick}>
                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
                        <Viewer key={pdfKey} fileUrl={pdfUrl} />
                        {/*<Viewer key={pdfKey} fileUrl={pdfUrl} defaultScale={SpecialZoomLevel.PageWidth} />*/}
                    </Worker>
                </div>
            )}
            <Modal 
                open={ isModalOpen } 
                onClose={ toggleModal } 
                center
                closeOnOverlayClick={ false }
                // classNames={{ modal: "custommodalform" }}
                closeOnEsc={ false }
            >
                <div className="modal-content">
                    <label>Comment why you are saving this webpage:</label>
                    <textarea 
                        rows="6" 
                        value={ comment } 
                        onChange={ handleCommentChange } 
                    />
                    <button 
                        onClick={ handleSaveWebpage }
                        disabled= { !comment }
                    >
                        Save
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default WebPageAsPDFDisplay;