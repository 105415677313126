const getPropertyValuesByKey = (collection, property) => {
    // Recursive function that returns an array of property values from a nested collection (array or object) for a particular property key
    collection = collection ? collection : [];

    return Object.entries(collection)
        .reduce((acc, [key, value]) => {
            return (key === property)
                ? (value && Array.isArray(value))
                    ? [...acc, ...value]
                    : [...acc, value]
                : (value && typeof value === 'object')
                    ? [...acc, ...getPropertyValuesByKey(value, property)]
                    : acc;
        }, []);
};

const getNestedValue = (collection, ...levels) => levels.reduce((acc, level) => acc && acc[level], collection);

export { getPropertyValuesByKey, getNestedValue }