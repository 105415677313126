import deepCopy from "../../../utils/deepCopy";

const SaveGridLayout = (props) => {

    const {
        gridLayout,
        updatedScreenLayout,
        originalPanels,
        screenComponentGUID,
        screenAttributes,
        ribbon,
        callback,
        sessionId,
        teamSpotliteAddress,
    } = props;


    const panelUpdates = updatedScreenLayout.length === 0 ? deepCopy(originalPanels) : deepCopy(updatedScreenLayout)

    // loop around the panels
    const screenLayoutValues = []
    panelUpdates.forEach(panel => {
      if (Object.values(panel)[0].Attributes.ComponentType === "Grid") {
        // console.log(Object.values(panel)[0].Calls)
        const gridCalls = Object.values(panel)[0].Calls
        const updatedCalls = gridCalls.map(call => {
          if (call.CallingObjectGUID === Object.keys(gridLayout)[0]) {
            // loop around and set the defaultWidth to equal the width of the changed grid
            for (let i = 0; i < call.Properties.columnHeadings.length; i++) {
              const column = call.Properties.columnHeadings[i];
              let gridLayoutValues = Object.values(gridLayout)[0]
              for (let j = 0; j < gridLayoutValues.length; j++) {
                const gridColumn = gridLayoutValues[j];
                if (column.propertyGUID === gridColumn.propertyGUID) {
                  // console.log(gridColumn)
                  column.defaultWidth = gridColumn.width
                }
              }
            }
          }
          // console.log(call)
        })
        // console.log(panel)
        screenLayoutValues.push(panel)
      } else {
        screenLayoutValues.push(panel)
      }
    })
    // console.log(this.state.updatedScreenLayout)
    // console.log(screenLayoutValues)

    //let teamSpotliteAddress = this.props.dataConfig.allTeams.currentTeamDetails.spotliteAddress;
    // PMD 08/12/22 Use the attribute value of the main screen
    // let screenViewGUID = this.props.viewGUID
    let screenViewGUID = screenComponentGUID
    // PMD 20/05/22 Being updated at backend
    // let screenVersionNumber = 99999   // Not currently supplied

    let screenDefinitionJSON = {};
    screenDefinitionJSON.Screen = {}
    screenDefinitionJSON.Screen.Attributes = screenAttributes;
    screenDefinitionJSON.Screen.ScreenRibbon = ribbon;
    screenDefinitionJSON.Screen.Panel = screenLayoutValues;

    // console.log(screenDefinitionJSON)
    let updatedScreenDefinition = { sessionId, teamSpotliteAddress, screenViewGUID, /* screenVersionNumber, */ screenDefinitionJSON }

    const callbackPayload = {
        updatedScreenLayout: screenLayoutValues,
        updatedScreenDefinition,
    };

    callback(callbackPayload);

}

export default SaveGridLayout;