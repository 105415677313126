
export const SET_SESSION = 'set_session';
export const LOGOUT = 'logout';
// PMD 09/12/21 remove sessionDetails
// export const LOAD_SESSION_DETAILS = 'load_session_details';
export const REDIRECT = 'redirect';
export const REDIRECT_COMPLETE = 'redirect_comeplete';
export const SET_SESSION_ERROR = 'set_session_error';
export const SET_SESSION_WORKING = 'set_session_working';
export const SET_MOBILE_EMBEDDED = 'set_mobile_embedded';
// PMD 09/12/21 remove sessionDetails
// export const SET_TEAM_GUID = 'set_team_guid';
export const SET_USERS_TEAMS = 'set_users_teams';
export const SET_PORTAL = 'set_portal';

export const SET_SEARCH_CRITERIA = 'set_search_criteria';
export const SELECT_CERTIFICATE = 'select_certificate';
export const LOAD_SWIMLANES = 'load_swimlanes';
// SAVE_SWIMLANES_ORDER Not Used
export const SAVE_SWIMLANES_ORDER = 'save_swimlanes_order';
export const SAVE_SWIMLANE = 'save_swimlane';
export const SELECT_SWIMLANE = 'select_swimlane';
// PMD 14/04/21 - P1 S1 Remove reference to Admin
// export const LOCK_CERTIFICATES = 'lock_certificates';
export const SET_APPLICATION_LAYOUT = 'set_application_layout';
export const LOAD_FULL_APPLICATION_DETAILS = 'load_full_application_details';
export const CERTIFICATES_FORCE_UPDATE = 'certificates_force_update';

// New Redux Actions to replace Certificate actions above
export const FETCH_APPLICATIONS_REQUEST = 'FETCH_APPLICATIONS_REQUEST';
export const FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS';
export const FETCH_APPLICATIONS_ERROR = 'FETCH_APPLICATIONS_ERROR';
export const UPDATE_APPLICATION_TASK_REQUEST = 'UPDATE_APPLICATION_TASK_REQUEST';
export const UPDATE_APPLICATION_TASKS = 'UPDATE_APPLICATION_TASKS';
export const UPDATE_APPLICATION_TASK_ERROR = 'UPDATE_APPLICATION_TASK_ERROR';
export const UPDATE_SELECTED_APPLICATION_TASK = 'UPDATE_SELECTED_APPLICATION_TASK';
export const UPDATE_APPLICATION_COLORS = 'UPDATE_APPLICATIONS_COLORS';
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';
export const UPDATE_APPLICATION_COMPONENT_TYPE = 'UPDATE_APPLICATION_COMPONENT_TYPE';
export const CLEAR_APPLICATION_TASKS = 'CLEAR_APPLICATION_TASKS';

export const RESET_CREDITS = 'reset_credits';
export const SELECT_CREDITS = 'select_credits';
export const SET_CREDITS_ERROR = 'set_credits_error';
export const RESET_CREDITS_ERROR = 'reset_credits_error';
export const INCREASE_CREDITS_STEP = 'increase_credits_step';
export const DECREASE_CREDITS_STEP = 'decrease_credits_step';
export const LOAD_CATALOGUE_CREDITS = 'load_catalogue_credits';
export const LOAD_CORRESPONDENCE_ADDRESS = 'load_correspondence_address';

export const LOAD_TRANSACTIONS = 'load_transactions';
export const SELECT_INVOICE = 'select_invoice';
export const LOAD_INVOICE = 'load_invoice';
export const RESET_INVOICE = 'reset_invoice';
export const SET_TRANSACTION_ERROR = 'set_transaction_error';

export const LOAD_COVER = 'load_cover';
export const LOAD_SETTINGS = 'load_settings';
export const SAVE_SETTINGS = 'save_settings';
export const SET_SETTINGS_ERROR = "set_settings_error";

// User Maintenance
export const LOAD_USERS = 'load_users';
export const LOAD_APPLICANTS = 'load_applicants'
export const SELECT_APPLICANT = 'select_applicant';
export const SET_USER_ERROR = 'set_user_error';

// Team Maintenance
export const LOAD_TEAMS = 'load_teams';
export const SELECT_USER = 'select_user';
export const SET_TEAM_ERROR = 'set_team_error';
export const SET_RELOAD_TEAM = 'set_reload_team';

// Team Maintenance
export const LOAD_DEPTS = 'load_depts';
export const SELECT_DEPT_USER = 'select_dept_user';
export const SET_DEPT_ERROR = 'set_dept_error';
export const SET_RELOAD_DEPT = 'set_reload_dept';


// export const LOAD_DOCUMENTS = 'load_documents';
// export const LOAD_APPLICATION_INSTANCE = 'load_application_instance';
// export const LOAD_PARTY = 'load_party';
// export const LOAD_NOTICES = 'load_notices';
// export const LOAD_TASKS = 'load_tasks';
// export const LOAD_TASK_COMMENTS = 'load_task_comments';

export const LOAD_PANEL_COMPONENTS = 'load_panel_contents';

export const SET_DATA_CONFIG = 'set_data_config_variables';
export const CHANGE_TEAM_REQUEST = 'CHANGE_TEAM_REQUEST';
export const CHANGE_TEAM_ERROR = 'CHANGE_TEAM_ERROR';

export const SET_SIDEBAR_VIEWGUID = 'set_sidebar_view_guid';
// export const SET_VIEWGUID_MAP = 'set_view_guid_map';

// Header Maintenance
export const SET_HEADER_TITLE = 'set_header_title';
export const SET_REDIRECT = 'set_redirect_url';
export const SET_REDIRECT_COMPLETE = 'set_redirect_complete';

// Generic Error Handling
export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

// get-data-view
export const GET_DATAVIEW_REQUEST = 'GET_DATAVIEW_REQUEST';
export const GET_DATAVIEW_SUCCESS = 'GET_DATAVIEW_SUCCESS';
export const GET_DATAVIEW_ERROR = 'GET_DATAVIEW_ERROR';

// post-data-view
export const POST_DATAVIEW_REQUEST = 'POST_DATAVIEW_REQUEST';
export const POST_DATAVIEW_SUCCESS = 'POST_DATAVIEW_SUCCESS';
export const POST_DATAVIEW_ERROR = 'POST_DATAVIEW_ERROR';

// upload-files
export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';

// Bookmarks
export const SET_BOOKMARK = 'SET_BOOKMARK';

// get-config
export const GET_CATALOGUE_REQUEST = 'GET_CATALOGUE_REQUEST';
export const GET_CATALOGUE_SUCCESS = 'GET_CATALOGUE_SUCCESS';
export const GET_CATALOGUE_ERROR = 'GET_CATALOGUE_ERROR';
