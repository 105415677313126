import * as actions from './actionCreators';
import config from '../config';
import { postRequest, apiParse } from '../utils/Common';
import { errorHandler } from '../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

export const getCatalogue = (options) => {
    return (dispatch, getState) => {
        const URI = `${config[env].URL}/get-config`;
        const credentials = 'omit';
        const headers = {
            sessionid: getState().session.sessionId,
        };
        dispatch(actions.getCatalogueRequest());

        return postRequest(URI, options, credentials, headers)
            .then(apiParse)
            .then(response => dispatch(actions.getCatalogueSuccess(response)))
            .catch(err => {
                errorHandler(err)
                dispatch(actions.getCatalogueError(err))
            });
    };
};