import deepCopy from "../../../utils/deepCopy";
import {
    certificatePanelGUID,
    onboardingCertificateGUID,
} from "../../../utils/Constants";

const SaveScale = (props) => {

    const {
        layout, 
        scale,
        updatedScreenLayout,
        originalPanels,
        screenComponentGUID,
        screenAttributes,
        ribbon, 
        callback,
        sessionId,
        teamSpotliteAddress,
    } = props;

    const screenLayoutValues = [];

    // Use the useSelector to grab session Id 
    //const session = useSelector(state => state.session);
    //const teamSpotliteAddress = useSelector(state => state.dataConfig.allTeams.currentTeamDetails.spotliteAddress);
    
    //console.log(session);
    //console.log(teamSpotliteAddress);

    let storeNewLayout = [];

    let _layout = {};
    Object.values(layout).forEach(layout => {
      if (!_.isEmpty(layout)) {
        _layout = layout;
      }
    });

    for (let i = 0; i < _layout.length; i++) {
      storeNewLayout.push(_layout[i]);
    }

    // PMD 29/09/21 Take a copy of the original panels
    // let originalPanelsCopy = JSON.parse(JSON.stringify(this.state.originalPanels))
    let originalPanelsCopy = updatedScreenLayout.length === 0 ? deepCopy(originalPanels) : deepCopy(updatedScreenLayout);

    // loop through layouts
    storeNewLayout.map((lay) => {

      if (lay.i === certificatePanelGUID || lay.i === onboardingCertificateGUID) {
        lay.scale = scale;
      }

      // create an object that is saved when the back button is clicked on the screen
      let layId = lay.i;

      // PMD 28/09/21 Update Screen Definition
      let updatedPanelLocation = JSON.stringify(lay).replace(/["]/g, "~");

      let updatedPanel = originalPanelsCopy.filter(panel => {
        if (panel[Object.keys(panel)[0]].Attributes.ComponentGUID === layId) {
          panel[Object.keys(panel)[0]].Attributes.PanelLayout = updatedPanelLocation;
          return panel;
        }
      })

      screenLayoutValues.push(updatedPanel[0]);

      return screenLayoutValues;
    });

    // PMD 28/09/21 Create new object array for screen layouts with with following inputs
    /*
    TeamSpotliteAddress 
    Contact'sLanguage GUID (or ID)
    Screen viewGUID
    ScreenVersionNumber
    ScreenDefinitionJSON
    */
  
    let screenViewGUID = screenComponentGUID;
    // let screenViewGUID = this.props.viewGUID
    // let screenVersionNumber = 99999   // FIXME: Not currently supplied
    let screenDefinitionJSON = {};
    screenDefinitionJSON.Screen = {};
    screenDefinitionJSON.Screen.Attributes = screenAttributes;
    screenDefinitionJSON.Screen.ScreenRibbon = ribbon;
    screenDefinitionJSON.Screen.Panel = screenLayoutValues;

    let updatedScreenDefinition = { sessionId, teamSpotliteAddress, screenViewGUID, /* screenVersionNumber, */ screenDefinitionJSON };

    const callbackPayload = {
        updatedScreenLayout: screenLayoutValues,
        updatedScreenDefinition,
    };

    callback(callbackPayload);

};

export default SaveScale;

