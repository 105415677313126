import React, { Component } from 'react';
import { connect } from 'react-redux';

import { redirect, redirectComplete, updateApplicationLayout } from '../../actions';

import { Redirect } from 'react-router-dom';
import BusinessSearchResults from './BusinessSearchResults';
import BusinessSearchForm from './BusinessSearchForm';
import BusinessSearchConfirm from './BusinessSearchConfirm';
import config from '../../config';
import { postRequest, apiParse } from '../../utils/Common.js';
import { errorHandler } from '../../utils/errorHandler';

// import * as SampleCompanyProfileCDDM from '../../content/SampleCompanyProfileCDDM.json';

const env = process.env.NODE_ENV || 'development';

class BusinessSearch extends Component {

	constructor(props) {
		super(props);

		this.state = {
			department: '',
			searchResults: null,
			searchValue: null,
			selectedBusiness: null,
			regAddress: [],
			useRegisteredAddress: false,
			fullBusinessDetails: null,
			redirect: false,
			redirectPath: '',
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.setState({ redirect: false });

		const { componentType, label } = this.props.selectedApplicationLayout;

		if (typeof componentType === 'undefined' || componentType === '') {
			this.props.updateApplicationLayout({ componentType: "", label: "Business Search", isVisible: false });
		} else {
			this.props.updateApplicationLayout({ componentType: componentType, label: label, isVisible: false });
		}

	}

	setUseRegisteredAddress = value => {
		this.setState({ useRegisteredAddress: value });
	}

	setDefaultAddress = address => {

		this.props.setAddress(address);
		//	this.props.setRegisteredAddress(address);
		this.setUseRegisteredAddress(true);
	}

	setUnformattedAddress = (unformattedAddress) => {
		const companyName = this.state.selectedBusiness.name;
		const URI = `${config[env].URL}/address-lookup-fail`;
		const credentials = 'omit';
		const options = {
			companyName,
			address: unformattedAddress
		};

		postRequest(URI, options, credentials)
			.then(apiParse)
			.then(result => result)
			.catch(error => {
				// PMD 20/05/22 Generic error handler
				errorHandler(error)
				error
			})

		const address = [];
		address[0] = { addressLine1: unformattedAddress.street_address };
		address[1] = { addressLine2: null };
		address[2] = { addressLine3: null };
		address[3] = { locality: unformattedAddress.locality ? unformattedAddress.locality : null };
		address[4] = { province: unformattedAddress.region ? unformattedAddress.region : null };
		address[5] = { postalCode: unformattedAddress.postal_code ? unformattedAddress.postal_code : null };
		address[6] = { country: unformattedAddress.country ? unformattedAddress.country : null };

		return address;
	}

	handleResolveAddress = (address) => {
		this.props.setAddress(address);
		this.props.setRegisteredAddress(address);
		this.setUseRegisteredAddress(true);
		this.setState({
			regAddress: address,
			confirmSelectedBusiness: true,
			useRegisteredAddress: true
		});
	}

	retrieveDataFromAPI = (companyNumber) => {
		const URI = `${config[env].URL}/get-company-details`;
		const credentials = "omit";

		let options = {
			sessionId: this.props.sessionId,
			// FIXME: Hardcoded Jurisdiction
			jurisdiction: 'gb',
			companyNumber
		};

		postRequest(URI, options, credentials)
			.then(apiParse)
			.then(data => {

				this.setState({
					fullBusinessDetails: JSON.stringify(data)
				});

				// create the application
				return this.handleCreateApplication('OpenCorporates');
			})
			.catch(err => {
				// PMD 20/05/22 Generic error handler
				errorHandler(err)
				err.message || err
			});
	};


	getOpenCorporatesProfile = () => {

		this.retrieveDataFromAPI(this.state.selectedBusiness.company_number)

	};

	handleCreateApplication = (providerName) => {

		const URI = `${config[env].URL}/form-handler`;

		// console.log("PROVIDER", providerName)

		let sourceProvider = '';
		// PMD 24/02/21 Check whether we are using Experian or OpenCorporates or just creating an application 
		if (providerName === 'Experian' || providerName === 'OpenCorporates') {
			sourceProvider = providerName
		} else {
			sourceProvider = 'NoProviderSelected'
		}

		const options = {
			sessionId: this.props.sessionId,
			teamGUID: this.props.dataConfig.allTeams.currentTeamDetails.teamGUID,
			// FIXME: Hardcoded GUID
			viewGUID: 'f8733605-e08f-4675-9de6-8eec85e7f208',
			selectedBusiness: this.state.selectedBusiness,
			fullBusinessDetails: this.state.fullBusinessDetails, // may be null if full check was not run
			providerName: sourceProvider
		};

		const credentials = 'omit';

		postRequest(URI, options, credentials)
			.then(apiParse)
			.then(results => {
				// redirect to '/applications', application tasks will be reloaded
				// this.setState({ redirect: true, redirectPath: '/MyApplications' });
				this.setState({ redirect: true, redirectPath: this.props.bookmark.bookmarkPath });

			})
			.catch(err => {
				// PMD 20/05/22 Generic error handler
				errorHandler(err)
				err.message || err
			});
	}

	updateSearchResults = (searchData, searchValue) => {
		this.setState({
			// searchResults: JSON.parse(searchData)
			searchResults: searchData,
			searchValue
		});
	};

	updateSelectedBusiness = businessDetails => {
		this.setState({
			selectedBusiness: businessDetails
		});
	};

	clearSearchResults = () => {
		this.setState({
			searchResults: null,
			searchValue: null
		})
	};

	clearSelectedBusiness = () => {

		this.setState({
			selectedBusiness: null,
			confirmSelectedBusiness: false,
			regAddress: []
		});
	};

	render() {
		const {
			selectedBusiness,
			searchResults,
			searchValue
		} = this.state;

		//Redirect was causing a bug, look into how we redirect across the portal

		if (this.state.redirect) {
			return (
				<Redirect to={this.state.redirectPath} />
			)
		}

		if (selectedBusiness) {

			return (
				<BusinessSearchConfirm
					selectedBusiness={selectedBusiness}
					//updateConfirmBusinessSelection={this.updateConfirmBusinessSelection}
					// PMD 09/05/23 Use OpenCorporates to create Business application
					// handleCreateApplication={this.handleCreateApplication}
					handleBackButton={this.clearSelectedBusiness}
					// getCompanyProfile={this.getCompanyProfile}
					getOpenCorporatesProfile={this.getOpenCorporatesProfile}
				/>
			);
		}

		if (searchResults) {

			return (
				<BusinessSearchResults
					searchResults={searchResults}
					searchValue={searchValue}
					updateSelectedBusiness={this.updateSelectedBusiness}
					handleBackButton={this.clearSearchResults}
				/>
			);
		}

		return (

			<BusinessSearchForm
				updateSearchResults={this.updateSearchResults}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const { sessionId, dataConfig, redirectUrl } = state.session;
	const { selectedApplicationLayout } = state.applications;
	const bookmark = state.bookmark;
	return { sessionId, dataConfig, redirectUrl, selectedApplicationLayout, bookmark };
};

export default connect(mapStateToProps, { redirect, redirectComplete, updateApplicationLayout })(BusinessSearch);