import {
  SET_MOBILE_EMBEDDED,
  SET_SESSION_WORKING,
  SET_SESSION,
  LOGOUT,
  LOAD_SESSION_DETAILS,
  SET_SESSION_ERROR,
  REDIRECT,
  REDIRECT_COMPLETE,
  SET_DATA_CONFIG,
  SET_SIDEBAR_VIEWGUID,
  // SET_VIEWGUID_MAP, 
  SET_PORTAL,
} from './types';

// import { confirmAlert } from 'react-confirm-alert'
import { postRequest, getRequest, apiParseSpotlite, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';


export const setSessionWorking = (bool) => {
  return {
    type: SET_SESSION_WORKING,
    payload: bool
  };
};

export const setMobileEmbedded = (bool) => {
  return {
    type: SET_MOBILE_EMBEDDED,
    payload: bool
  };
};

export const sessionSet = (sessionId) => {
  return {
    type: SET_SESSION,
    payload: sessionId
  };
};

export const portalSet = (portalDetails) => {
  return {
    type: SET_PORTAL,
    payload: portalDetails
  };
}

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const setSessionError = (err) => {
  return {
    type: SET_SESSION_ERROR,
    payload: err
  };
};

export const setSidebarViewGUID = (viewGUID) => {

  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_VIEWGUID,
      payload: viewGUID
    });
  }
};

export const redirect = (url) => {
  return {
    type: REDIRECT,
    payload: url
  };
};

export const redirectComplete = () => {
  return {
    type: REDIRECT_COMPLETE
  };
};

// PMD 05/08/22 B2C Implementation
// export const login = ({ username, password }) => {

//   //console.log("LOGIN API CALLED");
//   return (dispatch, getState) => {

//     let URI = '';
//     let options = null;

//     // PMD 03/12/21 Login with password or invite code
//     if (isNaN(password)) {
//       URI = `${config[env].mainApiUrl}auth/LoginWithPassword`;
//       options = {
//         email: username,
//         p: password
//       };
//     } else {
//       URI = `${config[env].mainApiUrl}auth/LoginWithInviteCode`;
//       options = {
//         email: username,
//         invitecode: password
//       };
//     }
//     const credentials = 'omit';

//     return postRequest(URI, options, credentials)
//       .then(apiParseSpotlite)
//       .then(response => {
//         loginSuccess(dispatch, response);
//       })
//       .catch(err => {
//         // PMD 20/05/22 Add generic error handler
//         errorHandler(err)
//         loginFail(dispatch, err);
//       });
//   }
// };

export const login = ({ username, password, sessionDetails }) => {

  //console.log("LOGIN API CALLED");
  return (dispatch, getState) => {

    let URI = '';
    let options = null;

    // PMD 05/08/22 B2C Implementation. If b2c login used return the retrieved details
    if (sessionDetails) return loginSuccess(dispatch, sessionDetails)

    // PMD 03/12/21 Login with password or invite code
    if (isNaN(password)) {
      URI = `${config[env].mainApiUrl}auth/LoginWithPassword`;
      options = {
        email: username,
        p: password
      };
    } else {
      URI = `${config[env].mainApiUrl}auth/LoginWithInviteCode`;
      options = {
        email: username,
        invitecode: password
      };
    }
    const credentials = 'omit';

    return postRequest(URI, options, credentials)
      .then(apiParseSpotlite)
      .then(response => {
        loginSuccess(dispatch, response);
      })
      .catch(err => {
        // PMD 20/05/22 Add generic error handler
        errorHandler(err)
        loginFail(dispatch, err);
      });
  }
};

const loginSuccess = (dispatch, response) => {
  //console.log("LOGIN SUCCESS");
  dispatch({
    type: SET_SESSION,
    payload: response.SessionId
  });
};

const loginFail = (dispatch, err) => {
  dispatch({
    type: SET_SESSION,
    payload: ''
  });
  dispatch({
    type: SET_SESSION_WORKING,
    payload: false
  });

  dispatch({
    type: SET_SESSION_ERROR,
    payload: err.message
  });
};

export const loadDataConfig = () => {

  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      const URI = `${config[env].URL}/get-permissions`;

      const headers = {
        'Content-Type': 'application/json',
        'sessionid': getState().session.sessionId,
        'appkey': process.env.REACT_APP_APP_KEY,
        'portaltype': getState().session.portalDetails.portalType
      };

      // console.log("loadDataConfig ->> ", headers)

      const credentials = 'omit';

      return getRequest(URI, credentials, headers)
        .then(apiParse)
        .then(response => {
          dispatch({
            type: SET_DATA_CONFIG,
            payload: response
          });
        })
        .catch((err) => {
          console.log(err)
          // PMD 20/05/22 Add generic error handler
          errorHandler(err)
          // const title = "Error";
          // const msg = `Problem loading Configuration information. Please contact support error: ${err.message}`;
          // confirmAlert({
          //   title: title,
          //   message: msg,
          //   buttons: [
          //     {
          //       label: "OK",
          //       onClick: () => { /* changes saved */ },
          //     },
          //   ],
          //   closeOnEscape: false,
          //   closeOnClickOutside: false,
          // });
          dispatch({
            type: SET_DATA_CONFIG,
            payload: []
          });
          if (err.message.includes("Session expired")) {
            dispatch({
              type: SET_SESSION,
              payload: ''
            });
          }
        });
    }
  }
}

export const loadSessionDetails = ({ redirectUrl }) => {

  return (dispatch) => {
    // PMD 09/12/21 remove sessionDetails
    //   dispatch({
    //   type: LOAD_SESSION_DETAILS,
    //   payload: {}         // Just return an empty object whilst code is removed
    // });
    dispatch({
      type: SET_SESSION_WORKING,
      payload: false
    });
    dispatch({
      type: REDIRECT,
      payload: redirectUrl
    });
  }
}
// PMD 08/12/21 Remove API call
// export const loadSessionDetails = ({ redirectUrl }) => {

//   return (dispatch, getState) => {
//     const URI = `${config[env].URL}/client-portal-check-pin-activated?sessionId=${getState().session.sessionId}`;
//     const credentials = 'omit';

//     const headers = {};
//     return getRequest(URI, credentials, headers)
//       .then(apiParse)
//       .then(response => {
//         dispatch({
//           type: LOAD_SESSION_DETAILS,
//           payload: response
//         });
//         dispatch({
//           type: SET_SESSION_WORKING,
//           payload: false
//         });
//         dispatch({
//           type: REDIRECT,
//           payload: redirectUrl
//         });

//       })
//       .catch(err => {

//         dispatch({
//           type: SET_SESSION_ERROR,
//           payload: err.message
//         });
//         dispatch({
//           type: SET_SESSION_WORKING,
//           payload: false
//         });
//         dispatch({
//           type: LOGOUT
//         });
//       });
//   }
// };


export const verifyICO = ({ code, redirectUrl }) => {

  return async (dispatch, getState) => {

    //Name of the service used to check the url
    // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-validate-pin`;
    // const options = {
    //   sessionId: getState().session.sessionId,
    //   icoCode: String(code)
    // };
    // const credentials = 'omit';

    //console.log(options);

    // try {
    //   const apiParse = await postRequest(URI, options, credentials);
    //   const response = await apiParse(apiParse);

    //   //update the full access flag
    //   dispatch({
    //     type: LOAD_SESSION_DETAILS,
    //     payload: response
    //   });

    //   //redirect so page reloads
    //   dispatch({
    //     type: REDIRECT,
    //     payload: redirectUrl
    //   });
    // }
    // catch (err) {
    //   dispatch({
    //     type: SET_SESSION_ERROR,
    //     payload: err.message
    //   });
    // }

  }

};

export const verifyClientPortal = ({ code, redirectUrl }) => {

  return (dispatch, getState) => {

    const URI = `${config[env].URL}/client-portal-validate-pin`;
    const options = {
      sessionId: getState().session.sessionId,
      pinToken: Number(code)
    };
    const credentials = 'omit';
    return postRequest(URI, options, credentials)
      .then(apiParse)
      .then(response => {

        //update the full access flag
        // PMD 09/12/21 remove sessionDetails
        // dispatch({
        //     type: LOAD_SESSION_DETAILS,
        //     payload: response
        //   });

        //redirect so page reloads
        dispatch({
          type: REDIRECT,
          payload: redirectUrl
        });

      })
      .catch(err => {
        // PMD 20/05/22 Add generic error handler
        errorHandler(err)
        dispatch({
          type: SET_SESSION_ERROR,
          payload: err.message
        });

      });
  }
};