import deepCopy from "../../../utils/deepCopy";
import { useSelector } from 'react-redux';
import {
    certificatePanelGUID,
    onboardingCertificateGUID,
} from "../../../utils/Constants";

const SaveLayout = (props) => {

    //placeholder, e, and element, not currently used

    const {
        layout,
        scale, 
        oldItem, 
        newItem, 
        placeholder, 
        e, 
        element,
        sessionId,
        teamSpotliteAddress,
        updatedScreenLayout,
        originalPanels,
        screenComponentGUID,
        screenAttributes,
        ribbon,
        callback, 
    } = props;

    //Can only call a hook in a body of a function component, SaveLayout is still in the body of a class component.
    // const session = useSelector(state => state.session);
    //console.log(props);

    const screenLayoutValues = [];

    if (
        oldItem.w !== newItem.w ||
        oldItem.h !== newItem.h ||
        oldItem.x !== newItem.x ||
        oldItem.y !== newItem.y
      ) {
        let storeNewLayout = [];
  
        for (let i = 0; i < layout.length; i++) {
          let newItem = layout[i];
          let newLayout = { ...oldItem };
          newLayout.i = newItem.i;
          newLayout.w = newItem.w;
          newLayout.h = newItem.h;
          newLayout.x = newItem.x;
          newLayout.y = newItem.y;
          storeNewLayout.push(newLayout);
        }
  
        // PMD 29/09/21 Take a copy of the original panels
        let originalPanelsCopy = updatedScreenLayout.length === 0 ? deepCopy(originalPanels) : deepCopy(updatedScreenLayout)
  
        // loop through layouts
        storeNewLayout.map((lay) => {
  
          if (lay.i === certificatePanelGUID || lay.i === onboardingCertificateGUID) {
            lay.scale = scale;
          }
  
          // create an object that is saved when the back button is clicked on the screen
          let layId = lay.i
          // console.info(lay)
  
          // PMD 28/09/21 Update Screen Definition
          let updatedPanelLocation = JSON.stringify(lay).replace(/["]/g, "~")
          // console.log(originalPanelsCopy)
  
            let updatedPanel = originalPanelsCopy.filter(panel => {
                let panelComponents = panel[Object.keys(panel)[0]]
                if (panelComponents.Attributes.ComponentGUID === layId) {
                    panelComponents.Attributes.PanelLayout = updatedPanelLocation
                    return panel
                }
            })
  
            screenLayoutValues.push(updatedPanel[0])
            // console.info(screenLayoutValues)
            return screenLayoutValues;
        });
    }
  
   

    // PMD 28/09/21 Create new objectarray for screen layouts with with following inputs
    /*
    teamSpotliteAddress 
    screenViewGUID
    screenVersionNumber
    screenDefinitionJSON
    */
      
    // let screenViewGUID = this.props.viewGUID
    let screenViewGUID = screenComponentGUID
    // let screenVersionNumber = 99999   // Not currently supplied

    let screenDefinitionJSON = {};
    screenDefinitionJSON.Screen = {}
    screenDefinitionJSON.Screen.Attributes = screenAttributes
    screenDefinitionJSON.Screen.ScreenRibbon = ribbon
    screenDefinitionJSON.Screen.Panel = screenLayoutValues

    let updatedScreenDefinition = { sessionId, teamSpotliteAddress, screenViewGUID, /* screenVersionNumber, */ screenDefinitionJSON }

    

    const callbackPayload = {
        updatedScreenLayout: screenLayoutValues,
        updatedScreenDefinition,
    }

    callback(callbackPayload);

}

export default SaveLayout;