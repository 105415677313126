import React, { Component } from "react";
import { connect } from "react-redux";

import {
  logout,
  loadCatalogueCredits,
  // certificatesForceUpdate,
  updateApplicationLayout,
  setBookmark,
  updateApplicationTaskColors,
  fetchApplications,
} from "../../actions";

import _, { isEmpty } from "lodash";

import Loader from "../Loader/Loader";

import SubHeaderApplicationSearch from "../Header/SubHeaderApplicationSearch";
import CertificateCalendar from "./CertificateCalendar";
import ApplicationStats from "./ApplicationStats";
import KanbanLayout from "./KanbanLayout";

import DashboardPanels from "../Panels/DashboardPanel/DashboardPanels";
import NewDataGridController from "../Grid/NewDataGridController";

import { kanbanboardGUID, calendarGUID, summaryGUID, listscreenGUID } from "../../utils/Constants"

class LayoutController extends Component {

  constructor(props) {
    super(props);

    this.state = {
      componentType: "",
      label: "",
      isActive: false,
      isLoading: true,
      componentTypeViewGUID: ""
    };
  }

  componentDidMount() {
    // console.log(this.props.portalDetails.portalType)
    
    if (this.props.sessionId) {
      // FIXME: Handle Session expired
      // console.log(this.props.sessionId)
      //fetch application
      this.props.fetchApplications("")
      this.props.loadCatalogueCredits();
    }

    if (this.props.portalDetails.portalType === 'applicant') {
      this.setState({ componentType: 'Applicant' });
    }
    this.setState({ isLoading: false })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookmark.bookmarkGUID !== this.props.bookmark.bookmarkGUID) {
      // console.log('LayoutController - bookmark: ', prevProps.bookmark.bookmarkGUID, this.props.bookmark.bookmarkGUID);
      this.props.fetchApplications("");
    }

    if (prevProps.applicationsLoading !== this.props.applicationsLoading) {
      this.setState({ isActive: this.props.applicationsLoading });
    }

    // if (this.props.forceUpdate) {
    //   this.props.certificatesForceUpdate(false);
    // }

    if (prevProps.allTeams.currentTeamDetails.spotliteAddress !== this.props.allTeams.currentTeamDetails.spotliteAddress) {
      //console.log("All Teams", prevProps.allTeams.currentTeamDetails.spotliteAddress, this.props.allTeams.currentTeamDetails.spotliteAddress);
      if (this.props.sessionId) {
        this.props.fetchApplications("");
      }
      // if (this.props.portalDetails.portalType === 'applicant') {
      //   this.setState({ componentType: 'Applicant' });
      // }
    }

    if (prevProps.selectedApplicationLayout !== this.props.selectedApplicationLayout) {

      const { componentType, label } = this.props.selectedApplicationLayout;
      const { ToolBarOptions } = this.props.dataConfig.MyTaskScreen;

      if (typeof componentType !== 'undefined' && typeof label !== 'undefined') {
        if (isEmpty(componentType) && !isEmpty(ToolBarOptions)) {
          this.props.updateApplicationLayout({ componentType: ToolBarOptions[0].viewGUID, label: label, isVisible: true });
        } else {
          this.setState({ componentTypeViewGUID: componentType, label: label });
        }

      }
    }
  }

  onLogout() {
    this.props.logout();
  }

  renderKanban = () => {

    if (this.state.componentTypeViewGUID !== kanbanboardGUID) return;

    return <KanbanLayout />;

  }

  renderGrid = () => {

    const { applications } = this.props;

    if (this.state.componentTypeViewGUID !== listscreenGUID) return;

    if (!applications.Screens) return;

    return (
      <>
        {(this.state.componentTypeViewGUID === listscreenGUID && _.isEmpty(applications.Screens))
          ? <h2><strong><center>No information to display for the selected team</center></strong></h2>
          : <NewDataGridController applySearchCriteria={true} />}
      </>
    );
  };

  renderCalendar = () => {
    if (this.state.componentTypeViewGUID !== calendarGUID) return;

    return <CertificateCalendar />;
  };

  renderStats = () => {
    if (this.state.componentTypeViewGUID !== summaryGUID) return;

    return <ApplicationStats />;
  };

  renderApplicant = () => {

    const { allTeams, applications } = this.props
    const { currentTeamDetails } = allTeams
    if (this.state.componentType !== "Applicant" || isEmpty(applications)) return

    return (
      <div>
        <DashboardPanels
          viewGUID={applications.viewGUID}
          applicationInstanceGUID={currentTeamDetails.teamGUID}
          segmentSpotliteAddress={currentTeamDetails.spotliteAddress}
          dataSentToCertificatesPanel={this.getDataFromDashboardPanels}
        />
      </div>
    )

  }

  getDataFromDashboardPanels = () => {
    // dummy function
  }

  render() {
  
    const { isLoading } = this.state

    if (isLoading) return null

    const { portalDetails } = this.props

    // PMD 22/12/21 Applicant
    let subHeaderSearch, screensToLoad;
    if (portalDetails.portalType !== 'applicant') {
      subHeaderSearch = <SubHeaderApplicationSearch />
      screensToLoad = <>
        {this.renderKanban()}
        {this.renderGrid()}
        {this.renderCalendar()}
        {this.renderStats()}
      </>
    } else {
      subHeaderSearch = <></>
      screensToLoad = <>{this.renderApplicant()}</>
    }
    return (
      <div style={{ display: 'contents' }}>

        {subHeaderSearch}
        {/* <SubHeaderApplicationSearch /> */}

        <Loader
          className="main_layout_loader"
          active={this.state.isActive}
        />

        {screensToLoad}

        {/* {this.renderKanban()}
        {this.renderGrid()}
        {this.renderCalendar()}
        {this.renderStats()} */}

        {/* PMD 22/12/21 */}
        {/* {this.renderApplicant()} */}

      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const { sessionId, portalDetails, dataConfig } = state.session;

  const { allTeams } = state.session.dataConfig;

  const {
    loading,
    // forceUpdate,
  } = state.certificates;

  const {
    ApplicationTasks,
  } = state.applications.applications;

  const {
    applications,
    searchCriteria,
    selectedApplicationLayout,
    loaderActive,
    applicationsLoading,
  } = state.applications;
  
  const bookmark = state.bookmark;
  // const {
  //   layout,
  // } = state.applications.selectedApplicationLayout;

  return {
    sessionId,
    dataConfig,
    allTeams,
    ApplicationTasks,
    loading,
    selectedApplicationLayout,
    searchCriteria,
    // forceUpdate,
    applications,
    loaderActive,
    applicationsLoading,
    bookmark,
    portalDetails,
  };
};

export default connect(mapStateToProps, {
  logout,
  loadCatalogueCredits,
  // certificatesForceUpdate,
  updateApplicationLayout,
  setBookmark,
  updateApplicationTaskColors,
  fetchApplications,
})(LayoutController);
