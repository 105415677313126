import React from 'react'
import { connect } from "react-redux";

const DisplayedItem = (props) => {
  return (
    // TODO: Conditional colour for alert styles
    <p  style={{color: "#4D4F5C", fontSize: "1.2em", margin: "0", padding: "10px"}}>
        {props.item}
    </p>
  )
}

const mapStateToProps = (state, ownProps) => {
  const panelData = state.dashboard.panels[ownProps.panelGuid];
  return { panelData };
};
export default connect(mapStateToProps, {})(DisplayedItem);
