import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Redirect } from "react-router";

import {
    // selectInvoice,
    // loadTransactions,
    // setTransactionError,
    // resetInvoice,
    // updateApplicationLayout,
    // getCatalogue
} from '../../actions';
import DashboardPanels from '../Panels/DashboardPanel/DashboardPanels';

class MaintainSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            viewGUID: "",
            currentTeamDetails: {},
        }
    }

    componentDidMount() {
        //console.log(this.props)
        const { dataConfig, match } = this.props
        const { allTeams, AvatarMenu } = dataConfig
        const { currentTeamDetails } = allTeams
        //console.log(currentTeamDetails)
        //console.log(match.path)

        if (this.props.sessionId && this.props.dataConfig) {
            // Retrieve the ViewGUID to pass
            let viewGUID;
            const menuItem = AvatarMenu.find(menu => menu.RouterPath === match.path)
            //console.log(menuItem)

            this.setState({ isLoading: false, viewGUID: menuItem.viewGUID, currentTeamDetails: currentTeamDetails })
        }
    }

    render() {

        const { isLoading, viewGUID, currentTeamDetails } = this.state

        if (isLoading) return null

        console.log(viewGUID)

        return (
            <DashboardPanels
                viewGUID={viewGUID}
                applicationInstanceGUID={currentTeamDetails.teamGUID}
                segmentSpotliteAddress={currentTeamDetails.spotliteAddress}
            // dataSentToCertificatesPanel={this.getDataFromDashboardPanels}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const { sessionId, dataConfig } = state.session;
    // const { transactions, invoiceId, error } = state.transactions;
    // const { catalogueData, catalogueLoading, catalogueError } = state.catalogue;
    // return { sessionId, dataConfig, transactions, invoiceId, error, catalogueData, catalogueLoading, catalogueError };
    return { sessionId, dataConfig };
};

export default connect(mapStateToProps, {
    // selectInvoice,
    // loadTransactions,
    // setTransactionError,
    // resetInvoice,
    // updateApplicationLayout,
    // getCatalogue
})(MaintainSettings);

