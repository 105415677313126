import _, { inRange } from 'lodash';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

export default function DisplayDataGrid({ colMap, gridLayout, rowData, selectRow, gridLayoutChanged }) {

    const originalGridLayout = _.cloneDeep(gridLayout)
    //console.log(gridLayout)
    const ROW_HEIGHT = gridLayout.RowHeight
    const [rows, setRows] = useState(rowData);

    const [columns, setColumns] = useState(gridLayout.columns);


    // console.log({ key: gridLayout.columns[0].key, name: gridLayout.columns[0].name })

    if (rows !== rowData) {
        setRows(rowData);
    }

    const [[sortColumn, sortDirection], setSort] = useState([gridLayout.columns[0].key, 'NONE']);
    
    const sortedRows = useMemo(() => {

        if (sortDirection === 'NONE') return rows;

        let sortedRows = [...rows];

        switch (colMap.get(sortColumn)) {
            case 'string':
            case 'LookUpTable':
            case 'DateAndTime':
            case 'Date':
                // console.log('sortColumn propertyType is ', colMap.get(sortColumn));
                sortedRows = sortedRows.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]));
                break;
            case 'number':
                // console.log('sortColumn propertyType is ', colMap.get(sortColumn));
                sortedRows = sortedRows.sort((a, b) => a[sortColumn] - (b[sortColumn]));
                break;
            default:
                break;
        }

        return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;

    }, [rows, sortDirection, sortColumn, colMap]);

    const handleSort = useCallback((columnKey, direction) => {
        setSort([columnKey, direction]);
    }, []);

    // Callback function that has been passed to row that was clicked on
    const rowClicked = (params) => {
        //console.log("params", params);
        selectRow(params.row.id, params.row, params.column);
    };

    const emptyRowsRenderer = () => {
        return (
            <div style={{ marginTop: 10, fontSize: 20, textAlign: "center" }}>
                No records to display
            </div>
        );
    }

    // PMD 11/01/22 - Resize columns
    const handleColumnResize = (index, width) => {

        //const column = originalGridLayout.columns[index];
        const column = correctedColumns[index];
        const colKey = column.key;
        let col = originalGridLayout.columns.find(x => x.key == colKey);
        col.width = Math.round(width).toString();
        gridLayoutChanged([...originalGridLayout.columns])
        // gridLayoutChanged(gridLayout)
    }

    //filter columnHeadings
    const filteredHeadings = originalGridLayout.columnHeadings.filter(item => item.CRUDAction === 'R');
    //sort columnHeadings by view sequence
    const sortHeadings = filteredHeadings
    .sort((a, b) => {
        return a.viewSeq - b.viewSeq;
    });

    //construnct the correct column list
    let correctedColumns = [];

    sortHeadings.forEach(heading => {
        if(columns.find(column => column.propertyGUID === heading.propertyGUID)){
            correctedColumns.push(columns.filter(column => column.propertyGUID === heading.propertyGUID)[0]);
        }
    })

    const rowKeyGetter = (row) => {
        return row.id;
    }

    return (
        <>
            <DataGrid
                className="datagrid"
                rowKey="id"
                rowHeight={ROW_HEIGHT}
                columns={correctedColumns}
                onColumnResize={(idx, width) => handleColumnResize(idx, width)}
                rows={sortedRows}
                sortDirection={sortDirection}
                onSort={handleSort}
                emptyRowsRenderer={emptyRowsRenderer}
                onCellClick={rowClicked}
                rowKeyGetter={rowKeyGetter}
            />
        </>
    )
}
