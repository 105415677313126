import React, { Component } from "react";
import { connect } from "react-redux";
import _, { isEmpty } from "lodash";
import CreatableSelect from 'react-select/creatable';
import {
  loadCatalogueCredits,
  loadDataConfig,
  updateSearchCriteria,
  updateApplicationLayout,
} from "../../actions";
import GetFormData from "../Form/GetFormData";
import RenderModalForm from "../Form/RenderModalForm";
import ButtonContext from "../Context/ButtonContext";

import { confirmAlert } from "react-confirm-alert";

import GlobalSubHeader from './GlobalSubHeader';

import "react-confirm-alert/src/react-confirm-alert.css";
import { Redirect } from "react-router-dom";
import { newBusinessSearch, newWorkQueueGUID } from "../../utils/Constants";

import { saveToDB } from "../../utils/DatabaseFunctions";

import LayoutControllerTabs from "../Reusable/LayoutControllerTabs";
// import dummyData from "./dummy_section1";
// PMD 29/03/22 - Screen Ribbon
import dummyScreenRibbon from "./screenribbon_dummy1.json";
import ScreenRibbon from "../Reusable/ScreenRibbon";
import Loader from "../Loader/Loader";
import { errorHandler } from "../../utils/errorHandler";


const createOption = (label) => ({
  label,
  value: label,
});

class SubHeaderApplicationSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoadingService: true,
      buttonActive: false,
      buttonViewGUID: "",
      modalOpen: false,
      nextModalOpen: false,
      modalScreen: "",
      lobCode: "",
      lobName: "",
      serviceCode: "",
      serviceName: "",
      data: [],
      searchInputValue: '',
      redirect: false,
      header: "",
      isSaving: false,
    };
  }

  componentDidMount() {

    const { componentType, label } = this.props.selectedApplicationLayout;
    const { ToolBarOptions } = this.props.dataConfig.MyTaskScreen;

    // console.log(componentType)

    if (typeof componentType === 'undefined' || componentType === '') {
      // this.props.updateApplicationLayout({ componentType: "KanbanBoard", label: "My Account", isVisible: true });
      this.props.updateApplicationLayout({ componentType: ToolBarOptions[0].viewGUID, label: ToolBarOptions[0].Label, isVisible: true });
    } else {
      this.props.updateApplicationLayout({ componentType: componentType, label: label, isVisible: true });
    }

    if (!isEmpty(this.props.dataConfig)) {
      if (this.props.dataConfig.MyTaskScreen !== null)
        this.buildServiceList();
    }

    // }
  };

  componentDidUpdate(prevProps) {

    if (prevProps.dataConfig !== this.props.dataConfig) {
      if (this.props.dataConfig.MyTaskScreen !== null)
        this.buildServiceList();
    }

    if (prevProps.postViewLoading !== this.props.postViewLoading) {
      if (!this.props.postViewLoading && this.props.postViewData.Screen) {
        this.setState({ nextModalOpen: true });
      }
    }
  };

  buildServiceList() {

    if (typeof this.props.MyTaskScreen === 'undefined') {
      return;
    }

    // PMD 29/03/22 Screen Ribbon
    // const { TeamLoBMenu } = this.props.MyTaskScreen;
    // const TeamLoBMenu = dummyScreenRibbon.result.MyTaskScreen.TeamLoBMenu
    // PMD 29/03/22 FIXME: Use dummy json
    // const ScreenRibbon = dummyScreenRibbon.result.MyTaskScreen.ScreenRibbon
    const { ScreenRibbon } = this.props.MyTaskScreen;

    if (ScreenRibbon === null)
      // PMD 29/03/22 Screen Ribbon
      // if (TeamLoBMenu === null)
      return;

    if (typeof ScreenRibbon === 'undefined')
      // PMD 29/03/22 Screen Ribbon
      // if (typeof TeamLoBMenu === 'undefined')
      return;

    // console.log("DUMMY JSON", ScreenRibbon)

    // const dataArray = Array.from(TeamLoBMenu.linesofbusiness.map(options => options));

    this.setState({
      data: ScreenRibbon,
      isLoadingService: false
    });

  }

  selectComponent = (componentType, label) => {
    this.props.updateApplicationLayout({ componentType, label, isVisible: true });
  }

  onClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  cancelModal = () => {
    this.setState({
      modalOpen: false
    });
  };

  onCloseNext = (str) => {
    // console.log('SubHeaderApplicationSearch OnCloseNext (Modal), str=', str);
    this.setState({
      nextModalOpen: false
    });
  };

  deActivateButtonContext = () => {
    this.setState({
      buttonActive: false
    });
  };

  enoughCredit(item) {

    // console.log(item)
    // FIXME: Using hard coded JSON

    const { CatalogueCode } = this.props.dataConfig;
    let currentBalance = 0;
    if (this.props.catalogues) {
      currentBalance = this.getCurrentBalance(CatalogueCode);
    }
    // console.log(currentBalance)

    if (currentBalance >= item.Credits) {
      this.setState({
        modalOpen: true,
        modalScreen: item.viewGUID,
        serviceName: item.Name,
        serviceCode: item.code,
        // lobName: dataset.lobname,
        // lobCode: dataset.lobcode,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            //customUI so we can adjust the positions of the buttons
            <div className='react-confirm-alert-body'>
              <h1>Warning</h1>
              <p>You do not have enough credits for this service.</p>
              <div className='react-confirm-alert-button-group'>
                <button style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    onClose();
                  }}>
                  OK
                </button>
              </div>
            </div>
          );
        },
        closeOnEscape: true,
        closeOnClickOutside: false,
      });
    }
  }

  checkCredit = (item) => {

    // FIXME: This will need changing when JSON response is updated

    // console.log(item)

    if (item.Context === 'Redirect' || item.Context === 'Application') {
      this.setState({ redirect: true, redirectPath: item.RouterPath });
    } else if (item.Context === 'Modal') {
      this.enoughCredit(item);
    } else if (item.Context === 'Button') {
      // HERE we need to call a component to perform the required action.
      this.setState({ 
        buttonActive: true,
        buttonViewGUID: item.viewGUID
      });
    } else if (item.Context === 'Post') {
      const { currentTeamDetails } = this.props.allTeams;

      //Check KanbanLayout for more details, there may not be a segmentSpotliteAddress in this use case
      // segmentSpotliteAddress: newSegments[newApplicationTask.segmentGUID].spotliteAddress,
      //this.props.selectedCertificate.applicationId - we don't select any certificates

      //Empty object is spotlite object, empty string is segementSpotliteAddress

      // Add Spinner
      this.setState({ isSaving: true })
      return saveToDB(this.props.sessionId, item.viewGUID, currentTeamDetails.teamGUID, currentTeamDetails.teamGUID, {}, ""
      ).then((response) => {
        if (response.ViewResponse.Status == 'OK') {
          this.setState({ isSaving: false })
          this.renderAlertDialog("Success", "Catalogue has successfully been published.");
        }
      }).catch((err) => {
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        this.setState({ isSaving: false })
        // this.renderAlertDialog("Error", err);
      });
    }

  }


  renderAlertDialog(title, message) {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "OK",
          onClick: () => { /* changes saved */ },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  getCurrentBalance = (CatalogueCode) => {
    const entry = _.find(this.props.catalogues, { catalogueCode: CatalogueCode });
    return entry ? entry.creditBalance : 0;
  };

  renderModal = () => {
    // console.log('Rendering Modal --- this.state.modalOpen=', this.state.modalOpen);
    // console.log('Rendering Modal --- this.state.nextModalOpen=', this.state.nextModalOpen);
    const { currentTeamDetails } = this.props.allTeams;
    if (this.state.modalOpen) {
      return (
        <GetFormData
          open={this.state.modalOpen}
          closeModal={this.onClose}
          cancelModal={this.cancelModal}
          screenType={this.state.modalScreen}
          ServiceName={this.state.serviceName}
          ServiceCode={this.state.serviceCode}
          LoBName={this.state.lobName}
          LoBCode={this.state.lobCode}
        />
      );
    } else if (this.state.nextModalOpen) {
      return (
        <RenderModalForm
          open={this.state.nextModalOpen}
          onClose={this.onCloseNext}
          modalScreen={true}
          closeModal={this.onCloseNext}
          segmentSpotliteAddress={''}
          viewLoading={this.props.postViewLoading}
          viewData={this.props.postViewData}
        />
      );
    } else {
      return null;
    }
  };

  renderButtonContext = () => { 
    if (this.state.buttonActive) {

      // console.log(this.state.buttonActive, this.state.buttonViewGUID);

      return (
        <ButtonContext
          buttonViewGUID={this.state.buttonViewGUID}
          deActivateButtonContext={this.deActivateButtonContext}
        />
      )
    }
  }

  // 29/03/22 Pass the viewGUID
  launchModal = (viewGUID) => {
    // launchModal = () => {
    let servicecode = (viewGUID === newWorkQueueGUID ? 'CreateWorkQueue' : '')


    this.setState({
      modalOpen: true,
      modalScreen: viewGUID,
      serviceName: '',
      serviceCode: servicecode,
      lobName: '',
      lobCode: '',
    });

  };

  handleSearchChange = (value, actionMeta) => {

    this.props.updateSearchCriteria(
      value || [],
    );

  };

  handleSearchInputChange = (value) => {

    this.setState({
      searchInputValue: value
    })
  };

  handleSearchKeyDown = (event) => {

    const { searchInputValue } = this.state;

    if (!searchInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':

        this.props.updateSearchCriteria(
          [...this.props.searchCriteria, createOption(searchInputValue)]
        )
        this.setState({
          searchInputValue: ''
        })
        event.preventDefault();
        break;
      default:

    }
  };

  renderDropDownMenu() {

    if (isEmpty(this.state.data)) return

    return (
      <>
        <ScreenRibbon screenRibbon={this.state.data} onItemClick={this.checkCredit} handleClick={this.launchModal} />

        <Loader
          className="loader__spinner"
          active={this.state.isSaving}
        />
      </>
    )

  }

  renderSubHeaderToolbar() {

    const { componentType } = this.props.selectedApplicationLayout;
    const { dataConfig } = this.props

    return (
      <div className="sub_header_content">
        <div className="sub_header_container_one">
          {/* PMD 12/04/22 Remove hardcoded JSON */}
          {/* <LayoutControllerTabs section={dummyData} componentType={componentType} callback={this.selectComponent} /> */}
          <LayoutControllerTabs section={dataConfig.MyTaskScreen.ToolBarOptions} componentType={componentType} callback={this.selectComponent} />
        </div>

        <div className="sub_header_container_two">

          <div className="search_width">

            <CreatableSelect
              isClearable={true}
              isMulti={true}
              id="search"
              components={{ DropdownIndicator: null }}
              inputValue={this.state.searchInputValue}
              menuIsOpen={false}
              onInputChange={this.handleSearchInputChange}
              onKeyDown={this.handleSearchKeyDown}
              placeholder="Filter..."
              value={this.props.searchCriteria}
              onChange={this.handleSearchChange} />
          </div>

          {this.renderDropDownMenu()}
        </div>
      </div>
    );
  }

  render() {

    //const { isLoading, isLoadingService } = this.state;

    if (this.state.redirect) {
      return <Redirect to='/search' />;
    }

    if (this.state.searchInputValue === null) return null;
    // PMD 09/12/21 Remove sessionDetails
    // if (this.props.sessionDetails === null) return null;
    const { dataConfig } = this.props
    if (isEmpty(dataConfig)) return null;

    // console.log(dataConfig)

    if (!dataConfig || dataConfig.length === 0 || !dataConfig.MyTaskScreen) return null

    return (

      <GlobalSubHeader>
        {this.renderModal()}
        {this.renderButtonContext()}
        {this.renderSubHeaderToolbar()}
      </GlobalSubHeader>

    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId, /* sessionDetails, */ dataConfig } = state.session;
  const { MyTaskScreen, allTeams } = state.session.dataConfig;
  const { catalogues } = state.credits;
  const { searchCriteria, selectedApplicationLayout } = state.applications;
  const { postViewData, postViewLoading, postViewError } = state.postView;

  return {
    sessionId,
    // sessionDetails,
    dataConfig,
    MyTaskScreen,
    allTeams,
    searchCriteria,
    selectedApplicationLayout,
    catalogues,
    postViewData,
    postViewLoading,
    postViewError
  };
};

export default connect(mapStateToProps, {
  loadCatalogueCredits,
  loadDataConfig,
  updateSearchCriteria,
  updateApplicationLayout,
})(SubHeaderApplicationSearch);
