import { LOAD_TEAMS, SELECT_USER, SET_TEAM_ERROR, SET_RELOAD_TEAM } from '../actions/types';

const INITIAL_STATE = { 
  teams: [],
  selectedUser: {},
  reload: false,
  error: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_TEAMS:
      return { ...state, teams: action.payload };
    case SELECT_USER:
      return { ...state, selectedUser: action.payload };
    case SET_TEAM_ERROR:
      return { ...state, error: action.payload };
    case SET_RELOAD_TEAM:
      return { ...state, reload: action.payload };
    default:
      return state;
  }
};
