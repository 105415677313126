import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  selectCredits,
  setCreditsError,
  resetCreditsError,
  resetCredits,
  creditsRedirect,
  redirectComplete,
  increaseCreditsStep,
  decreaseCreditsStep,
  handleCreditsPaymentSuccess,
  updateApplicationLayout,
} from "../../actions";

import PaymentMethod from "../PaymentMethod/PaymentMethod";
import CreditsSplit from "../CreditsSplit/CreditsSplit";

import SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";

const env = process.env.NODE_ENV || "development";
const defaultTaxRate = '0.2';

class Credits extends Component {
  static defaultProps = {
    currency: "GBP",
    locale: "en-GB",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.updateApplicationLayout({ componentType: "", label: 'Credits', isVisible: false });
    this.props.resetCredits();
  }

  validateCredits = () => {
    //update the step if a credit has been selected
    if (!this.props.numberOfCredits) {
      this.props.setCreditsError("Select an option");
    } else {
      this.props.increaseCreditsStep();
    }
  };

  renderExtraCreditsSelection() {
    if (this.props.step !== 1) {
      return;
    }

    const { catalogueData, currency } = this.props;
    // const PaymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;
    const PaymentMethods = catalogueData.Currencies.GBP.PaymentMethods;

    return (
      
        <div className="card_grid">
          {PaymentMethods.CreditTypes.map((option, index) => {
            const creditsSelected = this.props.numberOfCredits === option;

            return (
              
              <div key={index} className="card">
                <div className="card_credit_container">
                  <div style={{ cursor: "pointer" }} onClick={() => this.handleCreditSelect(option)}>
                    <p className="boxBold padded line_clamp">{option.Name}</p>
                    <p className="boxNormal padded">
                      {option.StandardUnitPriceCredits === 1
                        ? `${option.StandardUnitPriceCredits} ${PaymentMethods.CreditName}`
                        : `${option.StandardUnitPriceCredits} ${PaymentMethods.CreditNamePlural}`}
                      {": " + this.renderPrice(option[currency].StandardUnitPriceCurrency)}
                    </p>

                    <p className="boxBold padded">Per Credit cost</p>
                    <p className="boxNormal padded">
                      {this.renderPrice(option[currency].CurrencyCreditConversionRate)} each {PaymentMethods.CreditAdditionalText}
                    </p>
                  </div>
                  <div className="card_content">
                    <input
                      className="card_button"
                      type="button"
                      onClick={() => this.handleCreditSelect(option)}
                      value={creditsSelected ? "Selected" : "Select"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
    );
  }

  renderPrice = (price) => {
    const { currency, locale } = this.props;

    return new Intl.NumberFormat(locale, { style: "currency", currency: currency }).format(price).replace(/\.00/g, "");
  };

  handleCreditSelect = (amount) => {
    // console.log(amount)
    // console.log(this.props.numberOfCredits)
    if (this.props.numberOfCredits === amount) {
      this.props.selectCredits(null);
    } else {
      this.props.selectCredits(amount);
      this.props.resetCreditsError();
      // Add Line below to remove Continue button commented out abopve
      this.props.increaseCreditsStep();
    }
  };

  onChangeEvt = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    this.setState({
      [targetName]: targetValue,
    });
  };

  renderPaymentGateway() {
    if (this.props.step !== 2) {
      return;
    }

    const { catalogueData } = this.props ?? {};
    const { numberOfCredits } = this.props ?? {};

    const netOrderValue = Number(numberOfCredits?.GBP?.StandardUnitPriceCurrency ?? 0);
  
    const applyTax = numberOfCredits?.VAT ?? false;
    const taxRate = applyTax
      ? Number(catalogueData?.Currencies?.GBP?.TaxPercentage ?? defaultTaxRate)
      : 0;

    const orderTotal = netOrderValue + (netOrderValue * taxRate);

    return (
      <div className="contentHolder">
        <div className="col_left" style={{ marginTop: "0px" }}>
          <PaymentMethod
            description={this.props.numberOfCredits.Name}
            amount={orderTotal}
            currency={this.props.currency}
            handlePaymentResponse={this.handlePaymentResponse}
            onInvoiceMe={this.onInvoiceMe}
            handleBackButton={this.handleBackButton}
          />
          {/*this.renderInvoiceMe()*/}
        </div>

        {/*<div className="col_left" style={{ marginTop: "10px" }}>
          {this.renderCancelButton()}
        </div>*/}

        {this.renderError()}
      </div>
    );
  }

  /*
  renderInvoiceMe = () => {
    return (
      <div>
        <h3 style={{ marginLeft: "0", marginTop: "10px" }}>Payment via Invoice</h3>
        <p>If you would prefer to pay via invoice please click here</p>
        <input
          type="button"
          className="button"
          style={{ marginLeft: "0px" }}
          onClick={this.onInvoiceMe}
          value="Pay via Invoice"
        />
      </div>
    );
  };

  renderCancelButton = () => {
    return (
      <div>
        <input
          type="button"
          className="button"
          style={{ marginLeft: "0px" }}
          onClick={this.handleBackButton}
          value="Cancel"
        />
      </div>
    );
  };
  */

  onInvoiceMe = (id) => {
    const { catalogueData } = this.props ?? {};
    const taxPercentage = Number(catalogueData?.Currencies?.GBP?.TaxPercentage ?? defaultTaxRate);
    const symbol = catalogueData?.Currencies?.GBP?.Symbol ?? '';

    var adjustedObj = {
      id,
      cancelReason: 'Client Requests Pay By Invoice',
      ...this.props.numberOfCredits, 
      taxPercentage,
      symbol,
    };
    this.props.handleCreditsPaymentSuccess(adjustedObj, 1);
  };

  renderCreditSplit() {
    if (this.props.step !== 3) {
      return;
    }

    return <CreditsSplit />;
  }

  handlePaymentResponse = (data) => {
    if (data?.status === "succeeded") {
      const { catalogueData } = this.props ?? {};
      const taxPercentage = Number(catalogueData?.Currencies?.GBP?.TaxPercentage ?? defaultTaxRate);
      const symbol = catalogueData?.Currencies?.GBP?.Symbol ?? '';

      var adjustedObj = { 
        id: data.id,
        purchasedVia: 'Client Portal',
        ...this.props.numberOfCredits,
        taxPercentage,
        symbol,
      };

      this.props.handleCreditsPaymentSuccess(adjustedObj, 0);
    } else {
      this.props.setCreditsError(`Status: ${data?.status} ${data?.type}`);
    }
  };

  shouldRenderBackButton = () => {
    if (this.props.step >= 3) return false; // only on step 1 & 2

    return true;
  };

  handleBackButton = (id) => {
    if (this.props.step === 1) {
      //go back to /accounts
      this.props.creditsRedirect("/account");
    } else if (this.props.step === 2) {
      this.props.selectCredits(null);
      this.props.decreaseCreditsStep();
    }
  };

  pageTitleClass = () => {
    if (this.props.step >= 3) return "pageTitle";

    return "pageTitle pageTitleBack";
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  render() {
    return (
      <div className="innerPage">

        <div className="boundToCreditScreen" /*className="credits contentHolder"*/>
          <div className="contentHolder indent">
            <h2>Purchase Extra Credits</h2>
          </div>

          {this.renderExtraCreditsSelection()}

          {this.renderPaymentGateway()}

          {this.renderCreditSplit()}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId, redirectUrl } = state.session;
  const { step, numberOfCredits, error } = state.credits;
  const { catalogueData } = state.catalogue;
  return { sessionId, redirectUrl, step, numberOfCredits, error, catalogueData };
};

export default connect(mapStateToProps, {
  selectCredits,
  setCreditsError,
  resetCreditsError,
  resetCredits,
  creditsRedirect,
  redirectComplete,
  increaseCreditsStep,
  decreaseCreditsStep,
  handleCreditsPaymentSuccess,
  updateApplicationLayout,
})(Credits);
