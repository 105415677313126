import { HIDE_ERROR } from '../actions/types'

const INITIAL_STATE = {
    error: null,
    isOpen: false
};

export default (state = INITIAL_STATE, action) => {
    const { error } = action;
    if (error) {
        console.log({ error })
        return {
            error: error,
            isOpen: true
        }
    } else if (action.type === HIDE_ERROR) {
        return {
            error: null,
            isOpen: false
        }
    }

    return state;
}