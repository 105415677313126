import { LOAD_TRANSACTIONS, SELECT_INVOICE, LOAD_INVOICE, RESET_INVOICE, SET_TRANSACTION_ERROR } from '../actions/types';

const INITIAL_STATE = { 
  transactions: [],
  invoiceId: '',
  invoice: null,
  error: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case SELECT_INVOICE:
      return { ...state, invoiceId: action.payload };
    case LOAD_INVOICE:
      return { ...state, invoice: action.payload};
    case SET_TRANSACTION_ERROR:
      return { ...state, error: action.payload};
    case RESET_INVOICE:
      return { ...state, invoiceId: '', invoice: null };
    default:
      return state;
  }
};
