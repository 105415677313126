import Form from '@rjsf/core';
import { filter, uniqWith, isEqual } from 'lodash';
import { connect } from 'react-redux';

import config from '../../config';
import { postRequest } from '../../utils/Common.js';

const env = process.env.NODE_ENV || 'testenv';

class CustomForm extends Form {
  constructor(props) {
    super(props);
    const superOnBlur = this.onBlur;

    this.onBlur = (...args) => {

      return 
      // FIXME: PMD 01/03/24 No blur validation currently carried out
      const field = args[0].split('_')[1];
      let { formData, uiSchema, errors, errorSchema, schema } = this.state;

      // PMD 04/02/22 Check for empty strings in fields
      if (
        formData[Object.keys(formData)][field] !== null &&
        typeof formData[Object.keys(formData)][field] !== 'boolean' &&
        typeof formData[Object.keys(formData)][field] !== 'object' &&
        formData[Object.keys(formData)][field].trim().length === 0
      ) {
        // console.log(args)
        // console.log(formData[Object.keys(formData)][field].trim().length)

        this.setState((prevState) => {
          const newState = Object.assign({}, prevState);
          newState.formData[Object.keys(formData)][field] = null;
        });
      }

      uiSchema = {
        ...uiSchema,
        // Email: { validateOnBlur: true },
        // PMD 09/06/21 Switch off Username validation
        Username: { validateOnBlur: true },
      };

      if (!uiSchema[field] || !uiSchema[field].validateOnBlur) {
        superOnBlur(...args);
      } else {
        // If we're not validating the field just return
        if (field.toLowerCase() !== 'username') return;

        // Handle the onBlur validation
        // console.log(formData)
        let formName = Object.keys(formData);
        // console.log({formName})

        const { errors: _errors, errorSchema: _errorSchema } = this.validate(
          formData,
          // formData[formName]
        );

        // console.log(_errors)
        // console.log(formData);

        // PMD 23/02/22 Remove check on whether the email
        // let validEmail = this.onEmailBlur(formData[formName][field]);
        // validEmail.then((result) => {
        //   // console.log(result)

        //   if (result.status === "error") {
        //     // console.log(result.error === "400 - Invalid Parameter")
        //     let errorMessage = result.error === "400 - Invalid Parameter" ? "Invalid Email Format" : result.error
        //     _errorSchema.AgentInvite = {}
        //     _errorSchema.AgentInvite.Username = {_errors: []}
        //     _errorSchema.AgentInvite.Username._errors.push(errorMessage)

        //     _errors.push({name: "minLength", property: "AgentInvite.Username", message: errorMessage, stack: errorMessage })
        //     // console.log(_errorSchema)
        //   }

        //   const prevOtherFieldErrors = filter(
        //     errors,
        //     // error => error["property"] !== `.${field}`
        //     error => error["property"] !== `AgentInvite.${field}`
        //   );

        //   const fieldErrors = filter(_errors, ["property", `AgentInvite.${field}`]);

        //   if (_errorSchema["AgentInvite"] !== undefined) {
        //     const fieldErrorSchema = _errorSchema["AgentInvite"][field];

        //     // console.log(fieldErrorSchema)

        //     this.setState(
        //       {
        //         errors: uniqWith(
        //           [...prevOtherFieldErrors, ...fieldErrors],
        //           isEqual
        //         ),
        //         // errorSchema: { ...errorSchema, [field]: fieldErrorSchema }
        //         errorSchema: { ...errorSchema, [`AgentInvite.${field}`]: fieldErrorSchema }
        //       },
        //       () => {
        //         superOnBlur(...args);
        //       }
        //     );

        //   }
        //   return _errors
        // })
      }
    };
  }

  // onEmailBlur = (emailAddress) => {
  //   const { sessionId } = this.props;

  //   const URI = `${config[env].URL}/form-validate-email`;
  //   const options = {
  //     sessionId: sessionId,
  //     email: emailAddress,
  //   };
  //   const credentials = "omit";

  //   return postRequest(URI, options, credentials)
  //     .then((apiParse) => {
  //       return apiParse;
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // };
}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  return { sessionId };
};

export default connect(mapStateToProps, {})(CustomForm);
