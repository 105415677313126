import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import "./modalstyle.css";
import "react-responsive-modal/styles.css";

import RenderModalForm from "./RenderModalForm";
import Loader from "../Loader/Loader";

import { getDataViewData } from "../../actions";

import { taskListPopupGUID } from "../../utils/Constants";
class GetFormData extends Component {
  constructor() {
    super();

    this.state = {
      redirect: false,
      showModal: false
    };
  }

  async componentDidMount() {
    const { dataConfig, selectedApplicationTask, ApplicationTasks, screenType, applicationGUID, spotliteAddress, portalDetails } = this.props;

    // console.log(' ++++ GetFormData : ', this.props);
    // console.log(' ++++ GetFormData : ', applicationGUID)

    let applicationInstanceGUID = null;
    // FIXME: Not sure why this check has to be here, feels like it should have been dealt with already.
    if (screenType === taskListPopupGUID) {
      if (portalDetails.portalType === 'applicant') {
        applicationInstanceGUID = dataConfig.allTeams.currentTeamDetails.teamGUID;
      } else {
        applicationInstanceGUID = ApplicationTasks[selectedApplicationTask].applicationGUID;
      }
    } else if (screenType === '0EF4FCAB-D3F2-443E-8ACC-BFAC98A3304D') {
      // Create Team GUID
      applicationInstanceGUID = dataConfig.allTeams.currentTeamDetails.departmentGUID;
    } else if (applicationGUID) {
      //console.log(applicationGUID)
      applicationInstanceGUID = applicationGUID;
    } else {
      applicationInstanceGUID = dataConfig.allTeams.currentTeamDetails.teamGUID;
    }

    //console.log(applicationGUID)
    //console.log(applicationInstanceGUID)

    const options = {
      ViewGUID: screenType,
      ApplicationInstanceGUID: applicationInstanceGUID,
      SpotliteAddress: spotliteAddress || "",
    };

    //console.log("Options passed into GetFormData", options);

    await this.props.getDataViewData(options);

    if (this.props.getViewError instanceof Error) {
      this.setState({
        redirect: true,
      });
    } else {
      this.openModal();
    }
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
    //console.log('closeModal')
    this.props.closeModal(str);
  };

  render() {
    const { redirect, showModal } = this.state;

    if (redirect) {
      return (
        <Redirect to={'/login'} />
      )
    }

    return (
      <>
        <RenderModalForm
          open={this.state.showModal}
          onClose={this.props.cancelModal}
          modalScreen={true}
          segmentSpotliteAddress={''}
          closeModal={this.props.closeModal}
          viewLoading={this.props.getViewLoading}
          viewData={this.props.getViewData}
          applicationGUID={this.props.applicationGUID}
        />
        <Loader
          className="loader__spinner"
          active={!showModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { dataConfig, portalDetails } = state.session;
  const { selectedApplicationTask } = state.applications
  const { ApplicationTasks } = state.applications.applications
  const { getViewData, getViewLoading, getViewError } = state.getView;

  return { dataConfig, portalDetails, selectedApplicationTask, ApplicationTasks, getViewData, getViewLoading, getViewError };
};

export default connect(mapStateToProps, { getDataViewData })(GetFormData);