import React from 'react';
import { Link } from "react-router-dom";

function SideBarOptions ({data, location, callback}) {

    const handleClick = (e) => {
        callback(e.target.dataset);
    }

    const returnClassname = (path, altPath) => {
        const { pathname } = location
        return pathname === path || pathname === altPath ? "menuDark" : "";
    };
    
    return (
        <ul>
            {data.map((item, index) =>            
                <li key={index} className={returnClassname(item.RouterPath, item.AltRouterPath)}>
                    <Link  to={item.RouterPath} onClick={handleClick}>
                        <div 
                            data-viewguid={item.viewGUID} 
                            data-label={item.Image.ImageLabel} 
                            data-context={item.Context} 
                            data-routerpath={item.RouterPath}
                        >
                            <img 
                                data-viewguid={item.viewGUID} 
                                data-label={item.Image.ImageLabel}
                                data-context={item.Context}
                                data-routerpath={item.RouterPath} 
                                className={item.Image.ImageType} 
                                src={`/assets/img/${item.Image.ImageFile}`}
                                width={20}
                                height={20} 
                                alt={item.ImageAltText} 
                            />
                            <p 
                                data-viewguid={item.viewGUID} 
                                data-label={item.Image.ImageLabel} 
                                data-context={item.Context}
                                data-routerpath={item.RouterPath} 
                            >
                                {item.Image.ImageLabel}
                            </p>
                        </div>
                    </Link>
                </li>
            )}
        </ul>
    );

 

}

export default SideBarOptions;