// PMD 05/08/22 B2C Implementation
import React from "react";
import { confirmAlert } from "react-confirm-alert"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { b2cPolicies, loginRequest, protectedResources } from "../../authConfig";
import Button from "../Reusable/Button";
import { errorHandler } from "../../utils/errorHandler";
import { callApiWithToken } from "./callApiWithToken"


// Popup
const handleLogin = (instance, props) => {
    // console.log(loginRequest)
    instance.loginPopup(loginRequest)
    .then(response => {
        // createSession(response.accessToken, props)
        // console.log(response)
        callApiWithToken(response.accessToken, protectedResources.apiSafeguarden.endpoint)
        .then(sessionDetails => {
            // console.log(sessionDetails);
            if (sessionDetails instanceof Error || sessionDetails.Result !== 'ok') {
                throw sessionDetails
            }
            props.b2cSignOn(sessionDetails)        
        })
        .catch(error => {
            console.log(error)
            let msgTitle, messageString, buttonLabel, clickAction
            if (instance.getAllAccounts().length > 0) {
                msgTitle = "Authentication Error"
                messageString = error.message ?? error.ErrorMsg +". Please contact support."
                buttonLabel = "OK"
                clickAction = () => {
                    instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })
                }
            } else {
                console.log(error)
                errorHandler(error);
            }

            confirmAlert({
                title: msgTitle,
                message: messageString,
                buttons: [
                    {
                        label: buttonLabel,
                        onClick: clickAction
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            })
        })
    })
    .catch(error => {
        console.error(error);
        if (error.errorMessage) {
            if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                instance.loginPopup(b2cPolicies.authorities.resetPassword)
                .then(response => handlePolicyChange(response))
            } else {
                errorHandler(error);
            }
        } else {
            errorHandler(error);
        }
    });
}

const handlePolicyChange = (response) => {
    if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
        window.alert("Profile has been updated successfully. \nPlease sign-in again.");
        instance.logout();
    } else if (response.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
        window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        instance.logout();
    }
}

export const SignInButton = (props) => {
    const {instance} = useMsal();
    // console.log(instance)

    return (
        <>
        <AuthenticatedTemplate>
            <Button label={"Spotlite Sign Out"} className={"button action dashboardaction"} handleClick={() => instance.logoutPopup({postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Button label={"Spotlite Login"} className={"button action dashboardaction"} handleClick={() => handleLogin(instance, props)} />
       </UnauthenticatedTemplate>
        </>
    );
}

