import {
  SELECT_CREDITS,
  SET_CREDITS_ERROR,
  RESET_CREDITS_ERROR,
  RESET_CREDITS,
  INCREASE_CREDITS_STEP,
  DECREASE_CREDITS_STEP,
  LOAD_CATALOGUE_CREDITS,
  LOAD_CORRESPONDENCE_ADDRESS,
  REDIRECT,
  SET_SESSION,
} from "./types";

import { postRequest, getRequest, apiParse } from "../utils/Common";
import config from "../config";
import { errorHandler } from "../utils/errorHandler";
const env = process.env.NODE_ENV || "development";

export const creditsRedirect = (url) => {
  return {
    type: REDIRECT,
    payload: url,
  };
};

export const clearCatalogueCredits = () => {
  return {
    type: LOAD_CATALOGUE_CREDITS,
    payload: [],
  };
};

export const selectCredits = (creditObj) => {
  return {
    type: SELECT_CREDITS,
    payload: creditObj,
  };
};

export const setCreditsError = (err) => {
  return {
    type: SET_CREDITS_ERROR,
    payload: err,
  };
};

export const resetCreditsError = () => {
  return {
    type: RESET_CREDITS_ERROR,
  };
};

export const resetCredits = () => {
  return {
    type: RESET_CREDITS,
  };
};

export const increaseCreditsStep = () => {
  return {
    type: INCREASE_CREDITS_STEP,
  };
};

export const decreaseCreditsStep = () => {
  return {
    type: DECREASE_CREDITS_STEP,
  };
};

export const handleCreditsPaymentSuccess = (orderObj, payByInvoice) => {
  return (dispatch, getState) => {
    // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
    // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-purchase-credits`;
    const URI = `${config[env].URL}/client-portal-purchase-credits`;
    const options = {
      sessionId: getState().session.sessionId,
      payByInvoice: payByInvoice,
      order: orderObj,
      // PMD 13/10/22 Add portaltype for use by getAndSetPermissions
      portaltype: getState().session.portalDetails.portalType,
    };
    const credentials = "omit";
    // const headers = { appkey: process.env.REACT_APP_APP_KEY };

    // postRequest(URI, options, credentials, headers)
    // console.log(URI, options)
    postRequest(URI, options, credentials)
      .then(apiParse)
      .then((data) => {
        //SUCCESS
        // move to next page

        dispatch({
          type: INCREASE_CREDITS_STEP,
        });
      })
      .catch((error) => {
        //ERROR
        // PMD 20/05/22 Add in error handler function
        errorHandler(error)

        dispatch({
          type: SET_CREDITS_ERROR,
          payload: error.message || error,
        });
      });
  };
};

export const loadCatalogueCredits = () => {
  return internalLoadCatalogueCredits();
};

function internalLoadCatalogueCredits() {
  return (dispatch, getState) => {
    const URI = `${config[env].URL}/client-portal-load-account-balances?sessionId=${getState().session.sessionId}`;
    const credentials = "omit";
    // PMD 17/12/21 Pass portal type across
    const headers = { portaltype: getState().session.portalDetails.portalType };

    // Moved headers to common.js
    getRequest(URI, credentials, headers)
      // getRequest(URI, credentials)
      .then(apiParse)
      .then((data) => {
        //SUCCESS
        dispatch({
          type: LOAD_CATALOGUE_CREDITS,
          payload: data,
        });
      })
      .catch((error) => {
        // console.log(error.message)
        // PMD 20/05/22 Add in error handler function
        errorHandler(error)

        // if (error.message.includes("expired") || error.message.includes("doesnt exist")) {
        //   dispatch({
        //     type: SET_SESSION,
        //     payload: "",
        //   });

        // }
        dispatch({
          type: SET_SESSION,
          payload: "",
        });

        //ERROR
        dispatch({
          type: SET_CREDITS_ERROR,
          payload: error.message || error,
        });
      });
  };
}

export const adjustCatalogueCredits = (creditArray) => {
  return (dispatch, getState) => {
    // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
    // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-post-account-balances`;
    const URI = `${config[env].URL}/client-portal-post-account-balances`;
    const options = {
      sessionId: getState().session.sessionId,
      accountAdjustments: creditArray,
      portalType: getState().session.portalDetails.portalType,
    };

    const credentials = "omit";

    postRequest(URI, options, credentials)
      .then(apiParse)
      .then((data) => {
        //SUCCESS
        dispatch({
          type: REDIRECT,
          payload: "/account",
        });
      })
      .catch((error) => {
        //ERROR
        // PMD 20/05/22 Add in error handler function
        errorHandler(error)

        dispatch({
          type: SET_CREDITS_ERROR,
          payload: error.message || error,
        });
      });
  };
};

export const loadCorrespondenceAddress = () => {
  return (dispatch, getState) => {
    // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
    // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-get-address?sessionId=${getState().session.sessionId}`;
    const URI = `${config[env].URL}/client-portal-get-address?sessionId=${getState().session.sessionId}`;
    const credentials = "omit";
    //  const headers = { appkey: process.env.REACT_APP_APP_KEY };

    const headers = {};

    // Moved headers to common.js
    // getRequest(URI, credentials, headers)
    getRequest(URI, credentials, headers)
      .then(apiParse)
      .then((data) => {
        //SUCCESS
        dispatch({
          type: LOAD_CORRESPONDENCE_ADDRESS,
          payload: data,
        });
      })
      .catch((error) => {
        //ERROR
        // PMD 20/05/22 Add in error handler function
        errorHandler(error)

        dispatch({
          type: SET_CREDITS_ERROR,
          payload: error.message || error,
        });
      });
  };
};

export const addCatalogue = (catalogueCode) => {
  return (dispatch, getState) => {
    // console.log("adding catalogue");

    // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
    // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-add-account-balance`;
    const URI = `${config[env].URL}/client-portal-add-account-balance`;
    const options = {
      sessionId: getState().session.sessionId,
      catalogueCode: catalogueCode,
      // PMD 13/10/22 Add portaltype for use by getAndSetPermissions
      portaltype: getState().session.portalDetails.portalType
    };

    // console.log(options);

    const credentials = "omit";
    // const headers = { appkey: process.env.REACT_APP_APP_KEY };

    // postRequest(URI, options, credentials, headers)
    postRequest(URI, options, credentials)
      .then(apiParse)
      .then((data) => {
        dispatch(internalLoadCatalogueCredits());
      })
      .catch((error) => {
        //ERROR
        // PMD 20/05/22 Add in error handler function
        errorHandler(error)

        dispatch({
          type: SET_CREDITS_ERROR,
          payload: error.message || error,
        });
      });
  };
};
