import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {
    applications: {},
    selectedApplicationTask: "",
    selectedApplicationLayout: "",
    applicationsLoading: false,
    applicationColor: {},
    searchCriteria: [],
    error: null,
};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.FETCH_APPLICATIONS_REQUEST:
            return {
                ...newState,
                applicationsLoading: true
            };
        case types.FETCH_APPLICATIONS_SUCCESS:
            //console.log(action.payload);
            return {
                ...newState,
                applications: action.payload,
                applicationsLoading: false
            };
        case types.FETCH_APPLICATIONS_ERROR:
            return {
                ...newState,
                error: action.payload,           
                // Do we need the line below or just stay with previous state for applications object ?
                // applications: [],
                applicationsLoading: false
            };
        case types.UPDATE_APPLICATION_TASK_REQUEST:
            return {
                ...newState,
                applicationsLoading: true
            };
        case types.UPDATE_APPLICATION_TASKS:
            return {
                ...newState,
                applications: {  
                    ...newState.applications,
                    ApplicationTasks:{
                        ...newState.applications.ApplicationTasks,
                        ...action.payload.ApplicationTasks,
                    } 
                },
                applicationsLoading: false,
                loaderActive: false
            };
        case types.CLEAR_APPLICATION_TASKS:
            return {
                ...newState,
                applications: {  
                    ...newState.applications,
                    ApplicationTasks: {
                    
                    },
                    Segments: {

                    }
                }
            };
        case types.UPDATE_APPLICATION_TASK_ERROR:
            return {
                ...newState,
                error: action.payload,
                applicationsLoading: false
            };
        case types.UPDATE_APPLICATION_COLORS:
            return {
                ...newState, 
                applicationColor: action.payload,
            };
        case types.UPDATE_APPLICATION_COMPONENT_TYPE:
            return {
                ...newState, 
                selectedApplicationLayout: action.payload,
            };
        case types.UPDATE_SEARCH_CRITERIA:
            return {
                ...newState,
                searchCriteria: action.payload,
            };
        case types.UPDATE_SELECTED_APPLICATION_TASK:
            return {
                ...newState,
                selectedApplicationTask: action.payload
            };
        case types.LOGOUT:
            return { 
                ...INITIAL_STATE 
            };
        default:
            return prevState;
    }
};