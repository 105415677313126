import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {
    fileDetails: {},
    filesLoading: true,
    fileUploadError: null
};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.UPLOAD_FILES_REQUEST:
            return {
                ...newState,
                fileDetails: {},
                filesLoading: true,
                fileUploadError: null
            };
        case types.UPLOAD_FILES_SUCCESS:
            return {
                ...newState,
                fileDetails: action.payload,
                filesLoading: false,
                fileUploadError: null
            };
        case types.UPLOAD_FILES_ERROR:
            return {
                ...newState,
                filesLoading: false,
                fileUploadError: action.payload,
            };
        default:
            return prevState;
    }
};