import { size } from "lodash";
import React, { Component } from "react";
import { connect } from 'react-redux';

class BusinessHeader extends Component {
  
    handleBackButton = () => {
    this.props.handleBackButton();
  };

  renderBackButton = () => {

    if (!this.props.showBackButton) return;

    const { backLabel } = this.props;

    return (
      <button
        onClick={this.handleBackButton}
        style={{
          backgroundColor: "transparent",
          borderWidth: 0,
          display: "flex",
          fontSize: '14px',
          fontWeight: '200',
          color: '#297FE5',
          cursor: 'pointer',
        }}>
        {backLabel}
      </button>
    );
  };

  render() {

    const { title } = this.props;

    return (
        <div className="business_header" >
            <div style={{fontSize: '22px', fontWeight: '500', cursor: 'default',}}>{title}</div>
            {this.renderBackButton()}
        </div>
    );
  }
}

const mapStateToProps = (state) => {  
  return { };
};

export default connect(mapStateToProps, { })(BusinessHeader);
