import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {
    catalogueData: {},
    catalogueLoading: true,
    catalogueError: null
};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.GET_CATALOGUE_REQUEST:
            return {
                ...newState,
                catalogueData: {},
                catalogueLoading: true,
                catalogueError: null
            };
        case types.GET_CATALOGUE_SUCCESS:
            return {
                ...newState,
                catalogueData: action.payload,
                catalogueLoading: false,
                catalogueError: null
            };
        case types.GET_CATALOGUE_ERROR:
            return {
                ...newState,
                catalogueError: action.payload,
                catalogueLoading: false
            };
        default:
            return prevState;
    }
};