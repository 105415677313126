import React, { Component } from 'react';

class GlobalSubHeader extends Component {

    render() {

        return (
          <div className="webportalBottombar">
            {
              this.props.children
            }
          </div>
        )
      }

}

export default GlobalSubHeader;