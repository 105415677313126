import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ModalForm from "../Form/ModalForm";
import GetFormData from "../Form/GetFormData";
import PostContext from '../Context/PostContext';
import ModalDialogForm from "../Form/ModalDialogForm";
import { isEmpty } from "lodash";
import { filterData } from "../../utils/Filter";
import Segment from "../Reusable/Segment";
import {
  updateApplicationTasks,
} from "../../actions";
import { newestFirst, newestLast, dueDateOrder, EventGUID, kanbanboardGUID } from "../../utils/Constants";

class KanbanLayout extends Component {

  constructor(props) {
    super(props);

    //We have a local state to hold the segments and tasks together for rendering
    //Changes are made directly to redux because we want the data to survive navigation
    this.state = {
      layout: {},
      modalOpen: false,
      postActive: false,
      modalScreen: "",
      postViewGUID: "",
      lobCode: "",
      lobName: "",
      serviceCode: "",
      serviceName: "",
      redirect: false,
      isDragDisabled: false,
      modalFormOpen: false,
      newReduxState: {},
      spotliteAddress: null
    };

  }

  componentDidMount() {
    this.buildLayout();
  }

  //IMPORTANT - you need prevProps as a parameter for prevState to work when using componentDidUpdate()
  componentDidUpdate(prevProps, prevState) {

    // if (prevState.layout !== this.state.layout) {
    //console.log("State has changed", prevState.applications, this.props.applications);
    // }

    if (prevProps.applications !== this.props.applications) {
      this.buildLayout();
    }

  }

  //Application Task, Query, Match all
  checkMatch(task, search, matchAll) {

    return Object.values(task).forEach(values => {
      if (values !== null) {
        if (matchAll) {
          return search.every(i => `${values}`.toUpperCase().includes(i.value.toUpperCase()));
        } else {
          return search.some(i => `${values}`.toUpperCase().includes(i.value.toUpperCase()));
        }
      }
    });
  }

  buildLayout() {

    if (typeof this.props.Segments === 'undefined' && typeof this.props.ApplicationTasks === 'undefined')
      return;

    let layout = {};

    let sortedSegments = Object.values(this.props.Segments).sort((a, b) =>
      a.viewSeq > b.viewSeq
      ? 1
      : a.viewSeq === b.viewSeq
      ? a.segmentName > b.segmentName
      ? 1
      : -1
      : -1,)

    Object.values(sortedSegments).map((segment) => {

      const tasks = [];
      const search = [...this.props.searchCriteria];

      segment.applicationTaskIDs.forEach(id => {

        const task = { ...this.props.ApplicationTasks[id] };

        if (!isEmpty(task) && isEmpty(search)) {
          tasks.push(task);
        } else if (!isEmpty(task) && filterData(task, search)) {
          tasks.push(task);
        }
      });

      const applicationTasks = {};

      tasks.forEach((task) => {
        applicationTasks[task.id] = task;
      });

      return layout[segment.segmentGUID] = {
        segment: segment,
        applicationTasks
      }

    });

    

    let isDragDisabled = false;

    if (this.props.Segmentation === 'TeamView') {
      isDragDisabled = true;
    }

    const newLayout = {
      layout: layout,
      isDragDisabled: isDragDisabled,
    };

    this.setState(newLayout);

  };

  onDragEnd = (result) => {

    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'column') {
      // console.log("Type", type);
      // console.log("Source DroppableId", source.droppableId, source.index);
      // console.log("Destination DroppableId", destination.droppableId, destination.index);

      // Source Index -> get the segmentGUID, than chang view GUID
      return;
    }

    const applicationTask = { ...this.props.ApplicationTasks[draggableId] };

    const newApplicationTask = {
      ...applicationTask,
      segmentGUID: destination.droppableId,
      // Cant have viewSeq for Application tasks, doen't help in ordering them
      //viewSeq: destination.index
    }

    let sourceIds = [];
    let destinationIds = [];

    Object.values(this.props.Segments)
      .filter(segment => segment.segmentGUID === source.droppableId)
      .forEach(function (item) {
        sourceIds = [...item.applicationTaskIDs];
      });

    Object.values(this.props.Segments)
      .filter(segment => segment.segmentGUID === destination.droppableId)
      .forEach(function (item) {
        destinationIds = [...item.applicationTaskIDs];
      });

    sourceIds.splice(source.index, 1);

    let newSegments = { ...this.props.Segments };

    let eventGUID = null;

    if (source.droppableId === destination.droppableId) {
      //Then we only change one array

      let priorityOrder = newSegments[source.droppableId].priorityOrderGUID;

      if (priorityOrder === null || priorityOrder === dueDateOrder) {
        //insert at destination index
        sourceIds.splice(destination.index, 0, draggableId);
      } else if (priorityOrder === newestFirst) {
        //insert at the beginning
        sourceIds.splice(0, 0, draggableId);
      } else if (priorityOrder === newestLast) {
        //insert at the end
        sourceIds.push(draggableId);
      } else {
        //insert at the beginning
        sourceIds.splice(0, 0, draggableId);
      }

      //For future use
      newSegments[source.droppableId].applicationTaskIDs = sourceIds;

      eventGUID = newSegments[source.droppableId].eventGUID;

    } else {

      let priorityOrder = newSegments[destination.droppableId].priorityOrderGUID;

      if (priorityOrder === null || priorityOrder === dueDateOrder) {
        //insert at destination index
        destinationIds.splice(destination.index, 0, draggableId);
      } else if (priorityOrder === newestFirst) {
        //insert at the beginning
        destinationIds.splice(0, 0, draggableId);
      } else if (priorityOrder === newestLast) {
        //insert at the end
        destinationIds.push(draggableId);
      } else {
        //insert at the beginning
        destinationIds.splice(0, 0, draggableId);
      }

      //For future use
      newSegments[source.droppableId].applicationTaskIDs = sourceIds;

      //For future uss
      newSegments[destination.droppableId].applicationTaskIDs = destinationIds;

      eventGUID = newSegments[destination.droppableId].eventGUID;

    };

    let postDetails = {
      segmentation: this.props.applications.Segmentation,
      taskSpotliteAddress: newApplicationTask.spotliteAddress,
      taskGUID: newApplicationTask.taskGUID,
      segmentSpotliteAddress: newSegments[newApplicationTask.segmentGUID].spotliteAddress,
      segmentGUID: newApplicationTask.segmentGUID,
    };

    let newReduxState = {
      postDetails: postDetails,
      Segments: newSegments,
      ApplicationTasks: {
        [draggableId]: newApplicationTask,
      }
    };

    // If eventGUID matches launch the modal else update application task immediately 
    if (eventGUID === EventGUID) {
      this.setState({ modalFormOpen: true, newReduxState });
    } else {
      this.props.updateApplicationTasks(newReduxState);
    }

  };

  onClose = () => {
    this.setState({
      modalOpen: false,
      postActive: false
    });
  };

  cancelModal = () => {
    this.setState({
      modalOpen: false,
      postActive: false
    });
  }


  launchOptions = (dataset, data) => {
    // console.log('dataset:', dataset);
    // console.log('launchOptions:', data);

    if (dataset.context === 'Modal') {
      //Run credit check first
      this.setState({
        modalOpen: true,
        modalScreen: dataset.viewGUID,
        serviceName: '',
        serviceCode: dataset.code,
        lobName: '',
        lobCode: '',
        spotliteAddress: data.segment.spotliteAddress

      });
    } else if (dataset.context === 'Redirect' || dataset.context === 'Application') {
      this.setState({ redirect: true, redirectPath: dataset.path });
    } else if (dataset.context === 'Post') {
      this.setState({
        postActive: true,
        postViewGUID: dataset.viewGUID,
        spotliteAddress: data.segment.spotliteAddress
      });
    }

  };

  closeModalForm = () => {
    this.setState({ modalFormOpen: false });
  };

  confirmModalForm = () => {
    this.props.updateApplicationTasks(this.state.newReduxState);
    this.setState({ modalFormOpen: false, newReduxState: {} });
  }

  renderModalForm() {

    return (
      <ModalDialogForm
        onClose={this.closeModalForm}
        onConfirm={this.confirmModalForm}
        children={"This is a test"}
        show={this.state.modalFormOpen}
      />
    );

  }

  renderModal = () => {
    if (this.state.modalOpen) {
      return (
        <GetFormData
          open={this.state.modalOpen}
          closeModal={this.onClose}
          cancelModal={this.cancelModal}
          screenType={this.state.modalScreen}
          ServiceName={this.state.serviceName}
          ServiceCode={this.state.serviceCode}
          LoBName={this.state.lobName}
          LoBCode={this.state.lobCode}
          spotliteAddress={this.state.spotliteAddress}
        />
      );
    } else {
      return null;
    }
  };

  renderPost = () => {
    const { postActive, postViewGUID, spotliteAddress } = this.state;
    if (postActive) {
      return (
        <PostContext
          postActive={postActive}
          closePost={this.onClose}
          postViewGUID={postViewGUID}
          spotliteAddress={spotliteAddress}
        />
      );
    }
  };

  renderDragDrop() {

    if (this.props.selectedApplicationLayout.componentType !== kanbanboardGUID || !this.props.WorkQueueMenu)
      return null;

    const getSegmentStyle = isDraggingOver => ({
      background: isDraggingOver ? "#f7f5f4" : "#f7f5f4",
    });

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
          //ignoreContainerClipping={false}
          isCombineEnabled={false}
        >
          {(provided, snapshot) => (
            // Container starts here
            <div className="segment_parent_container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            //style={getSegmentStyle(snapshot.isDraggingOver)}
            >
              {Object.values(this.state.layout).map((segment, index) => {

                // console.log(this.props)

                let data = [];
                if (this.props.Segmentation === 'TeamView') {
                  data = [...this.props.WorkQueueMenu.Options];
                }

                return this.renderSegment(segment, data, index);

              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );

  }



  renderSegment(segment, data, index) {

    return (
      <div key={`${segment.segment.segmentGUID + `_${index}` + `_segment_header_container`}`} className="segment_header_container">
        <Segment
          key={`${segment.segment.segmentGUID + `_${index}` + `_segment`}`}
          index={index}
          layout={segment}
          data={data}
          label={segment.segment.segmentName}
          optionsCallback={this.launchOptions}
          id={segment.segment.segmentGUID}
          isDisabled={this.state.isDragDisabled}
        />
      </div>
    );

  }

  render() {

    if (typeof this.props.Segments === 'undefined' && typeof this.props.ApplicationTasks === 'undefined')
      return null;

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect push to={this.state.redirectPath} />;
    }

    return (
      <div className="kanban">
        {this.renderModal()}
        {this.renderPost()}
        {this.renderDragDrop()}
        {this.renderModalForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const {
    searchCriteria,
    applications,
    selectedApplicationLayout,
  } = state.applications;

  const {
    Segments,
    ApplicationTasks,
    Segmentation,
  } = state.applications.applications;

  const {
    WorkQueueMenu,
  } = state.session.dataConfig;

  return {
    searchCriteria,
    applications,
    Segments,
    ApplicationTasks,
    Segmentation,
    selectedApplicationLayout,
    WorkQueueMenu,
  };
}

export default connect(mapStateToProps, { updateApplicationTasks, })(KanbanLayout);