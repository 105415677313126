import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadDepts,
  loadUsers,
  addUserToDept,
  removeUserFromDept
} from "../../actions";

import Select from "react-select";
import _ from "lodash";
import config from '../../config';
import { errorHandler } from "../../utils/errorHandler";
const env = process.env.NODE_ENV || 'development';

class Enterprise extends Component {

  constructor() {
    super();
    this.state = {
      deptSelectOptions: {},
      layout: "enterprise",
    };
  }

  componentDidMount() {
    this.props.loadUsers();
    this.props.loadDepts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionId !== this.props.sessionId || this.props.reload) {
      this.props.loadUsers();
    }

    if (this.props.users && prevProps.users !== this.props.users) {

      const formattedUsers = this.props.users.map((user) => {
        return {
          value: user.APGUID,
          label: user.Username,
          CGUID: user.CGUID
        }
      })

      this.setState({
        deptSelectOptions: formattedUsers,
      });
    }
  }

  render() {
    return (
      <div className="contentHolder boundToScreen containsSubHeader">
        {this.renderEnterprisePage()}
        {this.renderError()}
      </div>
    );
  }

  renderEnterprisePage = () => {
    return <>{this.renderEnterprise()}</>;
  };

  renderEnterprise = () => {

    // console.log(this.props.depts)
    // console.log(this.props.users)
    // console.log(env)
    if (_.isEmpty(this.props.depts)) {
      const URL = config[env].registrationURL;
      // console.log(URL)
      return (
        <div className="enterpriseHolder">
          <h2 className="enterpriseTitle">Multiple Business Units need to be registered to enable Enterprise functionality</h2>
          <a className="enterpriseAnchor" href={URL} target="_blank" rel="noopener noreferrer">Click here to Register a new Business Unit</a>

        </div>
      )
    }

    return this.props.depts.map((dept) => {
      // console.log(dept)

      return (
        <div className="enterpriseHolder" key={dept.deptId}>
          <>
            <div className="flexBetween">
              <h2 className="enterpriseTitle">{dept.deptName}</h2>
            </div>
            <Select
              isSearchable
              id="enterprise_select"
              name="users"
              classNamePrefix="react-select"
              options={this.filterOptionsByCurrentDept({
                dept: dept
              })}
              value={{ value: 0, label: "Select Agent to Second to this Department" }}
              onChange={this.addMemberToDept.bind(this, dept.deptGUID)}
            />
          </>
          <div className="userHolder">
            {this.buildDeptUsers({
              // teamGUID: team.teamGUID,
              // teamMembers: team.teamMembers,
              deptMembers: dept.deptMembers
            })}
          </div>
        </div>
      );
    })
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  filterOptionsByCurrentDept = ({ dept }) => {
    /*
    take deptSelectOptions and remove any that are already in our current team
    */
    if (_.isEmpty(this.state.deptSelectOptions)) return {};

    // console.log("deptSelectOptions - ",this.state.deptSelectOptions)
    // console.log("Props Users - ",this.props.users)
    // console.log("Dept - ",dept.deptMembers)

    let newOptions = this.state.deptSelectOptions.filter((member) => {
      //dont return if they are in currentTeamMembers

      let memberExists = _.findIndex(dept.deptMembers, {
        CGUID: member.CGUID
      });

      if (memberExists === -1) {
        return true;
      } else {
        return false;
      }
    })
    return newOptions;
  };

  addMemberToDept = (deptGUID, evt) => {
    // console.log(evt.label)
    // console.log(evt.value)
    // console.log(deptGUID)

    this.props.addUserToDept({
      deptGUID: deptGUID,
      PartyGUID: evt.value
    })
  }

  buildDeptUsers = ({ deptMembers }) => {
    return deptMembers.map((deptMember) => {
      return (
        <div className="user" key={deptMember.MyAgentAPGUID}>
          <div className="flexBetween">
            <p
              style={{ cursor: "pointer" }}
            // Don't allow user selection
            // onClick={() => this.selectUser(teamMember)}
            >
              {deptMember.MyAgent}
            </p>
            <img
              src="/assets/img/cross.png"
              alt="remove"
              style={{ height: 25, marginRight: 5, cursor: "pointer" }}
              onClick={() => this.removeUserFromDept({ deptMember })}
            ></img>
          </div>
        </div>
      )
    })
  }

  removeUserFromDept = ({ deptMember }) => {
    // const { ApplicationPartyGUID } = user;
    // console.log(deptMember)
    const { MyAgentAPGUID } = deptMember;

    this.props.removeUserFromDept({
      PartyGUID: MyAgentAPGUID
      // ApplicationPartyGUID: ApplicationPartyGUID
    })
      .then(() => this.props.loadDepts())
      .catch((err) => {
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        throw err;
      })
  };

}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  const { depts, selectedDeptUser, reload, error } = state.depts;
  const { users } = state.users;
  return {
    sessionId,
    selectedDeptUser,
    depts,
    reload,
    error,
    users
  };
};

export default connect(mapStateToProps, {
  loadDepts,
  loadUsers,
  addUserToDept,
  removeUserFromDept
})(Enterprise);
