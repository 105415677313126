import { HIDE_ERROR, SET_ERROR } from './types'

export function setError(error) {
    console.log(error)
    return {
        type: SET_ERROR,
        error: error
    }
}

export function hideError() {
    return {
        type: HIDE_ERROR
    }
}