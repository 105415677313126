
import { confirmAlert } from "react-confirm-alert"
import { logoutSession } from './DatabaseFunctions';
import { useMsal } from '@azure/msal-react'

let sessionId;

export const errorHandler = async (err) => {

    console.log(err)
    sessionId = getCookie("sessionId")
    // Redirect the pathname to the login page
    let logoutRedirect = '/login'

    let msgTitle, messageString, buttonLabel, clickAction;
    if (err.message.includes("Session doesnt exist") || err.message.includes("expired")) {
        msgTitle = "Session Expired"
        messageString = "Your session has expired. Please login again to continue."
        buttonLabel = "OK"
        clickAction = () => {
            location.pathname = logoutRedirect
        }
    } else if (err.message.includes("AADB2C90091") || err.message.includes("user_cancelled")) {
        // FIXME: 28/08/22 - Temporary Fix for when user cancels without changing password
        // msgTitle = "Login Cancelled"
        // messageString = `Attempt to login has been cancelled`
        // buttonLabel = "OK"
        // clickAction = () => {
        //     location.pathname = logoutRedirect
        // }
        // PMD 10/08/22 Just return as user has cancelled login
        return
    } else {
        msgTitle = "Error"
        messageString = `An unexpected error has occured. Please contact support error:  ${err.message}`
        buttonLabel = "OK"
        clickAction = () => {
            // location.pathname = logoutRedirect
        }
    }

    // PMD 08/08/22 Doesn't currenlty do anything
    // await logoutSession(sessionId)

    setCookie("sessionId", "", -999)
    confirmAlert({
        title: msgTitle,
        message: messageString,
        buttons: [
            {
                label: buttonLabel,
                onClick: clickAction
            },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    })
}

function getCookie(cookie_name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + cookie_name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(name, value, daysToLive) {
    // Encode value in order to escape semicolons, commas, and whitespace
    var cookie = name + "=" + encodeURIComponent(value);

    if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires
        after the specified number of days */
        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);

        document.cookie = cookie;
    }
}


// function logout(sessionId) {
//     let URI = `${config[env].mainApiUrl}auth/Logout`;
//     let headers = {
//         sessionId
//     };
//     let options = null;

//     const credentials = 'omit';

//     return new Promise((resolve, reject) => {
//         postRequest(URI, options, credentials, headers)
//             .then(apiParseSpotlite)
//             .then(response => {
//                 // console.log(response)
//                 let result = response.Result
//                 resolve(result)
//             })
//             .catch((error) => {
//                 // console.log(error)
//                 reject(error)
//             });

//     })
// }