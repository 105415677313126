// eslint-disable-next-line
const REGEXP_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEXP_PHONE = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/

export function validateEmail( email ){
	return new Promise( ( resolve, reject ) => {
		if (email.length === 0) {
			reject('An email address is required');
		} 
		REGEXP_EMAIL.test( String(email).toLowerCase() ) ? resolve(email) : reject(`Unexpected email address format`);
	} );
}

export function validatePhoneNo( phoneNumber ){
	return new Promise( ( resolve, reject ) => {
		REGEXP_PHONE.test( String(phoneNumber)) ? resolve(phoneNumber) : reject(`Unexpected phone number format`);
	} );
}

export function isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
	}
	// TODO: Check whether this change causes problems
    // eslint-disable-next-line
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}
