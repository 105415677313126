import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import './style/ServicePacks.css';
class ServicePackCatalogue extends Component {

	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'li'
	};

	constructor(props) {
		super(props);

		this.state = {
			expandCatalogue: false,
			hideCatalogue: false
		};
	}

	componentDidUpdate(prevProps) {

		if (this.props.currentCatalogues !== prevProps.currentCatalogues) {
			// the catalogues array has been updated
			// we need to reset and use this
			// console.log('ServicePackCatalogue updated ' + JSON.stringify(this.props.currentCatalogues));
		}

		if (this.props.catalogueSelected !== prevProps.catalogueSelected) {
			if (this.props.catalogueSelected) { // A catalogue is selected
				if (this.props.catalogueSelected === this.props.catalogue.CatalogueCode) { // This component is selected
					//setTimeout(() => {
					this.setState({
						expandCatalogue: true
					})
					// console.log("Calling");	
					//}, 300);
				} else { // Other components
					// console.log("Calling");	
					this.setState({
						hideCatalogue: true
					});
				}
			} else { // No catalogue selected
				if (prevProps.catalogueSelected === this.props.catalogue.CatalogueCode) { // This component was selected
					// console.log("Calling");	
					this.setState({
						expandCatalogue: false
					});
				} else { // Other components
					//setTimeout(() => {
					// console.log("Calling");	
					this.setState({
						hideCatalogue: false
					})
					//}, 300);
				}
			}
		}
	}

	handleBackButton = () => {
		// console.log("Calling");	
		this.props.onBackClick();
	};

	renderCatalogueSelectButton = () => {
		const {
			catalogueSelected
		} = this.props;

		const {
			CatalogueCode
		} = this.props.catalogue;

		const selected = catalogueSelected === CatalogueCode;


		return (
			<button
				className="card_button"
				type="button"
				onClick={() => this.props.onCatalogueSelect(CatalogueCode)}
			>
			Select
			</button>
		)
	};

	// renderSelectedText = (isSelected) => {

	// 	if (isSelected) {
	// 		return 'Selected';
	// 	}

	// 	return 'Select';
	// };

	renderAddCatalogueButton = () => {


		const mycat = this.props.catalogueSelected;
		// console.log('this.props.currentCatalogues:', this.props.currentCatalogues);
		// console.log('this.props.catalogues:', this.props.catalogues);

		// const found = _.findIndex(this.props.currentCatalogues, function(o) { 
		const found = _.findIndex(this.props.catalogues, function(o) { 
			return o.catalogueCode == mycat;
		});

		const label = found >= 0 ? "Catalogue Owned" : "Add Catalogue";
		const isDisabled = found >= 0 ? true : false;

		return (
			<button
				className="card_button"
				type="button"
				disabled={isDisabled}
				onClick={() => this.props.handleContinue()}
			>
				{label}
			</button>
		)


	}

	renderServicePacks = () => {

		return this.props.catalogue.ServicePacks.map((pack, index) => {
			return (
				<div
					className="card"
					key={index}
				>
					
					<div className="card_container">

					<div className="icon_wrapper" onClick={() => this.props.onPackSelect(pack)} >
						<div className="icon--info"/>
					</div>
				
					<div className="card_headline_wrapper">
						<span className="card_headline">
							{pack.ServicePackName}		
						</span>
					</div>

					<div className="card_content">
						{pack.ServicePackShortDescription}
					</div>
						
					{/* Add Catalogue */}
					{this.renderAddCatalogueButton()}
				
					</div>
				</div>
			)
		});
	};

	renderContents = () => {
		const {
			CatalogueShortDescription
		} = this.props.catalogue;

		if (this.state.expandCatalogue) {

			return (
				<div className="card_content">
					{this.renderServicePacks()}
				</div>
			)
		}

		return (
			<div className="card_content">
				<p>{CatalogueShortDescription}</p>
				{this.renderCatalogueSelectButton()}
			</div>
		)
	};

	render() {
		const {
			componentType
		} = this.props;

		const {
			CatalogueName,
			CatalogueShortDescription
		} = this.props.catalogue;

		const classExpanded = (this.state.expandCatalogue) ? 'card--expanded' : '';
		const classHide = (this.state.hideCatalogue) ? 'card--hide' : '';

		const Element = componentType;
		const classNames = `card ${classExpanded} ${classHide}`;

		if(this.state.expandCatalogue) {
			return (	
				<div className="card_services">
					<div className="headline_wrapper">
						<span className="card_headline">{CatalogueName}</span>
					</div>
					<div className="card_grid">
						{this.renderServicePacks()}
					</div>				
				</div>
			);
		} else {
			return (
				<Element className={classNames}>
					<div className="card_container">		
						<div className="card_headline_wrapper">
							<span className="card_headline">{CatalogueName}</span>
						</div>
						
						<div className="card_content">
							<p>{CatalogueShortDescription}</p>
							{this.renderCatalogueSelectButton()}
						</div>
					</div>
				</Element>
			);
		}		
	};
}

const mapStateToProps = (state) => {
	const { catalogues } = state.credits;
	return { catalogues };
};

export default connect(mapStateToProps, {})(ServicePackCatalogue);
