import React, { Component } from 'react';
import { connect } from 'react-redux';
// Kept for now as will need
import { Redirect } from 'react-router';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Loader from '../Loader/Loader';

import config from '../../config';
import { postRequest, apiParse } from '../../utils/Common';

import { fetchApplications } from '../../actions';
import { errorHandler } from '../../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

class PostContext extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      deleteStatus: false,
      deleteAttempted: false,
      postOutcomeMessage: null
    };
  }

  componentDidMount() {
    const { spotliteAddress, sessionId } = this.props;

    const URI = `${config[env].URL}/can-segment-be-deleted`;
    const credentials = 'omit';
    const options = { spotliteAddress };
    const headers = { sessionid: sessionId };

    return postRequest(URI, options, credentials, headers)
      .then(apiParse)
      .then(response => {
        this.setState(
          {
            deleteStatus: response.deleteStatus,
            postOutcomeMessage: response.message
          },
          () => this.confirmDeleteSegment()
        );
        return;
      })
      .catch(err => {
        errorHandler(err)
        postOutcomeMessage = err.message;
        return err;
      })
  }

  confirmDeleteSegment = () => {
    const { deleteStatus, postOutcomeMessage } = this.state;

    let buttonOptions = [];
    if (deleteStatus) {
      buttonOptions.push(
        {
          label: 'Yes',
          onClick: () => this.deleteSegment()
        },
        {
          label: 'No',
          onClick: () => this.postActionStatus()
        },
      )
    } else {
      buttonOptions.push(
        {
          label: 'OK',
          onClick: () => this.postActionStatus()
        }
      )
    }
    return (
      confirmAlert({
        title: 'Delete Work Queue',
        message: postOutcomeMessage,
        buttons: buttonOptions
      })
    );
  };

  deleteSegment = () => {
    const { spotliteAddress, sessionId } = this.props;

    const URI = `${config[env].URL}/delete-segment`;
    const credentials = 'omit';
    const options = { spotliteAddress };
    const headers = { sessionid: sessionId };

    this.setState({ 
      deleteAttempted: true
    });

    let postOutcomeMessage;
    return postRequest(URI, options, credentials, headers)
      .then(apiParse)
      .then(result => {
        postOutcomeMessage = result.message;
        return result.deleteStatus ? this.props.fetchApplications('') : null;
      })
      .catch(err => {
        postOutcomeMessage = err.message;
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        return err;
      })
      .finally(() => {
        this.setState(
          {
            postOutcomeMessage
          },
          () => this.postActionStatus()
        );
      })
  };

  postActionStatus = () => {
    const { deleteAttempted, postOutcomeMessage } = this.state;

    this.setState({
      loading: false
    });

    return (
      deleteAttempted
        ?
          confirmAlert({
            title: 'Delete Work Queue',
            message: postOutcomeMessage,
            buttons: [
              {
                label: 'OK',
                onClick: () => this.props.closePost()
              },
            ],
          })
        :
        this.props.closePost()
    );
  };

  render() {
    const { loading } = this.state;

    return (
      <Loader
        className="loader__spinner"
        active={loading}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  return { sessionId };
};

export default connect(mapStateToProps, { fetchApplications })(PostContext);