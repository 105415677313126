import Avatar from "react-avatar";
const dateFormatter = new Intl.DateTimeFormat(navigator.language);

function getConditionalValidator({
    value,
    condition,
    comparator,
}) {
    // console.table(value, condition, comparator)

    let result;
    switch (condition) {
        case "GT":
            result = value > comparator;
            break;
        case "LT":
            result = value < comparator;
            break;
        case "EQ":
            result = value === comparator;
            break;
        case "LE":
            result = value <= comparator;
            break;
        case "GE":
            result = value >= comparator;
            break;
        case "NE":
            result = value !== comparator;
            break;
        default:
            result = false;
    }
    return result;
}

export function TimestampFormatter({ timestamp }) {
    // console.log(timestamp instanceof Date)
    if (timestamp instanceof Date && !isNaN(timestamp.valueOf())) {
        // Check Valid Date
        return <>{dateFormatter.format(timestamp)}</>;
    } else {
        // Not a date return an empty value
        return <></>
    }

}

export function ImageFormatter({ thumbnail, name }) {
    return (
        <div
            style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
        >
            {thumbnail !== "" ? (
                <Avatar size="25" round={true} name={name} src={thumbnail} />
            ) : null}
        </div>
    );
}

export function ConditionFormatter(props) {
    // console.log(props)
    const { displayValue, conditions, row, column } = props;
    let updatedStyle = {}
    let defaultStyle = {}
    let screenValue;

    // console.log(conditions[0].formatRule.case)

    const conditionType = conditions[0].formatRule.case.filter((c) => {
        // console.log(row[column.key])
        // console.log(c.operand[0])
        // console.log(displayValue)


        if (column.controlName === 'text') {
            // return c.operand[0] === displayValue
            return getConditionalValidator({ value: c.operand[0], condition: c.operator, comparator: displayValue })
        } else if (column.controlName === 'date') {
            return getConditionalValidator({ value: c.operand[0], condition: c.operator, comparator: displayValue })
        }
    })

    if (conditionType.length > 0) {
        let matchFound = getConditionalValidator({ value: conditionType[0].operand[0], condition: conditionType[0].operator, comparator: displayValue })

        if (matchFound) {
            updatedStyle.background = conditionType[0].backColour
            updatedStyle.borderRadius = conditionType[0].borderRadius
            updatedStyle.color = conditionType[0].foreColour
            updatedStyle.fontSize = conditionType[0].fontSize
            updatedStyle.fontStyle = conditionType[0].fontStyle
            updatedStyle.fontVariant = conditionType[0].fontVariant
            updatedStyle.fontWeight = conditionType[0].fontWeight
            updatedStyle.textAlign = conditionType[0].textAlign
        }
        else {
            // 21/02/22 FIXME: Awaiting for consistence in rule definition exceptionMessage or exceptionRule 
            // defaultStyle = conditions[0].exceptionMessage.case[0] 
            // updatedStyle.background = defaultStyle.backColour
            // updatedStyle.borderRadius = defaultStyle.borderRadius
            // updatedStyle.color = defaultStyle.foreColour
            // updatedStyle.fontSize = defaultStyle.fontSize
            // updatedStyle.fontStyle = defaultStyle.fontStyle
            // updatedStyle.fontVariant = defaultStyle.fontVariant
            // updatedStyle.fontWeight = defaultStyle.fontWeight
            // updatedStyle.textAlign = defaultStyle.textAlign
        }
    }

    // Format Date columns
    if (column.controlName === 'date') {
        let dateToFormat = new Date(row[column.key])
        screenValue = TimestampFormatter({ timestamp: dateToFormat })
    } else {
        screenValue = displayValue
    }

    return (
        <div style={updatedStyle}>
            {screenValue}
        </div>
    )
}

