import {
  // PMD 09/12/21 remove sessionDetails
  // SET_TEAM_GUID,
  SET_MOBILE_EMBEDDED,
  SET_SESSION_WORKING,
  SET_SESSION,
  LOGOUT,
  // PMD 09/12/21 remove sessionDetails
  // LOAD_SESSION_DETAILS,
  SET_SESSION_ERROR,
  REDIRECT,
  REDIRECT_COMPLETE,
  SET_DATA_CONFIG,
  SET_SIDEBAR_VIEWGUID,
  CHANGE_TEAM_REQUEST,
  CHANGE_TEAM_ERROR,
  SET_PORTAL
  // SET_VIEWGUID_MAP,
} from '../actions/types';

import deepCopy from '../utils/deepCopy';

const INITIAL_STATE = {
  sessionId: '',
  // PMD 09/12/21 remove sessionDetails
  // sessionDetails: null,
  redirectUrl: '',
  error: '',
  working: false,
  mobileEmbedded: false,
  dataConfig: null,
  viewGUID: null,
  configLoading: false,
  portalDetails: null
  // viewGUIDMap: null,
};

export default (state = INITIAL_STATE, action) => {

  const newState = deepCopy(state);

  switch (action.type) {
    case SET_SESSION_WORKING:
      return { ...newState, working: action.payload };
    case SET_MOBILE_EMBEDDED:
      return { ...newState, mobileEmbedded: action.payload };
    case SET_SESSION:
      return { ...newState, sessionId: action.payload }; //'6f296111-1c37-4eac-85c2-701465c10b4f'
    case CHANGE_TEAM_REQUEST:
      return {
        ...newState,
        configLoading: true
      };
    case SET_DATA_CONFIG:
      return {
        ...newState,
        dataConfig: action.payload,
        configLoading: false
      };
    case CHANGE_TEAM_ERROR:
      return {
        ...newState,
        error: action.payload,
        configLoading: false
      };
    case LOGOUT:
      // PMD 09/12/21 remove sessionDetails
      // return { ...newState, sessionId: '', sessionDetails: null };
      return { ...newState, sessionId: null };
    // PMD 09/12/21 remove sessionDetails
    // case LOAD_SESSION_DETAILS:
    //   const newSessionDetails = action.payload;
    //   return { ...newState, sessionDetails: newSessionDetails };
    // PMD 09/12/21 remove sessionDetails
    // case SET_TEAM_GUID:
    // // sets only the team guid, keeps the rest of sessionDetails intact
    // const guidSessionDetails = { ...newState.sessionDetails }
    // guidSessionDetails.teamGUID = action.payload;

    // return { ...newState, sessionDetails: guidSessionDetails };
    // case SET_USERS_TEAMS:
    // sets the allUserTeams array within sessionDetails
    // doesnt alter any other sessionDetails
    // const usersSessionDetails = {...newState.sessionDetails}
    // usersSessionDetails.allUserTeams = action.payload;

    // return { ...newState, sessionDetails: usersSessionDetails };
    case SET_SESSION_ERROR:
      return { ...newState, error: action.payload };
    case REDIRECT:
      return { ...newState, redirectUrl: action.payload };
    case REDIRECT_COMPLETE:
      return { ...newState, redirectUrl: '' };
    case SET_SIDEBAR_VIEWGUID:
      return { ...newState, viewGUID: action.payload };
    // case SET_VIEWGUID_MAP:

    // let map = new Map();

    //Grab MenuItem GUIDs
    // action.payload.TopBarOptions.map((option) => {
    //   return map.set(option.ServiceCode, option.viewGUID);
    // });

    // action.payload.SideBarOptions.map((option) => {
    //   return map.set(option.ServiceCode, option.viewGUID);
    // });

    // console.log("SessionsReducer", action.payload);

    // return { ...state, viewGUIDMap: map };
    case SET_PORTAL:
      return { ...newState, portalDetails: action.payload };
    default:
      return state;
  }
};
