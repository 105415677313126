import PropTypes from 'prop-types';
import React, { Component } from 'react';
import config from '../../config';
import { postRequest, apiParse } from '../../utils/Common.js';
import { errorHandler } from '../../utils/errorHandler';
import BusinessHeader from '../Header/BusinessHeader';
import Loader from '../Loader/Loader';

import './style/BusinessSearch.css';

const env = process.env.NODE_ENV || 'development';

class BusinessSearchForm extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			error: null,
			workInProgress: false,
			companyName: '',
			companyNumber: '',
			companyNameInProgress: false,
			companyNumberInProgress: false

		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getSearchResults() {
		// const URI = `${config[env].BIZIQURL}${config[env].BIZIQPORT}/home/search?name=${this.state.searchValue}`;
		const { companyName, companyNumber } = this.state;
		// PMD 02/09/21 Remove PORT and home to enable use of API Gateway
		// const URI = `${config[env].URL}${config[env].PORT}/home/company-search`;
		const URI = `${config[env].URL}/company-search`;
		const options = {
			companyName,
			companyNumber
		};
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		// getRequest(URI, credentials, headers)
		return postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(response => {
				if (response instanceof Error) {
					throw response;
				}
				if (response.error) {
					throw new Error(response.error)
				}
				this.setState({
					workInProgress: false,
					companyNameInProgress: false,
					companyNumberInProgress: false

				});
				this.props.updateSearchResults(response.results, (companyName || companyNumber));
			})
			.catch(err => {
				// PMD 20/05/22 Generic error handler
				errorHandler(err)
				let errorMessage = err.message || err;
				this.setState({
					workInProgress: false,
					companyNameInProgress: false,
					companyNumberInProgress: false,
					error: errorMessage
				});
				return errorMessage;
			});
	}

	handleChange = event => {
		let error = this.state.error ? null : this.state.error;

		this.setState({
			[event.target.name]: event.target.value,
			[`${event.target.name}InProgress`]: false,
			error: error,
			workInProgress: false
		})
	};

	handleSubmit = event => {
		event.preventDefault();

		let { companyName, companyNumber } = this.state;
		if (companyName) {
			this.setState({
				companyNameInProgress: true,
				workInProgress: true
			});
			this.getSearchResults();
		} else if (companyNumber) {
			this.setState({
				companyNumberInProgress: true,
				workInProgress: true
			});
			this.getSearchResults();
		} else {
			this.setState({
				error: 'Please input your company / sole trader / partnership name OR number'
			});
		}
	};

	getButtonText() {
		return this.state.workInProgress ? "Searching, please Wait..." : "Search"
	}

	render() {
		const { error } = this.state;

		return (
			<>
				<BusinessHeader showBackButton={false} title={"Company Search"} backLabel={"Back to search"} />

				{/* <div style={{
					marginTop: 20,
					marginLeft: 30
				}}>
					<h2>This screen allow you to create an application for a UK business.</h2>
					<h2>Use the search feature below to find the business.</h2>
				</div> */}

				<div className="business-search">
					<div className="business_search_form">
						<form
							name="companySearch"
							id="companySearch"
							className="form"
							onSubmit={this.handleSubmit}
						>
							<fieldset className="form__fieldset">
								<label className="form__label" htmlFor="companyName">Company Name</label>
								<input
									className="form__input"
									type="text"
									name="companyName"
									value={this.state.companyName}
									onChange={this.handleChange}
									placeholder="Enter Company Name"
								/>
								<Loader
									className="form__input-spinner"
									active={this.state.companyNameInProgress}
									activeName="loader--active"
									loaderName="loader"
									spinnerName="loader__spinner"
								/>
							</fieldset>

							<fieldset className="form__fieldset">
								<label className="form__label" htmlFor="companyNumber">Company Number</label>
								<input
									className="form__input"
									type="text"
									name="companyNumber"
									value={this.state.companyNumber}
									onChange={this.handleChange}
									placeholder="Enter Company Number"
								/>
								<Loader
									className="form__input-spinner"
									// active={this.state.workInProgress}
									active={this.state.companyNumberInProgress}
									activeName="loader--active"
									loaderName="loader"
									spinnerName="loader__spinner"
								/>
							</fieldset>


							<fieldset className="form__fieldset">
								<input
									type="submit"
									className="button action button--wide"
									disabled={this.state.workInProgress}
									value={this.getButtonText()}
								/>
							</fieldset>

							<fieldset className="form__fieldset">
								<div className="form__message form__message--error">
									{error ? error : <br />}
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</>
		);
	}
}


export default BusinessSearchForm;