import React, { Component } from 'react';
import { Redirect } from "react-router";
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import AccountsIndex from './AccountsIndex';
import Credits from '../Credits/Credits';
import CreditsSplitContainer from '../CreditsSplit/CreditsSplitContainer';
import Catalogues from '../Catalogues/Catalogues';
import App404 from '../App404/App404';

import { getCatalogue } from "../../actions";

class Accounts extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false
    };
  }

  async componentDidMount() {
    if (isEmpty(this.props.catalogueData)) {
      await this.props.getCatalogue({
        appConfig: 'CCATALOG', 
        servicePackID: 'DEFAUlT'
      });

      if (this.props.catalogueError instanceof Error) {
        this.setState({
          redirect: true,
        });
      }
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return (
        <Redirect to={'/login'} />
      )
    } 

    if (this.props.catalogueLoading) {
      return null;
    }

    return (
      <Switch>
        <Route exact path='/account' 
          render={(props) => 
            <AccountsIndex {...props} />
          } 
        />
        <Route path='/account/credits' 
          render={(props) => 
            <Credits {...props} />
          } 
        />

        <Route path='/account/splitcredits' 
          render={(props) => 
            <CreditsSplitContainer {...props} />
          } 
        />

        <Route path='/account/catalogues' 
          render={(props) => 
            <Catalogues {...props} />
          } 
        />
          
        <Route component={App404}/>
      </Switch>

    );
  }
}

// export default Accounts;
const mapStateToProps = (state) => {
  const { catalogueData, catalogueLoading, catalogueError } = state.catalogue;

  return { catalogueData, catalogueLoading, catalogueError };
};

export default connect(mapStateToProps, { getCatalogue })(Accounts);
