import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from "react-router";
import { isEmpty } from "lodash";
import {
  selectInvoice,
  loadTransactions,
  setTransactionError,
  resetInvoice,
  updateApplicationLayout,
  getCatalogue
} from '../../actions';

// import SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";
import _ from 'lodash';

import TransactionInvoice from './TransactionInvoice';

import ScreenRibbon from '../Reusable/ScreenRibbon';
import TransactionsGrid from './TransactionsGrid';
import GetFormData from '../Form/GetFormData';
import { activityDownloadScreenGUID } from '../../utils/Constants'

class Transactions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      CatalogueNamesArr: [],
      modalOpen: false,
      downloadType: null,
      isLoading: true,
      redirect: false,
    };
  }

  async componentDidMount() {
    this.props.setTransactionError('');
    // console.log(this.props)
    this.props.loadTransactions();
    this.props.updateApplicationLayout({ componentType: "", label: 'Activity', isVisible: false });

    if (isEmpty(this.props.catalogueData)) {
      await this.props.getCatalogue({
        appConfig: 'CCATALOG',
        servicePackID: 'DEFAUlT'
      });

      if (this.props.catalogueError instanceof Error) {
        this.setState({
          redirect: true,
        });
      }
    }

    this.setState({
      CatalogueNamesArr: this.loadCatalogueNames(),
      isLoading: false
    })
  }

  loadCatalogueNames = () => {

    // const catalogueArray = SpotliteClientCatalogue.Catalogue.map(function (item, i) {
    const catalogueArray = this.props.catalogueData.Catalogue.map(function (item, i) {
      return { CatalogueCode: item.CatalogueCode, CatalogueName: item.CatalogueName }
    }, this);
    catalogueArray.push({ CatalogueCode: '999', CatalogueName: 'Unallocated' })

    return catalogueArray;
  }

  renderInvoice = () => {

    if (!this.props.invoiceId) return;

    return (
      <div>
        <TransactionInvoice handleBackButton={this.handleBackButton} />
      </div>
    )
  }

  renderTransactionList = () => {

    if (this.props.invoiceId) return;

    return (<TransactionsGrid rows={this.props.transactions} selectInvoice={(invoiceId) => this.cellClick(invoiceId)} />)

  }

  cellClick = (invoiceId) => {

    // console.log(invoiceId)

    if (invoiceId !== "Invoice ID") {
      this.props.selectInvoice(invoiceId);
    }
  }

  handleBackButton = () => {

    this.props.setTransactionError('');
    this.props.resetInvoice();
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onClose = () => {
    this.setState({ modalOpen: false });
  };

  cancelModal = () => {
    this.setState({ modalOpen: false });
  };

  handleButtonClick = (viewGUID) => {
    this.setState({ downloadType: viewGUID })
    this.onOpenModal()
  }

  renderModal = () => {
    if (this.state.modalOpen) {
      return (
        <GetFormData
          closeModal={this.onClose}
          cancelModal={this.cancelModal}
          screenType={activityDownloadScreenGUID}
          modalScreen={true}
        />
      )
    } else {
      return null;
    }

  }

  renderDownloadButtons = () => {
    const { dataConfig } = this.props

    // console.log(dataConfig)
    // Check for TransactionScreen setting in dataConfig
    if (!dataConfig.TransactionsScreen) return null

    if (!dataConfig.TransactionsScreen.ScreenRibbon) return null

    return (
      <div className='download-buttons'>
        <ScreenRibbon screenRibbon={dataConfig.TransactionsScreen.ScreenRibbon} handleButtonClick={(viewGUID) => this.handleButtonClick(viewGUID)} />
      </div>
    )
  }

  renderError = () => {
    return (
      <div className="error row_space_around">{this.props.error}</div>
    )
  }

  render() {
    const { redirect } = this.state;

    if (this.state.isLoading) return null;

    if (redirect) {
      return (
        <Redirect to={'/login'} />
      )
    }
    return (

      <>
        < div className="sub_header_content" >
          {this.renderDownloadButtons()}
        </div >

        <>
          {this.renderTransactionList()}

          {this.renderInvoice()}

          {this.renderModal()}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId, dataConfig } = state.session;
  const { transactions, invoiceId, error } = state.transactions;
  const { catalogueData, catalogueLoading, catalogueError } = state.catalogue;
  return { sessionId, dataConfig, transactions, invoiceId, error, catalogueData, catalogueLoading, catalogueError };
};

export default connect(mapStateToProps, {
  selectInvoice,
  loadTransactions,
  setTransactionError,
  resetInvoice,
  updateApplicationLayout,
  getCatalogue
})(Transactions);


