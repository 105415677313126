const intialState = {
    themeData: null,
    error: ""
}
const ThemeConfigReducer = (state = intialState, action = null) => {

    switch (action.type) {
        case "FetchConfigData":
            // console.log(action)
            return { ...state, themeData: action.themeData }
        case "ERROR":
            return { ...state, error: action.msg }
        default:
            return state
    }

}

export default ThemeConfigReducer