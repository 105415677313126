import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

//This is experimental testbed, not being used - Ben

class ModalDialogForm extends Component {
  
    render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    return (

      <Modal
        open={true}
        onClose={() => this.props.onClose()}
        center
        closeOnOverlayClick={false}
        classNames={{
          modal: "custom_modal_style",
        }}>

        <p>{this.props.children}</p>
        <p>Do you want to move this task into this segment.</p>
        <button type="button" className="custom_modal_button" data-dismiss="modal" onClick={() => this.props.onConfirm()}>Confirm</button>
        <button type="button" className="custom_modal_button indent" data-dismiss="modal" onClick={() => this.props.onClose()}>Cancel</button>
        
        
      </Modal>
    );
  }
}

ModalDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default ModalDialogForm;