import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import moment from "moment";
import Chart from 'react-google-charts';
import { filterData } from "../../utils/Filter";


class ApplicationStats extends Component {

  renderSegmentsPieChart = () => {

    //This is what we're calling but its empty
    //let statScreens = [...this.props.Screens];
    //let chartData = statScreens[3].Panels[0].WorkloadChart.ChartData;

    const chartData = [["Status Name", "No. of Applications"]];
    const slicesData = {};
    //No slices Data, would need chartData to not be an empty object to work

    if (!_.isEmpty(this.props.Segments)) {

      const segmentValues = _.values(this.props.Segments);
      const search = [...this.props.searchCriteria];

      for (var i = 0; i < segmentValues.length; i++) {

        if (isEmpty(search)) {
          chartData.push([
            segmentValues[i].segmentName,
            (segmentValues[i].applicationTaskIDs) ? segmentValues[i].applicationTaskIDs.length : 0
          ]);
        } else {

          let count = 0;

          segmentValues[i].applicationTaskIDs.forEach(element => {
            if (filterData(this.props.ApplicationTasks[element], search)) {
              count += 1;
            }
          });

          chartData.push([
            segmentValues[i].segmentName,
            count
          ]);
        }

        //explode the all
        slicesData[i] = { offset: 0.1 }
      }
    }

    return (
      <div className="chart">
        <h2 className="chartTitle">Current Workload</h2>
          <Chart
            width={'500px'}
            height={'400px'}
            chartType="PieChart"
            loader={<div style={{ marginLeft: 30 }}>Loading Chart</div>}
            data={chartData}
            options={{
              legend: 'none',
              pieSliceText: 'label',
              title: '',
              is3D: true,
              slices: slicesData,
            }}
            rootProps={{ 'data-testid': '1' }}
          />
      </div>
    )
  }



  renderServicePieChart = () => {

    const chartData = [["Service Name", "No. of Applications"]];
    const slicesData = {};

    //Going through application tasks and tallying services by service name
    if (!_.isEmpty(this.props.ApplicationTasks)) {

      const applicationTaskValues = { ...this.props.ApplicationTasks };
      const search = [...this.props.searchCriteria];

      let serviceData = {};

      let checkedData = Object.values(applicationTaskValues)
        .filter(task => isEmpty(search) || filterData(task, search));

      //console.log(checkedData);

      checkedData.forEach(function (item) {

        if (item.serviceName !== null) {

          let serviceTrimmed = item.serviceName.replace(/\s/g, '').toLowerCase();

          if (serviceData[serviceTrimmed]) {
            serviceData[serviceTrimmed].count += 1;
          } else {
            serviceData[serviceTrimmed] = {
              title: item.serviceName,
              count: 1
            }
          }
        }

      });

      //console.log(serviceData);

      const serviceValues = _.values(serviceData);

      for (var j = 0; j < serviceValues.length; j++) {
        chartData.push([
          serviceValues[j].title,
          serviceValues[j].count
        ]);
        //explode the all
        slicesData[j] = { offset: 0.1 }
      }

    }

    return (
      <div className="chart">
        <h2 className="chartTitle">Service Breakdown</h2>
          <Chart
            width={'500px'}
            height={'400px'}
            chartType="PieChart"
            loader={<div style={{ marginLeft: 30 }}>Loading Chart</div>}
            data={chartData}
            options={{
              legend: 'none',
              pieSliceText: 'label',
              title: '',
              is3D: true,
              slices: slicesData,
            }}
            rootProps={{ 'data-testid': '2' }}
          />
      </div>
    )
  }


  renderBarChart = () => {

    const chartData = [["Date", "No. of Applications"]];

    //Going through application tasks and tallying services by service name
    if (!_.isEmpty(this.props.ApplicationTasks)) {

      const applicationTaskValues = { ...this.props.ApplicationTasks };
      const search = [...this.props.searchCriteria];

      let dateData = {};

      let checkedData = Object.values(applicationTaskValues)
        .filter(task => isEmpty(search) || filterData(task, search));

      checkedData.forEach(function (item) {
        const createdDate = moment(item.taskCreatedDateAndTime, 'YYYY-MM-DDTHH:mm:ss.000Z').format('ddd DD/M');

        if (dateData[createdDate]) {
          dateData[createdDate].count += 1;
        } else {
          dateData[createdDate] = {
            title: createdDate,
            count: 1
          }
        }
      });

      //show last 7 days
      let dayMmt = moment().subtract(7, 'days');

      for (let k = 0; k < 7; k++) {

        dayMmt = dayMmt.add(1, 'days');

        let dayName = dayMmt.format('ddd DD/M');

        chartData.push([
          dayName,
          _.has(dateData, dayName) ? dateData[dayName].count : 0
        ]);
      }

    }

    return (
      <div className="chart">
        <h2 className="chartTitle">Applications last 7 days</h2>
          <Chart
            width={'500px'}
            height={'400px'}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
              legend: 'none',
              title: ''
            }}
          />
      </div>
    )
  }

  renderFutureBarChart = () => {

    const chartData = [["Date", "No. of Applications"]];



    if (!_.isEmpty(this.props.ApplicationTasks)) {

      const applicationTaskValues = { ...this.props.ApplicationTasks };
      const search = [...this.props.searchCriteria];

      let dateData = {};

      let checkedData = Object.values(applicationTaskValues)
        .filter(task => isEmpty(search) || filterData(task, search));

      checkedData.forEach(function (item) {
        const dueDate = moment(item.taskDueDateTime, 'YYYY-MM-DDTHH:mm:ss.000Z').format('ddd DD/M');

        if (dateData[dueDate]) {
          dateData[dueDate].count += 1;
        } else {
          dateData[dueDate] = {
            title: dueDate,
            count: 1
          }
        }
      });

      //show next 7 days
      let dayMmt = moment();

      for (let k = 7; k < 14; k++) {

        dayMmt = dayMmt.add(1, 'days');

        let dayName = dayMmt.format('ddd DD/M');

        chartData.push([
          dayName,
          (_.has(dateData, dayName)) ? dateData[dayName].count : 0
        ]);
      }

    }

    // console.log(chartData);

    return (
      <div className="chart">
        <h2 className="chartTitle">Due in the next 7 days</h2>
          <Chart
            width={'500px'}
            height={'400px'}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
              legend: 'none',
              title: ''
            }}
          />
      </div>
    )
  }

  render() {

    return (

      <div className="stats">

        {/* <div style={{ 
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '10px'
          }}> */}

        { this.renderSegmentsPieChart()}

        { this.renderServicePieChart()}

        { this.renderBarChart()}

        { this.renderFutureBarChart()}


        {/* </div> */}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { searchCriteria } = state.applications;
  const { Segments, Screens, ApplicationTasks } = state.applications.applications;
  return { Segments, Screens, ApplicationTasks, searchCriteria };
};

export default connect(mapStateToProps, {})(ApplicationStats);
