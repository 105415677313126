import { compact } from "lodash";
import { getValidationFormatter } from "./columnFormatters";
import { getConditionalValidator } from "./validators";

export function getColumns(gridLayout, defaultStyle) {
    const enhancedColumns = [];

    // console.log(gridLayout)
    for (let i = 0; i < gridLayout.columnHeadings.length; i++) {
        let newStyle = { ...defaultStyle }
        let newColumn = {};

        // console.log(defaultStyle)
        const column = gridLayout.columnHeadings[i];

        //console.log(column)

        // Set styling based on column or default
        newStyle.backgroundColor = column.backgroundColour || defaultStyle.backgroundColor
        newStyle.borderRadius = column.borderRadius || defaultStyle.borderRadius
        newStyle.textAlign = column.textAlign || defaultStyle.textAlign
        newStyle.color = column.foreColour || defaultStyle.color
        newStyle.fontFamily = column.fontFamily || defaultStyle.fontFamily
        newStyle.fontSize = column.fontSize || defaultStyle.fontSize
        newStyle.fontStyle = column.fontStyle || defaultStyle.fontStyle
        newStyle.fontWeight = column.fontWeight || defaultStyle.fontWeight
        newStyle.fontVariant = column.fontVariant || defaultStyle.fontVariant

        if (column.controlName === 'date') {
            newColumn = {
                renderCell(props) {
                    return getValidationFormatter({ validator: 'date', newStyle, data: props })
                }
            }
        }

        if (column.controlName === 'avatar') {
            newColumn = {
                renderCell(props) {
                    return getValidationFormatter({ validator: 'avatar', newStyle, data: props })
                    //return <div className="avatar_data_grid" style={{ backgroundImage: `url(${props.row[props.column.key]})` }} />;
                }
            }
        }

           // handle formatting based on condition rule
        if (column.ConditionalRule) {
            const columns = gridLayout.columnHeadings
            const conditionRules = gridLayout.conditionrules
            newColumn = {
                renderCell(props) {
                    return getValidationFormatter({ validator: 'hasConditionRule', conditionRule: column.ConditionalRule, columns, conditionRules, newStyle, data: props })
                }
            }
        }

         // rename fields 
         newColumn.key = column.columnPropertyName;
         newColumn.name = column.columnLabel;
         newColumn.width = parseInt(column.defaultWidth);
         newColumn.propertyType = column.propertyType;
         newColumn.propertyGUID = column.propertyGUID;
         newColumn.controlName = column.controlName;
         newColumn.resizable = true;//true;
         newColumn.sortable = false;//true
         // newColumn.style = newStyle

        // console.log(gridLayout)
        if(column.CRUDAction !== "H"){
            enhancedColumns.push(newColumn);
        }

        
    }
    return enhancedColumns
};

export function createColumnMap(columns) {
    let colMap = new Map();
    columns.forEach(column => colMap.set(column.key, column.propertyType));
    return colMap;
}

export function setDefaultStyle(gridLayout) {
    // console.log(gridLayout)
    let defaultStyle = {}
    defaultStyle.color = gridLayout.foreColour;
    defaultStyle.backgroundColor = gridLayout.backColour;
    defaultStyle.fontStyle = gridLayout.fontStyle;
    defaultStyle.fontVariant = gridLayout.fontVariant;
    defaultStyle.fontWeight = gridLayout.fontWeight;
    defaultStyle.fontSize = gridLayout.fontSize;
    defaultStyle.fontFamily = gridLayout.fontFamily;
    defaultStyle.borderRadius = gridLayout.borderRadius;
    defaultStyle.textAlign = gridLayout.textAlign;

    return defaultStyle;
}