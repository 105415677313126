import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreditsSplit from './CreditsSplit';
// import HeaderSimple from '../Header/HeaderSimple';

class CreditsSplitContainer extends Component {


  renderCreditSplit(){
    return (
      <CreditsSplit />
    )
  }

  render() {
    return (

      <div>

        {/* <HeaderSimple showBackButton={ false } title={ "Credits" } /> */}

        <div className="creditSplit">

          { this.renderCreditSplit() }

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const { } = state;
  return { };
};

export default connect(mapStateToProps,{})(CreditsSplitContainer);