import { SET_DEPT_ERROR, SET_RELOAD_DEPT, LOAD_DEPTS, SELECT_DEPT_USER } from './types';

import { postRequest, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';

export const setDeptError = (err) => {
  return {
    type: SET_DEPT_ERROR,
    payload: err
  };
};

export const loadDepts = () => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      const URI = `${config[env].URL}/get-depts-v1-0`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
      };

      return postDeptRequest(dispatch, URI, options, credentials);

    } else {
      handleDeptNoSession(dispatch);
    }
  }
};

export const addUserToDept = ({ deptGUID, PartyGUID }) => {

  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      const URI = `${config[env].URL}/add-user-to-dept-v1-0`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        deptGUID: deptGUID,
        PartyGUID: PartyGUID,
      };

      return postDeptRequest(dispatch, URI, options, credentials);

    } else {
      handleDeptNoSession(dispatch);
    }
  }
};

export const removeUserFromDept = ({ PartyGUID }) => {

  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      const URI = `${config[env].URL}/remove-user-from-dept-v1-0`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        PartyGUID: PartyGUID
      };

      return postDeptRequest(dispatch, URI, options, credentials);

    } else {
      handleDeptNoSession(dispatch);
    }
  }
};

function postDeptRequest(dispatch, URI, options, credentials) {
  /*
  identical for all endpoints that return the full team details
  */
  return postRequest(URI, options, credentials)
    .then(apiParse)
    .then(response => {

      // console.log("*** response!!");
      // console.log(response);

      let deptsArray = (Array.isArray(response.depts)) ? response.depts : [response.depts];

      dispatch({
        type: SET_RELOAD_DEPT,
        payload: false
      })

      dispatch({
        type: LOAD_DEPTS,
        payload: deptsArray
      });

    })
    .catch(err => {
      // PMD 20/05/22 Add generic error handler
      errorHandler(err)
      dispatch({
        type: SET_RELOAD_DEPT,
        payload: false
      })

      dispatch({
        type: SET_DEPT_ERROR,
        payload: "error loading users"
      });
    });
}

function handleDeptNoSession(dispatch) {
  //no secure id
  dispatch({
    type: SET_RELOAD_DEPT,
    payload: false
  })

  dispatch({
    type: LOAD_DEPTS,
    payload: []
  });
}

export const selectDeptUser = (usrObject) => {
  return {
    type: SELECT_DEPT_USER,
    payload: usrObject
  };
};
