import { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import './style/PaymentMethod.css';

const SubmitPayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [ message, setMessage ] = useState(null);
  const [ paymentSuccess, setPaymentSuccess ] = useState(false);
  const [ workInProgress, setWorkInProgress ] = useState(false);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setWorkInProgress(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      // FIXME - Routing
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required',
    });

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      setPaymentSuccess(true);
      setMessage(`Payment status: ${paymentIntent.status} 👍`);
      props.handlePaymentResponse(paymentIntent);
    } else {
        setWorkInProgress(false);
        error ? setMessage(error.message) : setMessage('Unexpected state');
    }
  };

  //Remove section from section payment-method__section
  return (
    <div className="payment-method__section">      
      <form className="payment-method">

        <div id="paymentSection" className="payment-method__paymentSection">
          <PaymentElement />
        </div>

        {/* Show any error or success messages */}
        <div className={paymentSuccess ? "form__message" : "form__message--error"}>
          {message ? message : null}
        </div>

        <button
          type="button"
          className="button"
          disabled={workInProgress}
          onClick={handleSubmit}
        >
          {workInProgress ? 'Processing ... ' : 'Place Order'}
        </button>
      </form>
    </div>
  );
};

export default SubmitPayment;