import React from "react";
import SectionTab from "../SectionTab/SectionTab";

export default function SubHeaderTeams(props) {

  let layoutDisplay = ''

  // PMD 16/02/21 - Asana P1 S1 RBAC Implementation Bug
  if (props.layout === 'teams') {
    layoutDisplay =
      <>
        <div className="right">{props.renderAddUserButton()}</div>
        <div className="">{props.renderAddTeamButton()}</div>
      </>
  } else if (props.layout === 'users') {
    layoutDisplay = <div className="right">{props.renderAddUserButton()}</div>
  }

  // if (!props.agentCRMEnabled) {
  //   if (props.layout === 'teams') {
  //     layoutDisplay =
  //       <> 
  //         <div className="right">{props.renderAddUserButton()}</div>
  //         <div className="">{props.renderAddTeamButton()}</div>
  //       </>
  //   } else if (props.layout === 'users') {
  //     // layoutDisplay = <div className="right">{props.renderAddUserButton()}</div>
  //   }
  // } else {
  //   if (props.layout === 'teams') {
  //     layoutDisplay = <div className="right">{props.renderAddTeamButton()}</div>
  //   } else if (props.layout === 'users') {
  //     layoutDisplay = <div className="right">{props.renderAddUserButton()}</div>
  //   }
  // }

  return (
    <div className="subHeader">
      <div className="subHeaderInner width100">
        <SectionTab
          activeImage="/assets/img/icon_users.png"
          inactiveImage="/assets/img/icon_usersOff.png"
          altName="users"
          title="Agents"
          isActive={props.layout === "users"}
          onClick={props.selectUsersPage}
        />
        <SectionTab
          activeImage="/assets/img/icon_group.png"
          inactiveImage="/assets/img/icon_groupOff.png"
          altName="team"
          title="Teams"
          isActive={props.layout === "teams"}
          onClick={props.selectTeamsPage}
        />
        <SectionTab
          activeImage="/assets/img/list.png"
          inactiveImage="/assets/img/listOff.png"
          altName="enterprise"
          title="Enterprise/Community"
          isActive={props.layout === "enterprise"}
          onClick={props.selectEnterprisePage}
        />
        {/* PMD 21/07/21 Hidden for production go live - P1 S3 Processes Tab */}
        {/* <SectionTab
          activeImage="/assets/img/list.png"
          inactiveImage="/assets/img/listOff.png"
          altName="processes"
          title="Processes"
          isActive={props.layout === "filters"}
          onClick={props.selectFiltersPage}
        /> */}
        {layoutDisplay}
        {/* {props.layout === "users" &&
          <div className="right">{props.renderAddUserButton()}</div>
        }
        {props.layout === "teams" &&
          <div className="right">{props.renderAddTeamButton()}</div>
        } */}
      </div>
    </div>
  );
}
