import { LOAD_COVER, LOAD_SETTINGS, SAVE_SETTINGS, SET_SETTINGS_ERROR } from '../actions/types';

const INITIAL_STATE = {
  // PMD 14/12/21 No longer used
  // settings: {
  //   organisationName: "",
  //   departmentName: "",
  //   spotliteCertificateEmailAddress: null,
  //   clientEmailDownload: 0,
  //   // ICOReferenceNumber: null,
  //   applicantEmailDownload: 0,
  //   correspondenceAddressLine1: null,
  //   correspondenceAddressLine2: null,
  //   correspondenceAddressCity: null,
  //   correspondenceAddressState: null,
  //   correspondenceAddressPostalCode: null
  // },
  cover: '',
  error: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_COVER:
      return { ...state, cover: action.payload };
    // PMD 14/12/21 No longer used
    // case LOAD_SETTINGS:
    //     return { ...state, settings: action.payload };
    //   case SAVE_SETTINGS:
    //     return { ...state };
    case SET_SETTINGS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

