import React, { Component } from 'react';
import { connect } from 'react-redux';
// Kept for now as will need
import { Redirect } from 'react-router';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Loader from '../Loader/Loader';

import config from '../../config';
import { 
    getRequest,
    postRequest, 
    apiParse 
} from '../../utils/Common';

import { fetchApplications } from '../../actions';
import { errorHandler } from '../../utils/errorHandler';

import { 
    CreateDBSEBulkBatchGUID, 
    SubmitDBSEBulkBatchGUID,
    ProcessCRB03GUID,
    ProcessCRB04GUID
} from '../../utils/Constants';

const env = process.env.NODE_ENV || 'development';

class ButtonContext extends Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            title: null,
            buttonOutcomeMessage: null
        };
    }

    async componentDidMount() {
        const { 
            sessionId, 
            buttonViewGUID,
            selectedApplicationTask,
            applicationTasks
        } = this.props;

        let URI = `${config[env].URL}/`;
        let credentials = 'omit';
        let headers = { sessionid: sessionId };
        let options = { viewGUID: buttonViewGUID };
        let functionToInvoke = postRequest;
        let args = [];
        let title = null;
        let buttonOutcomeMessage = null;

        try {
            switch (buttonViewGUID) {
                case CreateDBSEBulkBatchGUID:
                    title = `Create DBS EBulk Batch`;
                    URI = `${URI}create-ebulk-batch`;
                    // args = [ URI, options, credentials, headers ];
                    break;
                case SubmitDBSEBulkBatchGUID:
                    title = `Submit DBS EBulk Batch`;
                    URI = `${URI}submit-ebulk-batch`;
                    options.applicationGUID = applicationTasks[selectedApplicationTask].applicationGUID;
                    // args = [ URI, options, credentials, headers ];
                    break;
                case ProcessCRB03GUID:
                case ProcessCRB04GUID:
                    title = `Process ${buttonViewGUID === ProcessCRB03GUID ? 'CRB03' : 'CRB04'} Response`;
                    URI = `${URI}process-ebulk-batch`;
                    // args = [ URI, options, credentials, headers ];
                    break;
                default:
                    throw new Error(`Unknown Button Context for View ${buttonViewGUID}`);
            };
            // getRequest(URI, credentials, headers)
            // postRequest(URI, options, credentials, headers)

            args = functionToInvoke === postRequest
                ? [ URI, options, credentials, headers ]
                : [ URI, credentials, headers ] 


            let response = await apiParse(await functionToInvoke(...args));
            buttonOutcomeMessage = response.message;
            this.props.fetchApplications('');
        } 
        catch (err) {
            title = 'Error';
            buttonOutcomeMessage = err.message;
            errorHandler(err);
            return err;
        }
        finally {
            this.setState(
                { 
                    title,
                    buttonOutcomeMessage,
                    loading: false
                },
                () => this.postActionStatus()
            );
        };
    }   

    postActionStatus = () => {
        const { title, buttonOutcomeMessage } = this.state;

        this.setState({
            loading: false
        });

        return (
            confirmAlert({
                title: title,
                message: buttonOutcomeMessage,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => this.props.deActivateButtonContext()
                    },
                ],
            })
        );
    };

    render() {
        const { loading } = this.state;

        return (
            <Loader
                className="loader__spinner"
                active={loading}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { sessionId } = state.session;
    const { selectedApplicationTask } = state.applications.selectedApplicationTask;
    const { applicationTasks } = state.applications.applications.ApplicationTasks;
    return { sessionId, selectedApplicationTask, applicationTasks };
};

export default connect(mapStateToProps, { fetchApplications })(ButtonContext);