import * as actions from './actionCreators';
import config from '../config';
import { postRequest, apiParse } from '../utils/Common';
import { errorHandler } from '../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

export const fetchApplications = ({ searchCriteria }) => {
    // FIXME: searchCriteria is passed in originally
    // How does this relate to the bookmark ???
    return (dispatch, getState) => {
        /*
        const options = {
          sessionId: getState().session.sessionId,
          ...searchCriteria
        };
        */

        const URI = `${config[env].URL}/get-bookmark`;
        const credentials = 'omit';
        const headers = {
            sessionid: getState().session.sessionId,
            portaltype: getState().session.portalDetails.portalType,
        };
        const body = { 
            bookmarkGUID: getState().bookmark.bookmarkGUID
        }

        let portaltype = getState().session.portalDetails.portalType
        // let currentSpotliteAddress = getState().session.dataConfig.allTeams.currentTeamDetails.spotliteAddress

        // console.log(currentSpotliteAddress)
        // console.log(getState().session.portalDetails.portalType)
        dispatch(actions.fetchApplicationsRequest());

        return postRequest(URI, body, credentials, headers)
            .then(apiParse)
            .then(response => {

                // console.log(response);
                // console.log(portaltype)
                let bookmarkInfo = response

                if (portaltype === 'applicant') {
                    // Retrieve viewGUID

                    new Promise((resolve) => {
                        const URI = `${config[env].URL}/get-viewGUID-for-panels-v1-0`;
                        const credentials = 'omit';
                        const options = {
                            sessionId: getState().session.sessionId,
                            applicationDefinedByElementID: getState().session.dataConfig.allTeams.currentTeamDetails.type,
                            portaltype: getState().session.portalDetails.portalType
                        };
                        // console.log(URI)
                        // console.log(options)
                        return postRequest(URI, options, credentials)
                            .then(apiParse)
                            .then(response => {
                                // console.log("*** response!!");
                                bookmarkInfo.viewGUID = response
                                return dispatch(actions.fetchApplicationsSuccess(bookmarkInfo));
                            })
                            .catch(err => {
                                console.log(err)
                                throw err
                            });
                    })
                    // PMD 11/02/22 Remove hard coding of hash colours
                    // } else {
                    //     // PMD 21/12/21 Original path to handle agents
                    //     new Promise((resolve) => {
                    //         let updatedColors = updateTaskColors(response);
                    //         resolve(updatedColors);
                    //     }).then(updatedColors => {
                    //         return dispatch(actions.updateApplicationTaskColors(updatedColors));
                    //     }).then(() => {
                    //         return dispatch(actions.fetchApplicationsSuccess(response));
                    //     });
                }
                return dispatch(actions.fetchApplicationsSuccess(response));
            })
            .catch(err => {
                //console.log(err);
                // PMD 20/05/22 Add in error handler function
                errorHandler(err)

                return dispatch(actions.fetchApplicationsError(err));
            })
    };
};

export const clearApplicationTasks = (applicationTasks) => {

    return (dispatch) => {
        return dispatch(actions.clearApplication_Tasks(applicationTasks));
    }
}

export const updateApplicationTasks = (applicationTasks) => {
    return (dispatch, getState) => {
        // PMD 02/09/21 Remove PORT and home to enable use of API Gateway 
        // const URI = `${config[env].URL}${config[env].PORT}/home/move-task-to-new-segment`;
        const URI = `${config[env].URL}/move-task-to-new-segment`;
        const credentials = 'omit';
        const headers = {
            sessionid: getState().session.sessionId
        };

        const { postDetails, ...dispatchData } = applicationTasks;

        dispatch(actions.updateApplicationTaskRequest());
        return postRequest(URI, postDetails, credentials, headers)
            .then(apiParse)
            .then(response => {
                return dispatch(actions.updateApplication_Tasks(dispatchData));
            })
            .catch(err => {
                // PMD 20/05/22 Add in error handler function
                errorHandler(err)

                return dispatch(actions.updateApplicationTaskError(err));
            })
    };
};

export const updateSelectedApplicationTask = (id) => {
    return (dispatch) => {
        return dispatch(actions.updateSelectedApplication_Task(id));
    }
}

function updateTaskColors(response) {

    if (typeof response.Segments === 'undefined' && typeof response.ApplicationTasks === 'undefined')
        return;

    let __colorObj = {};

    const items = [
        { timeUnitElementGUID: '3C4F9131-3171-4A55-9765-AFBE4B75FFAE', unit: 'months' },
        { timeUnitElementGUID: '118F2586-6E57-45CF-9704-0338F270507E', unit: 'weeks' },
        { timeUnitElementGUID: '216DAC21-18B0-4743-9C0F-A855293473C6', unit: 'days' },
        { timeUnitElementGUID: 'E8E9D9BC-DA4C-4022-8E89-81CBB15ED714', unit: 'hours' },
        { timeUnitElementGUID: '9BE3009A-E572-498D-A214-29C19C646F23', unit: 'minutes' },
    ]

    //It could be null, deal with it

    let segments = response.Segments;

    let applicationTasks = response.ApplicationTasks;

    Object.values(segments).map((segment) => segment.applicationTaskIDs.map(id => {

        if (id in applicationTasks) {

            const task = applicationTasks[id];
            let today = new Date(Date.now()).toISOString();

            if (task.taskDueDateTime < today) {

                __colorObj[task.id] = {
                    overallStatusRAGHash: task.overallStatusRAGHash,
                    taskDueDateColorHash: '#FF6565',
                };

            } else {
                //Grab time unit and guid for Amber check
                let timeUnit = segment.SLATimeUnit;
                if (timeUnit === null) {
                    timeUnit = 0;
                }

                let timeUnitElementGUID = segment.timeUnitElementGUID;
                const item = items.find((item) => item.timeUnitElementGUID === timeUnitElementGUID);

                if (typeof item !== 'undefined') {

                    let date = new Date(task.taskDueDateTime);
                    let todaysDate = new Date(today);

                    let differenceInTime = date.getTime() - todaysDate.getTime();
                    let difference = 0;

                    switch (item.unit) {

                        case 'months':
                            difference = Math.round(differenceInTime / (1000 * 3600 * 24 * 7 * 4));
                            break;

                        case 'weeks':
                            difference = Math.round(differenceInTime / (1000 * 3600 * 24 * 7));
                            break;

                        case 'days':
                            difference = Math.round(differenceInTime / (1000 * 3600 * 24));
                            break;

                        case 'hours':
                            difference = Math.round(differenceInTime / (1000 * 3600));
                            break;

                        case 'minutes':
                            difference = Math.round(differenceInTime / 1000);
                            break;

                        default:
                            break;

                    }

                    if (difference < timeUnit) {
                        __colorObj[task.id] = {
                            overallStatusRAGHash: task.overallStatusRAGHash,
                            taskDueDateColorHash: '#FFA500',
                        };
                    } else {
                        __colorObj[task.id] = {
                            overallStatusRAGHash: task.overallStatusRAGHash,
                            taskDueDateColorHash: '#FFFFFF',
                        };
                    }
                } else {
                    // In the event that no timeElementGUID is provided set due date color to #FFFFFF
                    __colorObj[task.id] = {
                        overallStatusRAGHash: task.overallStatusRAGHash,
                        taskDueDateColorHash: '#FFFFFF',
                    };
                }
            }
        }
    }));

    return __colorObj;

}