import React from 'react';
import DropDownMenu from '../Reusable/DropDownMenu';
import MultiMenu from '../Reusable/MultiMenu';
import Button from '../Reusable/Button';
import { isEmpty } from 'lodash';

const ScreenRibbon = (props) => {
  const { screenRibbon, disableButton } = props;

  // console.log(props)

  if (screenRibbon === undefined) return null;

  const onItemClick = (value) => {
    props.onItemClick(value);
  };

  const handleClick = (value) => {
    // console.log(value)
    props.handleClick(value);
  };

  const handleButtonClick = (viewGUID) => {
    // console.log(viewGUID)
    props.handleButtonClick(viewGUID);
  };

  return screenRibbon.map((item, index) => {
    let label;
    let disabled = false;
    // console.log(item)
    // console.log(item.Context)
    if (item.Context === 'Button') {
      label = item.Label;
      //   console.log(disableButton);
      //   PMD 08/12/22 Changed to an array
      //   if (item.viewGUID === disableButton) {
      //     disabled = true;
      //   }
      if (disableButton && disableButton.indexOf(item.viewGUID) !== -1) {
        disabled = true;
      }

      return (
        <div className="top-level-menu" key={item.Label}>
          <Button
            label={label}
            handleClick={() => handleButtonClick(item.viewGUID)}
            className={'button action dashboardaction'}
            disabled={disabled}
          />
        </div>
      );
    }
    if (item.Context === 'Modal') {
      label = item.Label;
      // console.log(item.viewGUID)
      return (
        <div className="top-level-menu" key={item.Label}>
          <Button
            label={label}
            // FIXME: handleClick={this.launchModal}
            handleClick={() => handleClick(item.viewGUID)}
            className={'button action dashboardaction'}
          />
        </div>
      );
    }
    if (item.Context === 'Redirect') {
      label = item.Label;
      // console.log('redirect - item:', item);
      return (
        <div className="top-level-menu" key={item.Label}>
          <Button
            label={label}
            // FIXME: handleClick={this.launchModal}
            handleClick={() => handleClick(item.viewGUID)}
            className={'button action dashboardaction'}
          />
        </div>
      );
    }
    if (item.Context === 'Menu' || item.Context === 'undefined') {
      // console.log(item)
      label = item.Label;

      //check for ViewGUID empty, apply temporary addition to top-level-div action

      let _top_class_name = '';
      let _second_class_name = '';

      if (isEmpty(item.viewGUID)) {
        _top_class_name = 'top-level-div action';
        _second_class_name = 'second-level-menu';
      } else {
        _top_class_name = 'top-level-div action reduce';
        _second_class_name = 'second-level-menu reduce';
      }

      //return pathname === path || pathname === altPath ? "menuDark" : "";

      // FIXME: Temporary fix for subHeader
      let menu = [];
      menu.push(item.Menu);

      //console.log(menu)
      // if (item.menutype.length === 1) {
      if (menu.length === 1) {
        // console.log('Single', menu)
        return (
          <DropDownMenu
            key={index}
            toplevelClassName={'top-level-menu'}
            toplevelUIClassName={_top_class_name}
            secondLevelClassName={_second_class_name}
            label={label}
            onItemClick={onItemClick}
            // data={item.menutype} />
            data={menu}
          />
        );
      } else {
        // console.log('Multi')
        return (
          <MultiMenu
            toplevelClassName={'top-level-menu'}
            toplevelUIClassName={_class_name}
            secondLevelClassName={_second_class_name}
            thirdLevelClassName={'third-level-menu'}
            label={label}
            // TODO: Not implemented yet
            // callback={this.checkCredit}
            data={item.Menu}
          />
        );
      }
    }
  });
};

export default ScreenRibbon;
