// PMD 05/08/22 B2C Implementation

import { LogLevel } from "@azure/msal-browser";
import config from './config';
const env = process.env.NODE_ENV || 'development';

// console.log(config[env])

export const b2cPolicies = {
    clientId: config[env].clientId,
    names: {
        // signin: "B2C_1A_SIGNIN"
        signin: config[env].signin,
        resetPassword: config[env].resetPassword
    },
    authorities: {
        signin: {
            // authority: "https://safeguardenauth.b2clogin.com/safeguardenauth.onmicrosoft.com/B2C_1A_SIGNIN"
            authority: `${config[env].authority}/${config[env].signin}`
        },
        resetPassword: {
            authority: `${config[env].authority}/${config[env].resetPassword}`
        }
    },
    // authorityDomain: "safeguardenauth.b2clogin.com"
    authorityDomain: config[env].authorityDomain
}

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        // clientId: "4ea5ac9b-cd70-47d7-afc4-6086bac560a7", // This is the ONLY mandatory field; everything else is optional.
        clientId: b2cPolicies.clientId, // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signin.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        claimsBasedCachingEnabled: true, // Preserve MSAL 2.x default behavior, i.e. caches and matches tokens based on claims by default.
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiSafeguarden: {
        // endpoint: "https://api.spotlite.services/live/spotlite/user/createsession",
        endpoint: `${config[env].mainApiUrl}user/createsession`,
        // scopes: ["https://safeguardenauth.onmicrosoft.com/551455ce-e012-46f9-99fe-f5fbc1a932b7/access_as_user"], // e.g. api://xxxxxx/access_as_user
        scopes: [config[env].scopes], // e.g. api://xxxxxx/access_as_user
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.apiSafeguarden.scopes]
};
