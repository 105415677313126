import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateApplicationLayout,
  updateSelectedApplicationTask,
} from "../../actions";
import DashboardPanels from "../Panels/DashboardPanel/DashboardPanels";

// Imports for database connectivity
import { saveScreenDefinitionToDB } from "../../utils/DatabaseFunctions";
import { confirmAlert } from 'react-confirm-alert';
import { errorHandler } from "../../utils/errorHandler";

class ApplicationTaskPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      viewGUID: "",
      applicationGUID: "",
      applicationInstanceName: "",
      screenLayout: [],
      saveVisible: false,
      segmentSpotliteAddress: "",
      saveRequired: false,
    };

  }

  // handleBackButton = () => {
  //   this.props.updateSelectedApplicationTask("");
  //   if (this.state.screenLayout.length > 0) {
  //     this.savePanelSettingsToDB();
  //   }
  // };

  componentWillUnmount() {

    const { saveRequired } = this.state
    // console.log(this.state)

    if (saveRequired) {
      confirmAlert({
        title: "Save Changes",
        message: "Do you want to save the changes to the layout?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.savePanelSettingsToDB()
              // this.btn.removeAttribute("disabled");
              // this.props.fetchApplications("");
            }
            // );
          },
          {
            label: "No",
            onClick: () => {
              this.setState({ saveRequired: false })
              this.props.updateSelectedApplicationTask("");
            },

          },
        ],
      });

    } else {
      this.props.updateSelectedApplicationTask("");
    }
  }

  savePanelSettingsToDB = () => {
    const { screenLayout } = this.state;

    // console.info(screenLayout)
    if (screenLayout.length === 0) return
    // FIXME: Using invalid field name to prevent overwriting DataConfigurations table
    // console.log("NEEDS FIXING TO REMOVE REFERENCE TO screenLayout1")
    saveScreenDefinitionToDB(screenLayout)
      .then((result) => {
        //console.log(result)
        if (result instanceof Error) {
          throw new Error(result)
        } else {
          this.showAlert('Save changes', 'Changes were successfully saved.');
          this.setState({ saveRequired: false })
        }
      })
      .catch((err) => {
        // this.showAlert('Save changes', 'Failed to save changes.');
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        // // TODO: ERROR HANDLING
        // console.log(err.message);
        // console.log(err)
      });

  }

  showAlert(title, msg) {
    confirmAlert({
      title: title,
      message: msg,
      buttons: [
        {
          label: "OK",
          onClick: () => { /* changes saved */ },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  getDataFromDashboardPanels = (screenLayouts) => {
    // console.log(screenLayouts)
    this.setState(
      {
        screenLayout: screenLayouts,
        saveVisible: true,
        saveRequired: true,
      }
    );
  }

  // PMD 29/03/22 Save Layout Settings
  handleButtonClick = (viewGUID) => {
    const { saveLayoutGUID } = this.state
    // console.log(saveLayoutGUID)
    // console.log(viewGUID)
    if (viewGUID === saveLayoutGUID) {
      this.setState(
        {
          saveVisible: true,
          saveRequired: true,
        }
      );

      this.savePanelSettingsToDB()
    }
  }

  // gridLayoutChanged = (gridLayout) => {
  //   console.log(gridLayout)
  //   console.log(this.state)
  //   this.setState({ screenLayout: gridLayout })
  // }

  componentDidMount() {

    let task = this.props.ApplicationTasks[this.props.selectedApplicationTask];

    // console.log('ApplicationTaskPanel this.state:', this.state);
    // console.log('ApplicationTaskPanel this.props:', this.props);

    // PMD 04/03/22 Use Application Task Name
    // this.props.updateApplicationLayout({ componentType: "", label: 'My Account' });

    this.props.updateApplicationLayout({ componentType: "", label: task.applicationInstanceName, isVisible: false });
    this.setState({
      viewGUID: task.viewGUID,
      applicationGUID: task.applicationGUID,
      applicationInstanceName: task.applicationInstanceName,
      isLoading: false,
      saveVisible: false,
      segmentSpotliteAddress: this.props.location.segmentSpotliteAddress,
    });
  }

  componentWillUnmount() {
    //Check bookmark, apply change here
    let bookmarkLabel = this.props.bookmarkLabel;
    this.props.updateApplicationLayout({ componentType: "", label: bookmarkLabel, isVisible: false });
  }

  render() {

    const { isLoading } = this.state;

    if (isLoading) return null;

    return (
      <div>
        {/* FIXME: Remove currently unused toolbar. Also fixes screen size problem*/}
        {/* <ApplicationTaskDashboard
          showBackButton={false}
          showSaveButton={this.state.saveVisible}
          handleSaveButton={this.savePanelSettingsToDB}
          handleBackButton={this.handleBackButton}
        // title={this.state.applicationInstanceName} 
        /> */}

        <DashboardPanels
          viewGUID={this.state.viewGUID}
          applicationInstanceGUID={this.state.applicationGUID}
          segmentSpotliteAddress={this.state.segmentSpotliteAddress}
          dataSentToCertificatesPanel={this.getDataFromDashboardPanels}
          handleButtonClick={this.handleButtonClick}
        // gridLayoutChanged={this.gridLayoutChanged}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const { selectedApplicationTask } = state.applications;
  const { ApplicationTasks } = state.applications.applications;
  const { sessionId } = state.session;
  const { bookmarkLabel } = state.bookmark;

  return {
    selectedApplicationTask,
    ApplicationTasks,
    sessionId,
    bookmarkLabel,
  };
};

export default connect(mapStateToProps, {
  updateSelectedApplicationTask,
  updateApplicationLayout,
})(ApplicationTaskPanel);
