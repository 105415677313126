import { postRequest, apiParseScreenComplaint, apiParse, apiParseSpotlite } from "../utils/Common";
import config from "../config";
const env = process.env.NODE_ENV || "development";

export function saveToDB(sessionId, viewGUID, applicationInstanceGUID, teamGUID, SpotliteDataObject, segmentSpotliteAddress) {
  // console.log(teamGUID)
  // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
  // const URI = `${config[env].URL}${config[env].PORT}/home/post-data-view`;
  const URI = `${config[env].URL}/post-data-view`;
  const options = {
    sessionId,
    viewGUID,
    applicationInstanceGUID,
    teamGUID,
    SpotliteDataObject,
    segmentSpotliteAddress,
  };

  // console.log("saveToDB: ", options);

  const credentials = "omit";
  // return postRequest(URI, options, credentials, headers)
  return postRequest(URI, options, credentials)
    // .then(apiParse)
    .then(apiParseScreenComplaint)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('err:', err);
      return err;
    });
};

// PMD 29/09/21 Save screen definition back to the database
export function saveScreenDefinitionToDB(screenDefinition) {

  // console.info(screenDefinition)
  const { sessionId, screenViewGUID, teamSpotliteAddress, /* screenVersionNumber, */ screenDefinitionJSON } = screenDefinition

  const URI = `${config[env].URL}/save-screen-definition`;
  const headers = { sessionId }
  const options = {
    screenViewGUID,
    teamSpotliteAddress,
    // screenVersionNumber,
    screenDefinitionJSON
  };
  // console.log(URI)
  // console.info(options);

  const credentials = "omit";
  return postRequest(URI, options, credentials, headers)
    // .then(apiParseScreenComplaint)
    .then(apiParse)
    .then(response => {
      // console.log('response:', response);
      return response;
    })
    .catch(err => {
      console.log('err:', err);
      return err;
    });
};

export function logoutSession(sessionId) {
  let URI = `${config[env].mainApiUrl}auth/Logout`;
  let headers = {
    sessionId
  };
  let options = null;

  const credentials = 'omit';

  return new Promise((resolve, reject) => {
    postRequest(URI, options, credentials, headers)
      .then(apiParseSpotlite)
      .then(response => {
        // console.log(response)
        let result = response.Result
        resolve(result)
      })
      .catch((error) => {
        // console.log(error)
        reject(error)
      });

  })
}
