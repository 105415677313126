import React from 'react';
import RenderPanelForm from "../RenderPanelForm";
import { useSelector } from 'react-redux';

const PanelComponentForm = (props) => {

    const {
        panelGuid,
        panelContent,
        segmentSpotliteAddress,
    } = props;

    const session = useSelector(state => state.session);

    return (
        // styling for form panel
        <div style={{overflowY: "auto"}}>
            <RenderPanelForm 
                panelGuid={panelGuid} 
                sessionId={session.sessionId} 
                viewData={panelContent} 
                segmentSpotliteAddress={segmentSpotliteAddress} />

        </div>
      );

};

export default PanelComponentForm;
