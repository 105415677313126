import { confirmAlert } from "react-confirm-alert";
import { errorHandler } from "../../../utils/errorHandler";
import { saveToDB } from "../../../utils/DatabaseFunctions";
import _ from "lodash";

const CheckCredit = (props) => {
    
    const { 
        item,
        allTeams,
        sessionId,
        CatalogueCode,
        catalogues,
        callback,
    } = props;

    // FIXME: This will need changing when JSON response is updated

    if (item.Context === 'Redirect' || item.Context === 'Application') {
      //this.setState({ redirect: true, redirectPath: item.RouterPath });
    } else if (item.Context === 'Modal') {
      enoughCredit(item, catalogues, CatalogueCode, callback);
    } else if (item.Context === 'Post') {
  
        //Check KanbanLayout for more details, there may not be a segmentSpotliteAddress in this use case
        // segmentSpotliteAddress: newSegments[newApplicationTask.segmentGUID].spotliteAddress,
        //this.props.selectedCertificate.applicationId - we don't select any certificates
  
        //Empty object is spotlite object, empty string is segementSpotliteAddress
        return saveToDB(sessionId, item.viewGUID, allTeams.currentTeamDetails.teamGUID, allTeams.currentTeamDetails.teamGUID, {}, ""
        ).then((response) => {
          if (response.ViewResponse.Status == 'OK') {
            renderAlertDialog("Success", "Catalogue has successfully been published.");
          }
        }).catch((err) => {
          // PMD 20/05/22 Generic error handler
          errorHandler(err)
          renderAlertDialog("Error", err);
        });
    } else if (item.Context === 'Button') {
      console.log("Reaching the correct context")
    }

}

const getCurrentBalance = (catalogues, CatalogueCode) => {
    const entry = _.find(catalogues, {catalogueCode: CatalogueCode.CatalogueCode});
    return entry ? entry.creditBalance : 0;
};


 // PMD 29/03/22 Screen Ribbon
const enoughCredit = (item, catalogues, CatalogueCode, callback) => {

    // console.log(item)
    // FIXME: Using hard coded JSON

    let currentBalance = 0;
    if (catalogues) {
        //props, redux 
        currentBalance = getCurrentBalance(catalogues, CatalogueCode);
    }
    //console.log(currentBalance)

    if (currentBalance >= item.Credits) {
      //Fire Modal Callback
      callback(item);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            //customUI so we can adjust the positions of the buttons
            <div className='react-confirm-alert-body'>
              <h1>Warning</h1>
              <p>You do not have enough credits for this service.</p>
              <div className='react-confirm-alert-button-group'>
                <button style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    onClose();
                  }}>
                  OK
                </button>
              </div>
            </div>
          );
        },
        closeOnEscape: true,
        closeOnClickOutside: false,
      });
    }
}

const renderAlertDialog = (title, message) => {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
        {
          label: "OK",
          onClick: () => { /* changes saved */ },
        },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    });
}


export default CheckCredit;