import React from 'react';

function MultiMenu (props) {

    const {toplevelClassName, toplevelUIClassName, secondLevelClassName, thirdLevelClassName, label, data, onItemClick} = props;

    const element = (
        <div className={toplevelClassName}>
            <div className={toplevelUIClassName}>
                <p>{label}</p>
                <ul className={secondLevelClassName}>
                    {data.map((item, index) =>               
                    <div key={index}>
                        <p>{item.label}</p>
                        <ul className={thirdLevelClassName}>
                            {item.services.map((subItem, index) => 
                                <div key={index} className="SegmentMenu-dropdownButton" onClick={(e) => onItemClick(e.target.dataset)}>
                                    {/* {console.log(subItem)} */}
                                <p 
                                data-value={subItem.value} 
                                data-lobcode={subItem.lobCode} 
                                data-lobname={subItem.lobName}
                                data-viewguid={subItem.viewGUID}>
                                    {subItem.label}</p> 
                                </div>
                            )}
                        </ul>
                    </div>
                    )}
                </ul>
            </div>
        </div>
    );

    return element;

    /*
    return (
        //Container for entire menu
        <div className={toplevelClassName}>
            <div className={toplevelUIClassName}>
                <p>{label}</p>
                <ul className={secondLevelClassName}>
                    {data.map((item, index) =>               
                    <div key={index}>
                        <p>{item.label}</p>
                        <ul className={thirdLevelClassName}>
                            {item.services.map((subItem, index) => 
                                <div key={index} onClick={handleClick}>
                                <p 
                                data-value={subItem.value} 
                                data-lobcode={subItem.lobCode} 
                                data-lobname={subItem.lobName}
                                data-viewguid={subItem.viewGUID}>
                                    {subItem.label}</p> 
                                </div>
                            )}
                        </ul>
                    </div>
                    )}
                </ul>
            </div>
        </div>
    );*/
}

export default MultiMenu;