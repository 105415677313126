import React from 'react';

export default function LayoutControllerTabs(props) {

    const { section, componentType, callback } = props;

    // console.log(props)

    if(typeof section === 'undefined' || typeof componentType === 'undefined' || typeof callback === 'undefined')
        return null;

    return (

        <>
        {Object.values(section).map((task, index) => {

        let isActive = componentType === task.viewGUID;
        let classes = "section-tab" + (isActive ? " section-active" : "");
        // let imgFilename = isActive ? task.activeImage : task.inactiveImage;
        let name = task.Image.ImageFile;
        let suffix = name.slice(0, name.length - 4);
        let imgFilename = isActive ? `/assets/img/${task.Image.ImageFile}` : `/assets/img/${suffix}Off.png`;

        return  (
            // <div key={index} className={classes} onClick={() => callback(task.componentType, task.heading)}>
            //     <img
            //         src={imgFilename}
            //         alt={props.altName}
            //         className="dashboard-section-icons"
            //     />
            //     {/* {props.title && <div className="section-title">{props.title}</div>} */}
            // </div>
            <div key={index} className={classes} onClick={() => callback(task.viewGUID, task.Label)}>
            <img
                src={imgFilename}
                alt={task.Image.ImageLabel}
                className="dashboard-section-icons"
            />
            {/* {props.title && <div className="section-title">{props.title}</div>} */}
        </div>
    );
        })}
        </>
    );
}