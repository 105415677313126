import * as actions from './actionCreators';
import config from '../config';
import { postRequest, apiParse } from '../utils/Common';
import { errorHandler } from '../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

export const uploadFiles = (options) => {
    return (dispatch, getState) => {
        const URI = `${config[env].URL}/upload-files`;
        const credentials = 'omit';
        const body = {
            sessionId: getState().session.sessionId,
            ...options
        };

        dispatch(actions.uploadFilesRequest());

        return postRequest(URI, body, credentials)
            .then(apiParse)
            .then(response => dispatch(actions.uploadFilesSuccess(response)))
            .catch(err => {
                // PMD 20/05/22 Generic error handler
                errorHandler(err)
                dispatch(actions.uploadFilesError(err))
            }
            );
    };
};