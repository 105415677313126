// PMD 14/04/21 - P1 S1 Remove reference to Admin
// import { 
//   CERTIFICATES_FORCE_UPDATE,  
//   SELECT_CERTIFICATE, LOAD_SWIMLANES, 
//   SAVE_SWIMLANES_ORDER, SAVE_SWIMLANE, 
//   SELECT_SWIMLANE, LOCK_CERTIFICATES, 
//   SET_APPLICATION_LAYOUT, LOAD_FULL_APPLICATION_DETAILS,
//   SET_SEARCH_CRITERIA } from '../actions/types';
// import {
//   CERTIFICATES_FORCE_UPDATE, 
//   SELECT_CERTIFICATE, LOAD_SWIMLANES,
//   SAVE_SWIMLANES_ORDER, SAVE_SWIMLANE,
//   SELECT_SWIMLANE,
//   SET_APPLICATION_LAYOUT, LOAD_FULL_APPLICATION_DETAILS,
//   SET_SEARCH_CRITERIA
// } from '../actions/types';
import { LOGOUT } from '../actions/types';
// import _ from 'lodash';

const INITIAL_STATE = {
  // certificates: {},
  // searchCriteria: {
  //   lineOfBusiness: '',
  //   service: '',
  //   juristiction: '',
  //   search: [],
  //   searchInputValue: ""
  // },
  //selectedCertificate: {},
  //selectedCertificateFullDetails: {},
  //swimlanes: {},
  //swimlanesOrder: [],
  //selectedSwimlane: {},
  //locked: true,
  //layout: "column",
  //loading: false,
  //forceUpdate: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case CERTIFICATES_FORCE_UPDATE:
    //   return { ...state, forceUpdate: action.payload };

    // case SELECT_CERTIFICATE:
    //   return { ...state, selectedCertificate: action.payload, selectedCertificateFullDetails: {} };

    // case LOAD_FULL_APPLICATION_DETAILS:
    //   return { ...state, selectedCertificateFullDetails: action.payload };

    // case SET_SEARCH_CRITERIA:
    //   return { ...state, searchCriteria: action.payload };

    // case LOAD_SWIMLANES:
    //   // see initial-data.js for the structure we are creating here
    //   var columns = {};
    //   for (let j = 0; j < action.payload.length; j++) {
    //     columns['column-' + action.payload[j].PortalStatusID] = {
    //       ...action.payload[j],
    //       id: 'column-' + action.payload[j].PortalStatusID,   // a new id made from the portal status
    //       certificateIds: _.filter(_.values(state.certificates), (certObj) => { // an array containing the relevant certificate ids for this swimlane

    //         if (j === 0) {
    //           return certObj.portalStatusId === action.payload[j].PortalStatusID || !certObj.portalStatusId;
    //         }
    //         return certObj.portalStatusId === action.payload[j].PortalStatusID;
    //       }).map((filteredObj) => {
    //         return filteredObj.id
    //       })
    //     }
    //   }

    // var columnOrder = [];

    // for (var k = 0; k < action.payload.length; k++) {
    //   columnOrder.push('column-' + action.payload[k].PortalStatusID);
    // }

    // return { ...state, swimlanes: columns, swimlanesOrder: columnOrder };

    // case SAVE_SWIMLANES_ORDER:

    //   return { ...state, swimlanesOrder: action.payload };

    // case SELECT_SWIMLANE:
    //   return { ...state, selectedSwimlane: action.payload };

    // case SAVE_SWIMLANE:
    //   return {
    //     ...state, swimlanes: {
    //       ...state.swimlanes,
    //       [action.payload.id]: action.payload,
    //     }
    //   };

    // PMD 14/04/21 - P1 S1 Remove reference to Admin
    // case LOCK_CERTIFICATES:
    //   return { ...state, locked: action.payload }

    // case SET_APPLICATION_LAYOUT: {
    //   return { ...state, layout: action.payload }
    // }

    case LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

