import { LOAD_USERS, LOAD_APPLICANTS, SELECT_APPLICANT, SET_USER_ERROR } from './types';

import { postRequest, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';

export const setUserError = (err) => {
  return {
    type: SET_USER_ERROR,
    payload: err
  };
};

export const loadUsers = () => {
  // console.log('loadUsers')
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/get-users-v1-0`;
      const URI = `${config[env].URL}/get-users-v1-0`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        retrievalType: "agent"
      };

      return postUserRequest(dispatch, URI, options, credentials);

    } else {
      handleUserNoSession(dispatch);
    }
  }
};

// PMD 15/05/22 No longer used
// export const loadApplicants = () => {
//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/get-users-v1-0`;
//       const URI = `${config[env].URL}/get-users-v1-0`;
//       const credentials = 'omit';

//       const options = {
//         sessionId: getState().session.sessionId,
//         retrievalType: "applicant"
//       };

//       return postApplicantRequest(dispatch, URI, options, credentials);

//     } else {
//       handleUserNoSession(dispatch);
//     }
//   }
// };

// PMD 15/05/22 Replaced by post-data-view
// export const addApplicantToAgents = ({ PartyGUID }) => {
//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/add-applicant-to-usergroup`;
//       const URI = `${config[env].URL}/add-applicant-to-usergroup`;
//       const credentials = 'omit';

//       const options = {
//         sessionId: getState().session.sessionId,
//         PartyGUID: PartyGUID,
//       };

//       // console.log(options)

//       return postUserRequest(dispatch, URI, options, credentials)

//     } else {
//       // TODO:
//       handleUserNoSession(dispatch);
//     }
//   }
// };

export const removeApplicantFromAgents = (PartyGUID) => {
  // console.log(applicationPartyGUID.ApplicationPartyGUID)
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/remove-applicant-from-usergroup`;
      const URI = `${config[env].URL}/remove-applicant-from-usergroup`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        PartyGUID: PartyGUID.PartyGUID,
        // PMD 16/05/22 pass the portaltype
        portaltype: getState().session.portalDetails.portalType,
      };


      console.log(options)

      return postUserRequest(dispatch, URI, options, credentials);

    } else {
      // TODO:
      handleUserNoSession(dispatch);
    }
  }
};

function postUserRequest(dispatch, URI, options, credentials) {

  // console.log(URI, options);

  return postRequest(URI, options, credentials)
    .then(apiParse)
    .then(response => {

      // console.log("*** users response!!", response);

      dispatch({
        type: LOAD_USERS,
        payload: response
      });

    })
    .catch(err => {
      // PMD 20/05/22 Generic error handler
      errorHandler(err)
      dispatch({
        type: SET_USER_ERROR,
        payload: "error loading users"
      });
    });
}

// function postApplicantRequest(dispatch, URI, options, credentials) {

//   return postRequest(URI, options, credentials)
//     .then(apiParse)
//     .then(response => {

//       // console.log("*** Applicant response!!", response);

//       dispatch({
//         type: LOAD_APPLICANTS,
//         payload: response
//       });

//     })
//     .catch(err => {

//       dispatch({
//         type: SET_USER_ERROR,
//         payload: "error loading users"
//       });
//     });
// }


function handleUserNoSession(dispatch) {
  //no secure id

  dispatch({
    type: LOAD_USERS,
    payload: []
  });

  dispatch({
    type: LOAD_APPLICANTS,
    payload: []
  });

}

export const selectApplicant = (applicantObject) => {
  return {
    type: SELECT_APPLICANT,
    payload: applicantObject
  };
};

