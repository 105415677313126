import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadUsers,
  // loadApplicants,
  // addApplicantToAgents,
  removeApplicantFromAgents,
  loadTeams,
} from "../../actions";
import _ from "lodash";
// import Select from "react-select";
import { confirmAlert } from 'react-confirm-alert';
import { errorHandler } from "../../utils/errorHandler";


class Users extends Component {

  componentDidMount() {
    this.props.loadUsers();
    // this.props.loadApplicants();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.users !== this.props.users) {
  //     this.props.loadUsers();
  //   }
  // }

  render() {

    // console.log(this.props.applicants)

    return (
      <div className="contentHolder boundToScreen containsSubHeader">
        {this.renderUsersPage()}
        {this.renderError()}
      </div>
    );
  }

  renderUsersPage = () => {
    return <>{this.renderUsers()}</>;
  };

  renderUsers = () => {
    // let usergroupName;
    // console.log(this.props.users[0])

    // console.log(this.props.applicants)
    // this.loadApplicantRecords(this.props.applicants)

    if (typeof this.props.users !== 'undefined') {
      // FIXME: Retrieve ApplicationInstanceName based on GUID
      // usergroupName = _.get(this.props.users[0], 'ApplicationInstanceName' )
      //let usergroupName = _.get(this.props.users[0], 'AIName')
      // console.log(usergroupName)
    }

    return (
      <div className="agentHolder">
        <div className="userHolder">
          {this.buildAgents({
            users: this.props.users
          })}
        </div>
      </div>
    )
  }

  buildAgents = users => {
    return this.props.users.map((user) => {
      // console.log(user)
      return (
        <div className="user" key={user.APGUID}>
          <div className="flexBetween" >
            <p
              style={{ cursor: "pointer" }}
            // onClick={() => this.selectUser(teamMember)}
            >
              {user.Username}
              {/* {user.PartyFullName} */}
            </p>
            <img
              src="/assets/img/cross.png"
              alt="remove"
              style={{ height: 25, marginRight: 5, cursor: "pointer" }}
              onClick={() =>
                this.removeUserFromAgentList({ user })

              }
            ></img>

          </div>
        </div>
      )
    })
  }

  // PMD 15/05/22 No longer used
  // addApplicantToUsers = applicant => {

  //   // console.log(applicant.value)
  //   this.props.addApplicantToAgents({
  //     PartyGUID: applicant.value       // applicant.value = applicant.ApplicationPartyGUID
  //   })
  //     .then(() => {
  //       this.props.loadApplicants()
  //       this.props.loadTeams()
  //     })
  //     .catch((err) => {
  //       // FIXME: Error handling required
  //       throw err;
  //     })
  // }

  removeUserFromAgentList = ({ user }) => {
    // const { ApplicationPartyGUID } = user;
    const { APGUID, CGUID, AGUID } = user;

    // check whether user is assigned to any teams before allowing their removal
    // console.log(this.props.teams)
    // console.log({CGUID})
    // console.log({AGUID})
    const teamMember = []

    this.props.teams.map((team) => {
      // check to make sure we're not dealing usergroup
      // console.log(team)
      if (team.teamGUID !== AGUID) {
        // we are looking at a team so check whether the record to be removed is a member of a team
        // check whether the ContactGUID of the record to be deleted is in the teamMembers array
        let result = _.result(_.find(team.teamMembers, { 'ContactGUID': CGUID }), 'PartyFullName');
        // if ContactGUID found add to the array
        if (result !== undefined) {
          teamMember.push(team.teamName)
        }
      }

      return teamMember;
    })

    // console.log(teamMember)
    // check for user being a member of existing team
    if (!_.isEmpty(teamMember)) {
      confirmAlert({
        title: 'Agent Removal',
        message: `Agent currently assigned to the following team(s) - ${teamMember}. Please remove them from the team(s) and try again`,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              // no need to do anything
            },
          },
        ],
      });
    } else {

      this.props.removeApplicantFromAgents({
        PartyGUID: APGUID
        // ApplicationPartyGUID: ApplicationPartyGUID
      })
        .then(() => {
          // this.props.loadApplicants()
          this.props.loadTeams()
        }
        )
        .catch((err) => {
          // PMD 20/05/22 Generic error handler
          errorHandler(err)
          throw err;
        })
    }
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };
}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  const { users, applicants, error } = state.users;
  const { teams } = state.team;
  return {
    sessionId,
    users,
    applicants,
    error,
    teams
  };
};

export default connect(mapStateToProps, {
  loadUsers,
  // loadApplicants,
  // addApplicantToAgents,
  removeApplicantFromAgents,
  loadTeams,
})(Users);
