import { LOAD_DEPTS, SELECT_DEPT_USER, SET_DEPT_ERROR, SET_RELOAD_DEPT } from '../actions/types';

const INITIAL_STATE = { 
  depts: [],
  selectedDeptUser: {},
  reload: false,
  error: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_DEPTS:
      return { ...state, depts: action.payload };
    case SELECT_DEPT_USER:
      return { ...state, selectedDeptUser: action.payload };
    case SET_DEPT_ERROR:
      return { ...state, error: action.payload };
    case SET_RELOAD_DEPT:
      return { ...state, reload: action.payload };
    default:
      return state;
  }
};
