import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BusinessHeader from '../Header/BusinessHeader';

import Headline from '../Headline/Headline';

class BusinessSearchConfirm extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	renderAddress() {
		const { registered_address } = this.props.selectedBusiness;

		return (
			<dd>
				{
					Object.keys(registered_address).map((line, index) => {
						const addressLine = this.props.selectedBusiness.registered_address[line];

						if (addressLine) {
							return (
								<span key={index}>
									{addressLine}<br />
								</span>
							)
						}

						return null;
					})
				}
			</dd>
		);
	}

	render() {
		const {
			componentType,
			className,
			selectedBusiness,
		} = this.props;

		const { error } = this.state;

		const Element = componentType;
		const classNames = className;

		if (!selectedBusiness || !selectedBusiness.company_number || !selectedBusiness.name) {
			return (
				<Element className={classNames}>
					<Headline>
						Sorry, there seems to be a problem with the information we have. Please try a new search.
					</Headline>

					<button
						className="button action button--wide"
						type="button">
						Go back
					</button>
				</Element>
			)
		}

		return (
			<>
				<BusinessHeader
					showBackButton={true}
					handleBackButton={this.props.handleBackButton}
					title={`Create Application for ${this.props.selectedBusiness.name}`}
					backLabel={"Back to results"} />

				{/* <div style={{
					marginTop: 20,
					marginLeft: 30
				}}>
					<h2>From here you have two choices:</h2>
					<h2>1. simply create the application,</h2>
					<h2>2. create the application and perform a background search automatically</h2>
				</div> */}

				<div className="business-search">
					<div className="section">
						<div className="business_confirmation_form">
							<div className="business_confirmation_contents_form">
								<dl className="definition-list">

									<dt>Business location</dt>
									{this.renderAddress()}

									<dt>Company number</dt>
									<dd>{selectedBusiness.company_number}</dd>
								</dl>

								<fieldset className="form__fieldset">
									<div className="form__message form__message--error">
										{error}
									</div>
								</fieldset>
							</div>
							<div className="business_confirmation_button_form">

								{/* <fieldset className="form__fieldset">
								<input
									type="submit"
									className="button action button--wide"
									disabled={false}
									value="Create Application"
								/>
							</fieldset> */}
								{/* PMD 09/05/2023 Always use Open Corporates to create Business application */}
								{/* <button
								//style={{ marginTop: 10, width: 400 }}
								className="action"
								type="button"
								value="create"
								onClick={this.props.handleCreateApplication}>
								Create Application
							</button> */}

								<button
									//style={{ marginTop: 20, width: 400 }}
									className="action"
									type="button"
									value="detailed"
									onClick={((e) => this.props.getOpenCorporatesProfile(e))}>
									{/* PMD 09/05/2023 Always use Open Corporates to create Business application */}
									{/* Create Application with Open Corporates Info */}
									Create Application
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default BusinessSearchConfirm;