import { saveScreenDefinitionToDB } from "../../../utils/DatabaseFunctions";
import { errorHandler } from "../../../utils/errorHandler";

const SaveSettings = (props) => {

    const { screenLayout, callback } = props;

    //Not required at the moment

    // const payload = {
    //     saveRequired: false,
    //     screenLayout: {},
    // };
    if (Object.keys(screenLayout).length === 0) return
    // FIXME: Using invalid field name to prevent overwriting DataConfigurations table
    // console.log("NEEDS FIXING TO REMOVE REFERENCE TO screenLayout1")
    saveScreenDefinitionToDB(screenLayout)
        .then((result) => {
            if (result instanceof Error) {
                throw new Error(result)
            } else {
                callback();
            }
        })
        .catch((err) => {
        // this.showAlert('Save changes', 'Failed to save changes.');
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        // // TODO: ERROR HANDLING
        // console.log(err.message);
        // console.log(err)
    });

};

export default SaveSettings;
