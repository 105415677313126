import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.SET_BOOKMARK:
            return {
                ...newState,
                ...action.payload
            };
        default:
            return prevState;
    }
};