import React, { Component, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page, pdfjs } from "react-pdf";

import "./Panel.css";

import { panelMenuGUID } from "../../utils/Constants"

import config from "../../config";
import {
  postRequest,
  apiParseScreen,
} from "../../utils/Common.js";

import { getDataViewData } from "../../actions";

// import fakedata from "./panelmenu_220331.json"
import ScreenRibbon from "../Reusable/ScreenRibbon";
import ModalForm from "../Form/ModalForm";
import GetFormData from "../Form/GetFormData";
import { errorHandler } from "../../utils/errorHandler";
import { add, startsWith } from "lodash";
import { confirmAlert } from 'react-confirm-alert';

import PanelComponentList from "./PanelComponents/PanelComponentList";
import PanelComponentForm from "./PanelComponents/PanelComponentForm";
import PanelComponentImage from "./PanelComponents/PanelComponentImage";
import PanelComponentGrid from "./PanelComponents/PanelComponentGrid";

// Constants
const env = process.env.NODE_ENV || "testenv";


pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"
// TODO: Styling needs to be updated to use SASS

const Panel = (props) => {

  //const [numPages, setPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);
  //const [scale, setScale] = useState(1.0);
  const [isLoading, setIsLoading] = useState(true);
  const [screenRibbon, setScreenRibbon] = useState([]);
  const [submittedError, setSubmittedError] = useState("");

  const [modal, setModalParams] = useState({
    modalOpen: false,
    modalScreen: "",
    serviceCode: "",
    serviceName: "",
    lobCode: "",
    lobName: "",
  });

  const { panelGuid, panelTitle, handleAndIcon, panelType, applicationGUID } = props;
  const panelData = useSelector(state => state.dashboard.panels[props.panelGuid]);
  const panels = useSelector(state => state.dashboard.panels);
  
  const { sessionId, dataConfig } = useSelector(state => state.session);

  var showEditButton = false;
  var context = "";
  var callingObjectGUID = "";

  var spotliteAddress = panelData && panelData.spotliteAddress !== undefined ? panelData.spotliteAddress : "";

  if(panelType === 'Media' &&  panelData !== undefined && panelData.call !== undefined) {
    
    let retrievedCallEvent = panels[panelData.call].json.Calls.find(call => call.CallType === "CallEvent");
    if(retrievedCallEvent !== undefined) {
      context = retrievedCallEvent.Context;
      callingObjectGUID = retrievedCallEvent.CallingObjectGUID;
      showEditButton = true;
    }
    
  }


  /*

  useEffect(() => {

    try {
      const getData = async () => {
        await RetrieveDataFromAPI();
      }

      getData().catch(console.err);

    } catch (error) {
      errorHandler(error)
      throw new Error('Error retrieving data', error)
    }

  }, [props]);

  const onError = (e) => {
    console.log(e);
  }

  */

  // End of PDF Panel Functions

  const sendDataToDashboardPanels = (scale) => {

    //const { panelGuid } = props;

    if (typeof scale !== "number") {
      scale = 1.0;
    }

    props.dataSentToDashboardPanels({ panelGuid, scale })
  }

  // callback function
  const selectCertificate = (dataGridRowClicked) => {
    props.selectRow(dataGridRowClicked);
    // this.props.updateSelectedApplicationTask(dataGridRowClicked);
  };

  const gridLayoutChanged = (gridLayout) => {
    props.gridLayoutChanged(gridLayout)
  }

  const contentMissingAlert = (panelType, panelGuid) => {
    confirmAlert({
      title: 'Content Missing',
      message: `${panelType} Panel is missing data to correctly render panel ${panelGuid}. Please check database and try again.`,
      buttons: [
        {
          label: 'OK',
          onClick: () => {
            // no need to do anything
          },
        },
      ],
    });
  }

  const renderCorrectPanel = () => {
    const { panelGuid, panelType, panelContent, segmentSpotliteAddress, pdfHeight } = props;

    //Can't do this because we are extending component class.
    //const _panelData = useSelector(state => state.dashboard.panels[panelGuid]);
        
    if(panelType === 'Grid' && !panelContent || panelType === 'Form' && !panelContent) {
      //console.log(panelType, panelGuid, panelContent);
      contentMissingAlert(panelType, panelGuid);
    }

    if (!panelData || panelType === 'Grid' && !panelContent || panelType === 'Form' && !panelContent) return;

    switch (panelType) {
      case "List": {
        return <PanelComponentList panelGuid={panelGuid} applicationGUID={applicationGUID} />;
      }
      case "Form": {
        // console.log(panelGuid);
        // console.log(panelContent);
        return <PanelComponentForm panelGuid={panelGuid} panelContent={panelContent} segmentSpotliteAddress={segmentSpotliteAddress} applicationGUID={applicationGUID} />;
      }
      case "DocumentImage":
      case "Media": {
        // let panelData = 'https://sgtest02docs.blob.core.windows.net/kyb/output3.docx';
        return <PanelComponentImage panelData={panelData} pdfHeight={pdfHeight} sendDataToPanel={sendDataToDashboardPanels} applicationGUID={applicationGUID} />;

      }
      case "Grid": {
        //console.log(panelContent.Attributes.GridLayout)
        //console.log(`PanelType: ${panelType}, ComponentType: ${panelContent.Attributes.ComponentType}, Label: ${panelContent.Attributes.Label}`);
        return <PanelComponentGrid panelTitle={panelTitle} panelContent={panelContent} gridLayoutChanged={gridLayoutChanged} applicationGUID={applicationGUID} />;
      }
      default: {
        //console.log(panelType, "Component Type");
      }
    }
  };

  /*

  const panelMenuClick = () => {
    // console.log('Clicked')

    // FIXME: DUMMY JSON
    // const PanelMenu = fakedata.Screen.PanelMenu
    // console.log(PanelMenu)
    const URI = `${config[env].URL}/get-data-view`;
    const credentials = "omit";

    let options = {
      sessionId: sessionId,
      ViewGUID: panelMenuGUID,
      ApplicationInstanceGUID: props.panelGuid,
    };

    console.log('Panel, - panelMenuClick -  get-data-view:', options);
    postRequest(URI, options, credentials)
      .then(apiParseScreen)
      .then((data) => {
        // FIXME: Handle none 200 error messages in data
        if (data.ViewResponse.PropertyRef !== "200") {
          throw new Error(data);
        }
        // console.log('Handle the none 200 response')
      })
      .catch((err) => {
        // console.log(err)
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        // if (err.message === "Session doesnt exist") {
        // if (err.message.includes("Session doesnt exist")) {
        //   confirmAlert({
        //     title: "Session Expired",
        //     message: "Your session has expired. Please login again to continue.",
        //     buttons: [
        //       {
        //         label: "OK",
        //         onClick: () => { this.props.logout() },
        //       },
        //     ],
        //   });
        // } else {
        //   console.log(err.message);
        //   console.log(err);
        // }
        // TODO: ERROR HANDLING
        setIsLoading(false);
        setSubmittedError("Unable to load config");
      });
  }

  */

  const onItemClick = (item) => {
    // console.log("Item Clicked", value)
    launchModal(item.viewGUID, "")
  }

  const onEditClick = () => {
    
    if (context === 'modal') {
      console.log(spotliteAddress, context, callingObjectGUID)
      launchModal(callingObjectGUID, spotliteAddress);
    }

  }

  const renderModal = () => {

    if (modal.modalOpen) {
      return (
        <GetFormData
          open={modal.modalOpen}
          closeModal={onClose}
          cancelModal={cancelModal}
          screenType={modal.modalScreen}
          ServiceName={modal.serviceName}
          ServiceCode={modal.serviceCode}
          LoBName={modal.lobName}
          LoBCode={modal.lobCode}
          spotliteAddress={modal.spotliteAddress}
          applicationGUID={props.applicationGUID}
        />
      );
    } else {
      return null;
    }
  };

  const launchModal = (viewGUID, spotliteAddress) => {

    //If these calls are only be called here, consider a merger
    setModalParams({
      modalOpen: true,
      modalScreen: viewGUID,
      serviceName: '',
      serviceCode: '',
      lobCode: '',
      lobName: '',
      spotliteAddress 
    });

  };

  const onClose = () => {
    setModalParams({
      modalOpen: false,
      modalScreen: '',
      serviceName: '',
      serviceCode: '',
      lobCode: '',
      lobName: '',
    });
  };

  const cancelModal = () => {
    setModalParams({
      modalOpen: false,
      modalScreen: '',
      serviceName: '',
      serviceCode: '',
      lobCode: '',
      lobName: '',
    });
  };

  /*

  // Perform API calls to retrieve the data
  const RetrieveDataFromAPI = async () => {

    const URI = `${config[env].URL}/get-data-view`;
    const credentials = "omit";

    let options = {
      sessionId,
      ViewGUID: panelMenuGUID,
      // PMD 11/04/23 use the applicationGUID if available
      ApplicationInstanceGUID: props.applicationGUID ?? dataConfig.allTeams.currentTeamDetails.teamGUID,
      // ApplicationInstanceGUID: dataConfig.allTeams.currentTeamDetails.teamGUID,
    };


    console.log('Panel, - RetrieveDataFromAPI -  get-data-view:', options);

    postRequest(URI, options, credentials)
      .then(apiParseScreen)
      .then((data) => {

        // console.log(data);

        // FIXME: Handle none 200 error messages in data
        if (data.ViewResponse.PropertyRef !== "200") {
          throw new Error(data);
        }
        // console.log(data)
        // PMD 28/09/21 Save Original Panel Information to state

        //this data is not correct
        //setScreenRibbon(data.Screen.ScreenRibbon);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        // PMD 20/05/22 Generic error handler
        errorHandler(err)
        // if (err.message === "Session doesnt exist") {
        // if (err.message.includes("Session doesnt exist") || err.message.includes("expired")) {
        //   confirmAlert({
        //     title: "Session Expired",
        //     message: "Your session has expired. Please login again to continue.",
        //     buttons: [
        //       {
        //         label: "OK",
        //         onClick: () => { this.props.logout() },
        //       },
        //     ],
        //   });
        // } else {
        //   console.log(err.message);
        //   console.log(err);
        // }
        setIsLoading(false);
        setSubmittedError("Unable to load config");
      });
  };

  */

  //if (isLoading) return null;

  // PMD 29/03/22 Set the header depending on whether we need to show the icons
  // console.log(fakedata.Screen.ScreenRibbon)

  //PanelType === Media will have an edit button, pass eventCall

  //console.log(screenRibbon)

  // BF 09/07/2024 Quick fix to change media panel header. Needs a complete overhaul.
  var panelLabel = panelTitle;

  if(panelData !== undefined && panelType === 'Media' || panelData !== undefined && panelType === 'DocumentImage') {
    if(panelData.panelTitle !== undefined) {
      panelLabel = panelData.panelTitle;
    } 
  }

  const panelHeader = (

    <div className="dragHandle flex row center" /*style={{ justifyContent: "space-between" }}*/>
      {handleAndIcon ? <img src="/assets/img/grab.png" alt="grab handle" /> : null}
      <div style={{ /*paddingTop: "10px", paddingBottom: "10px"*/ width: "100%" }}>
      <span style={{ verticalAlign: "super", fontSize: "1.3em", paddingLeft: "5px", display: "inline-flex", width: "100%" }}>
        <div style={{ paddingLeft: "5px", paddingRight: "10px", width: "100%", margin: "auto", fontWeight: "bold"  }}>
        {panelLabel}
        </div>
        {showEditButton ?
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <button style={{ background: "transparent", border: "transparent", cursor: "pointer" }}   onClick={onEditClick}>
          <img
            style={{ width: "20px", height: "20px" }}
            alt="Edit"
            src="/assets/img/edit.svg"/>
          </button>
        </div>
        : null}
      </span>
      </div>
      {panelType !== "DocumentImage"
        ? handleAndIcon
          ? <ScreenRibbon screenRibbon={screenRibbon} onItemClick={onItemClick} />
          : null
        : null}
    </div>
  );

  return (
    <>
      {panelHeader}
      <div
        className="wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 28px)",
          marginRight: 10,
          marginLeft: 10,
        }}>

        {renderModal()}
        {renderCorrectPanel()}

      </div>
    </>
  );

}

export default Panel;
