import PropTypes from "prop-types";
import React, { Component } from "react";
import BusinessHeader from "../Header/BusinessHeader";

import "./style/BusinessSearchResults.css";

class BusinessSearchResults extends Component {
  static propTypes = {
    componentType: PropTypes.string,
  };

  static defaultProps = {
    componentType: "div",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClick = (businessDetails) => {
    if (businessDetails) {
      this.props.updateSelectedBusiness(businessDetails);
    }
  };

  renderResults() {
    /*
    if (this.props.searchResults.SearchResults.length) {
      return this.props.searchResults.SearchResults.map((result, index) => {
    */
    let numberFound = this.props.searchResults.companies.length;
    if (numberFound) {
      return this.props.searchResults.companies.map((result, index) => {
        let company = result.company;
        let displayDetails = numberFound > 1 ? `${company.name}, ${company.company_number}` : company.name;
        displayDetails += company.registered_address && company.registered_address.locality ? `, ${company.registered_address.locality}` : '';
        displayDetails += company.registered_address && company.registered_address.postal_code ? `, ${company.registered_address.postal_code}` : '';
        return (
          <li key={index} className="search-results__result">
            {/* eslint-disable-next-line */}
            <a
              className="search-results__link"
              onClick={(e) => this.handleClick(company, e)}
            >
              {displayDetails}
            </a>
          </li>
        )
      })
    }

    return (
      <li className="search-results__result">
        {/* eslint-disable-next-line */}
        <a
          className="search-results__link"
          onClick={this.props.handleBackButton}
        >
          Sorry, we can’t find any search results for “
          {this.props.searchValue}”. Try a new
          search!
        </a>
      </li>
    );
  }

  render() {
    const { searchResults, searchValue } = this.props;

    return (
      // <div className="height100">
      <>
        {/* <HeaderSimple
          showBackButton={true}
          handleBackButton={this.props.handleBackButton}
          // title={`Search results for “${searchResults.SearchSummary.BusinessName}”`}
          title={`Search results for “${searchValue}”`}
        /> */}
        <BusinessHeader 
        showBackButton={true}
        handleBackButton={this.props.handleBackButton}
        // title={`Search results for “${searchValue}”`} 
        title={"Search Results"} 
        backLabel={ "Back to search" }/>

        {/* <div
          style={{
            marginTop: 20,
            marginLeft: 30,
          }}
        >
          <h2>
            This screen allow you to create an application for a UK business.
          </h2>
          <h2>Use the search feature below to find the business.</h2>
        </div> */}

        <div className="business-search">
          <div className="section">
            <ul className="search-results">{this.renderResults()}</ul>
          </div>

          {/*
					<button
						className="button button--negative"
						type="button"
						onClick={this.props.handleBackButton}
						style={{marginTop: 20}}
					>
						Start a new search
					</button>
					*/}
        </div>
      {/* </div>*/}
      </>
    );
  }
}

export default BusinessSearchResults;
