import { useMemo, useState, useContext, createContext, useEffect } from 'react';
import DataGrid from 'react-data-grid';
// import { useFocusRef } from './useFocusRef';

const EmptyRowsRenderer = () => {
  return (
    // <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
    <div className="empty-grid">
      Nothing to show <span title="No data found">(´No data to display`)</span>
    </div>
  );
};

// PMD 14/11/22 Remove filtering option
// const FilterRenderer = ({ isCellSelected, column, children }) => {
//   const filters = useContext(FilterContext);
//   const { ref, tabIndex } = useFocusRef(isCellSelected);

//   //   console.log(column);

//   return (
//     <>
//       <div>{column.name}</div>
//       {/*  {filters.enabled && <div>{children({ ref, tabIndex, filters })}</div>} */}
//     </>
//   );
// };

// PMD 14/11/22 Remove filtering option
// const FilterContext = createContext(undefined);
const ROW_HEIGHT = 35;

export default function TransactionsGrid(props) {
  //   console.log(props);

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  // PMD 14/11/22 Remove filtering option
  //   const [filters, setFilters] = useState({
  //     AdditionalInfo: '',
  //     CatalogueCode: '',
  //     CreatedOnDateTime: '',
  //     DocumentTransactionExternalRef: '',
  //     Item: '',
  //     ServicePackCode: '',
  //     TransactionCompletedDate: '',
  //   });

  const filterColumnClassName = 'columnClass';
  // PMD 14/11/22 Remove filtering option
  //   const filterClassname = 'fliterClass';

  const dateFormatter = new Intl.DateTimeFormat(navigator.language);

  function TimestampFormatter({ timestamp }) {
    // console.log(typeof new Date(timestamp));
    if (!timestamp) return <></>;

    let timestamp1 = new Date(timestamp);
    if (timestamp1 instanceof Date && !isNaN(timestamp1.valueOf())) {
      return <>{dateFormatter.format(timestamp1)}</>;
    } else {
      // Not a date return an empty value
      return <></>;
    }
  }

  function TransactionCompletedValue({ status }) {
    if (status === true) {
      return <>PAID</>;
    } else if (status === false) {
      return <>UNPAID</>;
    } else {
      return <></>;
    }
  }

  function InvoiceFormatter({ invoiceId }) {
    return invoiceId ? <div className="invoice-id">{invoiceId}</div> : <></>;
  }

  const columns = [
    // { key: 'id', name: '', width: 0 },
    {
      key: 'DocumentTransactionExternalRef',
      name: 'Invoice Reference',
      width: 175,
      resizable: true,
      headerCellClass: filterColumnClassName,
      formatter(props) {
        return (
          <InvoiceFormatter
            invoiceId={props.row.DocumentTransactionExternalRef}
          />
        );
      },
      // PMD 14/11/22 Remove filtering option
      //   headerRenderer: (p) => (
      //     <FilterRenderer {...p}>
      //       {({ filters, ...rest }) => (
      //         <input
      //           {...rest}
      //           className={filterClassname}
      //           value={filters.DocumentTransactionExternalRef}
      //           onChange={(e) =>
      //             setFilters({
      //               ...filters,
      //               DocumentTransactionExternalRef: e.target.value,
      //             })
      //           }
      //           onKeyDown={inputStopPropagation}
      //         />
      //       )}
      //     </FilterRenderer>
      //   ),
    },
    // { key: 'SourceApplicationName', name: 'Name' },
    { key: 'Item', name: 'Item', resizable: true },
    // { key: 'CatalogueCode', name: 'Catalogue', resizable: true },
    // { key: 'ServicePackCode', name: 'Service Pack', resizable: true },
    // { key: 'LineOfBusinessCode', name: 'LoB Code', resizable: true },
    // { key: 'ServiceCode', name: 'Service Code', resizable: true },
    { key: 'ControlStandardCode', name: 'Check Type', resizable: true },
    { key: 'NumberOfCredits', name: 'Number Of Credits', resizable: true },
    { key: 'Amount', name: 'Amount (£)', resizable: true },
    { key: 'Quantity', name: 'Quantity', resizable: true },
    { key: 'UnitCost', name: 'Unit Cost (£)', resizable: true },
    // { key: 'StatusId', name: 'Status Id', resizable: true },
    // { key: 'AdditionalInfo', name: 'Additional Info', resizable: true },
    {
      key: 'CreatedOnDateTime',
      name: 'Created Date',
      resizable: true,
      formatter(props) {
        return <TimestampFormatter timestamp={props.row.CreatedOnDateTime} />;
      },
    },
    {
      key: 'TransactionDueDate',
      name: 'Invoice Due Date',
      resizable: true,
      formatter(props) {
        return <TimestampFormatter timestamp={props.row.TransactionDueDate} />;
      },
    },
    {
      key: 'TransactionCompleted',
      name: 'Invoice Paid',
      resizable: true,
      formatter(props) {
        // console.log(props.row.TransactionCompleted);
        return (
          <TransactionCompletedValue status={props.row.TransactionCompleted} />
        );
      },
    },
    {
      key: 'TransactionCompletedDate',
      name: 'Paid Date',
      resizable: true,
      formatter(props) {
        // console.log(props.row.CreatedOnDateTime);
        return (
          <TimestampFormatter timestamp={props.row.TransactionCompletedDate} />
        );
      },
    },
  ];

  function rowKeyGetter(row) {
    return row.id;
  }

  const rowClicked = (params) => {
    // console.log(row);
    // console.log(rowId);
    // params.row.id, params.row
    if (rowId.DocumentTransactionExternalRef) {
      props.selectInvoice(rowId.DocumentTransactionExternalRef);
    }
  };

  const gridElement = (
    <DataGrid
      className="transactions-grid"
      columns={columns}
      rows={rows}
      rowHeight={ROW_HEIGHT}
      rowKeyGetter={rowKeyGetter}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      onCellClick={rowClicked}
      //onRowClick={rowClicked}
      // width={1800}
      noRowsFallback={<EmptyRowsRenderer />}
    />
  );

  useEffect(() => {
    if (props.rows.length !== 0) {
      // console.log(props.rows);
      // Only show invoices
      const invoiceRows = props.rows.filter(
        (row) => row.DocumentTransactionExternalRef !== null,
      );
      setRows(invoiceRows);
      // setRows([]);
    }
  }, [props]);

  //   MAIN RENDER CALL
  //   if (rows.length === 0) return null;

  //   console.log(columns);

  return <>{gridElement}</>;
}
