import React, { Component } from "react";
import { connect } from "react-redux";
import config from '../../config';
import { postRequest, apiParse } from '../../utils/Common';

import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./PanelForm.css";
import { saveToDB } from "../../utils/DatabaseFunctions";
import CustomForm from "../Reusable/CustomForm";
import Loader from "../Loader/Loader";
// FIXME: Temp form json
// import uploadForm from "./uploadfiles_220110a.json";

import {
  fetchApplications,
  loadTeams,
} from "../../actions";
import { errorHandler } from "../../utils/errorHandler";

const env = process.env.NODE_ENV || 'development';

const customFormats = {
  //eslint-disable-next-line
  "phone-mobile": /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
  //eslint-disable-next-line
  // "email-format": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
};

const ObjectFieldTemplate = (props) => {
  // console.log(props.properties)
  return (
    <div>
      {/* {props.title} */}
      {/* {props.description} */}
      {props.properties.map((element, index) => (
        <div key={index} className="property-wrapper">
          {element.content}
        </div>
      ))}
    </div>
  );
};

const onError = (errors => {
  console.log("I have", errors.length, "errors to fix");
  console.log("Error message ->>", errors);
});

class PanelForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSaving: false,
      buttonText: "Submit",
      screenDisplayed: "",
      form: {
        panelGUID: null,
        SpotliteDataObject: {},
      },
    };

    this.schema = {};
    this.uiSchema = {};
    this.schemaProps = {};
    this.SpotliteDataObject = {};
    // let submissionArr = [];
    this.dataToWriteToDB = {};
    this.newDataToWrite = {};
    this.submitButton = null;
  }

  componentDidMount() {
    let { panelContent } = this.props;

    // console.log(' XXXXX PanelForm this.props:', this.props);
    const formDataToLoad = panelContent.Calls[0].RetrievedData;
    this.schema = _.cloneDeep(formDataToLoad.PropertyData);

    this.uiSchema = formDataToLoad.UISchema;
    this.schemaProps = this.schema.properties;

    if (this.uiSchema === undefined) {
      this.submitButton = <div></div>;
    } else if (this.uiSchema.SubmitButton) {
      this.submitButton = <div></div>;
    } else {
      // render submit button
      this.submitButton = (
        <div>
          <button ref={btn => { this.btn = btn }} className="custommodalformbutton" type="submit" >
            {this.state.buttonText}
          </button>
        </div>
      );
    }

    this.setState({ isLoading: false });
  }

  transformErrors(errors) {
    return errors.map((error) => {
      if (error.name === "format" && error.message.includes("email")) {
        error.message = "Invalid Email format";
      } else if (error.name === "format" && error.message.includes("phone")) {
        error.message = "Invalid Mobile Phone format";
      }
      return error;
    });
  }

  onError = (errors) => {
    console.log("Errors Handler", errors);
  };

  onSubmit = ({ formData }) => {
    this.setState({ isSaving: true })
    this.btn.setAttribute("disabled", "disabled");

    // Pass the CallingObjectGUID as the viewGUID as we are dealing with a form
    const viewGUID = this.props.panelContent.Calls[0].CallingObjectGUID;
    const { sessionId, modalScreen, applicationId, segmentSpotliteAddress, } = this.props;
    const { teamGUID } = this.props.dataConfig.allTeams.currentTeamDetails;

    // FIXME: Handle document upload form
    if (this.props.callingScreen !== "fileUpload") {
      // console.log(formData)
      this.buildSubmissionData({ formData });
    }

    if (modalScreen) {

      // console.log(this.props.callingScreen)
      switch (this.props.callingScreen) {
        case "newApplicant":
          return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress)
            .then(() => {
              this.props.fetchApplications("");
              confirmAlert({
                title: "Applicant Invite",
                message: "A SMS Message will be shortly sent to the applicant ",
                buttons: [
                  {
                    label: "OK",
                    onClick: () => {
                      //Submit server call to refresh 
                    },
                  },
                ],
              });
              this.props.onCloseModal();
            }).catch((err) => {
              // PMD 20/05/22 Generic error handler
              errorHandler(err)
              this.setState({
                loading: false,
                submittedError: `Unable to post data - ${err.message}`,
              });
              return;
            });
        case "newAgent":
        case "newWorkqueue":
        case "tasklistDetails":
        case "editWorkqueue":
          // case "usernameSearch":
          return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress)
            .then(() => {
              this.props.fetchApplications("");
              this.props.onCloseModal();
            }).catch((err) => {
              // PMD 20/05/22 Generic error handler
              errorHandler(err)
              this.setState({
                loading: false,
                submittedError: `Unable to post data - ${err.message}`,
              });
              return;
            });
        case "usernameSearch":
          // console.log('HERE: usernameSearch:', this.props);
          return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress)
            .then(data => {
              this.props.fetchApplications("");

              this.props.onCloseModal();
              // console.log('MODAL CLOSED !!!');
            })
            .catch((err) => {
              // PMD 20/05/22 Generic error handler
              errorHandler(err)
              this.setState({
                loading: false,
                submittedError: `Unable to post data - ${err.message}`,
              });
              return;
            });
        case "newTeam":
          return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress
          ).then(() => {
            this.props.loadTeams();
            this.props.onCloseModal();
          }).catch((err) => {
            // PMD 20/05/22 Generic error handler
            errorHandler(err)
            this.setState({
              loading: false,
              submittedError: `Unable to post data - ${err.message}`,
            });
            return;
          });
        case "fileUpload":

          const URI = `${config[env].URL}/upload-files`;
          const credentials = "omit";

          const options = {
            sessionId: this.props.sessionId,
            // PMD 25/07/21 - Pass applicationGUID
            applicationGUID: this.props.applicationId,
            fileInfo: formData,
          };
          // console.log(options)
          postRequest(URI, options, credentials)
            .then(apiParse)
            .then(response => {
              // console.log(response)
              // alert('Documents Uploaded')
              this.props.onCloseModal();
            })
            .catch((err) => {
              // PMD 20/05/22 Generic error handler
              errorHandler(err)
              this.setState({
                loading: false,
                submittedError: `Unable to upload files - ${err.message}`,
              });
              return;
            });


          this.setState({ isSaving: false })
          this.btn.removeAttribute("disabled");
          break;
        default:
          confirmAlert({
            title: "Save Changes",
            message: "Do you want to save the details you have entered?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress
                  ).then(() => {
                    this.props.fetchApplications("");
                    this.props.onCloseModal();
                  }).catch((err) => {
                    // PMD 20/05/22 Generic error handler
                    errorHandler(err)
                    this.setState({
                      loading: false,
                      submittedError: `Unable to post data - ${err.message}`,
                    });
                    // this.setState({ isSaving: false });
                    // this.btn.removeAttribute("disabled");
                    // this.props.fetchApplications("");
                    return;
                  });
                  // );
                },
              },
              {
                label: "No",
                onClick: () => {
                  this.setState({ isSaving: false })
                  this.btn.removeAttribute("disabled");
                },

              },
            ],
          });

          break;
      }


    } else {
      // PMD 03/02/22 Handle normal panel updates
      // console.log(this.dataToWriteToDB)
      return saveToDB(this.props.sessionId, viewGUID, this.props.applicationId, teamGUID, this.dataToWriteToDB, segmentSpotliteAddress)
        .then(() => {
          this.props.fetchApplications("");
          this.btn.removeAttribute("disabled");
          this.setState({ isSaving: false })
        }).catch((err) => {
          // PMD 20/05/22 Generic error handler
          errorHandler(err)
          console.log(err.message)
          this.setState({
            loading: false,
            submittedError: `Unable to post data - ${err.message}`,
          });
          return;
        });

    }


  };

  buildSubmissionData = ({ formData }) => {
    this.dataToWriteToDB = Object.keys(this.schemaProps).reduce((acc, formName) => {
      const formFields = this.schemaProps[formName].properties || {};
      const currentFormData = formData[formName] || {};

      for (const field in formFields) {
        const spotliteAddress = formFields[field].SpotliteAddress;
        acc[spotliteAddress] = {
          SpotliteAddress: spotliteAddress,
          CRUDAction: spotliteAddress.endsWith('.0.0.0') ? 'C' : 'U',
          PropertyGUID: formFields[field].PropertyGUID,
          DataValue: currentFormData[field] ? currentFormData[field] : formFields[field].default
        };
      }
      return acc;
    }, {});
  };

  // OLD buildSubmissionData function code body
  /*
    console.log(formData)

    for (const formSection in this.schemaProps) {
      // console.log(formSection);
      if (this.schemaProps.hasOwnProperty(formSection)) {
        // Identify each individual component on the form
        const formComponents = this.schemaProps[formSection].properties;
        const schemaComponents = this.uiSchema[formSection];
        //  console.log(formComponents);
        // loop the form components to create the empty update object
        for (const component in formComponents) {
          if (formComponents.hasOwnProperty(component)) {
            let bDataValueSet = false;

            let section = formData[formSection];
            let specificFormElement = formComponents[component];
            // console.log(formComponents[component])
            if (schemaComponents.hasOwnProperty(component)) {
              // field is in the uiSchema object check for ui:widget = hidden
              if (schemaComponents[component].hasOwnProperty(["ui:widget"])) {
                // schema object has a ui:widget property
                if (schemaComponents[component]["ui:widget"] === "hidden") {
                  // we are dealing with a hidden field so set the value from redux or props
                  // check whether the component matches an item in the props
                  if (this.props.hasOwnProperty(component)) {
                    specificFormElement.DataValue = this.props[component];
                  } else if (this.props.dataConfig.hasOwnProperty(component)) {
                    // not at props top level check but at the dataConfig top level
                    specificFormElement.DataValue = this.props.dataConfig[
                      component
                    ];
                  } else {
                    // missing values between form and dataConfig
                    if (!specificFormElement.hasOwnProperty("DataValue")) {
                      specificFormElement.DataValue = "";
                    }
                  }

                  bDataValueSet = true;
                }
              }
            }

            // data value hasn't already been set
            if (!bDataValueSet) {
              specificFormElement.DataValue = section[component];
            }

            const CRUDAction = formComponents[
              component
            ].SpotliteAddress.endsWith(".0.0.0")
              ? "C"
              : "U";

            specificFormElement.CRUDAction = CRUDAction;
            // specificFormElement.CRUDAction = "U";

            this.SpotliteDataObject = _.pick(specificFormElement, [
              "SpotliteAddress",
              "CRUDAction",
              "PropertyGUID",
              "DataValue",
            ]);
          }
          // console.log(this.SpotliteDataObject);
          this.dataToWriteToDB = Object.assign(
            this.newDataToWrite,
            _.keyBy([this.SpotliteDataObject], "SpotliteAddress")
          );
        }
      }
    }
  */

  render() {
    const { isLoading } = this.state;

    if (isLoading) return null;

    // let formDataPassed = { "segmentName": "Inbox" }

    // console.log(this.uiSchema)
    // console.log(this.schema)

    return (
      <>
        <CustomForm
          uiSchema={this.uiSchema}
          schema={this.schema}
          // formData={formDataPassed}
          autoComplete="off"
          customFormats={customFormats}
          showErrorList={true}
          ObjectFieldTemplate={ObjectFieldTemplate}
          // onChange={log("changed")}
          onSubmit={this.onSubmit}
          onError={onError}
          transformErrors={this.transformErrors}
        >
          {this.submitButton}
        </CustomForm>
        <Loader
          className="form__input-spinner"
          active={this.state.isSaving}
        />

      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sessionId, dataConfig } = state.session;
  const panelData = state.dashboard.panels[ownProps.panelGuid];
  const { selectedApplicationTask } = state.applications
  const { ApplicationTasks } = state.applications.applications
  const applicationGUID = (selectedApplicationTask && ApplicationTasks[selectedApplicationTask].applicationGUID) ? ApplicationTasks[selectedApplicationTask].applicationGUID : null;
  return { sessionId, dataConfig, panelData, applicationGUID };
};

export default connect(mapStateToProps, { fetchApplications, loadTeams })(PanelForm);
