import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import GlobalHeader from './GlobalHeader';
import Avatar from "react-avatar";
import AvatarMenuOptions from "../Reusable/AvatarMenuOptions";
import { isEmpty } from "lodash";
import Select, { components } from "react-select";
import GetFormData from "../Form/GetFormData";

import {
  setSidebarViewGUID,
  changeTeam,
  updateApplicationTasks,
  clearApplicationTasks,
} from "../../actions";

import config from "../../config";
import { postRequest } from "../../utils/Common.js";
import { errorHandler } from "../../utils/errorHandler";
import { object } from "prop-types";
import { current } from "@reduxjs/toolkit";

const env = process.env.NODE_ENV || 'development';

const colourStyles = {
  control: base => ({
    ...base,
    boxShadow: '0 3px 2px -2px #297FE5', "&:hover": { borderColor: '#f7f5f4' },
    borderRadius: 0,
    backgroundColor: '#f7f5f4 !important',
    borderColor: '#f7f5f4',
    cursor: 'pointer',
  }),
  option: defaultStyles => ({
    ...defaultStyles,
    fontSize: '1.1em',
  }),
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontSize: '1.1em',
  }),
  singleValue: defaultStyles => ({
    ...defaultStyles,
    fontSize: '1.2em',
  }),
};

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      redirect: false,
      redirectPath: '',
      thumbnail: '',
      data: [],
      teamListOptions: [],
      currentTeam: {},
      selectedTeam: {},
      modalOpen: false,
      modalScreen: "",
      serviceName: "",
      serviceCode: "",
      lobName: "",
      lobCode: "",
      teamList: [],
      isDisabled: false
    }

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }

  // componentDidMount() {

  //   document.addEventListener('mousedown', this.handleClickOutside);
  //   this.RetrieveDataFromAPI();
  //   // PMD 08/12/21 Remove sessionDetails check
  //   // if (this.props.sessionDetails !== null) {
  //   if (!isEmpty(this.props.dataConfig)) {
  //     console.log('Building Toolbar Options')
  //     this.buildToolbarOptions();
  //   }
  //   // }

  //   // this.buildTeamList();

  // }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    try {

      await this.retrieveThumbnail()

    } catch (err) {
      console.log(err)
      errorHandler(err)
    }

    try {

      await this.retrieveDropdownData()

    } catch (err) {
      console.log(err)
      errorHandler(err)
      // throw err;
    }

    if (!isEmpty(this.props.dataConfig)) {
      // console.log('Building Toolbar Options')
      this.buildToolbarOptions();
    }

  }

  retrieveThumbnail = () => {
    const { sessionId, portalDetails } = this.props;

    const URI = `${config[env].URL}/get-selfie-thumbnail-header`;
    const credentials = "omit";

    let options = {
      sessionId: sessionId,
      portaltype: portalDetails.portalType
    };

    postRequest(URI, options, credentials)
      .then((data) => {
        if (data instanceof Error) {
          throw new Error(data);
        }

        this.setState({
          thumbnail: data.result,
          isLoading: false,
        });
      })
      .catch((err) => {
        errorHandler(err)
        throw err;
      });
  }

  retrieveDropdownData = () => {
    const { sessionId, portalDetails } = this.props;

    const URI = `${config[env].URL}/get-team-list`;
    const credentials = "omit";

    let options = {
      sessionId: sessionId,
      portaltype: portalDetails.portalType
    };
    postRequest(URI, options, credentials)
      .then((data) => {
        if (data instanceof Error) {
          throw new Error(data);
        }

        //Stringify both the new result and the current state to check for differences, if no differences exist don't rebuild list
        if(JSON.stringify(data.result)!== JSON.stringify(this.state.teamList)) {
          // PMD 21/06/22 At this stage we don't know which Segmentation is required so process as if TeamView
          //console.log(data.result, this.state.teamList)
          this.buildDropdownList(data.result)
        }

      })
      .catch((err) => {
        errorHandler(err)
        throw err;
      });
  }


  // componentDidUpdate(prevProps, prevState) {

  //   const { allTeams } = this.props.dataConfig;

  //   try {

  //     if (prevProps.dataConfig.allTeams.currentTeamDetails.spotliteAddress !== allTeams.currentTeamDetails.spotliteAddress) {
  //       this.buildToolbarOptions();
  //       // this.buildTeamList();
  //     } else if (prevProps.applicationsLoading !== this.props.applicationsLoading && this.props.applicationsLoading === false) {

  //       const { currentTeamDetails } = this.props.allTeams;
  //       const { error } = this.props;

  //       //If error object is not undefined create alert
  //       if (typeof error.name !== 'undefined') {
  //         console.log(error)
  //         confirmAlert({
  //           title: error.name,
  //           message: error.message,
  //           buttons: [
  //             {
  //               label: "OK",
  //               onClick: () => { /* changes saved */ },
  //             },
  //           ],
  //           closeOnEscape: false,
  //           closeOnClickOutside: false,
  //         });
  //       }

  //       let label = "";
  //       if (isEmpty(currentTeamDetails.departmentName)) {
  //         label = currentTeamDetails.teamName;
  //       } else {
  //         label = currentTeamDetails.departmentName + ' - ' + currentTeamDetails.teamName;
  //       }

  //       const currentTeam = {
  //         accountName: currentTeamDetails.accountName,
  //         departmentName: currentTeamDetails.departmentName,
  //         value: currentTeamDetails.spotliteAddress,
  //         label: label,
  //       }

  //       this.setState({
  //         currentTeam: currentTeam,
  //         selectedTeam: currentTeam,
  //       });

  //     }
  //   } catch (e) {
  //     // This will prevent the crash but it doesnt stop us from applying checks, we cant go down several levels if its undefined 
  //     console.log(e);
  //     // PMD 20/05/22 Error handling
  //     errorHandler(e)
  //   }

  //   if (prevState.redirect === true) {
  //     this.setState({ redirect: false });
  //   }

  // }

  componentDidUpdate(prevProps, prevState) {

    try {

      if(prevProps.dataConfig.AvatarMenu !== this.props.dataConfig.AvatarMenu) {
        this.buildToolbarOptions();
      }

      if(!prevProps.applicationsLoading && this.props.applicationsLoading || prevProps.applicationsLoading && !this.props.applicationsLoading) {
   
          const { currentTeamDetails } =  this.props.allTeams

          let label = "";
          if (isEmpty(currentTeamDetails.departmentName)) {
            label = currentTeamDetails.teamName;
          } else {
            label = currentTeamDetails.departmentName + ' - ' + currentTeamDetails.teamName;
          }
  
          const currentTeam = {
            accountName: currentTeamDetails.accountName,
            departmentName: currentTeamDetails.departmentName,
            value: currentTeamDetails.spotliteAddress,
            label: label,
            teamName: currentTeamDetails.teamName
          }

          this.setState({
            currentTeam: currentTeam,
            selectedTeam: currentTeam,
            isDisabled: false,
          });
      }
    } catch (e) {
      // This will prevent the crash but it doesnt stop us from applying checks, we cant go down several levels if its undefined 
      console.log(e);
      // PMD 20/05/22 Error handling
      errorHandler(e)
    }

    if (prevState.redirect === true) {
      this.setState({ redirect: false });
    }
  }


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  selectTeam = (obj) => {
    if(obj.value !== this.state.currentTeam.value){
      this.setState({isDisabled: true})
      this.props.clearApplicationTasks({});
      this.props.changeTeam(obj.value);
    }
  };

  buildDropdownList = (teamlist) => {
    
    // if (!this.props.applicationsLoading) return null
    const { Segmentation } = this.props.applications

    let options, currentTeam;
    let label = ""

    switch (Segmentation) {
      case 'TeamView':
      case undefined:
        // case 'DepartmentView':
        // console.log(Segmentation)
        const teams = teamlist.map((item) => {

          if (Segmentation === 'DepartmentView') {
            label = `${item.departmentName}`
          } else {
            label = `${item.departmentName} - ${item.teamName}`
          }

          // label = `${item.departmentName} - ${item.teamName}`

          //console.log(item)

          return {
            accountName: item.teamName,
            departmentName: item.departmentName,
            value: item.spotliteAddress,
            label: label,
            defaultTeam: item.defaultTeam,
            teamName: item.teamName
          }
        })

        let uniqueDepartments = Array.from(new Set(teams.map(item => item.departmentName)));
        // FIXME: What is the SpotliteAddress associated with a Department?
        // console.log(uniqueDepartments)

        // console.log(teams)
        options = teams

        currentTeam = options.find(o => o.defaultTeam === 'Y')
        
        break;
      // case 'DepartmentView':
      // TODO:
      // break;
      default:
        break;
    }

    this.setState({
      teamList: teamlist,
      teamListOptions: options,
      currentTeam: currentTeam,
      selectedTeam: currentTeam,
      isLoading: false,
    });
  }

  // buildTeamList() {

  //   if (typeof this.props.allTeams === 'undefined')
  //     return;

  //   const { teamList, currentTeamDetails, currentApplicantAgentDetails } = this.props.allTeams;

  //   const teams = teamList.map(item => {

  //     let label = "";
  //     if (isEmpty(item.departmentName)) {
  //       label = item.teamName;
  //     } else {
  //       label = item.departmentName + ' - ' + item.teamName;
  //     }

  //     // PMD 15/12/21 Handle cases when applicant logs in
  //     if (isEmpty(item.accountName) && isEmpty(item.departmentName)) {
  //       item.accountName = item.teamName;
  //       item.departmentName = item.teamName;
  //     }

  //     return {
  //       accountName: item.accountName,
  //       departmentName: item.departmentName,
  //       value: item.spotliteAddress,
  //       label: label,
  //     }
  //   });

  //   teams.sort((a, b) => {

  //     if (a.accountName.toLowerCase() === b.accountName.toLowerCase()) {

  //       let x = a.departmentName.toLowerCase(), y = b.departmentName.toLowerCase();

  //       if (x === y) {
  //         let i = a.label.toLowerCase(), j = b.label.toLowerCase();
  //         return i < j ? -1 : i > j ? 1 : 0;
  //       }
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     }
  //     return a.accountName.toLowerCase() - b.accountName.toLowerCase();
  //   });

  //   //let options = [];
  //   let subOption = [];

  //   let uniqueAccountNames = Array.from(new Set(teams.map(item => item.accountName)));

  //   const options = uniqueAccountNames.map(item => {
  //     subOption = teams.filter(team => team.accountName === item);
  //     return ({ label: item, options: subOption });
  //   });

  //   // console.log(currentTeamDetails)

  //   let label = "";
  //   if (isEmpty(currentTeamDetails.departmentName)) {
  //     label = currentTeamDetails.teamName;
  //   } else {
  //     // TODO: Check this when switching of team works to ensure correct team is rendered
  //     label = currentTeamDetails.departmentName + ' - ' + currentTeamDetails.teamName;
  //   }

  //   const currentTeam = {
  //     accountName: currentTeamDetails.accountName,
  //     departmentName: currentTeamDetails.departmentName,
  //     value: currentTeamDetails.spotliteAddress,
  //     label: label,
  //   }

  //   this.setState({
  //     teamListOptions: options,
  //     currentTeam: currentTeam,
  //     selectedTeam: currentTeam,
  //     isLoading: false,
  //   });
  // };

  buildToolbarOptions() {
    let toolbarOptions = [];

    const { AvatarMenu } = this.props.dataConfig;

    if (AvatarMenu === null)
      return;

    if (typeof AvatarMenu === 'undefined')
      return;

    toolbarOptions = AvatarMenu.map(option => option);

    if (isEmpty(toolbarOptions.filter(option => option.ServiceCode === 'LOGOUT'))) {
      toolbarOptions.push(
        {
          Label: 'LOGOUT',
          RouterPath: '/login',
          Context: 'Logout',
          Hiny: "",
          viewGUID: "",
          Image: {},
        }
      )
    }

    this.setState({
      data: toolbarOptions,
    });

    return;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeDropDownMenu();
    }

  }

  onClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  cancelModal = () => {
    this.setState({
      modalOpen: false,
    });
  }

  renderModal() {

    if (this.state.modalOpen) {
      return (
        <GetFormData
          open={this.state.modalOpen}
          closeModal={this.onClose}
          cancelModal={this.cancelModal}
          screenType={this.state.modalScreen}
          ServiceName={this.state.serviceName}
          ServiceCode={this.state.serviceCode}
          LoBName={this.state.lobName}
          LoBCode={this.state.lobCode}
        />
      );
    } else {
      return null;
    }
  };

  // RetrieveDataFromAPI = () => {
  //   const { sessionId, portalDetails } = this.props;

  //   const URI = `${config[env].URL}/get-selfie-thumbnail-header`;
  //   const credentials = "omit";

  //   // console.log(this.props)

  //   let options = {
  //     sessionId: sessionId,
  //     portaltype: portalDetails.portalType
  //     // PMD 08/12/21 Remove sessionDetails
  //     // ContactGUID: sessionDetails.contactGUID,
  //   };

  //   postRequest(URI, options, credentials)
  //     .then((data) => {
  //       if (data instanceof Error) {
  //         throw new Error(data);
  //       }

  //       this.setState({
  //         thumbnail: data.result,
  //         isLoading: false,
  //       });
  //     })
  //     .catch((err) => {
  //       // PMD 20/05/22 Generic error handler
  //       errorHandler(err)
  //       throw err;
  //     });
  // };

  returnClassname = (path, altPath) => {
    const { pathname } = this.props.location;
    return pathname === path || pathname === altPath ? "menuDark" : "";
  };

  myFunction = () => {
    // this.setState({ showAvatarMenu: true })
    document.getElementById("myDropdown").classList.toggle("show");
  }

  launchOption = (dataset) => {

    if (dataset.context === 'Modal') {
      this.setState({
        modalOpen: true,
        modalScreen: dataset.viewGUID,
        serviceName: '',
        serviceCode: dataset.serviceCode,
        lobName: '',
        lobCode: '',
      });
    } else if (dataset.context === 'Redirect' || dataset.context === 'Application') {
      this.setState({ redirect: true, redirectPath: dataset.path });
      // } else if (dataset.context === 'OldPortal') {
      //   window.location.href = "https://clientportal.spotlite.services"
    } else if (dataset.context === 'Logout') {
      const { onLogout } = this.props;
      onLogout();
    }

    //this.props.setSidebarViewGUID(dataset.viewguid);
  }

  closeDropDownMenu = () => {
    let dropdowns = document.getElementsByClassName("avatar_drop_down_content");
    let i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }

  renderExpandButton = () => {

    const { onHandleSidebar } = this.props;

    return (
      <div className="webportalTopbarExpandButton">
        <button
          onClick={onHandleSidebar}
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            display: "flex",
            cursor: "pointer",
          }}>
          <img
            className="pageBackIcon"
            alt="back"
            src="/assets/img/menu_black_36dp.svg" />
        </button>
      </div>
    );
  }

  renderToolbarHeader = () => {

    const Control = props => (

      <div className={"control_styles"}>
        <h2 className="right top font">{this.props.label}</h2>
        <components.Control {...props} />
        {/* We need to change the props, using screens */}

      </div>

    );

    const Group = props => (
      <div className="group_styles">
        <components.Group {...props} />
      </div>
    );

    if (this.props.isVisible) {

      //console.log(`Current Team: ${this.state.currentTeam.accountName}`, `Selected Team: ${this.state.selectedTeam.accountName}`)
      
      return (
        <div className="webportalTopbarHeader">
          <div className="header_top_row">

            <Select
              isSearchable={false}
              isDisabled={this.state.isDisabled}
              id="teamSelect"
              name="teamSelect"
              className="fillSpace normalCombo"
              classNamePrefix="react-select"
              components={{ Control, Group }}
              options={this.state.teamListOptions}
              value={this.state.selectedTeam}
              onChange={this.selectTeam}
              defaultValue={this.state.currentTeam}
              styles={colourStyles}
              onMenuOpen={this.onMenuOpen}
            />

          </div>

        </div>
      );
    } else {
      return (
        <div className="webportalTopbarHeader">
          <div className="header_top_row">
          <h2 className="right top font">{this.props.location.label !== undefined ? this.props.location.label : this.props.label}</h2>
          </div>
        </div>
      )
    }
  }

  onMenuOpen = () => {
    this.retrieveDropdownData();
  }

  renderToolbarAction = () => {

    const { thumbnail } = this.state;

    let thumbnailName = "";
    let thumbnailImage = "";

    /* PMD 20/11/20 
    this.state.thumbnail will either contain 
      - a link to the thumbnail 
      or if none present 
      - the name of the person logged in 
    */

    if (thumbnail !== undefined && thumbnail.slice(-3) === "jpg") {
      // thumbnail present
      thumbnailImage = thumbnail;
    } else {
      thumbnailName = thumbnail;
    }

    return (
      <div className="webportalTopbarActions">
        <div ref={this.wrapperRef} className="avatar_drop_down">
          <Avatar size="45" round={true} name={thumbnailName} src={thumbnailImage} onClick={this.myFunction} className="avatar_drop_down_btn" />
          <div id="myDropdown" className="avatar_drop_down_content">
            {/* Avatar list component */}
            <AvatarMenuOptions
              data={this.state.data}
              onItemClick={this.launchOption} />
          </div>
        </div>
      </div>
    );
  }

  render() {


    const { isLoading, redirect } = this.state;


    if (redirect) {
      return <Redirect push to={this.state.redirectPath} />;
    }


    if (isLoading) return null;

    return (

      <GlobalHeader>
        {this.renderExpandButton()}
        {this.renderToolbarHeader()}
        {this.renderToolbarAction()}
        {this.renderModal()}
      </GlobalHeader>
    );

  }

}

const mapStateToProps = (state) => {
  const { sessionId, /* sessionDetails, */ mobileEmbedded, dataConfig, error, portalDetails } = state.session;
  const { applicationsLoading, applications } = state.applications;
  const { allTeams } = state.session.dataConfig;
  const { label, isVisible } = state.applications.selectedApplicationLayout;

  return {
    sessionId,
    // sessionDetails,
    mobileEmbedded,
    dataConfig,
    error,
    allTeams,
    label,
    applicationsLoading,
    portalDetails,
    isVisible,
    applications,
  };
};

export default withRouter(connect(mapStateToProps, {
  setSidebarViewGUID,
  changeTeam,
  updateApplicationTasks,
  clearApplicationTasks,
})(Header));