import React from 'react';
import { isEmpty } from 'lodash';

export default function SegmentMenu(props) {

    const { id, data, onItemClick } = props;

    const element = (
        <div id={id} className="segment_header_drop_down_content">
            <ul>
                {data.map((item, index) => renderDefaultItem(item, index, onItemClick))}
            </ul>
        </div>
    );
    
    return element;
}

function renderDefaultItem(item, index, onItemClick) {

    const element = (
        <div key={index} className="tooltip">
            <div 
                id="dropdownButton" 
                className="SegmentMenu-dropdownButton" 
                key={item.key} 
                onClick={() => onItemClick({
                    viewGUID:   item.viewGUID,
                    path: item.RouterPath,
                    code: item.Name,
                    credits: item.Credits,
                    context: item.Context,
                })}
                >
                {renderHint(item.Hint)}
                {renderIcon(item)}
                {renderLabel(item)}
            </div>
        </div>
    );

    return element;

}

function renderLabel(item) {

    let label = "";

    label = (typeof item.Label === 'undefined' || item.Label === null
        ? typeof item.ServiceCode === 'undefined' || item.ServiceCode === null ? "Text Missing" : item.ServiceCode
        : item.Label);

    return label;

}

function renderHint(hint) {

    let _hint = (typeof hint  === 'undefined' || hint === null
    ? "" : hint);

    if(isEmpty(_hint) || _hint === null){
        return null;
    } else {
        return <span className="tooltiptext right">{_hint}</span>
    }
}

function renderIcon(item) {

    let imageSource = (typeof item.ImageSource  === 'undefined' || item.ImageSource === null
    ? "" : item.ImageSource);

    let imageAltText = (typeof item.ImageAltText  === 'undefined' || item.ImageAltText === null
    ? "" : item.ImageAltText);

    if(isEmpty(imageSource)){
        // Returns a div of equal width and margin of icon to maintain consistency
        return <div className="SegmentMenu-no-menuIcon"/>;
    } else {
        return (
            <img 
            className="SegmentMenu-menuIcon" 
            src={imageSource} 
            alt={imageAltText} />
        );
    }
}
