import React, { Component } from "react";
import { connect } from "react-redux";
import DisplayedItem from "./DisplayedItem";
// import { loadFakePanelComponents } from "../../actions";
import { loadPanelComponents } from "../../actions";
import fakeFormData from "../../content/formdata_200120.json";
import _ from "lodash";
import {isGuid} from '../../utils/Validation'

class PanelDocumentRow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  selectedDocHandler = docid => {
    const { panelData } = this.props;
    let targetPanelGuid = "";
    let jsonToLoad = "";

    // Retrieve the name of the first item in the Cards section
    const firstCard = Object.keys(panelData.Cards[0])[0];
    console.log(docid)
    // console.log(panelData)
    panelData.Cards[0][firstCard].Calls.map(call => {
      targetPanelGuid = call.CallingObjectGUID;
      switch (call.CallingObjectName) {
        case "DocumentContentPanel":
          jsonToLoad = fakeFormData;
          break;
        case "DocumentImagePanel":
          // console.log(_.omit(panelData.Calls[0].RetrievedData, "ViewResponse"));
          let dataArrToDisplay = _.omit(panelData.Calls[0].RetrievedData,"ViewResponse");
          // console.log(dataArrToDisplay)
          let dataToDisplay = Object.keys(dataArrToDisplay)[0];
          // console.log(dataToDisplay)
          let imageToLoad = dataArrToDisplay[dataToDisplay][docid].MediaLocation.Url;
          // console.log(imageToLoad)
          jsonToLoad = (imageToLoad !== undefined) ? imageToLoad : "";
          break;
        default:
          jsonToLoad = "";
      }
      // PMD 17/03/20 added return statement
      return this.props.loadPanelComponents({
        panelGuid: targetPanelGuid,
        json: jsonToLoad
      });
    });
  };

  render() {
    const { docRow } = this.props;

    // console.log(panelData)
    //console.log(docRow)

    return docRow.map(doc => {
      // console.log(doc);
      return (
        <div
          key={doc.id}
          onClick={() => this.selectedDocHandler(doc.id)}
          style={{
            backgroundColor: "#FFF",
            boxShadow: "0px 2px 6px #0000000A",
            margin: "10px",
            display: "flex",
            justifyContent: "spce-between",
            alignItems: "baseline",
            alignContent: "flex-end",
            cursor: "pointer"
          }}
        >
          {Object.values(doc).map((item, index) => {
            // console.log(item)
            // console.log(new Date(item).toLocaleString('en-GB'))
            if (isGuid([item])) {
              // don't display values
              return null
            } else if (new Date(item).toLocaleString('en-GB') !== "Invalid Date") {
              return <DisplayedItem key={index} item={new Date(item).toLocaleString('en-GB')} />;
            } else {
              return <DisplayedItem key={index} item={item} />;
            }
            // return isGuid([item])? null: <DisplayedItem key={index} item={item} />
            // return <DisplayedItem key={index} item={item} />;
          })}
        </div>
      );
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const panelData = state.dashboard.panels[ownProps.panelGuid];
  return { panelData };
};

export default connect(mapStateToProps, { loadPanelComponents })(
  PanelDocumentRow
);
