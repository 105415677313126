import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import {
  resetCreditsError,
  clearCatalogueCredits,
  loadCatalogueCredits,
  creditsRedirect,
  redirectComplete,
  updateApplicationLayout,
} from '../../actions';
import ServicePacks from '../ServicePacks/ServicePacks';

class Catalogues extends Component {

  constructor(props) {
		super(props);

		this.state = {
			catalogueSelected: false,
		};
	}

  componentDidMount() {
    this.props.updateApplicationLayout({ componentType: "", label: 'Catalogues', isVisible: false });
    this.props.resetCreditsError();
    this.props.loadCatalogueCredits();
  }

  handleServicePackSubmit = (purchaseOptions) => {
    // funcitonlity not enabled, we are just using this as info rather than anything more

    // console.log('caught purchaseOptions ' + JSON.stringify(purchaseOptions)); 
  };

  handleCatalgueSelected = (isSelected) => {

    this.setState({catalogueSelected: isSelected});
  }

  renderServicePacks() {

    return (
      <ServicePacks
        handleServicePackSubmit={this.handleServicePackSubmit}
        currentCatalogues={this.props.catalogues}
        catalogueSelected={this.handleCatalgueSelected}
      />
    )
  }

  /* PMD 13/10/2022 - Causes screen to automatically redirect when first called */
  // renderRedirectChecker() {

  //   if (this.props.redirectUrl) {
  //     this.props.redirectComplete();
  //     return (
  //       <Redirect to={this.props.redirectUrl} />
  //     )
  //   }
  // }

  render() {

    const seviceButtonClass = `services_back_button${this.state.catalogueSelected ? '--hide' : ''}`;

    return (

      <div className="innerPage">

        <Link to="/account">
          <button
              className={seviceButtonClass}
              type="button"
              >
                <span>Back to Account</span>
          </button>
        </Link>

        <div className="contentHolder">

          {this.renderServicePacks()}

        </div>

        {/* PMD 13/10/2022 - Causes screen to automatically redirect when first called */}
        {/* {this.renderRedirectChecker()} */}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogues } = state.credits;
  const { redirectUrl } = state.session
  return { catalogues, redirectUrl };
};

export default connect(mapStateToProps, {
  resetCreditsError,
  clearCatalogueCredits,
  creditsRedirect, redirectComplete,
  loadCatalogueCredits,
  updateApplicationLayout,
})(Catalogues);
