import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { selectInvoice, loadInvoice, setTransactionError } from '../../actions';
// import TransactionInvoicePrint from './TransactionInvoicePrint';
import ReactToPrint from "react-to-print";
import Button from "../Reusable/Button";
import PrintWrapper from './PrintWrapper';

class TransactionInvoice extends Component {

  componentDidMount() {

    this.props.setTransactionError('');
    this.props.loadInvoice();

  }

  renderInvoiceHeader = () => {

    if (!this.props.invoice) return;

    // console.log(this.props.invoice)

    return (
      <div className="invoice_header">

        <div className="invoice_padder">

          <div>Invoice: #{this.props.invoiceId}</div>

          <div className="invoice_header_details">
            <div>{this.props.invoice.OrganisationName}</div>
            <div>Date: {moment(this.props.invoice.TransactionDate, "YYYY-MM-DD HH:mm:ss").format('Do MMM YYYY')}</div>
          </div>

          <div className="invoice_header_values">
            <div className="invoice_header_values_title">Amount (£):</div>
            <div className="invoice_header_values_value">{this.props.invoice.CurrencyAmountExcludingTax.toFixed(2)}</div>
            <div className="invoice_header_values_title">Vat (£):</div>
            <div className="invoice_header_values_value">{this.props.invoice.TaxAmount.toFixed(2)}</div>
            <div className="invoice_header_values_title">Total (£):</div>
            <div className="invoice_header_values_value">{this.props.invoice.CurrencyAmountIncludingTax.toFixed(2)}</div>
          </div>

          <div className="clearFloat" />
        </div>
      </div>
    )
  }

  renderInvoiceDetails = () => {

    if (!this.props.invoice) return;

    return (
      <div className="invoice_details">

        <div className="invoice_padder">

          <div>Invoice Details</div>

          <div className="invoice_item_row">
            <div className="invoice_item alignLeft"><strong>Item</strong></div>
            <div className="invoice_item"><strong>Credits Purchased</strong></div>
            <div className="invoice_item"><strong>Cost (ex Vat)</strong></div>
          </div>

          {this.renderInvoicelines()}

          <div className="clearFloat" />

        </div>
      </div>
    )
  }

  renderInvoicelines = () => {

    // console.log(this.props.invoice)
    if (this.props.invoice.documentTransactionDetail.length) {
      return (
        this.props.invoice.documentTransactionDetail.map((detailItem, index) => {
          return (
            <div key={index} className="invoice_item_row">
              <div className="invoice_item alignLeft">{detailItem.Item}</div>
              <div className="invoice_item">{detailItem.TransactionCredits}</div>
              <div className="invoice_item">{detailItem.TransactionCurrencyAmountExcludingTax.toFixed(2)}</div>
            </div>
          )
        })
      )
    }

    return null;
  }

  renderInvoiceStatus = () => {

    if (!this.props.invoice) return;

    return (

      <div className="invoice_details">

        <div className="invoice_padder">

          <div>Invoice Status</div>

          <div className="row_space_between" style={{ marginLeft: '0', marginTop: '10px' }}>

            <div className="invoice_status_item">Status: {(this.props.invoice.TransactionCompleted) ? "Paid" : "Unpaid"}</div>
            <div className="invoice_status_item">Due Date: {this.formatDate(this.props.invoice.TransactionDueDate)}</div>
            <div className="invoice_status_item">Paid Date: {this.formatDate(this.props.invoice.TrasactionCompletedDate)}</div>
            <div className="invoice_status_item">
              {
                //this.renderPayButton() 
              }
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderPayButton = () => {

    if (!this.props.invoice) return;

    if (this.props.invoice.TransactionCompleted !== 0) return;

    return (
      <input type="button" className="button" value="Pay Now" style={{ margin: '-10px 0 0 0', padding: '8px 20px' }} onClick={this.handlePayNow} />
    )
  }

  handlePayNow = () => {
    // console.log('pay now');
  }


  formatDate = (dte) => {

    const newDte = moment(dte, "YYYY-MM-DD HH:mm:ss");

    if (newDte.isValid()) {
      return newDte.format('DD/MM/YYYY')
    }
    return "n/a";
  }

  renderError = () => {
    return (
      <div className="error row_space_around">{this.props.error}</div>
    )
  }

  handleBackButton = () => {
    this.props.handleBackButton();
  };

  render() {
    return (

      <div className="contentHolder">

        <div className="transactions">
          <div className="invoice_padder">

            {this.renderInvoiceHeader()}

            {this.renderInvoiceDetails()}

            {this.renderInvoiceStatus()}
          </div>
        </div>


        <div className="row_left">

          {/* Render Back Button */}

          <Button
            className="button"
            handleClick={this.handleBackButton}
            label={"Cancel"} />

          <ReactToPrint
            trigger={() => <input
              type="button"
              className="button"
              value="Print"
              style={{ marginTop: 0, marginLeft: 10 }}
            />}
            content={() => this.printComponentRef} />

        </div>

        {this.renderError()}

        <div className="hiddenPrintComponent" style={{ position: 'absolute', top: -2000 }}>
          {/* PMD 20/10/22 Provide a wrapper that doesn't reference redux to fix bug */}
          {/* <TransactionInvoicePrint ref={el => (this.printComponentRef = el)} /> */}
          <PrintWrapper ref={el => (this.printComponentRef = el)} />
        </div>

      </div >


    );
  }
}


const mapStateToProps = (state) => {
  const { invoiceId, invoice, error } = state.transactions;
  return { invoiceId, invoice, error };
};

export default connect(mapStateToProps, { selectInvoice, loadInvoice, setTransactionError })(TransactionInvoice);