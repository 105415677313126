import React, { Component } from 'react';

class App404 extends Component {

  render() {
    return (

      <div>

        <div className="pageTitle">
          <h1>Page Not Found</h1>
        </div>

        <div className="contentHolder">
          
          404

        </div>

      </div>
    );
  }
}

export default App404;
