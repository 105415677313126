import deepCopy from '../../utils/deepCopy'
import { ImageFormatter, TimestampFormatter, ConditionFormatter } from './validators'

export const getValidationFormatter = ({ validator, newStyle, newColumn, conditionRule, columns, conditionRules, data }/*...props*/) => {
     
    //console.log(...props);
    const { row, column } = data;

    switch (validator) {
        case 'hasConditionRule':
            // console.log(conditionRule)
            // console.log(newColumn)
            // console.log(columns)
            // console.log(conditionRules)
            // FIXME: Mutates state
            const rulesCopy = deepCopy(conditionRules)
            const conditionsToCheck = rulesCopy.filter((condition) => condition.conditionrulename === conditionRule)
            // console.log(conditionsToCheck[0])

            // Check whether the formatting depends on a different column
            let columnUsedForFormat = {}
            /*
            if (conditionsToCheck[0].testColumn !== newColumn.propertyGUID) {
                // TODO: Get associated columns details
            } else {
                columnUsedForFormat = newColumn
            }*/
            // console.log(columnUsedForFormat)

            return (
                <div style={{ newStyle }}>
                    <ConditionFormatter displayValue={row[column.key]} row={row} /*formatBasedOnColumn={columnUsedForFormat}*/ conditions={conditionsToCheck} column={column} />
                    {/* {row[newColumn.key]} */}
                </div>
            )
            
            break;
        case 'avatar':          
                return (<div style={newStyle}>
                    <ImageFormatter
                        thumbnail={row[column.key]}
                        name={row.ApplicationInstanceName} />
                </div>)
            break;
        case 'date':
                return (<div style={newStyle}>
                    <TimestampFormatter timestamp={new Date(row[column.key])} />
                </div>)
            break;

        default:
            //  Handle Conditional Validation
            break;
    }
    // return ({ value }) => {
    //     // console.log(value)
    //     return <></>
    // }
}

