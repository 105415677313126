import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {
    getViewData: {},
    getViewLoading: true,
    getViewError: null
};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.GET_DATAVIEW_REQUEST:
            return {
                ...newState,
                getViewData: {},
                getViewLoading: true,
                getViewError: null
            };
        case types.GET_DATAVIEW_SUCCESS:
            return {
                ...newState,
                getViewData: action.payload,
                getViewLoading: false,
                getViewError: null
            };
        case types.GET_DATAVIEW_ERROR:
            return {
                ...newState,
                getViewError: action.payload,
                getViewLoading: false
            };
        default:
            return prevState;
    }
};