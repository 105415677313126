import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { loadCatalogueCredits, resetCreditsError } from "../../actions";

import SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";

import { updateApplicationLayout } from "../../actions";

class AccountsIndex extends Component {
  componentDidMount() {
    this.props.updateApplicationLayout({ componentType: "", label: 'Account', isVisible: false });
    this.props.resetCreditsError();
    this.props.loadCatalogueCredits();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionId !== this.props.sessionId) {
      this.props.resetCreditsError();
      this.props.loadCatalogueCredits();
    }
  }

  renderUnallocatedCatalogue = () => {
    // cant use === as code is a string
    // eslint-disable-next-line
    const cataloguesIndex = _.findIndex(this.props.catalogues, function (o) {
      return o.catalogueCode === "999";
    });

    if (cataloguesIndex !== -1) {
      return (
        <div className="group">
          <p className="textContentLarge unallocatedText">
            You have {this.props.catalogues[cataloguesIndex].creditBalance}{" "}
            unallocated credits
          </p>

          <div className="flex">
            {/* PMD 14/10/22 Make the button the whole link */}
            {/* <button
              className="button button-small-top-margin"
              style={{ width: "230px" }}
            >
              <Link to="account/splitcredits">Allocate Now</Link>
            </button> */}
            <Link to="account/splitcredits">
              <button
                className="button button-small-top-margin"
                style={{ width: "230px" }}
                disabled={this.props.catalogues[cataloguesIndex].creditBalance <= 0}
              >
                Allocate Now
              </button>

            </Link>

          </div>
        </div>
      );
    }
  };

  renderCurrentCatalogues = () => {
    if (this.props.catalogues) {
      // const cataloguesJSON = SpotliteClientCatalogue.Catalogue;
      const cataloguesJSON = this.props.catalogueData.Catalogue;

      const catalogueNamesArray = cataloguesJSON.map(function (item, i) {
        return {
          CatalogueCode: item.CatalogueCode,
          CatalogueName: item.CatalogueName,
        };
      }, this);

      //console.log(this.props.catalogues);

      return this.props.catalogues.map(function (item, i) {
        // cant use === as code is a string
        // eslint-disable-next-line
        const catalogueNameIndex = _.findIndex(catalogueNamesArray, function (
          o
        ) {
          return o.CatalogueCode === item.catalogueCode;
        });

        // set the catalogue name from the JSON if we have one
        let catalogueName = "";
        if (catalogueNameIndex !== -1) {
          catalogueName = catalogueNamesArray[catalogueNameIndex].CatalogueName;
        }

        // cant use === as code is a string
        // eslint-disable-next-line
        if (item.catalogueCode != 999) {
          //no unallocated credits
          return (
            <div key={i} className="card">
            {/* <div key={i} className="box servicePack"> */}
              <div className="card_credit_container"> 
                <p className="cardBold">Catalogue:</p>
                <p className="cardLinePadded">{catalogueName} </p>

                <p className="cardBold cardLinePadded">Credit Balance:</p>
                <p className="cardLinePadded">{item.creditBalance}</p>
              </div>
              <div className="boxCta padded"></div>
            </div>
          );
        } else {
          return null;
        }
      }, this);
    }
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  render() {

    return (
      <div className="innerPage">

        <div className="accounts boundToScreen">
          <div className="group">
            <h2>Current Catalogues</h2>

            {/* <div className="unmaxed"> */}

              <ul className="card_grid">
                {this.renderCurrentCatalogues()}
              </ul>
            {/* </div> */}

            <div className="">
              {this.renderUnallocatedCatalogue()}

              {this.renderError()}
            </div>
          </div>

          <div className="group">
            <h2 className="">Services</h2>

            <div className="group flex">
              {/* PMD 13/10/22 Wrapp the button in the Link */}
              {/* <button
                className="button button-small-top-margin"
                style={{ width: "230px" }}
              >
                <Link to="account/credits">Add Credits</Link>
              </button> */}
              <Link to="account/credits">
                <button
                  className="button button-small-top-margin"
                  style={{ width: "230px" }}>
                  Add Credits
                </button>
              </Link>
              {/* PMD 13/10/22 Wrapp the button in the Link */}
              {/* <button
                className="button button-small-top-left-margin"
                style={{ width: "230px" }}
              >
                <Link to="account/catalogues">View Services</Link>
              </button> */}
              <Link to="account/catalogues">
                <button
                  className="button button-small-top-left-margin"
                  style={{ width: "230px" }}>
                  View Services
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  const { catalogues, error } = state.credits;
  const { catalogueData } = state.catalogue;
  return { sessionId, catalogues, error, catalogueData };
};

export default connect(mapStateToProps, {
  loadCatalogueCredits,
  resetCreditsError,
  updateApplicationLayout,
})(AccountsIndex);
