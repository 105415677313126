// REPLACED MAY 2020 see below
// import fetch from 'isomorphic-fetch';

// export function apiParse(data) {
//   console.log('apiParse - Data::', data);
//   switch (data.status) {
//     case 'ok':
//       return data.result;
//     case 'error':
//       throw new Error(data.error || 'Unknown Error');
//     default:
//       throw new Error('Unknown Status');
//   }
// }

// export function apiParseSpotlite(data) {
//   console.log('apiParseSpotlite - Data::', data);
//   switch (data.Result) {
//     case 'ok':
//       return data;
//     default:
//       throw new Error(data.ErrorMsg)|| 'Unknown Error';
//   }
// }

// // TODO: Check with Martyn whether we should get JSON changed
// export function apiParseScreen(data) {
//   console.log('apiParseScreen - Data::', data);
//   switch (data.ViewResponse.PropertyRef) {
//     case '200':
//       return data;
//     default:
//       throw new Error(data.ErrorMsg)|| 'Unknown Error';
//   }
// }

// // FIXME: Lambeth Demo on 30/03/20
// // check for circular references and ignore if found
// const getCircularReplacer = () => {
//   const seen = new WeakSet();
//   return (key, value) => {
//     if (typeof value === 'object' && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// };

// export function postRequest(URI, options, credentials, headers = {}) {
//   //  console.log(URI);
//   //  console.log(options)
//   return fetch(URI, {
//     method: 'POST',
//     credentials: credentials,
//     headers: {
//       ...headers,
//       'Content-Type': 'application/json'
//     },
//     // body: JSON.stringify(options)
//     body: JSON.stringify(options, getCircularReplacer())

//   })
//   .then(res => res.json())
//   .catch(err => err)
// }
// /*
// export function postRequestWithStatus(URI, options, credentials, headers = {}) {
//   console.log(URI);
//   return fetch(URI, {
//     method: 'POST',
//     credentials: credentials,
//     headers: {
//       ...headers,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(options)
//   })
//   .then(response => {
//     const statusCode = response.status;
//     const data = response.json();
//     return Promise.all([statusCode, data]);
//   })
//   .then(res => {
//     console.log('my api call');
//     console.log(res[0], res[1]);
//     if (res[0] == 'error'){
//       throw new Error(res[1].error || 'Unknown Error');
//     } else {
//       return {status: res[0], data: res[1]};
//     }
//   })
//   //.then(res => res.json()) // ensure ethe whole res is passed back
//   .catch(err => err)
// }
// */
// export function getRequest(URI, credentials, headers = {}) {
//   return fetch(URI, {
//     method: 'GET',
//     Accept: 'application.json',
//     credentials: credentials,
//     headers: {
//       ...headers
//     },
//   })
//   .then(function(response) {
//     if (response.status === 401) {
//       throw new Error('Error: This service requires authentication');
//     }
//     return response.json();
//   })
//   .catch(err => err)
// }


import fetch from 'isomorphic-fetch';
import { getNestedValue } from './collectionUtils';
// import { confirmAlert } from "react-confirm-alert";
// import { SET_SESSION } from "../actions/types"

//  const env = process.env.NODE_ENV || 'development';
export function apiParse(data) {
  //console.log('apiParse - Data::', data);
  switch (data.status) {
    case 'ok':
      return data.result;
    case 'error':
      throw new Error(data.error || 'Unknown Error');
    default:
      throw new Error('System unavailable at this time. Please try later or check the Service Availability page on the CDD Services website.');//replaces Unknown Status
  }
}

export function apiParseDataView(data) {
  //console.log('apiParseDataView - Data::', data);
  //console.log('apiParseDataView - data.status::', data.status);
  if (data.status && data.status === 'error') {
    throw new Error(data.error || 'Unknown Error');
  }

  let status = getNestedValue(data, 'ViewResponse', 'PropertyRef') || getNestedValue(data, 'ViewResponse', 'CollectionUpdate', 'PropertyRef') || getNestedValue(data, 'CollectionUpdate', 'PropertyRef');
  // switch (data.ViewResponse.PropertyRef || data.ViewResponse.CollectionUpdate.PropertyRef) {

  // console.log(status)
  switch (status) {
    case '200':
    case '202':
      return data;
    default:
      console.log(data)
      throw new Error(data.ErrorMsg || 'Unknown Error');
  }
}

export function apiParseSpotlite(data) {
  //console.log('apiParseSpotlite - Data::', data);
  switch (data.Result) {
    case 'ok':
      return data;
    default:
      throw new Error(data.ErrorMsg) || 'Unknown Error';
  }
}

export function apiParseScreen(data) {
  // console.log('apiParseScreen - Data::', data);
  if (data.status && data.status === 'error') {
    //console.log(data.error)
    // if (data.error.includes("Session doesnt exist") || data.error.includes("expired")) {
    //   confirmAlert({
    //     title: "Session Expired",
    //     message: "Your session has expired. Please login again to continue.",
    //     buttons: [
    //       {
    //         label: "OK",
    //         onClick: () => { this.props.logout() },
    //       },
    //     ],
    //   });
    // }
    throw new Error(data.error || 'Unknown Error');
    // return data
  }

  switch (data.ViewResponse.PropertyRef) {
    case '200':
      return data;
    default:
      throw new Error(data.ErrorMsg) || 'Unknown Error';
  }
}

export function apiParseScreenComplaint(data) {
  // console.log('apiParseScreenComplaint - Data::', data);
  if (data.status && data.status === 'error') {
    throw new Error(data.error || 'Unknown Error');
  }
  let propRef = '';

  if (data.ViewResponse && data.ViewResponse.PropertyRef) {
    propRef = data.ViewResponse.PropertyRef
  }
  if (data.ViewResponse && data.ViewResponse.CollectionUpdate.PropertyRef) {
    propRef = data.ViewResponse.CollectionUpdate.PropertyRef
  }
  if (data.CollectionUpdate && data.CollectionUpdate.PropertyRef) {
    propRef = data.CollectionUpdate.PropertyRef
  }
  // PropRef not set correctly throw an error
  if (propRef === '') throw new Error(data)

  // Use a variable to check value of return PropertyRef
  // switch (data.ViewResponse.PropertyRef || data.ViewResponse.CollectionUpdate.PropertyRef) {
  // switch (data.ViewResponse.CollectionUpdate.PropertyRef) {
  switch (propRef) {
    case '200':
    case '202':
      return data;
    default:
      throw new Error(data.ErrorMsg || 'Unknown Error');
  }
}
// FIXME: Lambeth Demo on 30/03/20
// check for circular references and ignore if found
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
export function postRequest(URI, options, credentials, headers = {}) {
  //  console.log(URI);
  //  console.log(options)
  return fetch(URI, {
    method: 'POST',
    credentials: credentials,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      appkey: process.env.REACT_APP_APP_KEY
    },
    // body: JSON.stringify(options)
    body: JSON.stringify(options, getCircularReplacer()),
  })
    .then((res) => res.json())
    .catch((err) => err);
}
/*
export function postRequestWithStatus(URI, options, credentials, headers = {}) {
  console.log(URI);
  return fetch(URI, {
    method: 'POST',
    credentials: credentials,
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options)
  })
  .then(response => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]);
  })
  .then(res => {
    console.log('my api call');
    console.log(res[0], res[1]);
    if (res[0] == 'error'){
      throw new Error(res[1].error || 'Unknown Error');
    } else {
      return {status: res[0], data: res[1]};
    }
  })
  //.then(res => res.json()) // ensure ethe whole res is passed back
  .catch(err => err)
}
*/
export function getRequest(URI, credentials, headers = {}) {
  return fetch(URI, {
    method: 'GET',
    Accept: 'application.json',
    credentials: credentials,
    headers: {
      ...headers,
      appkey: process.env.REACT_APP_APP_KEY
    },
  })
    .then(function (response) {
      if (response.status === 401) {
        throw new Error('This service requires authentication');
      }
      return response.json();
    })
    .catch((err) => err);
}
