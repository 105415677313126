// Hardcoded GUIDs
const universeGUID = '68BEFFB8-F039-4E5A-BC7C-69D658DB686B';
const defaultLanguageGUID = 'C54CD611-95A1-43EE-AB91-B82FDAB42275';
const pendingStatusGUID = '7BAD11FA-D486-4D13-9290-BDBECBBB6380';
const exceptionStatusGUID = 'BF2D16E1-5528-480B-948D-F3DA1E87F96E';
const suspendedStatusGUID = '812CAEF5-3CBA-479D-85E9-33C9C747BE35';
const activeStatusGUID = '3BF4ED5C-40E2-49A1-A795-9A8C882A2600';

// Panel GUIDs
const selfiePanelGUID = '1C258597-60D9-4FD8-BCF5-ECE72DF7739D';
const certificatePanelGUID = '7BEE84A9-C36E-49AE-9FF2-CB8BE7E68FA6';
// Hull4Heroes GUIDs
// const immunityPassportGUID = '7ED9AE7C-1237-4459-B25F-2898D4131C07';
// const veteransPassportGUID = 'F6FFC580-F253-42FB-8E46-699CA40A86A1';
// const individualSkillsGUID = 'FC1CCF32-E37A-47DC-8021-B21B03FF4B86';
const onboardingCertificateGUID = 'A318838E-4CCB-44B5-91B4-47D654DB7774';

// Screen GUIDs
const displayPanelsGUID = '39ED6CF3-E51E-44A9-93ED-B605627429B5';
const caseGUID = '039C7BCB-4387-499E-83F5-43ED528AAF00';
const newApplicantGUID = '2F76BB22-513C-476B-8A4E-AFF8DA00D51B';

// Temp to test switch
const newAgentGUID = 'D1BE1205-21B1-4D29-94AA-6C65C0C5922C';
const newTeamGUID = '0EF4FCAB-D3F2-443E-8ACC-BFAC98A3304D';

module.exports = {
  universeGUID,
  defaultLanguageGUID,
  pendingStatusGUID,
  exceptionStatusGUID,
  suspendedStatusGUID,
  activeStatusGUID,
  displayPanelsGUID,
  selfiePanelGUID,
  certificatePanelGUID,
  newApplicantGUID,
  newAgentGUID,
  newTeamGUID,
  // Hull4Heroes GUIDs
  // immunityPassportGUID,
  // veteransPassportGUID,
  // individualSkillsGUID,
  onboardingCertificateGUID,
  caseGUID,
  MyTasksGUID: '8D50FDE8-8F6C-428D-829C-EDF8CBA4E75C',

  ApplicationTaskNameGUID: '1358A7C7-AC86-4660-B8EF-73CC95591CC7',
  ApplicationPartyIDGUID: 'B7C857D9-D5A5-4035-88F9-647856DAC2F6',
  ApplicationInstanceNameGUID: '2EA791D5-222A-44D4-877C-80BF5E2367A5',
  ServiceNameGUID: 'D2FEA8EC-84B2-4533-8D50-B3EC65ADD0E7',
  ApplicationTaskCreatedDateGUID: 'B1578452-E7C1-451C-8DF9-FC9DD0F72371',
  ApplicationTaskDueDateTimeGUID: '26E77399-89C2-47E6-AA59-77A880663CBE',
  OverallStatusGUID: '8220B0F7-3729-4E76-B4A3-1279397BD891',
  BookmarkGUID: '8D50FDE8-8F6C-428D-829C-EDF8CBA4E75C',
  TaskDueDateGUID: '26E77399-89C2-47E6-AA59-77A880663CBE',
  newWorkQueueGUID: 'AC49CAFE-32C1-41FC-9702-C8C551016983',
  editWorkQueueGUID: 'DD177740-D71E-45F4-B785-86D223C0F2CA',
  newBusinessSearch: '1B3A7CC1-3A35-446F-8581-9D0F1FA1773C',
  KYBCertificateGUID: 'AFACDDEB-7AE5-4663-9407-74BB1065326F',
  AllowableActionGUID: 'AD18FE01-7A3F-4426-8E20-5D5BB125F6B8',
  newestFirst: 'F3D4AA3A-EECC-4BD5-B6F4-05CAECB69EED',
  newestLast: 'DEB763F4-AC90-4EC0-9686-3A99A1419884',
  dueDateOrder: '4ED4BBD2-6E9F-4415-9D8D-1164067552B3',
  EventGUID: 'D3A99CDD-DD2C-4427-B089-AC2C780FF5C4',
  fileUploadGUID: 'D66E9BB9-0252-446E-BD4E-8525CFCAB4B5',
  taskListPanelGUID: 'E687DABB-CC67-4407-9B10-3F866202ADB3',
  taskListPopupGUID: '56B23D0C-2AFD-44E0-9E8A-FCC132FE8BD1',
  usernameSearchGUID: 'C9A05D83-EBA3-4138-83C3-5B126D08C54A',
  saveLayoutGUID: '8E9C7815-1BE6-468F-A527-E5573F4C6D2F',
  editLayoutGUID: '853DBC27-26D3-4560-9AE3-AD57DCAF2027',
  panelMenuGUID: 'EA641F6C-6F14-4BBF-8BB6-8E4394B9745E',
  kanbanboardGUID: 'CA7F9719-D83C-4FE1-B606-7E130563FFF0',
  listscreenGUID: 'E687DABB-CC67-4407-9B10-3F866202ADB3',
  calendarGUID: 'B4707B35-3875-4F9C-823D-7931907E1B7C',
  summaryGUID: '655BB1F3-C80A-4A3A-9AE3-2D0A9E3566CB',
  activityDownloadScreenGUID: '0F35F265-9C08-4909-911F-6A2601A46031',
  CreateDBSEBulkBatchGUID: 'DDF1534B-7D60-4474-9517-99E2EE010B29',
  SubmitDBSEBulkBatchGUID: 'B313A525-B62B-4F85-8DA5-2C2FF7CC1911',
  ProcessCRB03GUID: '02FC0D4C-6318-4E68-A024-6EA0FFC40541',
  ProcessCRB04GUID: 'A3D08D31-580E-4E94-9601-1D81E48981D9'
};
