import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadTeams,
  loadUsers,
  selectUser,
  loadCatalogueCredits,
  addUserToTeam,
  removeUserFromTeam,
  deleteTeam,
  updateApplicationLayout,
} from "../../actions";
import _ from "lodash";
import Select from "react-select";

import { newAgentGUID, newTeamGUID } from "../../utils/Constants";
import SubHeaderTeams from "../Header/SubHeaderTeams";
import Users from "../Users/Users";
import TeamFilters from "../TeamFilters/TeamFilters";
import Enterprise from "../Enterprise/Enterprise";
import Button from "../Reusable/Button";
import GetFormData from "../Form/GetFormData";
import SegmentMenu from "../Reusable/SegmentMenu";

class Team extends Component {

  constructor(props) {
    super();

    this.state = {
      teamSelectOptions: {},
      layout: "teams",
      creatingTeam: false,
      modalOpen: false,
      modalScreen: "",
      selectedCatalogue: "",
      teamViewGUID: "",
      isSaving: false,
      isLoading: true,
      selectedTeamAndUser: {},
      // PMD 01/08/22 Add Team dropdown
      isDisabled: true,
      applicationGUID: null,
      spotliteAddress: ""
    };

  }

  componentDidMount() {
    this.props.updateApplicationLayout({ componentType: "", label: 'Team', isVisible: false });
    this.props.loadTeams()
    this.props.loadUsers()
    // load the catalogue information
    this.props.loadCatalogueCredits();
    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps) {

    if (prevProps.sessionId !== this.props.sessionId || this.props.reload) {
      this.props.loadTeams();
      // PMD 14/05/22 Also reload Users
      this.props.loadUsers();
      this.props.loadCatalogueCredits();
    }

    if (this.props.teams && prevProps.teams !== this.props.teams) {

      if (this.props.users) {
        const formattedUsers = this.props.users.map((user) => {
          return {
            value: user.APGUID,
            label: user.Username,
            ContactGUID: user.CGUID
          }
        });

        this.setState({
          teamSelectOptions: formattedUsers
        })
      }
    }
  }

  filterOptionsByCurrentTeam = ({ teamGUID }) => {
    /*
    take teamSelectOptions and remove any that are already in our current team
    */
    if (_.isEmpty(this.state.teamSelectOptions)) return {};

    var currentTeamMembers = _.find(this.props.teams, { teamGUID: teamGUID }).teamMembers;

    var newOptions = this.state.teamSelectOptions.filter((member) => {
      //don't return if they are in currentTeamMembers
      var memberExists = _.findIndex(currentTeamMembers, {
        ContactGUID: member.ContactGUID,
      });
      if (memberExists === -1) {
        return true;
      }
      return false;
    });

    return newOptions;
  };

  renderTeams = () => {

    //dont show if a user is selected
    if (!_.isEmpty(this.props.selectedUser)) return;

    // console.log(this.props.teams)

    return this.props.teams.map(function (team, i) {

      let options = this.filterOptionsByCurrentTeam({
        teamGUID: team.teamGUID,
      })

      if (_.isEmpty(options)) {
        options = []
      }

      return (
        <div key={team.teamGUID} className="team_header_container">
          <div className="team_container">
            <div className="team_header">
              <h2 className="teamTitle">{team.teamName}</h2>
              <div className="team_header_drop_down_container">
                {/* PMD 17/10/22 Don't crash if the TeamMenu option is missing in the DataConfigurations record */}
                {this.props.TeamMenu && this.props.TeamMenu &&
                  this.renderDropDown(this.state.isDisabled, this.props.TeamMenu.Options, i, team)
                }
              </div>
            </div>
            <div className="team_task_container">
              <Select
                isSearchable={false}
                id="team_select"
                name="users"
                classNamePrefix="react-select"
                options={options}
                value={{ value: 0, label: "Add a Team Member" }}
                onChange={this.addMemberToTeam.bind(this, team.teamGUID)}
              />
              <div >
                {this.buildUsers({
                  teamGUID: team.teamGUID,
                  teamMembers: team.teamMembers,
                })}
              </div>
            </div>
          </div>
        </div>
      )
    }, this);
  };

  addMemberToTeam = (teamGUID, evt) => {

    let userAdded = this.props.addUserToTeam({
      teamGUID: teamGUID,
      PartyGUID: evt.value, // ApplicationPartyGUID of selected Team Member
    });

  };

  removeMemberFromTeam = ({ teamMember }) => {
    const { PartyGUID } = teamMember;

    this.props.removeUserFromTeam({
      PartyGUID: PartyGUID,
    });
  };

  renderAddTeamButton = () => {
    return (
      <button
        type="button"
        className="button action add"
        onClick={this.handleCreateTeam}
      >
        Add Team
      </button>
    );
  };

  handleCreateTeam = () => {


    this.setState({
      modalOpen: true,
      modalScreen: newTeamGUID,
      //selectedCatalogue: dataset.value,
    });

  };

  buildUsers({ teamGUID, teamMembers }) {
    return teamMembers.map(function (teamMember, i) {
      return (
        <div className="team_container" key={i}>
          <div className="flexBetween">
            <button
              style={{ cursor: "pointer", border: "none", background: "transparent", color: "blue", textDecoration: "underline" }}
              // onClick={() => alert(JSON.stringify(teamMember))}
              onClick={() => this.updateUserTeam(teamMember)}
            >
              {teamMember.PartyFullName}
            </button>

            <p>{teamMember.AgentRole}</p>
            <img
              src="/assets/img/cross.png"
              alt="remove"
              style={{ height: 25, marginRight: 5, cursor: "pointer" }}
              onClick={() =>
                this.removeMemberFromTeam({ teamGUID, teamMember })
              }
            ></img>
          </div>
        </div>
      );
    }, this);
  }

  renderAddUserButton = () => {
    // console.log(this.props.catalogues)
    if (this.props.catalogues.length > 0) {

      return (
        <>
          <Button
            label={"Add Agent"}
            handleClick={this.openModal}
            className={"button action add indentRight"}
            value={newAgentGUID}
          />
        </>
      );
    }
  };

  updateUserTeam = (teamMember) => {
    // console.log(teamMember)
    this.setState({ selectedTeamAndUser: teamMember, spotliteAddress: teamMember.SpotliteAddress })
    let e = {}
    e.target = {}
    e.target.value = newAgentGUID
    this.openModal(e)
  }

  renderTeamsPage = () => {
    return (
      <div>
        {this.renderTeams()}
      </div>
    );
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  selectUsersPage = () => {
    this.setState({ layout: "users" });
  };

  selectEnterprisePage = () => {
    this.setState({ layout: "enterprise" });
  };

  selectTeamsPage = () => {
    this.setState({ layout: "teams" });
  };

  selectFiltersPage = () => {
    this.setState({ layout: "filters" });
  };

  renderMain = () => {

    if (!_.isEmpty(this.props.selectedUser)) return false;

    return (
      <div className="innerPage">

        <div className="teams">
          <SubHeaderTeams
            layout={this.state.layout}
            renderAddUserButton={this.renderAddUserButton}
            renderAddTeamButton={this.renderAddTeamButton}
            selectUsersPage={this.selectUsersPage}
            selectTeamsPage={this.selectTeamsPage}
            selectEnterprisePage={this.selectEnterprisePage}
            selectFiltersPage={this.selectFiltersPage}
          />

          {this.state.layout === "teams" && (
            <div className="contentHolder boundToScreen containsSubHeader">
              {this.renderTeamsPage()}
              {this.renderError()}
            </div>
          )}
          {this.state.layout === "users" && <Users />}
          {this.state.layout === "enterprise" && <Enterprise />}
          {this.state.layout === "filters" && <TeamFilters />}
        </div>
      </div>
    );
  };

  // PMD 01/08/22 Add team dropdown menu

  renderDropDown = (isDisabled, data, index, team) => {

    const onMenuItemClick = () => {
      console.log("Here")
      document.getElementById(index).classList.toggle("show");
    }

    const onItemClick = (event) => {
      // TODO: Do we need to pass the team details as well??
      //console.log('team:', team);
      // console.log(event)
      this.setState({
        applicationGUID: team.teamGUID,
        spotliteAddress: team.spotliteAddress
      });
      document.getElementById(index).classList.toggle("show");
      let e = {};
      e.target = {};
      e.target.value = event.viewGUID;

      this.openModal(e);

    }

    isDisabled = false;

    if (isDisabled) {
      return null;
    } else {

      return (
        <div className="team_header_drop_down_container" >
          <div id={`${index}_dropdown`} className="team_header_menu_icon" onClick={() => onMenuItemClick()}>
            <SegmentMenu
              id={index}
              data={data}
              onItemClick={onItemClick}
            />
          </div>
        </div>
      );
    }
  }

  render() {

    const { isLoading } = this.state;

    if (isLoading) return null

    return (
      <div>
        {this.renderMain()}
        {this.renderModal()}
      </div>
    );
  }

  renderModal = () => {

    if (this.state.modalOpen) {
      // console.log(this.state.spotliteAddress)
      return (
        <GetFormData
          open={this.state.modalOpen}
          closeModal={this.onClose}
          cancelModal={this.cancelModal}
          screenType={this.state.modalScreen}
          selectedCatalogue={this.state.selectedCatalogue}
          applicationGUID={this.state.applicationGUID}
          spotliteAddress={this.state.spotliteAddress}
        />
      );
    } else {
      return null;
    }
  };

  openModal = (e) => {
    //console.log("Modal Target - ", e);
    this.setState({
      modalOpen: true,
      modalScreen: e.target.value,
    });
  };

  onClose = () => {
    this.setState({
      modalOpen: false,
      spotliteAddress: "",
      applicationGUID: null
    });
  };

  cancelModal = () => {
    this.setState({
      modalOpen: false,
      spotliteAddress: "",
      applicationGUID: null
    });
  };

}

const mapStateToProps = (state) => {
  const { sessionId } = state.session;
  const { teams, /*userGroup, */ selectedUser, reload, error } = state.team;
  const { users } = state.users
  const { companyNumber } = state.settings;
  const { catalogues } = state.credits;
  const { TeamMenu } = state.session.dataConfig;

  // PMD 03/09/20 - Retrieve Company Number
  return {
    sessionId,
    selectedUser,
    teams,
    // userGroup,
    reload,
    error,
    // PMD 03/09/20 - Retrieve Company Number
    companyNumber,
    catalogues,
    users,
    TeamMenu,
  };
};

export default connect(mapStateToProps, {
  loadTeams,
  loadUsers,
  selectUser,
  loadCatalogueCredits,
  addUserToTeam,
  removeUserFromTeam,
  // createTeam,
  deleteTeam,
  updateApplicationLayout,
})(Team);
