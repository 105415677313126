import * as actions from './actionCreators';
import config from '../config';
import { postRequest, apiParseDataView } from '../utils/Common';
import { errorHandler } from '../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

export const getDataViewData = (options) => {
    return (dispatch, getState) => {
        const URI = `${config[env].URL}/get-data-view`;
        const credentials = 'omit';
        const body = {
            sessionId: getState().session.sessionId,
            ...options
        };

        // console.log(URI);
        // console.log('getDataViewData: ', body);
        dispatch(actions.getDataViewRequest());

        return postRequest(URI, body, credentials)
            .then(apiParseDataView)
            .then(response => dispatch(actions.getDataViewSuccess(response)))
            .catch(err => {
                // PMD 20/05/22 Add generic error handler
                errorHandler(err)
                dispatch(actions.getDataViewError(err))
            });
    };
};

export const postDataViewData = (options) => {
    return (dispatch, getState) => {
        const URI = `${config[env].URL}/post-data-view`;
        const credentials = 'omit';
        const body = {
            sessionId: getState().session.sessionId,
            ...options
        };

        // console.log("postDataViewData: ", body)

        dispatch(actions.postDataViewRequest());

        return postRequest(URI, body, credentials)
            .then(apiParseDataView)
            .then(response => dispatch(actions.postDataViewSuccess(response)))
            .catch(err => {
                // PMD 20/05/22 Add generic error handler
                errorHandler(err)
                dispatch(actions.postDataViewError(err))
            });
    };
};