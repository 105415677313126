import React from "react";

function Button({ type = "button", label, handleClick, className, value, disabled = false, style }) {
  return (
    <button className={className} type={type} onClick={handleClick} value={value} disabled={disabled} style={style}>
      {label}
    </button>
  );
}

export default Button;
