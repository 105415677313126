
import { LOAD_PANEL_COMPONENTS, /* LOAD_DOCUMENTS, LOAD_APPLICATION_INSTANCE, LOAD_PARTY, LOAD_NOTICES, LOAD_TASKS, LOAD_TASK_COMMENTS, LOGOUT */ } from './types';

// import { postRequest, apiParse } from '../utils/Common';
// import config from '../config';
const env = process.env.NODE_ENV || 'development';


// export const clearDocuments = () => {
//   return {
//     type: LOAD_DOCUMENTS,
//     payload: []
//   };
// };


// export const clearApplicationInstance = () => {
//   return {
//     type: LOAD_APPLICATION_INSTANCE,
//     payload: [{}]
//   };
// };

// export const clearParty = () => {
//   return {
//     type: LOAD_PARTY,
//     payload: [{}]
//   };
// };

// export const clearNotices = () => {
//   return {
//     type: LOAD_NOTICES,
//     payload: []
//   };
// };

// export const clearTasks = () => {
//   return {
//     type: LOAD_TASKS,
//     payload: []
//   };
// };

// export const clearTaskComments = () => {
//   return {
//     type: LOAD_TASK_COMMENTS,
//     payload: []
//   };
// };

// export const loadFakePanelComponents = ({panelGuid, json}) => {
//   return {
//     type: LOAD_PANEL_COMPONENTS,
//     payload: {
//       panelGuid,
//       json
//     }
//   };
// };

export const loadPanelComponents = ({ panelGuid, json, call, panelTitle, spotliteAddress }) => {
  return {
    type: LOAD_PANEL_COMPONENTS,
    payload: {
      panelGuid,
      json,
      call,
      panelTitle,
      spotliteAddress 
    }
  };
};


// export const loadDocumentsByApplicationId = ({ applicationId }) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/get-documents`;
//       const URI = `${config[env].URL}/get-documents`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         applicationId: applicationId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           // console.log("*** caught response ***");
//           // console.log(response);
//           dispatch({
//             type: LOAD_DOCUMENTS,
//             payload: response
//           });
//         })
//         .catch(err => {
//           if (err.message === "Session doesnt exist") {
//             dispatch({
//               type: LOGOUT
//             });
//           } else {
//             dispatch({
//               type: LOAD_DOCUMENTS,
//               payload: []
//             });
//           }
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_DOCUMENTS,
//         payload: []
//       });
//     }
//   }
// }

// PMD 12/4/21 - Endpoint Tidy 
// export const loadApplicationInstanceByApplicationId = ({applicationId}) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId){

//       const URI = `${config[env].URL}${config[env].PORT}/home/get-application-instance`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         applicationId: applicationId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//           .then(apiParse)
//           .then(response => {
//             console.log("*** caught response ***");
//             console.log(response);
//             dispatch({
//               type: LOAD_APPLICATION_INSTANCE,
//               payload: response
//             });
//           })
//           .catch(err => {
//             if (err.message === "Session doesnt exist"){
//               dispatch({
//                 type: LOGOUT
//               });
//             }else {
//               dispatch({
//                 type: LOAD_APPLICATION_INSTANCE,
//                 payload: [{}]
//               });
//             }
//           });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_APPLICATION_INSTANCE,
//         payload: [{}]
//       });
//     }
//   }
// }


// export const loadPartyByApplicationId = ({ applicationId }) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       //  const URI = `${config[env].URL}${config[env].PORT}/home/get-party`;
//       const URI = `${config[env].URL}/get-party`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         applicationId: applicationId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           // console.log("*** caught response ***");
//           // console.log(response);
//           dispatch({
//             type: LOAD_PARTY,
//             payload: response
//           });
//         })
//         .catch(err => {
//           if (err.message === "Session doesnt exist") {
//             dispatch({
//               type: LOGOUT
//             });
//           } else {
//             dispatch({
//               type: LOAD_PARTY,
//               payload: [{}]
//             });
//           }
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_PARTY,
//         payload: [{}]
//       });
//     }
//   }
// }

// export const loadNoticesByApplicationId = ({ applicationId }) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/get-application_notices`;
//       const URI = `${config[env].URL}/get-application_notices`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         applicationId: applicationId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           // console.log("*** caught response ***");
//           // console.log(response);
//           dispatch({
//             type: LOAD_NOTICES,
//             payload: response
//           });
//         })
//         .catch(err => {
//           if (err.message === "Session doesnt exist") {
//             dispatch({
//               type: LOGOUT
//             });
//           } else {
//             dispatch({
//               type: LOAD_NOTICES,
//               payload: []
//             });
//           }
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_NOTICES,
//         payload: []
//       });
//     }
//   }
// }


// export const loadTasksByApplicationId = ({ applicationId }) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/get-application-tasks`;
//       const URI = `${config[env].URL}/get-application-tasks`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         applicationId: applicationId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           // console.log("*** caught response ***");
//           // console.log(response);
//           dispatch({
//             type: LOAD_TASKS,
//             payload: response
//           });
//         })
//         .catch(err => {
//           if (err.message === "Session doesnt exist") {
//             dispatch({
//               type: LOGOUT
//             });
//           } else {
//             dispatch({
//               type: LOAD_TASKS,
//               payload: []
//             });
//           }
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_TASKS,
//         payload: []
//       });
//     }
//   }
// }

// export const loadTaskComments = ({ taskId }) => {

//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/get-application-task-comments`;
//       const URI = `${config[env].URL}/get-application-task-comments`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         taskId: taskId
//       };

//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           // console.log("*** caught response ***");
//           // console.log(response);
//           dispatch({
//             type: LOAD_TASK_COMMENTS,
//             payload: response
//           });
//         })
//         .catch(err => {
//           if (err.message === "Session doesnt exist") {
//             dispatch({
//               type: LOGOUT
//             });
//           } else {
//             dispatch({
//               type: LOAD_TASK_COMMENTS,
//               payload: []
//             });
//           }
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_TASK_COMMENTS,
//         payload: []
//       });
//     }
//   }
// }

