import { LOAD_TRANSACTIONS, SELECT_INVOICE, LOAD_INVOICE, RESET_INVOICE, SET_TRANSACTION_ERROR } from './types';

import { postRequest, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';


export const setTransactionError = (error) => {
  return {
    type: SET_TRANSACTION_ERROR,
    payload: error
  };
};

export const selectInvoice = (invoiceId) => {
  return {
    type: SELECT_INVOICE,
    payload: invoiceId
  };
};

export const resetInvoice = () => {
  return {
    type: RESET_INVOICE
  };
};

// TODO: PMD 21/12/22 Changed to a get-data-view call

export const loadTransactions = () => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-load-transactions`;
      const URI = `${config[env].URL}/client-portal-load-transactions`;

      const options = {
        sessionId: getState().session.sessionId,
        // PMD 18/10/22 Pass portaltype for use in getAndSetPermissions
        portaltype: getState().session.portalDetails.portalType,
      };
      const credentials = 'omit';

      return postRequest(URI, options, credentials)
        .then(apiParse)
        .then(response => {
          // console.log('response:', response);
          loadTransactionsSuccess(dispatch, response);
        })
        .catch(err => {
          loadTransactionsFail(dispatch, err);
        });
    } else {
      //no secure id
      dispatch({
        type: LOAD_TRANSACTIONS,
        payload: []
      });
    }
  }
};

// FIXME: NEEDS CONVERTING TO USE GET-DATA-VIEW
// export const loadTransactions = () => {
//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-load-transactions`;
//       const URI = `${config[env].URL}/get-data-view`;

//       const options = {
//         sessionId: getState().session.sessionId,
//         ApplicationInstanceGUID: getState().session.dataConfig.allTeams.currentTeamDetails.teamGUID,
//         ViewGUID: "0486F3EF-3AC9-4F5B-8AEF-77577C747042",
//         // portaltype: getState().session.portalDetails.portalType,
//       };
//       const credentials = 'omit';

//       return postRequest(URI, options, credentials)
//         .then(apiParse)
//         .then(response => {
//           console.log('response:', response);
//           loadTransactionsSuccess(dispatch, response);
//         })
//         .catch(err => {
//           loadTransactionsFail(dispatch, err);
//         });
//     } else {
//       //no secure id
//       dispatch({
//         type: LOAD_TRANSACTIONS,
//         payload: []
//       });
//     }
//   }
// };


const loadTransactionsFail = (dispatch, err) => {

  //alert(err.message);
  // PMD 20/05/22 Generic error handler
  errorHandler(err)
  dispatch({
    type: LOAD_TRANSACTIONS,
    payload: []
  });
  dispatch({
    type: SET_TRANSACTION_ERROR,
    payload: err.message
  });
};

const loadTransactionsSuccess = (dispatch, response) => {
  dispatch({
    type: LOAD_TRANSACTIONS,
    payload: response
  });
};



export const loadInvoice = () => {

  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-get-invoice`;
      const URI = `${config[env].URL}/client-portal-get-invoice`;

      const options = {
        sessionId: getState().session.sessionId,
        invoiceNumber: getState().transactions.invoiceId,
        // PMD 18/10/22 Pass the portaltype for use in getAndSetPermissions
        portaltype: getState().session.portalDetails.portalType,
      };
      const credentials = 'omit';

      return postRequest(URI, options, credentials)
        .then(apiParse)
        .then(response => {
          loadInvoiceSuccess(dispatch, response);
        })
        .catch(err => {
          loadInvoiceFail(dispatch, err);
        });
    } else {
      //no secure id
      dispatch({
        type: LOAD_INVOICE,
        payload: null
      });
    }
  }
};


const loadInvoiceFail = (dispatch, err) => {

  /***
   * forcing success response for testing
   */
  //console.log('invoice error: ' + err);
  //alert(err.message);

  // PMD 20/05/22 Generic error handler
  errorHandler(err)
  dispatch({
    type: LOAD_INVOICE,
    payload: null
  });
  dispatch({
    type: SET_TRANSACTION_ERROR,
    payload: err.message
  })
};

const loadInvoiceSuccess = (dispatch, response) => {

  // console.log('got invoice success');
  // console.log(response);

  dispatch({
    type: LOAD_INVOICE,
    payload: response
  });
};

