import React from 'react';

export default function DropDownMenu (props) {

    const {toplevelClassName, toplevelUIClassName, secondLevelClassName, label, data, onItemClick} = props;

    //console.log(data)

    const element = (
        <div className={toplevelClassName}>
            <div className={toplevelUIClassName}>
            <p>{label}</p>
                {data.map((item, index) => renderItem(item, index, onItemClick, secondLevelClassName))}
            </div>
        </div>
    );

    return element;
}

function renderItem(item, index, onItemClick, secondLevelClassName) {
    
    var data;

    if(item.constructor === Object) {
        data = [item];
    } else {
        data = item;
    }

    return (
        <ul key={index} className={secondLevelClassName}>
            {data.map((item, i) => {
                //console.log(item);
                if(item !== null) {
                    return renderDefaultItem(item, i, onItemClick);
                }  
            })}
        </ul>
    );
    
}

function renderDefaultItem(item, index, onItemClick) {
        
    const element = (
       
        <div 
            className="SegmentMenu-dropdownButton"
            key={index}  
            onClick={() => onItemClick(
                item
            )}>
            <p>{item.Label ?? item.Name}</p> 
        </div>
    );

    return element;
}
