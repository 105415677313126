import React, { Component } from "react";
// import Bpmn from "../Bpmn/Bpmn";
// import BpmnApp from "../Bpmn/BpmnApp";


class TeamFilters extends Component {

  render() {

    return (
      <>
        {/* Needs programming */}
      </>
    )

  }

}

export default TeamFilters;