import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import SubmitPayment from './SubmitPayment';
import { Elements } from '@stripe/react-stripe-js';

import { getRequest, postRequest, apiParse } from '../../utils/Common';
import config from '../../config';
import { errorHandler } from '../../utils/errorHandler';

const env = process.env.NODE_ENV || "development";

const PaymentMethod = (props) => {
    const [ stripePromise, setStripePromise ] = useState(null);
    const [ clientSecret, setClientSecret ] = useState('');
    const [ id, setid ] = useState(null);

    const URI = config[env].URL;
    const credentials = 'omit';

    useEffect(() => {
        getRequest(`${URI}/get-payment-config`, credentials)
            .then(apiParse)
            .then(publishableKey => setStripePromise(loadStripe(publishableKey)))
            .catch(err => errorHandler(err));
    }, []);

    useEffect(() => {
        const options = {
            description: props.description,
            amount: props.amount,
            currency: props.currency
        };
        postRequest(`${URI}/create-payment-intent`, options, credentials)
            .then(apiParse)
            .then(response => {
                setid(response.id);
                setClientSecret(response.clientSecret);
                return;
        })
            .catch(err => errorHandler(err));
    }, []);

    const handleInvoiceMe = async (event) => {
        event.preventDefault();
    
        if (!stripePromise || !id) {
          return;
        }
        props.onInvoiceMe(id);
    };

    const handleCancel = async (event) => {
        event.preventDefault();

        if (!stripePromise || !id) {
            return;
        }
        props.handleBackButton(id)
    }

    return (
        <>
            { 
                stripePromise && 
                clientSecret && 
                (
                    <div>
                        <label>Please fill out your payment details</label> 
                        <div>
                            <Elements 
                                stripe={stripePromise} 
                                options={{ clientSecret }}
                            >
                                <SubmitPayment 
                                    handlePaymentResponse={props.handlePaymentResponse}
                                />
                            </Elements>
                        </div>
                        <h3 style={{ marginLeft: "0", marginTop: "10px" }}>Payment via Invoice</h3>
                        <p>If you would prefer to pay via invoice please click here</p>
                        <input
                            type="button"
                            className="button"
                            style={{ marginLeft: "0px" }}
                            onClick={handleInvoiceMe}
                            value="Pay via Invoice"
                        />
                        <div className="col_left" style={{ marginTop: "10px" }}>
                            <input
                                type="button"
                                className="button"
                                style={{ marginLeft: "0px" }}
                                onClick={handleCancel}
                                value="Cancel"
                            />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default PaymentMethod;