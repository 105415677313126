import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from 'react-router-dom';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./PanelForm.css";
import CustomForm from "../Reusable/CustomForm";
import validator from '@rjsf/validator-ajv8';

// import CustomForm1 from "../Reusable/CustomForm1";
import Loader from "../Loader/Loader";

import {
  // fetchApplications,
  // loadTeams,
  postDataViewData,
  uploadFiles
} from "../../actions";

import deepCopy from '../../utils/deepCopy';
import { getPropertyValuesByKey } from '../../utils/collectionUtils';
import { fileUploadGUID, activityDownloadScreenGUID } from "../../utils/Constants";
// import { errorHandler } from "../../utils/errorHandler";
// import temp_download_json from "./dowloadtransactions_221118a.json"

const customFormats = {
  //eslint-disable-next-line
  "phone-mobile": /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
  //eslint-disable-next-line
  // "email-format": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
};

// CHECK: Dont think this is React props. if not change variable name
const ObjectFieldTemplate = (props) => {
  return (
    <div>
      {/* {props.title} */}
      {/* {props.description} */}
      {props.properties.map((element, index) => (
        <div key={index} className="property-wrapper">
          {element.content}
        </div>
      ))}
    </div>
  );
};

const onError = (errors => {
  console.log("I have", errors.length, "errors to fix");
  console.log("Error message ->>", errors);
});

class RenderPanelForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSaving: false,
      buttonText: "Submit",
      componentGUID: null,
      redirect: false,
      redirectPath: null
    };

    // CHECK: Why arent we using state here? Is it because of the form component because using 'this' wont cause the component to render if the value changes ???
    this.schema = {};
    this.uiSchema = {};
    this.schemaProps = {};
    this.formSubmissionData = {};
    this.submitButton = null;
  }

  postSubmit = (name, response) => {
    confirmAlert({
      title: name,
      message: response,
      buttons: [
        {
          label: 'OK',
        }
      ]
    });
  };

  async componentDidMount() {
    let { viewData } = this.props;

    // console.log('********** RenderPanelForm - this.props:', this.props);

    // FIXME: Use TEMP JSON
    let dataViewDataCopy = deepCopy(viewData);
    // let dataViewDataCopy = deepCopy(temp_download_json);

    // May need to modify getPropertyValuesByKey to provide option to exit on first match find as well as default (i.e. all matches)
    await this.setState(
      {
        componentGUID: getPropertyValuesByKey(dataViewDataCopy, 'ComponentGUID')[0] || null
      },

      // PMD 17/11/22 Mainpulate the response if fileUpload or TransactionDownload
      // async () => await this.addPropertiesIfFileUpload(dataViewDataCopy)
      async () => await this.addAmendFormProperties(dataViewDataCopy)

    );

    this.schema = getPropertyValuesByKey(dataViewDataCopy, 'PropertyData')[0] || {};
    this.uiSchema = getPropertyValuesByKey(dataViewDataCopy, 'UISchema')[0] || {};
    this.schemaProps = getPropertyValuesByKey(this.schema, 'properties')[0] || {};

    // console.log({ dataViewDataCopy })
    // console.log('uiSchema: ', this.uiSchema)
    // console.log('uiSchema: ', this.uiSchema.ReferenceProfessionalCollection['ui:order'])

    // this.uiSchema.ReferenceProfessionalCollection['ui:order'] = [
    //   'Prof_Ref1_name',
    //   'Prof_Ref1_company',
    //   'Prof_Ref1_role', 
    //   'Prof_Ref1_relationship', 
    //   'Prof_Ref1_email', 
    //   'Prof_Ref1_phone', 
    //   'Prof_Ref2_name', 
    //   'Prof_Ref2_company',
    //   'Prof_Ref2_role', 
    //   'Prof_Ref2_relationship', 
    //   'Prof_Ref2_email', 
    //   'Prof_Ref2_phone']

    // console.log('schemaProps: ', this.schemaProps)

    // Set submit button if required
    if (this.uiSchema === undefined || this.uiSchema.SubmitButton) {
      this.submitButton = <div></div>;
    } else {
      this.submitButton = (
        <div>
          <button id="submitButtonId" className="custommodalformbutton" type="submit" >
            {/* <button id="submitButtonId" type="submit" > */}
            {this.state.buttonText}
          </button>
        </div>
      );
    }
    this.setState({ isLoading: false });
  }

  addAmendFormProperties = async (dataViewResponse) => {
    // This function will purposely mutate the dataViewResponse object passed as a parameter to this function
    const { componentGUID } = this.state;

    // Add properties for File Upload capability
    if (componentGUID === fileUploadGUID) {
      let enhancedPanelContents = Object.values(dataViewResponse.Screen.RetrievedData[0])[0];

      enhancedPanelContents.PropertyData.properties.DocumentInformation.required.push('UploadFiles')
      enhancedPanelContents.PropertyData.properties.DocumentInformation.properties.UploadFiles = { type: 'array', title: 'Select files to be uploaded', items: { type: 'string', format: 'data-url' } }

      // Update UISchema to include the UploadFiles 
      enhancedPanelContents.UISchema.DocumentInformation['ui:order'].push("UploadFiles")
      // Restrict file types
      enhancedPanelContents.UISchema.DocumentInformation.UploadFiles = { 'ui:options': { accept: '.pdf, .jpeg, .png, .txt, .doc, .docx' } }

    }

    if (componentGUID === activityDownloadScreenGUID) {

      let updatedPanelContents = Object.values(dataViewResponse.Screen.RetrievedData[0])[0];

      // console.log(updatedPanelContents.PropertyData)

      // FIXME: NEEDS FIXING BY DAVID
      updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.properties.SelectionCriteria.default = "478623F2-B670-4E6B-97C5-91A080DC1968"
      updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.properties.DownloadType.default = "437456FC-B80D-4924-8354-5B1B0119D106"

      // Create dependencies object to control showing of date fields
      updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.dependencies = {}
      const dependenciesObj = updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.dependencies
      dependenciesObj.SelectionCriteria = {}
      dependenciesObj.SelectionCriteria.oneOf = []

      // Use GUID for Select Dates
      /*
      "035424FC-FD9E-4383-A3E1-6D017443D7A4" = "1 Week"
      "A59A1115-C274-4583-8599-A1E5594EA5AB" = "2 Weeks"
      "2A26F167-EFE9-4242-97EF-9046ED56C024" = "1 Month"
      "E78BDF08-6BFD-4526-A03A-DFF7DE3FB158" = "3 Months"
      "A709C8AC-764A-4379-BB6F-C2963CEBD361" = "Select Dates"
      "478623F2-B670-4E6B-97C5-91A080DC1968" = "Since Last Download"
      */

      const selectDateIndex = updatedPanelContents.PropertyData.definitions.SelectionCriteria.enum.indexOf("A709C8AC-764A-4379-BB6F-C2963CEBD361")

      const eNumCopy = [...updatedPanelContents.PropertyData.definitions.SelectionCriteria.enum]

      for (let i = 0; i < eNumCopy.length; i++) {
        if (i === selectDateIndex) {
          eNumCopy.splice(i, 1)
        }
      }

      const selectionObject = {
        properties: {
          SelectionCriteria: {
            enum: eNumCopy
          }
        }
      }

      const selectDatesObject = {

        // Create an object for the "Select Dates" condition GUID value "A709C8AC-764A-4379-BB6F-C2963CEBD361"
        properties: {
          SelectionCriteria: {
            enum: ["A709C8AC-764A-4379-BB6F-C2963CEBD361"]
          }
        }
      }

      dependenciesObj.SelectionCriteria.oneOf.push(selectionObject)
      dependenciesObj.SelectionCriteria.oneOf.push(selectDatesObject)

      // Add FromDate and ToDate to the Select Dates part of the dependency
      const { FromDate, ToDate } = updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.properties
      dependenciesObj.SelectionCriteria.oneOf[1].properties.FromDate = FromDate
      dependenciesObj.SelectionCriteria.oneOf[1].properties.ToDate = ToDate

      // Remove the original values to ensure they're only displayed when required
      delete updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.properties.FromDate
      delete updatedPanelContents.PropertyData.properties.ActivityDownloadCollection.properties.ToDate

      // Add the required fields

      dependenciesObj.SelectionCriteria.oneOf[1].required = []
      dependenciesObj.SelectionCriteria.oneOf[1].required.push("FromDate", "ToDate")
      // console.log(dependenciesObj)

      // console.log(updatedPanelContents.PropertyData)

    }
  };

  // PMD 17/11/22 Original addPropertiesIfFileUpload code
  // addPropertiesIfFileUpload = async (dataViewResponse) => {
  //   // This function will purposely mutate the dataViewResponse object passed as a parameter to this function
  //   const { componentGUID } = this.state;

  //   if (componentGUID === fileUploadGUID) {
  //     let enhancedPanelContents = Object.values(dataViewResponse.Screen.RetrievedData[0])[0];

  //     enhancedPanelContents.PropertyData.properties.DocumentInformation.required.push('UploadFiles')
  //     enhancedPanelContents.PropertyData.properties.DocumentInformation.properties.UploadFiles = { type: 'array', title: 'Select files to be uploaded', items: { type: 'string', format: 'data-url' } }

  //     // Update UISchema to include the UploadFiles 
  //     enhancedPanelContents.UISchema.DocumentInformation['ui:order'].push("UploadFiles")
  //     // Restrict file types
  //     enhancedPanelContents.UISchema.DocumentInformation.UploadFiles = { 'ui:options': { accept: '.pdf, .jpeg, .png, .txt, .doc, .docx' } }

  //     // **** TF: N.B. CODE ABOVE BASED ON COMMENTED OUT CODE SNIPPET BELOW, COPIED FROM NOW OBSOLETE ModalForm.js ***

  //     // ******************************************************************************************* 
  //     // 
  //     // PMD 25/05/22 DUMMY HARDCODED Supplemented file upload response with additional fields
  //     // 
  //     // HARDCODED AT DAVIDS REQUEST - NO ERROR TRAPPING IF RESPONSE IS MALFORMED
  //     // 
  //     // *******************************************************************************************
  //     // console.log(panelContent)
  //     // enhancedPanelContents = deepCopy(panelContent)
  //     // console.log(enhancedPanelContents.Calls[0].RetrievedData);

  //     // enhancedPanelContents.Calls[0].RetrievedData.PropertyData.properties.DocumentInformation.required.push('UploadFiles')
  //     // enhancedPanelContents.Calls[0].RetrievedData.PropertyData.properties.DocumentInformation.properties.UploadFiles = { type: 'array', title: 'Select files to be uploaded', items: { type: 'string', format: 'data-url' } }

  //     // Update UISchema to include teh UploadFiles 
  //     // enhancedPanelContents.Calls[0].RetrievedData.UISchema.DocumentInformation['ui:order'].push("UploadFiles")
  //     // Restrict file types
  //     // enhancedPanelContents.Calls[0].RetrievedData.UISchema.DocumentInformation.UploadFiles = { 'ui:options': { accept: '.pdf, .jpeg, .png, .txt, .doc, .docx' } }

  //     // console.log(enhancedPanelContents.Calls[0].RetrievedData);

  //     // ******************************************************************************************
  //     // 
  //     // END OF HARD CODING
  //     // 
  //     // ******************************************************************************************

  //   }
  // };


  transformErrors(errors) {
    return errors.map((error) => {
      if (error.name === "format" && error.message.includes("email")) {
        error.message = "Invalid Email format";
      } else if (error.name === "format" && error.message.includes("phone")) {
        error.message = "Invalid Mobile Phone format";
      }
      return error;
    });
  }

  onError = (errors) => {
    console.log("Errors Handler", errors);
  };

  onSubmit = async ({ formData }) => {

    // const { closeModal } = this.props;
    // closeModal('&&&&&&& MODAL CLOSE');
    // return;

    this.setState({ isSaving: true });
    const btn = document.getElementById("submitButtonId");
    btn.setAttribute('disabled', '');

    const { viewData, modalScreen, applicationGUID, segmentSpotliteAddress, uploadFiles, postDataViewData, closeModal } = this.props;

    const { componentGUID } = this.state;

    // FIXME: May need to change as could be more than one CallType equal to "CallEvent" in future
    const calls = getPropertyValuesByKey(viewData, 'Calls');
    const callEvent = calls.find(call => call.CallType === 'CallEvent') || {};
    const callAction = calls.find(call => call.CallType === 'CallAction') || {};
    const callData = calls.find(call => call.CallType === 'CallData') || {};

    const viewGUID = (callEvent.CallingObjectGUID
      ? callEvent.CallingObjectGUID
      : callAction.CallingObjectGUID
        ? callAction.CallingObjectGUID
        : callData.CallingObjectGUID
          ? callData.CallingObjectGUID
          : null
    );

    const { teamGUID } = this.props.dataConfig.allTeams.currentTeamDetails;

    // console.log(callAction)
    let defaultApplicationGUID
    // Document upload does not call post-data-view so need to handle this seperately
    if (componentGUID === fileUploadGUID) {
      const options = {
        applicationGUID: applicationGUID,
        fileInfo: formData,
      };
      await uploadFiles(options);

    } else {

      this.buildFormSubmissionData(
        { formData },
        callAction?.Properties || {}
      );

      defaultApplicationGUID = applicationGUID ?? teamGUID;

      // const applicationToUse = callAction?.CreateApplication ? null : applicationGUID;
      // console.log(defaultApplicationGUID)
      // console.log(callAction)

      const applicationToUse = callAction?.CreateApplication ? null : defaultApplicationGUID;
      const options = {
        viewGUID,
        teamGUID,
        applicationInstanceGUID: applicationToUse,
        // applicationInstanceGUID: applicationGUID ?? teamGUID,
        SpotliteDataObject: this.formSubmissionData,
        segmentSpotliteAddress
      };

      // console.log(options)

      let postResponse = await postDataViewData(options);
      // FIXME: PMD 12/04/23 Display a message to the user
      // console.log(postResponse)

      if(postResponse === undefined){
        btn.removeAttribute('disabled');
        closeModal('&&&&&&& MODAL CLOSE');
        return;
      }
      
      const { Status, CollectionUpdate } = postResponse.payload
      if (Status === 'OK') {
        this.postSubmit(CollectionUpdate.PropertyName, CollectionUpdate.PropertyValue);
      }
    }

    // this.props.fetchApplications('');
    // this.props.history.goBack();
    // this.props.history.push(this.props.history.location.pathname);
    // this.props.history.go(0);
    // window.location.reload();

    const currentPath = this.props.location.pathname;
    // const currentPath = window.location.pathname;
    // console.log(currentPath)
    // this.props.history.replace(`/refresh${currentPath.replace(/\//gi, '-')}`);
    // window.location.replace(`/refresh-${currentPath.slice(1)}`);
    // window.location.replace(`${currentPath}/refresh`);

    // this.props.history.replace(`/refresh${currentPath}`);
    // if (!currentPath.includes('certificate')) {
    if (!currentPath.includes(`${defaultApplicationGUID}`)) {
      this.props.history.replace(`/refresh${currentPath}`);
      setTimeout(() => {
        this.props.history.replace(currentPath);
      }, 0);
    } else {
      // this.setState({ redirect: true, redirectPath: currentPath });
    }

    if (modalScreen) {
      // console.log('RenderPanelForm - closeModal');
      btn.removeAttribute('disabled');
      closeModal('&&&&&&& MODAL CLOSE');
    }

    btn.removeAttribute('disabled');

    return;
  };

  calculateFromDate = (selectionCriteria, schemaToDate) => {
    /*
    "035424FC-FD9E-4383-A3E1-6D017443D7A4" = "1 Week"
    "A59A1115-C274-4583-8599-A1E5594EA5AB" = "2 Weeks"
    "2A26F167-EFE9-4242-97EF-9046ED56C024" = "1 Month"
    "E78BDF08-6BFD-4526-A03A-DFF7DE3FB158" = "3 Months"
    "A709C8AC-764A-4379-BB6F-C2963CEBD361" = "Select Dates"
    "478623F2-B670-4E6B-97C5-91A080DC1968" = "Since Last Download"
    */
    let currentDate;
    currentDate = new Date();
    // console.log(schemaToDate)
    switch (selectionCriteria) {
      case "035424FC-FD9E-4383-A3E1-6D017443D7A4":
        currentDate.setDate(currentDate.getDate() - 7);
        break;
      case "A59A1115-C274-4583-8599-A1E5594EA5AB":
        currentDate.setDate(currentDate.getDate() - 14);
        break;
      case "2A26F167-EFE9-4242-97EF-9046ED56C024":
        currentDate.setMonth(currentDate.getMonth() - 1);
        break;
      case "E78BDF08-6BFD-4526-A03A-DFF7DE3FB158":
        currentDate.setMonth(currentDate.getMonth() - 3);
        break;
      case "478623F2-B670-4E6B-97C5-91A080DC1968":
        return schemaToDate.default;
      default:
        break;
    }

    return currentDate.toLocaleDateString('en-CA');
  }

  buildFormSubmissionData = ({ formData }, propertyList) => {

    const { componentGUID } = this.state

    // Handle forms except Activity Download Form
    if (componentGUID !== activityDownloadScreenGUID) {
      this.formSubmissionData = Object.keys(this.schemaProps).reduce((acc, formName) => {

        const formFields = this.schemaProps[formName].properties || {};
        const currentFormData = formData[formName] || {};

        for (const field in formFields) {
          const spotliteAddress = formFields[field].SpotliteAddress;
          const propertyGUID = formFields[field].PropertyGUID;
          acc[spotliteAddress] = {
            SpotliteAddress: spotliteAddress,
            // CRUDAction: spotliteAddress.endsWith('.0.0.0') ? 'C' : 'U',
            CRUDAction: propertyList?.[propertyGUID]?.CRUD ?? 'C',
            PropertyGUID: propertyGUID,
            DataValue: currentFormData[field] ? currentFormData[field] : formFields[field].default
          };
        }
        return acc;
      }, {});
    } else {

      // Handle Activity Download Form  
      let formPropertiesCount = Object.keys(formData.ActivityDownloadCollection).length

      // if only 2 properties on form we need to calculate the FromDate based on the SelectionCriteria
      let startDate;
      if (formPropertiesCount === 2) {
        let current;
        current = new Date();
        const today = current.toLocaleDateString('en-CA');

        let schemaToDate = this.schemaProps.ActivityDownloadCollection.dependencies.SelectionCriteria.oneOf[1].properties.ToDate

        startDate = this.calculateFromDate(formData.ActivityDownloadCollection.SelectionCriteria, schemaToDate)
        formData.ActivityDownloadCollection.FromDate = startDate;
        formData.ActivityDownloadCollection.ToDate = today
      }

      let propertyValues = this.schemaProps.ActivityDownloadCollection

      let formProperties = Object.keys(formData.ActivityDownloadCollection)

      this.formSubmissionData = formProperties.reduce((acc, formName) => {
        let spotliteAddress;
        // If field is a date field get the values from dependencies otherwise properties
        if (formName === 'FromDate' || formName === 'ToDate') {
          // retrieve values from dependencies
          let dependenciesProperties = propertyValues.dependencies.SelectionCriteria.oneOf[1].properties
          spotliteAddress = dependenciesProperties[formName].SpotliteAddress
          acc[spotliteAddress] = {
            SpotliteAddress: spotliteAddress,
            CRUDAction: spotliteAddress.endsWith('.0.0.0') ? 'C' : 'U',
            PropertyGUID: dependenciesProperties[formName].PropertyGUID,
            DataValue: formData.ActivityDownloadCollection[formName]
          }

        } else {
          spotliteAddress = propertyValues.properties[formName].SpotliteAddress
          acc[spotliteAddress] = {
            SpotliteAddress: spotliteAddress,
            CRUDAction: spotliteAddress.endsWith('.0.0.0') ? 'C' : 'U',
            PropertyGUID: propertyValues.properties[formName].PropertyGUID,
            DataValue: formData.ActivityDownloadCollection[formName]
          }
        }
        return acc;
      }, {})

    }
  };

  customValidate = (formData, errors) => {
    const { componentGUID } = this.state
    // If activity download screen validate ToDate is after FromDate
    if (componentGUID === activityDownloadScreenGUID) {
      if (formData && formData.ActivityDownloadCollection) {
        if (formData.ActivityDownloadCollection.FromDate > formData.ActivityDownloadCollection.ToDate) {
          errors.ActivityDownloadCollection.FromDate.addError(
            "The 'From Date' should be before the 'To Date'",
          );
        }
      }
    }
    return errors;
  };


  render() {
    const { isLoading, redirect, redirectPath } = this.state;

    // console.log('render() redirect:redirectPath', redirect, ' : ', redirectPath);

    if (redirect) {
      return <Redirect push to={{
        pathname: redirectPath,
        state: { viewGUID, applicationGUID, spotliteAddress: address }
      }} />;
    }

    if (isLoading) {
      return null;
    }

    return (
      <>
        <CustomForm
          uiSchema={this.uiSchema}
          schema={this.schema}
          autoComplete="off"
          customFormats={customFormats}
          showErrorList={false}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onSubmit={this.onSubmit}
          onError={onError}
          transformErrors={this.transformErrors}
          validate={this.customValidate}
          // New validation
          validator={validator}

        >
          {this.submitButton}
        </CustomForm>
        {/* <CustomForm1
          uiSchema={this.uiSchema}
          schema={this.schema}
          onSubmit={this.onSubmit} 
          ObjectFieldTemplate={ObjectFieldTemplate}
        /> */}

        <Loader
          className="form__input-spinner"
          active={this.state.isSaving}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sessionId, dataConfig } = state.session;
  const panelData = state.dashboard.panels[ownProps.panelGuid];
  const { selectedApplicationTask } = state.applications
  const { ApplicationTasks } = state.applications.applications
  // const applicationGUID = (selectedApplicationTask && ApplicationTasks[selectedApplicationTask].applicationGUID) ? ApplicationTasks[selectedApplicationTask].applicationGUID : null;
  const { postViewData, postViewLoading, postViewError } = state.postView;
  const { fileDetails, filesLoading, fileUploadError } = state.fileUpload;

  return {
    sessionId,
    dataConfig,
    panelData,
    // applicationGUID,
    postViewData,
    postViewLoading,
    postViewError,
    fileDetails,
    filesLoading,
    fileUploadError
  };
};

export default withRouter(connect(mapStateToProps, { /* fetchApplications, loadTeams, */ postDataViewData, uploadFiles })(RenderPanelForm));