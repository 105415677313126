import React, { Component } from 'react';
import { connect } from "react-redux";
import { taskListPanelGUID, onboardingCertificateGUID } from '../../utils/Constants';
import {
    setError,
    updateSelectedApplicationTask,
    loadPanelComponents,
    getDataViewData
} from "../../actions";
import { isEqual, isEmpty, cloneDeep } from 'lodash';
import { getColumns, createColumnMap, setDefaultStyle } from './columns';
import { Redirect } from 'react-router-dom';
import DisplayDataGrid from './DataGrid';
import GetFormData from '../Form/GetFormData';
import { filterData } from "../../utils/Filter";
import { taskListPopupGUID } from "../../utils/Constants";
import { confirmAlert } from 'react-confirm-alert';
import deepCopy from '../../utils/deepCopy';

class NewDataGridController extends Component {

    state = {
        isLoading: true,
        redirect: false,
        rowSpotliteAddress: '',
        viewGUID: '',
        applicationGUID: '',
        OriginalGridLayout: {},
        GridLayout: {},
        OriginalRowData: [],
        RowData: [],
        colMap: {},
        // PMD 12/10/21 Add ModalScreen
        modalOpen: false,
        modalScreen: "",
        modalSpotliteAddress: "",
    }

    componentDidMount() {
        const { applications, rowData } = this.props;

        //console.log('cDM - NewDataGridController - this.props:', this.props);
        //console.log(applications, rowData)

        let rows = [];
        let gridLayout = {}
        // let newGridLayout = {}

        if (!applications) {
            // FIXME: Error Handling Required - JSON response incorrect
            setError({ error: 'JSON Format Error in DataGridController' });
            alert('JSON Format Error - ', this.props);
        }

        let topLevelGrid = applications.Screens.find(screen => screen.ComponentGUID === taskListPanelGUID)
        if (topLevelGrid && !rowData) {
            // There will only be one panel but we're not sure what it is called
            rows = applications.RetrievedData
            gridLayout = topLevelGrid.Panels[0][Object.keys(topLevelGrid.Panels[0])[0]].GridLayout
            this.setState({ 
                OriginalGridLayout: gridLayout, 
                RowData: rows, 
                OriginalRowData: rows 
            });

        } else {
            // use the gridLayout passed by the props
            // PMD 28/01/22 Create the gridLayout by concatenating the gridLayout with the gridProperties
            // console.log(this.props.gridProperties.CallType !== undefined);
            // console.log(this.props.gridLayout)
            gridLayout = { ...this.props.gridLayout, ...this.props.gridProperties.Properties }

            // console.log(gridLayout);

            rows = rowData;
            this.setState({ 
                OriginalGridLayout: gridLayout, 
                RowData: rows, 
                OriginalRowData: rows,
            })
        }

        this.buildRowData(rows);
        let defaultStyle = setDefaultStyle(gridLayout)
        let gridColumns = getColumns(gridLayout, defaultStyle);
        let colMap = createColumnMap(gridColumns);

        let newGridLayout = cloneDeep(gridLayout)
        newGridLayout.columns = gridColumns
        this.setState({ GridLayout: newGridLayout, colMap, isLoading: false });

    }

    componentDidUpdate(prevProps) {

        if (prevProps.searchCriteria !== this.props.searchCriteria) {
            // console.log('NewDataGridController - cDU1');
            this.buildRowData(this.state.OriginalRowData);
        }
        if (prevProps.applications && !isEqual(prevProps.applications.RetrievedData, this.props.applications.RetrievedData)) {
            // console.log('NewDataGridController - cDU2');
            this.buildRowData(this.props.applications.RetrievedData);
        }

        //console.log(prevProps.rowData !== this.props.rowData);
        if(prevProps.rowData !== this.props.rowData){
            // console.log(prevProps.rowData, this.props.rowData);
            this.buildRowData(this.props.rowData);
        }

    }

    buildRowData(rows) {
        const { applySearchCriteria } = this.props;

        if (applySearchCriteria) {
            let filteredRows = [];
            const search = [...this.props.searchCriteria];
            // console.log(rows);

            Object.values(rows).forEach(row => {
                if (isEmpty(search) || filterData(row, search)) {
                    filteredRows.push(row);
                }
            });
            //console.log(filteredRows);

            this.setState({ RowData: filteredRows });
       } else {
            this.setState({ RowData: rows });
        }
    }

    // callback function to display task details
    selectRow = (dataGridRowClicked, originalGridRowData, originalGridColumnData) => {
        const { rowData, callEventGUID, callDataGUID } = this.props

        //let applicationRecord = Object.values(this.props.applications.ApplicationTasks).find(item => item.id === dataGridRowClicked);

        //console.log(dataGridRowClicked, originalGridRowData, originalGridColumnData);
        //console.log(rowData);
        
        // PMD 01/10/21 - Don't respond to click if in a panel
        if (!rowData) {
            // this.props.rowData is only present if called in a panel therefore this is top level call
            this.props.updateSelectedApplicationTask(dataGridRowClicked);
            let applicationRecord = Object.values(this.props.applications.ApplicationTasks).find(item => item.id === dataGridRowClicked);
            //console.log(applicationRecord.viewGUID, applicationRecord.applicationGUID, applicationRecord.spotliteAddress);

            this.setState({
                redirect: true,
                viewGUID: applicationRecord.viewGUID,
                applicationGUID: applicationRecord.applicationGUID,
                rowSpotliteAddress: applicationRecord.spotliteAddress,
            });
        } else {

            const rowSelectedData = rowData.find(record => record.id === dataGridRowClicked)
            var context = this.props.context;
            
            switch (context) {

                case 'modal':
                    //console.log(`Context: ${this.props.context}`)
                    // PMD 26/11/21 Retrieve the SpotliteAddress of the clicked column using the key of the original data and the translated values
                    
                    let spotliteAddress = rowSelectedData[`${originalGridColumnData.key}_SA`]

                    if (callEventGUID) {
                        this.openModal(callEventGUID, spotliteAddress)
                    }
                    break;

                case 'redirect':
                    
                    let applicationRecord = Object.values(this.props.applications.ApplicationTasks).find(item => item.id === dataGridRowClicked);

                    if(applicationRecord !== undefined) {
                        /*
                        `* In order to do a redirect we need the following for the target
                         * 1 - viewGUID
                         * 2 - applicationGUID
                         * 3 - spotliteAddress
                         * Currently for case 1 (sleeping soldiers) we get this from the applicant record but in case 2 (row from form) we
                         * need these paramters supploed to us in the rowSelectedData 
                        */
                      
                        //This spotlite address wont be appropriate
                        let rowSpotliteAddress = rowSelectedData[`${originalGridColumnData.key}_SA`]
                        
                        // Use

                        // this.setState({
                        //     redirect: true,
                        //     viewGUID: applicationRecord.viewGUID,
                        //     applicationGUID: applicationRecord ? applicationRecord.applicationGUID : callEventGUID;,
                        //     rowSpotliteAddress: rowSpotliteAddress,
                        // });

                    } else {
                         //Fire alert
                        confirmAlert({
                            title: 'applicationGUID Missing',
                            message: `ApplicationGUID missing. Please check database and try again`,
                            buttons: [
                              {
                                label: 'OK',
                                onClick: () => {
                                  // no need to do anything
                                },
                              },
                            ],
                          });
                    }

                    break;

                case 'readonly':
                 
                    if (!rowSelectedData.ImageURL) {
                        let dataValue = JSON.parse(rowSelectedData.JSONData);
                        // if the dataValue is an object it is a JSON file
                        if (typeof dataValue === 'object') {
                            displayData = `${rowSelectedData.JSONData}.json`
                        } else if (dataValue.startsWith('<') && dataValue.endsWith('>')) {
                            // data is an xml file
                            displayData = `${rowSelectedData.JSONData}.xml`
                        } else {
                            // Value isn't a valid JSON or XML data so just pass the value
                            displayData = `${rowSelectedData.JSONData}.unknown`
                        }
                        console.log(displayData);
                    } 
                    break;
    

                default:
                    
                    let displayData;
                    
                    let __spotliteAddress = rowSelectedData[`${originalGridColumnData.key}_SA`]
                   
                    if (rowSelectedData.ImageURL) {
                        displayData = rowSelectedData.ImageURL
                        //console.log(`Reaching here ${displayData}`)
                        this.returnDocListImageToPanel(displayData, __spotliteAddress)
                    } else if (rowSelectedData.JSONData) {
                        // if the dataValue is an object it is a JSON file
                        if (rowSelectedData.JSONData.startsWith('{') && rowSelectedData.JSONData.endsWith('}')) {
                            //console.log("Json file");
                            //let dataValue = JSON.parse(rowSelectedData.JSONData);
                            //console.log(dataValue);
                            displayData = `${rowSelectedData.JSONData}.json`
                        } else if (rowSelectedData.JSONData.startsWith('<') && rowSelectedData.JSONData.endsWith('>')) {
                            // data is an xml file
                            displayData = `${rowSelectedData.JSONData}.xml`
                        } else {
                            // Value isn't a valid JSON or XML data so just pass the value
                            displayData = `${rowSelectedData.JSONData}.unknown`
                        }
                        //console.log(`Reaching here ${displayData}`)
                        this.returnDocListImageToPanel(displayData, __spotliteAddress)
                    } else {
                        //Check if we have a callData
                        
                        if(!isEmpty(callDataGUID)){

                            const {selectedApplicationTask, ApplicationTasks } = this.props;
                            const applicationInstanceGUID = ApplicationTasks[selectedApplicationTask].applicationGUID;
                    
                            //console.log(`Application Instance GUID: ${applicationInstanceGUID}`);

                            const options = {
                                ViewGUID: callDataGUID,
                                ApplicationInstanceGUID: applicationInstanceGUID,
                                SpotliteAddress: __spotliteAddress || "",
                              };
        
                            //console.log(options)
                            this.callGetData(options).then(val => {
                                displayData = val
                                //console.log(val);
                                this.returnDocListImageToPanel(displayData, __spotliteAddress)
                            })
                        } else {
                            displayData = `Data.unknown` 
                            this.returnDocListImageToPanel(displayData, __spotliteAddress)
                        }

                    }
                  
                break;
            }
            
            // PMD 04/10/21 Row in panel grid clicked
            // Retrieve information from rowData for the selected row
            //const rowSelectedData = rowData.find(record => record.id === dataGridRowClicked)
            // console.log(rowSelectedData)
            // Identify if row clicked is on DocumentList panel i.e.id starts with image-
            // PMD 24/05/22 id starts with di instead of image
            /*
            if (rowSelectedData && rowSelectedData.id.startsWith("di-") || rowSelectedData && rowSelectedData.id.startsWith("dlv-")) {
                // PMD 09/01/23 If no image url associated then assume it is json data and add a .json extension
                // let url = rowSelectedData.ImageURL 
                let displayData;

                if (rowSelectedData.ImageURL) {
                    displayData = rowSelectedData.ImageURL
                } else {
                    try {
                        JSON.parse(rowSelectedData.JSONData)
                        displayData = `${rowSelectedData.JSONData}.json`
                    } catch (e) {
                        // Value isn't a valid JSON data so just pass the value
                        displayData = `${rowSelectedData.JSONData}.unknown`
                    }
                }
                // let url = rowSelectedData.ImageURL ? rowSelectedData.ImageURL : `${rowSelectedData.JSONData}.json`
                // console.log(displayData);

                this.returnDocListImageToPanel(displayData)
            } else {
                // PMD 26/11/21 Retrieve the SpotliteAddress of the clicked column using the key of the original data and the translated values
                let spotliteAddress = rowSelectedData[`${originalGridRowData.key}_SA`]
                // console.log(spotliteAddress);
                // console.log(callEventGUID);
                
                if (callEventGUID) {
                    this.openModal(callEventGUID, spotliteAddress)
                }
            }
            */
        }
    };

    async callGetData(options) {
        
        await this.props.getDataViewData(options);

        let dataViewDataCopy = deepCopy(this.props.getViewData);
        var json = `${dataViewDataCopy.JsonData}.json`
  
        return json;
    }

    gridLayoutChanged = (updatedGridLayout) => {
        const { CallingObjectGUID } = this.props.gridProperties
        let panelObj = {}
        panelObj[CallingObjectGUID] = updatedGridLayout
        this.props.gridLayoutChanged(panelObj)
    }

    returnDocListImageToPanel = (documentURL, spotliteAddress) => {

        return this.props.loadPanelComponents({
            panelGuid: onboardingCertificateGUID,
            json: documentURL,
            call: this.props.ComponentGUID,
            panelTitle: this.props.panelTitle,
            spotliteAddress
        });
    }

    render() {

        const { isLoading, redirect, applicationGUID, viewGUID, rowSpotliteAddress } = this.state;

        if (isLoading) return null;

        if (redirect) {

            var addressParts = rowSpotliteAddress.split('-');
            
            let spotliteAddress = "";
            
            if(addressParts.length > 1){
                spotliteAddress = addressParts[addressParts.length -1];
            } else {
                spotliteAddress = addressParts[0];
            }

            return <Redirect push to={
                {
                    pathname: `${this.props.bookmark.bookmarkPath}/${applicationGUID}`, 
                    state:{viewGUID, applicationGUID, spotliteAddress}
                }
            } />;
        }

        return (
            <>
                <DisplayDataGrid gridLayout={this.state.GridLayout} rowData={this.state.RowData} colMap={this.state.colMap} selectRow={this.selectRow} gridLayoutChanged={this.gridLayoutChanged} />
                <div>{this.renderModal()}</div>
            </>
        )
    }

    renderModal = () => {

        const { modalOpen, modalScreen, modalSpotliteAddress } = this.state;

        if (modalOpen) {
            return (
                <GetFormData
                    open={modalOpen}
                    closeModal={this.onClose}
                    cancelModal={this.onClose}
                    screenType={modalScreen}
                    spotliteAddress={modalSpotliteAddress}
                />
            );
        } else {
            return null;
        }
    };

    openModal = (e, spotliteAddress) => {
        this.setState({
            modalOpen: true,
            modalScreen: e,
            modalSpotliteAddress: spotliteAddress
            // modalScreen: e.target.value,
        });
    };

    onClose = () => {
        //console.log("Closing");
        this.setState({ modalOpen: false });
    };
}

const mapStateToProps = (state) => {
    const { dataConfig, portalDetails } = state.session;
    const { applications, selectedApplicationTask, searchCriteria } = state.applications;
    const bookmark = state.bookmark;
    const { getViewData } = state.getView;

    const { ApplicationTasks } = state.applications.applications

    return {
        dataConfig,
        portalDetails,
        applications,
        selectedApplicationTask,
        searchCriteria,
        getViewData,
        bookmark,
        ApplicationTasks
    };
};

export default connect(mapStateToProps, {
    setError,
    updateSelectedApplicationTask,
    loadPanelComponents,
    getDataViewData
})(NewDataGridController);

