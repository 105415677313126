import { LOAD_USERS, LOAD_APPLICANTS, SELECT_APPLICANT, SET_USER_ERROR } from '../actions/types';

const INITIAL_STATE = { 
  users: [],
  applicants: [],
  selectedApplicant: {},
  error: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, users: action.payload };
    case LOAD_APPLICANTS:
        return { ...state, applicants: action.payload };
    case SELECT_APPLICANT:
      return { ...state, selectedApplicant: action.payload };
    case SET_USER_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
