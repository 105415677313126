module.exports = {
    development: {
        // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
        // PORT: 4090,
        // URL: 'http://localhost:',
        URL: 'http://localhost:4090/home',
        // URL: 'https://app-dev-clienthub.azurewebsites.net/home',
        //mainApiUrl: 'https://spotlite-api-dev.azurewebsites.net/api/v1/',
        mainApiUrl: process.env.REACT_APP_DEV_MAIN_API_URL,
        bizIQUrl: 'https://cmsprod01-spotlite-biziq.azurewebsites.net/',
        worldpayClientKey: 'T_C_f65a3608-b3f3-430c-ae76-217b2f10ec4a', //test
        passwordResetURL: process.env.REACT_APP_DEV_PASSWORD_RESET_URL,
        registrationURL: process.env.REACT_APP_DEV_REGISTRATION_URL,
        // B2C - DEVPROD Environment
        authorityDomain: 'safeguardenauth.b2clogin.com',
        authority: 'https://safeguardenauth.b2clogin.com/ebf9051a-0eb2-471a-840d-783a03819985',
        signin: 'B2C_1A_SIGNIN',
        resetPassword: 'B2C_1A_PASSWORDRESET',
        scopes: 'https://safeguardenauth.onmicrosoft.com/551455ce-e012-46f9-99fe-f5fbc1a932b7/access_as_user',
        clientId: '4ea5ac9b-cd70-47d7-afc4-6086bac560a7'

        // B2C Settings when using DEV environment
        // authorityDomain: 'spotlitedev.b2clogin.com',
        // authority: 'https://spotlitedev.b2clogin.com/d01c4aaa-d9a1-4759-bdd0-2b7a600f1675',
        // signin: 'B2C_1A_SIGNIN',
        // resetPassword: 'B2C_1A_PASSWORDRESET',
        // scopes: 'https://spotlitedev.onmicrosoft.com/d9a75c53-692e-4c6a-854e-0914487cc56c/access_as_user',
        // clientId: '2e0b5a0f-b337-4d1b-a808-817e47dfeeb4'

    },
    test: {
        // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
        // PORT: 4090,
        // URL: 'http://localhost:',
        URL: 'http://localhost:4090/home',
        mainApiUrl: 'https://spotlite-api-dev.azurewebsites.net/api/v1/',
        // bizIQUrl: 'http://localhost:4090/',
        bizIQUrl: 'https://cmsprod01-spotlite-biziq.azurewebsites.net/',
        worldpayClientKey: 'T_C_f65a3608-b3f3-430c-ae76-217b2f10ec4a', //test
        passwordResetURL: 'https://sgprod01-api-spotlite.azurewebsites.net/api/v1/profile/RequestPasswordResetPublic',
        appsetting: process.env.WEBSITE_NODE_DEFAULT_VERSION
    },
    production: {
        // PORT: 443,
        URL: process.env.REACT_APP_PROD_URL,
        mainApiUrl: process.env.REACT_APP_PROD_MAIN_API_URL,
        bizIQUrl: 'https://cmsprod01-spotlite-biziq.azurewebsites.net/',
        worldpayClientKey: 'L_C_200f9336-f842-44a3-b058-bf9d1319e361', //test
        passwordResetURL: process.env.REACT_APP_PROD_PASSWORD_RESET_URL,
        registrationURL: process.env.REACT_APP_PROD_REGISTRATION_URL,
        // B2C
        authorityDomain: 'safeguardenauth.b2clogin.com',
        authority: 'https://safeguardenauth.b2clogin.com/safeguardenauth.onmicrosoft.com',
        signin: 'B2C_1A_SIGNIN',
        resetPassword: 'B2C_1A_PASSWORDRESET',
        scopes: 'https://safeguardenauth.onmicrosoft.com/551455ce-e012-46f9-99fe-f5fbc1a932b7/access_as_user',
        clientId: '4ea5ac9b-cd70-47d7-afc4-6086bac560a7'
        // B2C Settings when DEPLOYING TO DEV environment
        // authorityDomain: 'spotlitedev.b2clogin.com',
        // authority: 'https://spotlitedev.b2clogin.com/d01c4aaa-d9a1-4759-bdd0-2b7a600f1675',
        // signin: 'B2C_1A_SIGNIN',
        // resetPassword: 'B2C_1A_PASSWORDRESET',
        // scopes: 'https://spotlitedev.onmicrosoft.com/d9a75c53-692e-4c6a-854e-0914487cc56c/access_as_user',
        // clientId: '2e0b5a0f-b337-4d1b-a808-817e47dfeeb4'

    }
};