import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './style/Loader.css';

export default class Loader extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		active: false
	};

	render() {
		const {
			componentType,
			className
		} = this.props;

		const Element = componentType;
		const activeClass = this.props.active ? 'loader--active' : '';
		const customClassNames = className ? className : '';
		const classNames = `loader ${activeClass} ${customClassNames}`;

		return (
			<Element className={classNames}>
				<span className="loader__spinner" />
			</Element>
		);
	}
}
