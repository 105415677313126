import { LOAD_TEAMS, SELECT_USER, SET_TEAM_ERROR, SET_RELOAD_TEAM } from './types';
import * as actions from './actionCreators';

import { getRequest, postRequest, apiParse } from '../utils/Common';
import config from '../config';
import { errorHandler } from '../utils/errorHandler';
const env = process.env.NODE_ENV || 'development';



export const changeTeam = (spotliteAddress) => {
  // console.log(this.props)

  return (dispatch, getState) => {

    // console.log('TeamAction.js changeTeam --> ', spotliteAddress)

    if (getState().session.sessionId) {
      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/change-team?spotliteAddress=${spotliteAddress}`;
      const URI = `${config[env].URL}/change-team?spotliteAddress=${spotliteAddress}`;
      const credentials = 'omit';

      const headers = {
        sessionid: getState().session.sessionId,
        // PMD 17/12/21 Add portaltype
        portaltype: getState().session.portalDetails.portalType,

      };

      dispatch(actions.changeTeamRequest());
      return getRequest(URI, credentials, headers)
        .then(apiParse)
        .then(response => {
          return dispatch(actions.changeTeamSuccess(response));
        })
        .catch(err => {
          // PMD 20/05/22 Generic error handler
          errorHandler(err);
          // return dispatch(actions.changeTeamError(err));
        })
    }
  };
};

export const setTeamError = (err) => {
  return {
    type: SET_TEAM_ERROR,
    payload: err
  };
};

export const loadTeams = () => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/get-teams`;
      const URI = `${config[env].URL}/get-teams`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        // PMD 09/05/22 Add portalType
        portaltype: getState().session.portalDetails.portalType
      };

      return postTeamRequest(dispatch, URI, options, credentials);

    } else {
      handleTeamNoSession(dispatch);
    }
  }
};

export const addUserToTeam = ({ teamGUID, PartyGUID }) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/add-user-to-team`;
      const URI = `${config[env].URL}/add-user-to-team`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        teamGUID: teamGUID,
        PartyGUID: PartyGUID,
        // PMD12/05/22 Pass portaltype
        portaltype: getState().session.portalDetails.portalType
      };

      return postTeamRequest(dispatch, URI, options, credentials);

    } else {
      handleTeamNoSession(dispatch);
    }
  }
};

export const removeUserFromTeam = ({ /*teamGUID,*/ PartyGUID }) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/remove-user-from-team`;
      const URI = `${config[env].URL}/remove-user-from-team`;
      const credentials = 'omit';

      const options = {
        sessionId: getState().session.sessionId,
        // PMD 12/05/22 teamGUID not used
        // teamGUID: teamGUID,
        PartyGUID: PartyGUID,
        // PMD 12/05/22 Pass portalType
        portaltype: getState().session.portalDetails.portalType
      };

      return postTeamRequest(dispatch, URI, options, credentials);

    } else {
      handleTeamNoSession(dispatch);
    }
  }
};

// PMD 25/05/22 Replaced by a ModalForm
// export const createTeam = ({ teamName, viewGUID }) => {
//   return (dispatch, getState) => {

//     if (getState().session.sessionId) {

//       // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
//       // const URI = `${config[env].URL}${config[env].PORT}/home/create-team`;
//       const URI = `${config[env].URL}/create-team`;
//       const credentials = 'omit';

//       const options = {
//         sessionId: getState().session.sessionId,
//         teamName: teamName,
//         viewGUID: viewGUID,
//         currentTeamGUID: getState().session.dataConfig.allTeams.currentTeamDetails.teamGUID
//       };

//       return postTeamRequest(dispatch, URI, options, credentials);

//     } else {
//       handleTeamNoSession(dispatch);
//     }
//   }
// };

export const deleteTeam = ({ teamGUID }) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      // PMD 02/09/21 Remove PORT and home to enable use of API Gateway
      // const URI = `${config[env].URL}${config[env].PORT}/home/delete-team`;
      const URI = `${config[env].URL}/delete-team`;
      const credentials = 'omit';
      // const headers = { appkey: process.env.REACT_APP_APP_KEY };

      const options = {
        sessionId: getState().session.sessionId,
        teamGUID: teamGUID
      };

      // return postTeamRequest(dispatch, URI, options, credentials, headers);
      return postTeamRequest(dispatch, URI, options, credentials);

    } else {
      handleTeamNoSession(dispatch);
    }
  }
};

function postTeamRequest(dispatch, URI, options, credentials) {
  /*
  identical for all endpoints that return the full team details
  */
  return postRequest(URI, options, credentials)
    .then(apiParse)
    .then(response => {

      // console.log("*** team response!!");
      // console.log(response);

      let teamsArray = (Array.isArray(response.teams)) ? response.teams : [response.teams];

      dispatch({
        type: SET_RELOAD_TEAM,
        payload: false
      })

      dispatch({
        type: LOAD_TEAMS,
        payload: teamsArray
      });

    })
    .catch(err => {
      // PMD 20/05/22 Generic error handler
      errorHandler(err);
      dispatch({
        type: SET_RELOAD_TEAM,
        payload: false
      })

      dispatch({
        type: SET_TEAM_ERROR,
        payload: "error loading users"
      });
    });
}

function handleTeamNoSession(dispatch) {
  //no secure id
  dispatch({
    type: SET_RELOAD_TEAM,
    payload: false
  })

  dispatch({
    type: LOAD_TEAMS,
    payload: []
  });
}

export const selectUser = (usrObject) => {
  return {
    type: SELECT_USER,
    payload: usrObject
  };
};


export const deleteUser = (ContactGUID) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId) {

      const URI = `${config[env].URL}/delete-user`;

      const options = {
        sessionId: getState().session.sessionId,
        ContactGUID: ContactGUID,
        // PMD 12/05/22 Pass portaltype as well
        portaltype: getState().session.portalDetails.portalType
      };

      const credentials = 'omit';
      return postRequest(URI, options, credentials)
        .then(apiParse)
        .then(response => {

          //clear the selected user once saved
          dispatch({
            type: SELECT_USER,
            payload: {}
          });

          dispatch({
            type: SET_RELOAD_TEAM,
            payload: true
          })

        })
        .catch(err => {
          // PMD 20/05/22 Generic error handler
          errorHandler(err)
          dispatch({
            type: SET_TEAM_ERROR,
            payload: err.message
          });
        });
    } else {
      //no secure id
      dispatch({
        type: SELECT_USER,
        payload: {}
      });
    }
  }
};

// PMD 12/4/21 - Endpoint Tidy 
/*
export const updatePassword = (userObject) => {
  return (dispatch, getState) => {

    if (getState().session.sessionId){

      const URI = `${config[env].URL}${config[env].PORT}/home/client-portal-change-password`;

      const options = {
        ...userObject,
        sessionId: getState().session.sessionId
      };

      console.log('returning');
      console.log(options);

      const credentials = 'omit';
      const headers = { appkey: process.env.REACT_APP_APP_KEY };

      return postRequest(URI, options, credentials, headers)
          .then(apiParse)
          .then(response => {

            //clear the selected user once saved
            dispatch({
              type: SELECT_USER,
              payload: {}
            });

            dispatch({
              type: SET_RELOAD_TEAM,
              payload: true
            })

          })
          .catch(err => {

            dispatch({
              type: SET_TEAM_ERROR,
              payload: err.message
            });
          });
    } else {
      //no secure id
      dispatch({
        type: SELECT_USER,
        payload: {}
      });
    }
  }
};

*/