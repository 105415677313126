

const GetApplicationGUID = (props) => {
  
    const { viewGUID, teamGUID, departmentGUID, userSettingsGUID, applicationGUID } = props;
    
    switch(viewGUID){
  
      //BusinessSettings      
      //Maintain Subscriptions
      //View Activity   ​
      //Support Tickets
      //Risks And Issues​
      //Policies and Standards
      //Training
  
      case ['6A2293C0-A0D4-4E93-B8C9-456312168FA0',
          '067BF45A-43FD-4F50-BAAC-49AFCFD718E7',
          '0486F3EF-3AC9-4F5B-8AEF-77577C747042', 
          '0193ACDF-57E5-4A58-AA9B-3A688B07AE0A',
          'B6B88B2C-4CBD-48E9-9D4FDE751C98',
          '64F07C2D-3DB6-467F-A60D-6A8D1CC71D36',
          '4087D42F-FFC3-4D82-A4AC-86EEBDC66803'].includes(viewGUID) :
        return departmentGUID;
  
    
      //Teams Settings
      case ['DE5F3647-08CF-4047-B9DB-4B317E5B5312'].includes(viewGUID) :
        return teamGUID;
      
        //Agent Settings
      case ['E512BF2E-1BB3-4FB6-BF5F-CFAB4834F1FD'].includes(viewGUID):
        return userSettingsGUID;
  
      //If none match, return the viewGUID
      default:
        return applicationGUID;
  
    }
  }

  export default GetApplicationGUID;