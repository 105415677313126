import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

// import { loadFakePanelComponents } from "../../actions";
import PanelDocumentRow from "./PanelDocumentRow";

class PanelDocumentList extends Component {
  renderSpotliteObjects = () => {
    const { panelData, panelGuid } = this.props;

    // console.log('Panel List -', panelData)

    let docList = Object.values(_.omit(panelData.Calls[0].RetrievedData, "ViewResponse"))[0]

    // Retrieve a list of the documents
    const fieldsToDisplay = panelData.Cards.map(card => {
      const fieldsInfo = [];
      // console.log(card)
      // console.log(Object.keys(card)[0])
      fieldsInfo.push(
        card[Object.keys(card)[0]].Attributes.FieldMapping[0]
      );
      return (
        fieldsInfo
      );
    });

    // console.log(fieldsToDisplay)
    // console.log(docList)

    const docRow = [];

    for (const doc in docList) {
      let docListRow = {};
      docListRow.id = doc;
      for (let i = 0; i < fieldsToDisplay.length; i++) {
        docListRow[fieldsToDisplay[i][0]] = docList[doc][fieldsToDisplay[i][0]];
      }

      docRow.push(docListRow);
    }

    return <PanelDocumentRow panelGuid={panelGuid} docRow={docRow} />;
  };

  render() {
    return this.renderSpotliteObjects();
  }
}

const mapStateToProps = (state, ownProps) => {
  const panelData = state.dashboard.panels[ownProps.panelGuid];
  return { panelData };
};

export default connect(mapStateToProps, {})(PanelDocumentList);
