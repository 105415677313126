
export function filterData(data, searchCriteria){

    let match = false;

    Object.values(data).forEach(values => {
        if(values !== null){
            if(searchCriteria.some(i => `${values}`.toUpperCase().includes(i.value.toUpperCase()))){
                match = true;
            }
        }
    });  

    return match;
}