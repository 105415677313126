import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import { addCatalogue } from '../../actions';
import ServicePackCatalogue from '../ServicePacks/ServicePackCatalogue';
import ServicePackItems from '../ServicePacks/ServicePackItems';
import ServicesModal from '../ServicesModal/ServicesModal';

import SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";

import './style/ServicePacks.css';
import './style/ServiceTable.css';
// import { flatMap } from 'lodash';

class ServicePacks extends Component {

	static propTypes = {
		componentType: PropTypes.string,
		currency: PropTypes.string,
		locale: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		currency: 'GBP',
		locale: 'en-GB'
	};

	constructor(props) {
		super(props);

		this.state = {
			catalogueSelected: null,
			notFirstTimeSelected: false,
			error: null,
			message: null,
			jurisdiction: 'GBR',
			packSelected: null,
			displayServiceItems: false
		};
	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.currentCatalogues !== prevProps.currentCatalogues) {
			// the catalogues array has been updated
			// we need to reset and use this
			//console.log('reset and use ' + JSON.stringify(this.props.currentCatalogues));
			//this.onCatalogueBack();
		}

		if (this.state.catalogueSelected !== prevState.catalogueSelected) {
			this.setState({
				notFirstTimeSelected: true
			});
		}




		if (this.state.packSelected !== prevState.packSelected) {
			if (this.state.packSelected) {
				setTimeout(() => {
					scrollToComponent(this.serviceItemSection, {
						offset: -50,
						align: 'top',
						duration: 500
					})
				}, 500);
			}
		}
	}

	handleCatalogueSelect = catalogueID => {
		this.setState({
			catalogueSelected: catalogueID
		});

		this.props.catalogueSelected(true);
	};

	handlePackSelect = pack => {
		this.setState({
			packSelected: pack,
			displayServiceItems: true//this triggers the next state
		});
	};

	handleModalClose = () => {
		this.setState({
			packSelected: null,
			displayServiceItems: false
		});
	}

	handleContinue = () => {
		//this.setState({
		//	displayServiceItems: false
		//});
		this.handleConfirmButton();
	}

	handleConfirmButton = () => {

		this.setState({ message: null });

		this.props.addCatalogue(this.state.catalogueSelected);
	};

	onCatalogueBack = () => {
		
		this.setState({
			catalogueSelected: null,
			packSelected: null,
			displayServiceItems: false,
		});

		this.props.catalogueSelected(false);

	};

	renderServices = () => {

		const catalogues = this.props.catalogueData.Catalogue;
		// const catalogues = SpotliteClientCatalogue.Catalogue;
		const notFirstTimeSelectedClass = !this.state.catalogueSelected && this.state.notFirstTimeSelected ? ' services--animate' : '';
		const servicesClassNames = `services${this.state.catalogueSelected ? ' services--selected' : ''}${notFirstTimeSelectedClass}`;
		
		//console.log(servicesClassNames)
		
		const catalogueSelected = `services_back_button${this.state.catalogueSelected ? '' : '--hide'}`;
		
		//Change servicesClassNames to card_grid

		return (

			<>

				<button
					className={catalogueSelected}
					type="button"
					onClick={this.onCatalogueBack}>
						<span className="icon__text">Back</span>
				</button>


			<ul className="card_grid">
				{
					catalogues.map((catalogue, index) => {
						return (
							<ServicePackCatalogue
								key={index}
								catalogue={catalogue}
								currentCatalogues={catalogues}
								onCatalogueSelect={this.handleCatalogueSelect}
								catalogueSelected={this.state.catalogueSelected}
								onPackSelect={this.handlePackSelect}
								handleContinue={this.handleContinue}
								packSelected={this.state.packSelected}
								onBackClick={this.onCatalogueBack}
							>
								{catalogue.Features.length ? <h2>{catalogue.CatalogueName}</h2> : null}
								{this.renderFeatures(catalogue.Features)}
							</ServicePackCatalogue>
						)
					})
				}
			</ul>

			</>
		)
	};

	renderFeatures = features => {

		if (features.length) {
			return (
				<ul>
					{
						features.map((feature, index) => {
							return (
								<li key={index}>{feature}</li>
							)
						})
					}
				</ul>
			)
		}

		return null;
	};

	renderServiceItems = () => {
		const {
			packSelected,
			displayServiceItems

		} = this.state;

		// const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;

		const paymentMethods = this.props.catalogueData.Currencies.GBP.PaymentMethods;

		
		if (packSelected && packSelected.Jurisdictions[0].LinesOfBusiness[0].Services && displayServiceItems) {
			return (
				<ServicesModal
					icon="info"
					iconClassName="card_info_icon"
					handleModalClose={this.handleModalClose}
				>
					<ServicePackItems
						//ref={(ref) => { this.serviceItemSection = ref; }}
						catalogueSelected={this.state.catalogueSelected}
						packSelected={packSelected}
						paymentMethods={paymentMethods}
						handleContinue={this.handleContinue}
						onCatalogueBack={this.onCatalogueBack}
					/>
				</ServicesModal>
				
			)
		}

		return null;
	};

	render() {

		return (
			<div>

				{this.renderServices()}

				{this.renderServiceItems()}

			</div>
		);
	};
}


const mapStateToProps = (state) => {
	//const { catalogues } = state.credits;
	const { catalogueData } = state.catalogue;
	return { catalogueData };
};

export default connect(mapStateToProps, { addCatalogue })(ServicePacks);