import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import reducers from './reducers';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    ...reducers
  }),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, routerMiddleware(history)),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, routerMiddleware),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: routerMiddleware
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
  
});

export const history = createReduxHistory(store);