// import * as actions from './actionCreators';
import config from '../config';
import { postRequest, apiParse } from '../utils/Common';
import { errorHandler } from '../utils/errorHandler';

const env = process.env.NODE_ENV || 'development';

export const fetchThemeData = (domainname) => {

    return (dispatch, getState) => {

        const URI = `${config[env].URL}/get-theme`;
        const credentials = 'omit';

        const headers = {
            "Content-Type": "application/json",
            'appkey': process.env.REACT_APP_APP_KEY,
        };

        const options = {
            domainsuffix: domainname
        }

        // console.log(URI)

        return postRequest(URI, options, credentials, headers)
            .then(apiParse)
            .then(response => {
                if (response) {
                    return dispatch({ type: "FetchConfigData", themeData: response });
                } else {
                    return dispatch({ type: "ERROR", msg: "System unavailable at this time. Please try later or check the Service Availability page on the CDD Services website." });
                }
            })
            .catch(err => {
                // PMD 20/05/22 Generic error handler
                errorHandler(err)
                return dispatch({ type: "ERROR", msg: "System unavailable at this time. Please try later or check the Service Availability page on the CDD Services website." });
            })


    }
}