import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from 'react-responsive-modal';

export default class ServicesModal extends PureComponent {

    static propTypes = {
        icon: PropTypes.string,
        iconClassName: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    state = {
        open: true,
    }

    handleClose = () => {
		this.setState({
			open: false
        });
        this.props.handleModalClose();
	};

	handleOpen = event => {
		event.preventDefault();
		
		this.setState({
			open: true
		});
	};

	render() {
		const {
			children
		} = this.props;

		return (
            <Modal
                open={this.state.open}
                onClose={this.handleClose}
                center
            >
                {children}
            </Modal>
		);
	}

}