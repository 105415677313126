import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';


class TransactionInvoicePrint extends Component {


  renderHeader = () => {

    return (

      <div>
        <img className="logo" src="/assets/img/logo_cddmanagement.svg" alt="CDD Services" />


        <div>
          <div className="headerRight">
            Manchester Digital Innovation and Security Hub<br />
            Heron House, 1 Lincoln Square<br />
            Manchester, M2 5LN<br />
            United Kingdom

            {/* PMD 20/10/22 Updated address */}
            {/* 2 Mount Street, Manchester<br />
            M2 5WQ, United Kingdom */}
          </div>
          <div className="headerRight">
            +44 (0) 161 511 5399<br />
            support@cdd.services
          </div>
        </div>

        <div className="clearFloat" />

      </div>
    )


  }


  renderInvoiceHeader = () => {

    if (!this.props.invoice) return;



    return (
      <div className="invoice_header">

        <div className="invoice_padder">

          <table className="invoice_header_table">
            <tbody>
              <tr>
                <th className="invoice_header_table_col1">{(this.props.invoice.TransactionCompleted) ? "RECEIPT" : "INVOICE"}</th>
                <th className="invoice_header_table_col2">Invoice Number</th>
                <th className="invoice_header_table_col3">Tax Date</th>
              </tr>
              <tr>
                {/* PMD 09/06/22 - Only use correspondence address if present in redux*/}
                <td className="invoice_header_table_col1">{this.props.invoice.OrganisationName} <br />{(!this.props.settings) ? '' : this.props.settings.correspondenceAddressLine1}<br />{(!this.props.settings) ? '' : this.props.settings.correspondenceAddressLine2}<br />{(!this.props.settings) ? '' : this.props.settings.correspondenceAddressCity}<br />{(!this.props.settings) ? '' : this.props.settings.correspondenceAddressState}<br />{(!this.props.settings) ? '' : this.props.settings.correspondenceAddressPostalCode}<br /></td>
                {/* <td className="invoice_header_table_col1">{this.props.invoice.OrganisationName} <br />{this.props.settings.correspondenceAddressLine1}<br />{this.props.settings.correspondenceAddressLine2}<br />{this.props.settings.correspondenceAddressCity}<br />{this.props.settings.correspondenceAddressState}<br />{this.props.settings.correspondenceAddressPostalCode}<br /></td> */}
                <td>{this.props.invoice.DocumentExternalRef}</td>
                <td>{moment(this.props.invoice.TransactionDate, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY')}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    )
  }

  renderInvoiceDetails = () => {

    if (!this.props.invoice) return;

    return (
      <div className="invoice_details">

        <div className="invoice_padder">

          <table className="invoice_details_table">
            <tbody>
              <tr>
                <th className="invoice_details_table_col1">Item</th>
                <th className="invoice_details_table_col2">Credits Purchased</th>
                <th>Cost (ex Vat)</th>
              </tr>

              {this.renderInvoicelines()}
            </tbody>
          </table>

        </div>
      </div>
    )

  }

  renderInvoiceTotals = () => {

    if (!this.props.invoice) return;

    return (

      <div className="invoice_totals">

        <div className="invoice_padder">

          {/* PMD 27/04/21 - April 21 DB Changes */}
          {/* <table className="invoice_totals_table">
          <tbody>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Subtotal (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.InvoiceAmount.toFixed(2)}</td> 
              </tr>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Vat (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.InvoiceVat.toFixed(2)}</td> 
              </tr>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Total (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.InvoiceTotal.toFixed(2)}</td> 
              </tr>
            </tbody>
            </table> */}
          <table className="invoice_totals_table">
            <tbody>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Subtotal (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.CurrencyAmountExcludingTax.toFixed(2)}</td>
              </tr>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Vat (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.TaxAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <th className="invoice_totals_table_col1 align_right">Total (£):</th>
                <td className="invoice_totals_table_col2">{this.props.invoice.CurrencyAmountIncludingTax.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    )

  }



  renderInvoicelines = () => {

    // PMD 28/04/21 - April 21 DB Changes
    // if (this.props.invoice.invoiceDetails.length) {
    //   return (
    //     this.props.invoice.invoiceDetails.map((detailItem, index) => {
    //       return (
    //         <tr key={index}>
    //           <td className="invoice_details_table_col1">{detailItem.Item}</td>
    //           <td className="invoice_details_table_col2">{detailItem.CreditsPurchased}</td>
    //           <td>{detailItem.Amount.toFixed(2)}</td>
    //         </tr>
    //       )
    //     })
    //   )
    // }
    if (this.props.invoice.documentTransactionDetail.length) {
      return (
        this.props.invoice.documentTransactionDetail.map((detailItem, index) => {
          return (
            <tr key={index}>
              <td className="invoice_details_table_col1">{detailItem.Item}</td>
              <td className="invoice_details_table_col2">{detailItem.TransactionCredits}</td>
              <td>{detailItem.TransactionCurrencyAmountExcludingTax.toFixed(2)}</td>
            </tr>
          )
        })
      )
    }

    return null;
  }


  renderInvoiceFooter = () => {

    if (!this.props.invoice) return;

    return (

      <div className="invoice_footer">

        <div className="invoice_footer_left">
          <div className="invoice_padder">
            {/* PMD 20/10/22 Updated address details */}
            {/* CDD Management Services Ltd<br />
            2 Mount Street<br />
            Manchester<br />
            M2 5WQ<br /><br /> */}
            CDD Services Ltd<br />
            Manchester Digital Innovation and Security Hub<br />
            Heron House, 1 Lincoln Square<br />
            Manchester, M2 5LN<br />
            Tel: 0161 511 5399<br />
            Email: support@cddmanagement.services<br />
            Company Reg No: 5164842
            {/* Company Reg No: 10036916 */}
          </div>
        </div>

        <div className="invoice_footer_right">
          <div className="invoice_padder">
            Please make payment directly to:<br />
            A/c Name: CDD Management Services Ltd<br />
            Sort Code:  01-05-31<br />
            A/c No: 43460089<br /><br />
            BIC: NWBK GB 2L<br />
            IBAN: GB95 NWBK 0105 3143 4600 89<br />
            VAT No:  300 5504 62<br />

          </div>
        </div>


      </div>

    )
  }


  render() {

    return (
      <div className="transactions transactionsPrint">

        {this.renderHeader()}

        {this.renderInvoiceHeader()}

        {this.renderInvoiceDetails()}

        {this.renderInvoiceTotals()}

        <div className="clearFloat"></div>

        {this.renderInvoiceFooter()}

        <div className="clearFloat"></div>

      </div>
    )

  }
}



const mapStateToProps = (state) => {
  const { invoice } = state.transactions;
  const { settings } = state.settings;
  return { invoice, settings };
};

export default connect(mapStateToProps, {})(TransactionInvoicePrint);