import { isEmpty } from 'lodash';
import React from 'react';

export default function AvatarMenuOptions (props) {

    const { data, onItemClick } = props;

    // console.log(data);

    const element = (
        <ul>
            {data.map((item, index) => renderDefaultItem(item, index, onItemClick))}
        </ul>
    );

    return element;

}

function renderDefaultItem(item, index, onItemClick) {

    // console.log(item);

    const element = (
        <div key={index} className="tooltip">  
            <div
                id="dropdownButton" 
                className="SegmentMenu-dropdownButton" 
                key={item.key} 
                onClick={() => onItemClick({
                    context: item.Context,
                    viewGUID: item.viewGUID,
                    path: item.RouterPath,
                    serviceCode: item.ServiceCode,
                })}>
                {renderHint(item.Hint)}
                {renderIcon(item)}
                {renderLabel(item)}
            </div>
        </div> 
    );
    return element;

}

function renderLabel(item) {

    let label = "";

    label = (typeof item.Label === 'undefined' || item.Label === null
        ? typeof item.ServiceCode === 'undefined' || item.ServiceCode === null ? "Text Missing" : item.ServiceCode
        : item.Label);

    return <p>{label}</p>;

}

function renderHint(hint) {

    let _hint = (typeof hint  === 'undefined' || hint === null
    ? "" : hint);

    if(isEmpty(_hint) || _hint === null){
        return null;
    } else {
        return <span className="tooltiptext left">{_hint}</span>;
    }

}

function renderIcon(item) {

    let src = "";
    let alt = "";
    // console.log(item)
    // console.log(item.ImageAltText)
    
    try {
        src = (typeof item.Image.ImageFile  === 'undefined' || item.Image.ImageFile === null
        ? "" : item.img.src);
    
        alt = (typeof item.ImageAltText  === 'undefined' || item.ImageAltText === null
        ? "" : item.img.alt);

    } catch (e) {
        // FIXME: One for BEN
        // console.log(e);
    }

    if(isEmpty(src)){
        // Returns a div of equal width and margin of icon to maintain consistency
        return <div className="SegmentMenu-no-menuIcon"/>;
    } else {
        return (

            <img 
            className="SegmentMenu-menuIcon" 
            src={src} 
            alt={alt} 
            width="18"
            height="18"
            />

        );
    }
}
