import { LOAD_PANEL_COMPONENTS, /* LOAD_DOCUMENTS, LOAD_APPLICATION_INSTANCE, LOAD_PARTY, LOAD_NOTICES, LOAD_TASKS, LOAD_TASK_COMMENTS */ } from '../actions/types';

const INITIAL_STATE = { 
  
  // documents: [],
  // applicationInstance: {},
  // party: {},
  // notices: [],
  // tasks: [],
  // taskComments: [],

  panels: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    // case LOAD_DOCUMENTS:
    //   return { ...state, documents: action.payload };

    // case LOAD_APPLICATION_INSTANCE:
    //   return { ...state, applicationInstance: action.payload[0] };

    // case LOAD_PARTY:
    //   return { ...state, party: action.payload[0] };

    // case LOAD_NOTICES:
    //   return { ...state, notices: action.payload };

    // case LOAD_TASKS:
    //   return { ...state, tasks: action.payload };

    // case LOAD_TASK_COMMENTS:
    //   return { ...state, taskComments: action.payload };

    case LOAD_PANEL_COMPONENTS:
      // create a new panel object for this data
      var newPanels = {...state.panels};
      //newPanels[action.payload.panelGuid] = action.payload.json;

      newPanels[action.payload.panelGuid] = action.payload

      return { ...state, panels: newPanels};

    default:
      return state;
  }
};

