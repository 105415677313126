import React from "react";

export default function SectionTab(props) {
  let classes = "section-tab" + (props.isActive ? " section-active" : "");
  let imgFilename = props.isActive ? props.activeImage : props.inactiveImage;

  return (
    <div className={classes} onClick={props.onClick}>
      <img
        src={imgFilename}
        alt={props.altName}
        className="dashboard-section-icons"
      />
      {props.title && <div className="section-title">{props.title}</div>}
    </div>
  );
}
