import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import './style/ServicePacks.css';

class ServicePackItems extends Component {

	static propTypes = {
		componentType: PropTypes.string
	};

	render() {
		const {
			packSelected,
			paymentMethods
		} = this.props;

		return (
			

			<div className="services_modal">
				
				<h2>Services Available To You With This Pack</h2>

				<table className="service-table">
					<thead>
					<tr>
						<td>Service</td>
						<td className="service-table__column service-table__column--small">Cost</td>
					</tr>
					</thead>
					<tbody>
					{ packSelected.Jurisdictions[0].LinesOfBusiness[0].Services.map((service, index) => {
						return (
							<tr key={index}>
								<td>
									<span className="service-table__title">{service.ServiceName}</span>
									<span className="service-table__description">{service.ServiceShortDescription}</span>
								</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">
									<span className="service-table__cost">{service.NumberOfCredits}</span>
									{service.NumberOfCredits ? <span className="service-table__unit">{service.NumberOfCredits === '1' ? paymentMethods.CreditName : paymentMethods.CreditNamePlural}</span> : null}
								</td>
							</tr>
						)
					}) }
					</tbody>
				</table>
			</div>
		);
	};
}

const mapStateToProps = (state) => {
  const { catalogues } = state.credits;
  return { catalogues };
};

export default connect(mapStateToProps, { })(ServicePackItems);
