import React, { Component } from "react";
import { Modal } from "react-responsive-modal";

import "./modalstyle.css";
import "react-responsive-modal/styles.css";

import { getNestedValue } from '../../utils/collectionUtils';
import RenderPanelForm from "../Panels/RenderPanelForm";

export default function RenderModalForm(props) {
    const { open, modalScreen, segmentSpotliteAddress, viewLoading, viewData, applicationGUID } = props;
    const panelFormName = getNestedValue(viewData, 'Screen', 'Attributes', 'Label') || null;
    //console.log('&&&&&&& RenderModalForm: ', props)

    return (!viewLoading
        ?
        <Modal
            open={open}
            onClose={props.onClose}
            center
            closeOnOverlayClick={false}
            classNames={{ modal: "custommodalform" }}
            closeOnEsc={false}
        >
            <h2>{panelFormName}</h2>
            <RenderPanelForm
                modalScreen={modalScreen}
                segmentSpotliteAddress={segmentSpotliteAddress}
                closeModal={props.closeModal}
                viewData={props.viewData}
                applicationGUID={applicationGUID}
            />

        </Modal>
        :
        null
    );
}