import React, { useState, Component } from 'react';
import Button from "../../Reusable/Button";
import { Document, Page,pdfjs } from "react-pdf";
import XMLViewer from 'react-xml-viewer'

// PMD 30/01/24 React 18 upgrade component    
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const PanelComponentImage = (props) => {

    // PMD 30/01/24 React 18 upgrade component
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.js',
    //     import.meta.url,
    //   ).toString();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; 

    const {
        panelData,
        pdfHeight,
    } = props;
    
    const [scale, setScale] = useState(1); 
    const [pageNumber, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const changeScale = (scaleIncrement) => {
        let newScaleString = Number(scale + scaleIncrement).toFixed(1);
        let newScale = parseFloat((newScaleString), 1);
        setScale(newScale);
        props.sendDataToPanel(newScale);
    };

    // PDF Panel Controlling functions
    const onLoadSuccess = ({ numPages }) => {
        setPageCount(numPages)
        
        //Also set scale
        
        //this.setState({ numPages, scale: this.props.panelDataGrid.scale ? this.props.panelDataGrid.scale : 1 });
    }

    const generateData = (data) => {

        const newData = Object.keys(data).reduce((result, currentKey) => {
            if (typeof data[currentKey] === 'string' || data[currentKey] instanceof String || data[currentKey] === null) { 
                
                result.push(generateElement(currentKey, data[currentKey]));
            } else {
                const nested = generateData(data[currentKey]);
                result.push(...nested);
            }
            return result;
        }, []);
        return newData;
    };

    var index = 0;

    // PMD 09/01/23 Display JSON data in Documents panel
    const generateElement = (key, value) => {
        const isHTML = isHTMLDocument(value);
        const keyValue = isHTML ? null : key;

        index = index + 1;
        
        return (
            // <div key={key} style={{ border: "1px solid #ddd" }}>
                <tr key={index} style={{ border: "1px solid #ddd" }}>
                <td style={{ fontWeight: "bold", paddingRight: "10px" }}>{keyValue}</td>
                {isHTML ? generateHTML(value) : <td>{value}</td>}
                </tr>
            // </div>
        );
    };

    const isHTMLDocument = (value) => {
        const htmlDocument = new DOMParser().parseFromString(value, 'text/html');
        return Array.from(htmlDocument.body.childNodes).some(node => node.nodeType === 1);
    };

    const generateHTML = value => <td dangerouslySetInnerHTML={{__html: value}}></td>;
    
    if (!panelData) return null

    // let imageType = panelData.slice(-3);
    let imageType = panelData.json.split('.').pop();
    let displayImage = "";

    //console.log(imageType)

    switch (imageType) {

        case "doc":
        case "docx":
            // console.log(panelData)
            let doc = "https://view.officeapps.live.com/op/embed.aspx?src=" + panelData.json;

            displayImage = (
                <div
                className="imageDisplay"
                style={{
                    height: "100%",
                    width: "100%",
                }}>

                <iframe title="Word Document" src={doc} width='100%' height='100%' frameBorder='0' />

                </div>
            );
            break;
        case "jpg":
        case "jpeg":
        case "png":
            // console.log({panelData})
            displayImage = (
                <>
                <a style={{ fontSize: "16px" }} href={panelData.json}>
                    {" "}
                    Download File...
                </a>

                <div
                    className="imageDisplay"
                    style={{
                    height: "95%",
                    width: "95%",
                    // objectFit: "contain",
                    backgroundImage: `url(${panelData.json})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    }}>
                </div>
                </>
            );
            break;
        // case "txt":
        //   let blob = panelData;
        //   let reader = new FileReader();
        //   console.log(typeof panelData)
        //   reader.addEventListener("loaded", () => {
        //     console.log(reader.result)
        //   });
        //   reader.readAsText(blob)

        //   displayImage = (
        //     <div
        //       className="imageDisplay"
        //       style={{
        //         height: "100%",
        //         width: "100%",
        //         // objectFit: "contain",
        //         backgroundImage: ``,
        //         backgroundSize: "contain",
        //         backgroundPosition: "center",
        //         backgroundRepeat: "no-repeat",
        //       }}>
        //     </div>
        //   );

        //   break;
        case "pkpass":   //pkpass type
            displayImage = (
                <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                <h3>Unable to display Apple Passbook file types</h3>
                </div>
            )
        break
        case imageType.startsWith("com"):
        case "pdf":
            displayImage = (
                <div className="pdfWrapper">
                    <div
                        className="pdfImage"
                    >
                        <a style={{ fontSize: "16px" }} href={panelData.json}>
                            {" "}
                            Download File...
                        </a>
                    </div>
                    <nav>
                        <Button
                            label={"Previous"}
                            handleClick={() => setPage((c) => (c - 1))}
                            className={"button--secondary action"}
                            disabled={pageNumber <= 1}
                        />
                        <span style={{ marginRight: 5, marginLeft: 5, fontSize: "13px" }}>
                            Page{" "}
                            {pageNumber || (pageCount ? 1 : "--")}{" "}
                            of {pageCount || "--"}
                        </span>
                        <Button
                            label={"Next"}
                            handleClick={() => setPage((c) => (c + 1))}
                            className={"button--secondary action"}
                            disabled={pageNumber >= pageCount}
                        />
                        <span
                            // Place some space between pages and scale 
                            style={{ marginRight: 10, marginLeft: 10, fontSize: "13px" }}
                        />
                        <Button
                            label={"-"}
                            handleClick={() => changeScale(-0.1)}
                            className={"button--secondary action"}
                            disabled={scale <= 0.3}
                        />
                        <span
                            style={{ marginRight: 5, marginLeft: 5, fontSize: "13px" }}
                        >
                            Scale: {scale}
                        </span>

                        <Button
                            label={"+"}
                            handleClick={() => changeScale(0.1)}
                            className={"button--secondary action"}
                            disabled={scale >= 4.0}
                        />
                    </nav>
                    <Document
                        className="pdfDocument"
                        // file={panelData}
                        file={panelData.json}
                        onLoadSuccess={onLoadSuccess}
                    // loading
                    >
                        <Page
                            className="pdfPage"
                            pageNumber={pageNumber}
                            scale={scale}
                            // scale={displayScale}
                            // height={"500"}
                            height={pdfHeight}
                            // renderMode={"svg"}
                        />
                    </Document>
                </div>
            );
            // console.log(panelData)
            break;
        case "json":
            // PMD 09/01/23 Display JSON data in Document Panel
            let data = panelData.json.split('.').slice(0, -1).join('.')
            displayImage = (
                // <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                <table style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                <tbody>
                    {generateData(JSON.parse(data))}
                    {/* {data} */}
                </tbody>

                </table>
                // </div>
            )
            break
        case "xml":
            let xmldata = panelData.json.split('.').slice(0, -1).join('.')
            console.log(xmldata)
            displayImage = (
                <div>
                    <XMLViewer xml={xmldata} />
                </div>
            )
            break;
        case "mp4":
        case "webm":
            const videoType = `video/${imageType}`;
            displayImage = (
                <div>
                    <video 
                        style={{ 
                            "border": "2px solid #000",
                            "borderRadius": "8px",
                            "boxShadow": "0 0 10px rgba(0, 0, 0, 0.5)" 
                        }}
                        width="100%" 
                        // height="100%" 
                        controls
                    >
                        <source src={panelData.json} type={videoType} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
            break;
        case "unknown":
            let unknownData = panelData.json.split('.').slice(0, -1).join('.')
            let displayText = unknownData.trim() === "" ? "No additional information held for selected document" : `Information held for the selected document - ${unknownData}`
            // displayImage = (
            //     <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
            //     <h3>{displayText}</h3>
            //     </div>
            // )
            displayImage = (
                <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                </div>
            )
            break;
        default:
            console.log('panelData:', panelData);
            displayImage = (
                // <div style={{ fontFamily: "ProximaNova, Serif" }}>
                <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                {/* { <h3>Documents with an extension of .<strong>{imageType}</strong> can not currently be displayed. Please contact support</h3> } */}
                </div>
            )
            break;
    }
    return (
        // styling for form panel

        <div
        className={"form-wrapper"}
        style={{
            marginBottom: 25,
            paddingBottom: 10,
            backgroundColor: "#FFFFFF",
            overflowY: "auto",
            height: "100%",
        }}
        >
        {displayImage}

        {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc' 
        width='1366px' height='623px' frameborder='0'>
            This is an embedded 
            <a target='_blank' href='http://office.com'>Microsoft Office</a>
            document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
            </iframe> */}

        {/* <iframe src="https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"></iframe> */}
        {/* <iframe src="https://docs.google.com/gview?url=http://remote.url.tld/./output3.docx.doc&embedded=true"></iframe> */}

        </div>
    );


};

export default PanelComponentImage;