import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sessionSet, setSessionWorking, loadSessionDetails, setMobileEmbedded } from '../../actions';
import DashboardPanels from "../Panels/DashboardPanel/DashboardPanels"



class MobileScreen extends Component {

  constructor(props) {
    super(props);

    this.query = this.useQuery();
  }

  useQuery() {
    return new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    this.props.setMobileEmbedded(true);
    this.props.setSessionWorking(true);
    this.props.sessionSet(this.query.get("sessionId"));
  }

  componentDidUpdate(prevProps) {

    if (this.props.sessionId && prevProps.sessionId !== this.props.sessionId) {
      // console.log("sessionId set to = " + this.props.sessionId);
      this.props.loadSessionDetails({});
    }
  }

  renderDashboardPanels() {

    // PMD 09/12/21 Remove sessionDetails
    // if (!this.props.sessionId || !this.props.sessionDetails) return;
    if (!this.props.sessionId || !this.props.dataConfig) return;

    return (
      <DashboardPanels viewGUID={this.query.get("viewGUID")} applicationInstanceGUID={this.query.get("applicationInstanceGUID")} />
    )
  }

  renderWorking = () => {

    if (!this.props.working) return;

    return (
      <div>Please Wait...</div>
    )
  }

  renderError = () => {

    if (!this.props.error) return;

    return (
      <div>{this.props.error}</div>
    )
  }


  render() {
    return (

      <div style={{ margin: "0 50px 0 0px" }}>

        {this.renderWorking()}

        {this.renderError()}

        {this.renderDashboardPanels()}
      </div>
    )
  }
}


const mapStateToProps = ({ session }) => {
  const { sessionId, /* sessionDetails, */ working, error, dataConfig } = session;
  return { sessionId, /* sessionDetails, */ working, error, dataConfig };
};

export default connect(mapStateToProps, { loadSessionDetails, sessionSet, setSessionWorking, setMobileEmbedded })(MobileScreen);
