import React, { Component } from "react";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import { Redirect } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import {
  setSessionWorking,
  redirectComplete,
  setSessionError,
  redirect,
  login,
  loadSessionDetails,
  loadDataConfig,
  loadSettings,
  // fetchThemeData,
} from "../../actions";
import config from "../../config";
import { postRequest } from "../../utils/Common.js";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { errorHandler } from "../../utils/errorHandler";
import { SignInButton } from "./SignInButton";

const env = process.env.NODE_ENV || "development";

class Login extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    // console.log(window.location)

    this.state = {
      forgotInput: "",
      sourceUrl: window.location.hostname,
      // portalToLoad: "",
      portalType: ""
    };

    this.onLoginTriggered = this.onLoginTriggered.bind(this);

  }

  componentDidMount() {

    if (this.props.sessionId !== "" && this.props.dataConfig) {
      this.props.redirect("/");
    } else if (this.props.sessionId && !this.props.dataConfig) {
      this.callAPIs();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { cookies } = this.props;

    if (prevProps.sessionId !== this.props.sessionId) {
      if (this.props.sessionId) {
        cookies.set("sessionId", this.props.sessionId, { path: "/" });
        this.callAPIs();
      } else {
        cookies.remove("sessionId", { path: "/" });
      }
    }
  }

  renderRedirectChecker() {
    // if (this.props.dataConfig && this.props.sessionDetails) {
    if (this.props.dataConfig && this.props.sessionId) {

      //We can't update here becasue we are rendering
      // this.props.redirectComplete();
      let redirectUrl = '/';

      if (typeof this.props.location.state !== 'undefined') {
        redirectUrl = this.props.location.state.from.pathname;
      }

      return <Redirect to={redirectUrl} />;
    }
  }

  callAPIs() {

    let redirectUrl = '/';
    if (typeof this.props.location.state !== 'undefined') {
      redirectUrl = this.props.location.state.from.pathname;
    }

    this.props.loadDataConfig();
    this.props.loadSessionDetails({ redirectUrl });
    // PMD 14/12/21 Load Settings no longer required
    // this.props.loadSettings();
  }

  onLoginTriggered(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    const username = data.get("username");
    const password = data.get("password");
    //const redirectUrl = this.props.location.state.from.pathname

    this.props.setSessionError("");

    this.props.setSessionWorking(true);
    // console.log("LOGIN BUTTON PRESSED");
    this.props.login({ username, password });
  }

  loginButtonText = () => {
    if (this.props.working) {
      return "Please Wait";
    } else {
      return "Login";
    }
  };

  handleForgottenChange = (e) => {
    this.setState({ forgotInput: e.target.value });
  };

  onForgetTriggered = () => {
    const msg = `Please enter the email address you use to access the system`;

    const title = "Password Reminder";

    const forgotDialog = ({ onClose }) => {
      const handleClicked = () => {
        const searchURI = `${config[env].passwordResetURL}?email=${this.state.forgotInput}`;
        const credentials = "omit";

        postRequest(searchURI, credentials)
          //.then(apiParse)
          .then((results) => {
            if (results.Result.toLowerCase() !== "ok") {
              const title = "Error sending password reset";
              const msg = results.ErrorMsg + ". Please contact support.";
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="customAlert dialogBack">
                      <h3>{title}</h3>
                      <p>{msg}</p>
                      <button className="button action" onClick={onClose}>
                        OK
                      </button>
                    </div>
                  );
                },
              });
            }
            // Do error handling here
          })
          .catch((err) => {
            // PMD 20/05/22 Generic error handler
            errorHandler(err)
            err.message || err
          });
        // };
        // onClose();
      };
      return (
        <div className="customAlert dialogBack">
          <h3>{title}</h3>
          <p>{msg}</p>
          <input
            className="form__login"
            id="emailaddress"
            type="email"
            autoCapitalize="false"
            name="emailAddress"
            placeholder="Username"
            onChange={this.handleForgottenChange}
          />

          {/* <button onClick={handleClickedNo}>No</button> */}
          <div>
            <button
              className="button action"
              onClick={() => {
                handleClicked();
                onClose();
              }}
            >
              Send Request
            </button>
          </div>
        </div>
      );
    };

    confirmAlert({
      customUI: forgotDialog,
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  renderLoginForm = () => {
    const { sessionId, portalDetails } = this.props
    // if (this.props.sessionId) return null;
    // console.log(this.props)
    if (sessionId) return null;
    let passwordPrompt;
    passwordPrompt = portalDetails.portalType === 'agent' ? 'Password' : 'Invite Code';

    return (
      <div>
        {/* PMD 25/08/22 Use B2C Login */}
        {/* <form onSubmit={this.onLoginTriggered} className="form" style={{ marginTop: "10px" }}>
          <fieldset className="form__fieldset__form">
            <input
              className="form__login"
              id="username"
              type="email"
              autoCapitalize="false"
              name="username"
              autoComplete="username"
              placeholder="Username"
            />
          </fieldset>
          <fieldset className="form__fieldset__form">
            <input className="form__login" id="password" name="password" type="password" autoComplete="current-password" placeholder={passwordPrompt} />
          </fieldset>
          <fieldset className="form__fieldset__form" style={{ marginTop: "4rem" }}>
            <input
              style={{ margin: "auto" }}
              type="submit"
              value={this.loginButtonText()}
              className="button"
              disabled={this.props.working}
            />
          </fieldset>
        </form> */}
        {/* PMD 05/08/22 B2C Implementation */}
        <div style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}>
          <SignInButton b2cSignOn={this.b2cSignOn} />
        </div>
        {/* PMD 25/08/22 Use B2C Login */}
        {/* <button className="linkbutton" onClick={this.onForgetTriggered} style={{ marginTop: "10px" }}>
          Forgot Password?
        </button> */}
      </div>
    );
  };

  b2cSignOn = (sessionData) => {
    // console.log(sessionData)
    this.props.setSessionError("");
    this.props.setSessionWorking(true);

    this.props.login({ username: "", password: "", sessionDetails: sessionData })
  }

  renderDetailsUpdate = () => {
    // PMD 09/12/21 remove sessionDetails
    // if (this.props.sessionId && !this.props.sessionDetails) {
    if (this.props.sessionId && !this.props.dataConfig) {
      return <div className="centerTxt">Loading Settings, please wait.</div>;
    }
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  render() {
    // let logoImage = this.props.portalDetails.logo
    const { logo, logoWidth, logoHeight, loginLeft } = this.props.portalDetails
    // console.log(this.props.portalDetails)
    let loginLeftImage = loginLeft !== "/assets/img/undefined" ? `url(${loginLeft})` : "url('/assets/img/bg.svg')"

    return (

      <div className="login">
        {/* <div className="loginLeft" style={{backgroundImage: "url('/assets/img/bg.svg')"}}></div> */}
        <div className="loginLeft" style={{ backgroundImage: loginLeftImage }}></div>

        <div className="loginRight" >
          <div className="contentHolder" >
            {/* <h1 className="centerTxt">CLIENT PORTAL</h1> */}
            {/* <img src={logoImage} width="300px" alt="company logo"></img> */}
            <div className="image">
              <img src={logo} width={logoWidth} height={logoHeight} alt="company logo" />
            </div>
            <h1 className="centerTxt">{this.props.portalDetails.portalDisplayName}</h1>

            <p className="centerTxt" style={{ marginTop: 10, marginBottom: 25 }}>
              Welcome back! Please login to your account.
            </p>

            {this.renderLoginForm()}

            {this.renderDetailsUpdate()}

            {this.renderError()}
          </div>
        </div>

        {this.renderRedirectChecker()}

      </div >
    );
  }
}

const mapStateToProps = ({ session }) => {
  const { sessionId, sessionDetails, dataConfig, error, working, portalDetails } = session;
  // const { themeData } = themeConfig;
  return { sessionId, sessionDetails, dataConfig, error, working, portalDetails, /*themeData */ };
};

export default connect(mapStateToProps, {
  setSessionWorking,
  setSessionError,
  redirect,
  redirectComplete,
  login,
  loadSessionDetails,
  loadDataConfig,
  loadSettings,
})(withCookies(Login));
