import React from 'react';
import PanelDocumentList from "../PanelDocumentList";

const PanelComponentList = (props) => {

    const {
        panelGuid,
    } = props;

    return (
        // styling for List panel
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            paddingBottom: 10,
            // background: "yellow"
          }}
        >
          <PanelDocumentList panelGuid={panelGuid} />
        </div>
      );

};

export default PanelComponentList;