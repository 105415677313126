
// import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router'

import SessionReducer from './SessionReducer';
import CertificatesReducer from './CertificatesReducer';
import CreditsReducer from './CreditsReducer';
import TransactionsReducer from './TransactionsReducer';
import SettingsReducer from './SettingsReducer';
import TeamReducer from './TeamReducer';
import DashboardReducer from './DashboardReducer';
import UserReducer from './UserReducer';
import DepartmentReducer from './DepartmentReducer';
import ApplicationReducer from './applicationsReducer';
import ErrorReducer from './errorReducer'
import ThemeConfigReducer from './themeConfigReducer';
import GetDataViewReducer from './GetDataViewReducer';
import PostDataViewReducer from './PostDataViewReducer';
import UploadFilesReducer from './UploadFilesReducer';
import BookmarkReducer from './bookmarkReducer';
import GetCatalogueReducer from './GetCatalogueReducer';

const reducers = {
  session: SessionReducer,
  certificates: CertificatesReducer,
  credits: CreditsReducer,
  transactions: TransactionsReducer,
  settings: SettingsReducer,
  team: TeamReducer,
  dashboard: DashboardReducer,
  users: UserReducer,
  depts: DepartmentReducer,
  applications: ApplicationReducer,
  errors: ErrorReducer,
  themeConfig: ThemeConfigReducer,
  getView: GetDataViewReducer,
  postView: PostDataViewReducer,
  fileUpload: UploadFilesReducer,
  bookmark: BookmarkReducer,
  catalogue: GetCatalogueReducer
};
export default reducers;

/*
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  session: SessionReducer,
  certificates: CertificatesReducer,
  credits: CreditsReducer,
  transactions: TransactionsReducer,
  settings: SettingsReducer,
  team: TeamReducer,
  dashboard: DashboardReducer,
  users: UserReducer,
  depts: DepartmentReducer,
  applications: ApplicationReducer,
  errors: ErrorReducer,
  themeConfig: ThemeConfigReducer,
  getView: GetDataViewReducer,
  postView: PostDataViewReducer,
  fileUpload: UploadFilesReducer,
  bookmark: BookmarkReducer,
  catalogue: GetCatalogueReducer
});

export default createRootReducer;
*/

/*
export default combineReducers({
  session: SessionReducer,
  certificates: CertificatesReducer,
  credits: CreditsReducer,
  transactions: TransactionsReducer,
  settings: SettingsReducer,
  team: TeamReducer,
  dashboard: DashboardReducer,
  users: UserReducer,
  depts: DepartmentReducer,
  applications: ApplicationReducer,
  errors: ErrorReducer,
  themeConfig: ThemeConfigReducer,
  getView: GetDataViewReducer,
  postView: PostDataViewReducer,
  fileUpload: UploadFilesReducer
});
*/
