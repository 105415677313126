import { 
  SELECT_CREDITS, 
  SET_CREDITS_ERROR, 
  RESET_CREDITS_ERROR,
  RESET_CREDITS,
  INCREASE_CREDITS_STEP,
  DECREASE_CREDITS_STEP,
  LOAD_CATALOGUE_CREDITS,
  LOAD_CORRESPONDENCE_ADDRESS
 } from '../actions/types';

 // step 1 = select credits
 // step 2 = payment gateway provider form
 // step 3 = Success / split between catalogues (if required)

const INITIAL_STATE = { 
  step: 1,
  correspondenceAddress: {
    correspondenceAddressLine1: '',
    correspondenceAddressLine2: '',
    correspondenceAddressCity: '',
    correspondenceAddressState: '',
    correspondenceAddressPostalCode: ''
  },
  numberOfCredits: null,
  error: '',
  catalogues: []
};

const maxStep = 3;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_CREDITS:
      return { ...state, numberOfCredits: action.payload};
    case SET_CREDITS_ERROR:
      return { ...state, error: action.payload };
    case RESET_CREDITS_ERROR:
      return { ...state, error: '' };
    case INCREASE_CREDITS_STEP:
      const increaseStep = state.step + 1;
      return { ...state, step: (increaseStep > maxStep) ? maxStep : increaseStep, error: '' };
    case DECREASE_CREDITS_STEP:
      const decreaseStep = state.step - 1;
      return { ...state, step: (decreaseStep < 1) ? 1 : decreaseStep, error: '' };
    case RESET_CREDITS:
      return { ...INITIAL_STATE };
    case LOAD_CATALOGUE_CREDITS:
      return { ...state, catalogues: action.payload };
    case LOAD_CORRESPONDENCE_ADDRESS:
      return { ...state, correspondenceAddress: action.payload };
    default:
      return state;
  }
};
