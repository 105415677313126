import deepCopy from '../utils/deepCopy';
import * as types from '../actions/types';
  
const INITIAL_STATE = {
    postViewData: {},
    postViewLoading: true,
    postViewError: null
};
  
export default (prevState = INITIAL_STATE, action = null) => {
    if (!action) {
        return prevState;
    }
    const newState = deepCopy(prevState);
    
    switch (action.type) {
        case types.POST_DATAVIEW_REQUEST:
            return {
                ...newState,
                postViewData: {},
                postViewLoading: true,
                postViewError: null
            };
        case types.POST_DATAVIEW_SUCCESS:
            return {
                ...newState,
                postViewData: action.payload,
                postViewLoading: false,
                postViewError: null
            };
        case types.POST_DATAVIEW_ERROR:
            return {
                ...newState,
                postViewLoading: false,
                postViewError: action.payload
            };
        default:
            return prevState;
    }
};