import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import _ from "lodash";
import {
  loadCatalogueCredits,
  adjustCatalogueCredits,
  setCreditsError,
  resetCreditsError,
  creditsRedirect,
  redirectComplete,
  updateApplicationLayout,
} from "../../actions";
// import * as SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";
import SpotliteClientCatalogue from "../../content/SpotliteClientCatalogue.json";
// import HeaderSimple from "../Header/HeaderSimple";

class CreditsSplit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unallocated: 0,
      unallocatedDisplay: 0,
      allocated: {},
    };
  }

  componentDidMount() {
    this.props.updateApplicationLayout({ componentType: "", label: 'Split Credits', isVisible: false });
    this.props.loadCatalogueCredits();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.catalogues !== this.props.catalogues) {
      this.setUnallocatedState();
    }

    if (prevProps.sessionId !== this.props.sessionId) {
      this.props.loadCatalogueCredits();
    }
  }

  renderSuccessTitle = () => {
    if (this.props.step === 3) {
      // we are in step 3 of the credits process
      // therefore show a successfull message
      return (
        <div className="row_space_around">
          <p className="textContentLarge">Credit purchase successful</p>
        </div>
      );
    }
  };

  setUnallocatedState = () => {
    // cant use === as code is a string
    var unallocatedRecord = _.findIndex(this.props.catalogues, function (o) {
      // eslint-disable-next-line
      return o.catalogueCode == 999;
    });

    let unallocatedCount = 0;
    if (unallocatedRecord !== -1) {
      unallocatedCount = this.props.catalogues[unallocatedRecord].creditBalance;
    }

    this.setState({
      unallocated: unallocatedCount,
      unallocatedDisplay: unallocatedCount,
      allocated: {},
    });
  };

  renderUnallocatedData = () => {
    return (
      <div className="creditSplitContainer">
        <p>You have {this.state.unallocatedDisplay} unallocated Credits</p>
      </div>
    );
  };

  getCurrentBalance = (CatalogueCode) => {
    const entry = _.find(this.props.catalogues, { catalogueCode: CatalogueCode });

    return entry ? entry.creditBalance : 0;
  };

  renderCurrentCatalogues = () => {
    //const cataloguesJSON = SpotliteClientCatalogue.Catalogue;

    /*
    return cataloguesJSON.map(function(item, i){

      return (

        <div key={i} className="box">
          <div>
            <p className="boxBold padded">Catalogue:</p>
            <p className="boxNormal padded">{ item.CatalogueName } </p>

            <p className="boxBold padded">Credit Balance:</p>
            <p className="boxNormal padded">{this.getCurrentBalance(item.CatalogueCode)}</p>

            <fieldset className="form__fieldset__box">
              <input 
                name={"catalogue" + item.CatalogueCode}
                type="number" 
                className="form__input__small"
                placeholder="Enter Additional Allocation"
                value={ this.state.allocated['catalogue' + item.CatalogueCode] } 
                onChange={(evt) => { this.handleCatalogueAllocationChange(evt, item.CatalogueCode) }} 
              />
            </fieldset>
          </div>
        </div>
      )

    }, this);

    */

    if (this.props.catalogues) {

      // console.log(this.props.catalogues)

      // const cataloguesJSON = SpotliteClientCatalogue.Catalogue;
      const cataloguesJSON = this.props.catalogueData.Catalogue;

      // console.log(cataloguesJSON)

      const catalogueNamesArray = cataloguesJSON.map(function (item, i) {
        return { CatalogueCode: item.CatalogueCode, CatalogueName: item.CatalogueName };
      }, this);

      // console.log(catalogueNamesArray)
      return this.props.catalogues.map(function (item, i) {
        // cant use === as code is a string
        const catalogueNameIndex = _.findIndex(catalogueNamesArray, function (o) {
          // eslint-disable-next-line
          return o.CatalogueCode == item.catalogueCode;
        });

        // set the catalogue name from the JSON if we have one
        let catalogueName = "";
        if (catalogueNameIndex !== -1) {
          catalogueName = catalogueNamesArray[catalogueNameIndex].CatalogueName;
        }

        // cant use === as code is a string
        // eslint-disable-next-line
        if (item.catalogueCode != 999) {
          // dont display unallocated credits here
          return (
            <div key={i} className="card">
              <div className="card_credit_container">

                <p className="cardBold">Catalogue:</p>
                <p className="cardLinePadded">{catalogueName} </p>

                <p className="cardBold cardLinePadded">Credit Balance:</p>
                <p className="cardLinePadded">{item.creditBalance}</p>

                {/* <p className="boxBold padded">Catalogue:</p>
                <p className="boxNormal padded">{catalogueName} </p>

                <p className="boxBold padded">Credit Balance:</p>
                <p className="boxNormal padded">{item.creditBalance}</p> */}

                <p className="cardBold cardLinePadded">Credits Allocated:</p>
                <fieldset className="form__fieldset__box">
                  <input
                    name={"catalogue" + item.catalogueCode}
                    type="number"
                    className="form__input__small"
                    placeholder="Enter Additional Allocation"
                    value={this.state.allocated["catalogue" + item.catalogueCode]}
                    onChange={(evt) => {
                      this.handleCatalogueAllocationChange(evt, item.catalogueCode);
                    }}
                  />
                </fieldset>
              </div>
            </div>
          );


          return (
            <div key={i} className="box servicePack">
              <div>
                <p className="boxBold padded">Catalogue:</p>
                <p className="boxNormal padded">{catalogueName} </p>

                <p className="boxBold padded">Credit Balance:</p>
                <p className="boxNormal padded">{item.creditBalance}</p>

                <fieldset className="form__fieldset__box">
                  <input
                    name={"catalogue" + item.catalogueCode}
                    type="number"
                    className="form__input__small"
                    placeholder="Enter Additional Allocation"
                    value={this.state.allocated["catalogue" + item.catalogueCode]}
                    onChange={(evt) => {
                      this.handleCatalogueAllocationChange(evt, item.catalogueCode);
                    }}
                  />
                </fieldset>
              </div>
            </div>
          );
        } else {
          return null;
        }
      }, this);
    }
  };

  handleCatalogueAllocationChange = (evt, CatalogueCode) => {
    const newAllocatedObject = { ...this.state.allocated, ["catalogue" + CatalogueCode]: evt.target.value };

    let allocCount = 0;

    _.forEach(newAllocatedObject, function (item, i) {
      allocCount += Number(item);
    });

    if (evt.target.value < 0) {
      this.props.setCreditsError("Negative allocations are not permited");
    } else if (this.state.unallocated - allocCount < 0) {
      this.props.setCreditsError("Not enough unallocated credits to continue");
    } else {
      this.props.resetCreditsError();
    }

    this.setState({
      allocated: newAllocatedObject,
      unallocatedDisplay: this.state.unallocated - allocCount,
    });
  };

  renderError = () => {
    return <div className="error row_space_around">{this.props.error}</div>;
  };

  validateSplit = () => {
    // validate that they way they have split is acceptable
    let allocCount = 0;
    let allocNegativeError = false;
    let isChanged = false;

    _.forEach(this.state.allocated, function (item, i) {
      allocCount += Number(item);
      if (Number(item) < 0) {
        allocNegativeError = true;
      }
      if (Number(item) !== 0) {
        isChanged = true;
      }
    });

    const unalloc = this.state.unallocated - allocCount;

    if (allocNegativeError) {
      this.props.setCreditsError("Negative allocations are not permited");
    } else if (unalloc < 0) {
      this.props.setCreditsError("Not enough unallocated credits to continue");
    } else if (!isChanged) {
      // nothng changed, just redirect
      this.props.creditsRedirect("/account");
    } else {
      this.props.resetCreditsError();
      // send data to the server
      let finalAllocations = [];
      _.forEach(this.state.allocated, function (item, i) {
        // PMD 18/10/22 CatalogueCode is a string
        // finalAllocations.push({ catalogueCode: Number(i.substr(9)), balanceAdjustment: Number(item) });
        finalAllocations.push({ catalogueCode: i.substring(9), balanceAdjustment: Number(item) });
      });
      // PMD 18/10/22 CatalogueCode is a string
      // finalAllocations.push({ catalogueCode: 999, balanceAdjustment: -allocCount });
      finalAllocations.push({ catalogueCode: '999', balanceAdjustment: -allocCount });

      this.props.adjustCatalogueCredits(finalAllocations);
    }
  };

  renderRedirectChecker() {
    // console.log(this.props.redirectUrl)
    if (this.props.redirectUrl && this.props.redirectUrl !== '/') {
      this.props.redirectComplete();

      return <Redirect to={this.props.redirectUrl} />;
    }
  }

  handleBackButton = () => {
    this.props.creditsRedirect("/account");
  };

  render() {
    return (
      <div>
        <div className="contentHolder indent">
          {this.renderSuccessTitle()}

          {this.renderUnallocatedData()}
        </div>

        <div className="card_grid">{this.renderCurrentCatalogues()}</div>

        <div className="contentHolder indent">
          {this.renderError()}

          <input
            type="button"
            className="button"
            value="Finish"
            onClick={() => {
              this.validateSplit();
            }}
          />

          {/* Don't redirect for now */}
          {this.renderRedirectChecker()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId, redirectUrl } = state.session;
  const { catalogues, step, error } = state.credits;
  const { catalogueData } = state.catalogue;
  return { sessionId, redirectUrl, catalogues, step, error, catalogueData };
};

export default connect(mapStateToProps, {
  creditsRedirect,
  redirectComplete,
  setCreditsError,
  resetCreditsError,
  loadCatalogueCredits,
  adjustCatalogueCredits,
  updateApplicationLayout,
})(CreditsSplit);
