import React from 'react';
import NewDataGridController from "../../Grid/NewDataGridController";
import { isEmpty } from "lodash";

const PanelComponentGrid = (props) => {

    const {
        panelContent,
        applicationGUID,
        panelTitle,
    } = props;

    const gridLayoutChanged = (gridLayout) => {
        props.gridLayoutChanged(gridLayout)
    }

    // TODO: Error handling

    // PMD 17/05/22 GridLayout location changed
    // let gridLayout = panelContent.Settings.properties.Layout.GridLayout


    // let gridLayout = {
    //     "RowHeight": 30,
    //     "ColumnWidth": 100,
    //     "FrozenLeftColumns": [],
    //     "FrozenHeaderRows": [],
    //     "Layout": null,
    //     "Style": null,
    //     "foreColour": "#000000",
    //     "backColour": null,
    //     "fontFamily": null,
    //     "fontStyle": "0",
    //     "fontVariant": null,
    //     "fontWeight": null,
    //     "fontSize": null,
    //     "borderRadius": 0,
    //     "textAlign": "",
    //     "lineHeight": ""
    // };

    // if (panelContent.Attributes.GridLayout !== null) {
    //     gridLayout = panelContent.Attributes.GridLayout;
    // }

    // PMD 20/05/22 GridLayout location changed
    let gridLayout = panelContent.Attributes.GridLayout
    let label = panelContent.Attributes.Label

    // Find the RetrievedData and pass it into the grid as the rows data
    // let rows = panelContent.Calls[0].RetrievedData
    let retrievedDataCall = panelContent.Calls.find(call => call.RetrievedData)
    let rows = retrievedDataCall.RetrievedData;

    // Extract Grid CallAction 
    let retrievedCallAction = panelContent.Calls.find(call => call.CallType === "CallAction")
    // console.log(retrievedCallAction)
    // let gridProperties = retrievedCallAction.Properties
    let gridProperties = retrievedCallAction;
    // PMD 17/05/22 GridLayout location changed
    // PMD 20/05/22 GridLayout location changed
    // let gridLayout = gridProperties.Properties.GridLayout

    // Retrieve the callevent for the panel and pass the guid through
    let retrievedCallEvent = panelContent.Calls.find(call => call.CallType === "CallEvent")
    let callEventGUID = retrievedCallEvent ? retrievedCallEvent.CallingObjectGUID : ""

    // Retrieve the callData for the panel and pass guid through
    let retrieveCallData = panelContent.Calls.find(call => call.CallType === "CallData")
    let callDataGUID = retrieveCallData ? retrieveCallData.CallingObjectGUID : ""

    // determine context
    // if callData exists, look for context, if it doesn't exist check callEvent
    let context = "";

    if(retrieveCallData !== undefined && retrieveCallData.Context !== undefined) {
        context = retrieveCallData.Context;
        //set context to "" to aid development
        context = ""

    } else if(retrievedCallEvent !== undefined && retrievedCallEvent.Context !== undefined) {
        context = retrievedCallEvent.Context;
    }

    // If callEventGUID is empty set it to applicationGUID, use callEVentGUID in NewDataGridController if applicationGUID from row can be retreived 
    if(isEmpty(callEventGUID)) {
        callEventGUID = applicationGUID;
    }
        
    return (
        <>
            <NewDataGridController 
                rowData={rows} 
                panelTitle={panelTitle}
                gridLayout={gridLayout} 
                gridProperties={gridProperties} 
                callDataGUID={callDataGUID}
                callEventGUID={callEventGUID}
                ComponentGUID={panelContent.Attributes.ComponentGUID}
                context={context} 
                gridLayoutChanged={gridLayoutChanged}
                //rowSelected={rowSelected}
                applySearchCriteria={false}
            />
        </>
    );

};

export default PanelComponentGrid;