import React, { Component } from "react";
import { connect } from "react-redux";
import PanelForm from "../Panels/PanelForm";
// import fakescreen from "./render_modal_from_grid_in_panel_211014.json"
// import fakedata from "./task_211213.json"
// import fakedata from "./dummyFileUpload.json"

import _ from "lodash";

import config from "../../config";
import { postRequest, apiParseScreen } from "../../utils/Common.js";

import {
  newAgentGUID, newApplicantGUID, newWorkQueueGUID, editWorkQueueGUID, newTeamGUID,
  fileUploadGUID, taskListPopupGUID, usernameSearchGUID,
} from "../../utils/Constants";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./modalstyle.css";

// import dummyJson from "./dummyJson1.json";
// import { confirmAlert } from "react-confirm-alert";
import { Redirect } from "react-router";
import { getDataViewData } from "../../actions";
import { errorHandler } from "../../utils/errorHandler";
import deepCopy from "../../utils/deepCopy";


// Constants
const env = process.env.NODE_ENV || "development";

let panelContent = "";

class ModalForm extends Component {

  constructor() {
    super();

    this.state = {
      isLoading: true,
      screenDisplayed: "",
      screenName: "",
      redirect: false,
      ignoreScreenType: false
    };
  }

  componentDidMount() {

    const { screenType, spotliteAddress } = this.props;


    // PMD 25/11/21 Added spotliteAddress to call
    // this.RetrieveDataFromAPI(screenType);

    this.RetrieveDataFromAPI(screenType, spotliteAddress);

  }

  // PMD 25/11/21 Added spotliteAddress to call
  // RetrieveDataFromAPI = (displayGUID) => {
  RetrieveDataFromAPI = async (displayGUID, spotliteAddress = null) => {
    const URI = `${config[env].URL}/get-data-view`;
    const credentials = "omit";
    const { dataConfig, selectedApplicationTask, ApplicationTasks } = this.props

    // PMD 15/12/21 Check whether we should use the teamGUID or the selected Application GUID
    let appInstanceGUID = ''
    switch (displayGUID) {
      case taskListPopupGUID:
        // PMD 23/12/21 Handle case where the record is an applicant record
        // appInstanceGUID = ApplicationTasks[selectedApplicationTask].applicationGUID;
        if (this.props.portalDetails.portalType === 'applicant') {
          appInstanceGUID = dataConfig.allTeams.currentTeamDetails.teamGUID;
        } else {
          appInstanceGUID = ApplicationTasks[selectedApplicationTask].applicationGUID;
        }
        break;
      default:
        // appInstanceGUID = this.props.dataConfig.allTeams.currentTeamDetails.teamGUID;
        appInstanceGUID = dataConfig.allTeams.currentTeamDetails.teamGUID;
        break;
    }


    let options = {
      sessionId: this.props.sessionId,
      ViewGUID: displayGUID,
      // ApplicationInstanceGUID: this.props.dataConfig.allTeams.currentTeamDetails.teamGUID,
      ApplicationInstanceGUID: appInstanceGUID,
      SpotliteAddress: spotliteAddress
    };
    // console.log('ModalForm - get-data-view:', options);

    postRequest(URI, options, credentials)
      .then(apiParseScreen)
      .then((data) => {
        // console.log(data)
        // if (data.ViewResponse.PropertyRef !== "200" && data.Screen.Panel[0] && data.Screen.RetrievedData[0]) {
        if (data.ViewResponse.PropertyRef !== "200" || data.Screen.RetrievedData[0] === undefined || data.Screen.Panel[0] === undefined) {
          // throw new Error(`Form information for VG ${displayGUID} and AIG ${this.props.dataConfig.allTeams.currentTeamDetails.teamGUID} not correctly retrieved`);
          // console.log('data:', data);
          throw new Error(`Form information for VG ${displayGUID} and AIG ${dataConfig.allTeams.currentTeamDetails.teamGUID} not correctly retrieved`);
        }

        this.initializeScreen(displayGUID, data);

        /*
        this.buildPanels(data);
        // this.buildPanels(fakedata);
    
        // console.log(this.props.screenType)
    
        let screenName = ''
        switch (this.props.screenType) {
          case newApplicantGUID:
            screenName = "newApplicant";
            break;
          case newAgentGUID:
            screenName = "newAgent";
            break;
          case newWorkQueueGUID:
            screenName = "newWorkqueue";
            break;
          case editWorkQueueGUID:
            screenName = "editWorkqueue";
            break;
          case newTeamGUID:
            screenName = "newTeam";
            break;
          case fileUploadGUID:
            screenName = "fileUpload";
            break;
          case taskListPopupGUID:
            screenName = "tasklistDetails";
            break;
          case usernameSearchGUID:
            screenName = "usernameSearch";
            console.log(`${this.props.screenType} - ${screenName}`);
            break;
          default:
            break;
        }
    
        this.setState({
          isLoading: false,
          screenDisplayed: this.props.screenType,
          screenName: screenName,
        });
        */
      })
      .catch((err) => {
        // PMD 20/05/22 Generic error handler
        // console.log(err)
        errorHandler(err)

        // Error handling not working
        //Session doesnt exist

        //Might need to directly call logout
        // confirmAlert({
        //   title: "Error Loading Form",
        //   message: `Please contact support. Unable to Load Configuration Data. Error message - ${err.message}`,
        //   buttons: [
        //     {
        //       label: "OK",
        //       onClick: () => {
        //         this.setState({ redirect: true });
        //       },
        //     },
        //   ],
        // });

        // console.log(err);
        // console.log(err.message);
        // TODO: Ask Martyn - handle errors
        this.setState({
          loading: false,
          submittedError: "Unable to load config",
        });
      });
  };

  initializeScreen = (displayGUID, data) => {
    this.buildPanels(data);
    // this.buildPanels(fakedata);
    // console.log('initializeScreen: displayGUID', displayGUID);
    let screenName = ''
    switch (displayGUID) {
      case newApplicantGUID:
        screenName = "newApplicant";
        // console.log(`initializeScreen: ${displayGUID} - ${screenName}`);
        break;
      case newAgentGUID:
        screenName = "newAgent";
        // console.log(`initializeScreen: ${displayGUID} - ${screenName}`);
        break;
      case newWorkQueueGUID:
        screenName = "newWorkqueue";
        break;
      case editWorkQueueGUID:
        screenName = "editWorkqueue";
        break;
      case newTeamGUID:
        screenName = "newTeam";
        break;
      case fileUploadGUID:
        screenName = "fileUpload";
        break;
      case taskListPopupGUID:
        screenName = "tasklistDetails";
        break;
      case usernameSearchGUID:
        screenName = "usernameSearch";
        // console.log(`initializeScreen: ${displayGUID} - ${screenName}`);
        break;
      default:
        break;
    };

    this.setState({
      isLoading: false,
      screenDisplayed: displayGUID,
      screenName: screenName,
    });
  };

  buildPanels = (data) => {

    // PMD 12/10/21 Take a copy of the object to avoid mutation
    const copiedData = _.cloneDeep(data)

    let panelArr = copiedData.Screen.Panel[0];
    let activePanel = Object.values(panelArr)[0];
    let panelCall = activePanel.Calls[0];

    // PMD 12/10/21 Modal forms only have a single panel so capture the RetrievedData for the screen
    let retrievedData = Object.values(copiedData.Screen.RetrievedData[0])[0];

    panelCall.RetrievedData = retrievedData

    panelContent =
      _.isUndefined(panelCall.RetrievedData)
        || _.isEmpty(_.omit(panelCall.RetrievedData, "ViewResponse"))
        ? "No Data Retrieved"
        : activePanel;

  };

  onCloseModal = () => {
    this.props.closeModal();
  };

  renderModalForm = () => {
    if (!this.props.open) return null;
    /*
  console.log('renderModalForm - this.state.screenDisplayed=', this.state.screenDisplayed);
  console.log('renderModalForm - this.state.screenName=', this.state.screenName);
  console.log(this.props.screenType)
  */
    let enhancedPanelContents;
    // check to see whether the screen to be displayed has changed
    if (this.props.screenType && this.props.screenType !== this.state.screenDisplayed && !this.props.screenType.id && !this.state.ignoreScreenType) return null;
    let appGUID = this.props.dataConfig.allTeams.currentTeamDetails.teamGUID
    if (this.props.screenType === fileUploadGUID) {
      // set the appGUID to that of the selected application
      const { ApplicationTasks, selectedApplicationTask } = this.props
      appGUID = ApplicationTasks[selectedApplicationTask].applicationGUID


      // ******************************************************************************************* 
      // 
      // PMD 25/05/22 DUMMY HARDCODED Supplemented file upload response with additional fields
      // 
      // HARDCODED AT DAVIDS REQUEST - NO ERROR TRAPPING IF RESPONSE IS MALFORMED
      // 
      // *******************************************************************************************
      // console.log(panelContent)
      enhancedPanelContents = deepCopy(panelContent)
      // console.log(enhancedPanelContents.Calls[0].RetrievedData);
      enhancedPanelContents.Calls[0].RetrievedData.PropertyData.properties.DocumentInformation.required.push('UploadFiles')
      enhancedPanelContents.Calls[0].RetrievedData.PropertyData.properties.DocumentInformation.properties.UploadFiles = { type: 'array', title: 'Select files to be uploaded', items: { type: 'string', format: 'data-url' } }

      // Update UISchema to include teh UploadFiles 
      enhancedPanelContents.Calls[0].RetrievedData.UISchema.DocumentInformation['ui:order'].push("UploadFiles")
      // Restrict file types
      enhancedPanelContents.Calls[0].RetrievedData.UISchema.DocumentInformation.UploadFiles = { 'ui:options': { accept: '.pdf, .jpeg, .png, .txt, .doc, .docx' } }

      // console.log(enhancedPanelContents.Calls[0].RetrievedData);

      // ******************************************************************************************
      // 
      // END OF HARD CODING
      // 
      // ******************************************************************************************

    } else {
      // PMD 25/05/22 Use original panelContents unenhanced
      enhancedPanelContents = panelContent
    }
    // console.log('call to <Modal> - <PanelForm>', this.state.screenName);

    return (
      <>
        <Modal
          open={this.props.open}
          onClose={() => this.onCloseModal()}
          center
          closeOnOverlayClick={false}
          classNames={{
            modal: "custommodalform",
          }}
          closeOnEsc={false}
        >
          <h2>{panelContent.Attributes.Label}</h2>
          <PanelForm
            // PMD 25/05/22 Using manipulated contents for file uploads
            // panelContent={panelContent}
            panelContent={enhancedPanelContents}
            sessionId={this.props.sessionId}
            applicationId={appGUID}
            modalScreen={true}
            parentTaskId={this.props.swimlanesOrder}
            onCloseModal={() => this.onCloseModal()}
            ServiceName={this.props.ServiceName}
            ServiceCode={this.props.ServiceCode}
            LoBName={this.props.LoBName}
            LoBCode={this.props.LoBCode}
            callingScreen={this.state.screenName}
            CatalogueCode={this.props.selectedCatalogue}
            segmentSpotliteAddress={""}
            spotliteAddress={this.props.spotliteAddress}
          />
        </Modal>
      </>
    );
  };

  render() {

    const { isLoading, redirect } = this.state;

    if (redirect) {
      // set session id to null
      return (
        <Redirect to={'/login'} />
      )
    }

    // console.log('render - this.state.isLoading:', isLoading);
    if (isLoading) return null;

    return this.renderModalForm();
  }
}

const mapStateToProps = (state) => {
  const { sessionId, dataConfig, portalDetails } = state.session;
  const { swimlanesOrder } = state.certificates
  const { selectedApplicationTask } = state.applications
  const { ApplicationTasks } = state.applications.applications
  const { viewData, getViewLoading } = state.getView;
  return {
    sessionId,
    dataConfig,
    portalDetails,
    swimlanesOrder,
    selectedApplicationTask,
    ApplicationTasks,
    viewData,
    getViewLoading
  };
};

export default connect(mapStateToProps, { getDataViewData })(ModalForm);
