import React from 'react';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from 'lodash';
import Task from "../LayoutController/applicationTask";
import SegmentMenu from "../Reusable/SegmentMenu";


export default function Segment(props) {

    const { index, layout, data, label, id, isDisabled} = props;

    const grid = 2;

    let droppableId = (typeof layout.segment.segmentGUID  === 'undefined'
    ? `${index}` : layout.segment.segmentGUID);

    
    // const getTaskListStyle = isDraggingOver => ({
    //     background: isDraggingOver ? "lightblue" : "#E9E9E8",
    // });
    

    const getSegmentContainerStyle = (isDragging, draggableStyle) => ({
        //background: isDragging ? "lightgreen" : "#E9E9E8",
        
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: "#E9E9E8",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const segmentElement = (
   
        <Draggable draggableId={id} index={index} isDragDisabled={isDisabled}>
            {(provided, snapshot)=> (
                //Container
                <div 
                ref={provided.innerRef}
                {...provided.draggableProps} 
                {...provided.dragHandleProps}
                style={getSegmentContainerStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    {/* Header */}
                    <div className="segment_header" onClick={() => mouseDownFunc(index, `${index}_dropdown`)}>

                        <div className="tooltip">{label}{renderHint(layout.segment.segmentHint)}</div>

                        {data.length ? renderDropDown(isDisabled, data, index, props) : null}
                       
                    </div>

                    {/* Tasklist */}

                    <Droppable droppableId={droppableId} index={index} type="task">
                        {(provided, snapshot) => (
        
                            <div className="segment_task_container"
                            ref={provided.innerRef}  
                            {...provided.droppableProps}
                            // style={getTaskListStyle(snapshot.isDraggingOver)}
                            >
                            {Object.values(layout.applicationTasks).map((task, index) => {
                                return <Task
                                key={task.id} 
                                index={index}
                                segmentAddress={layout.segment.spotliteAddress} 
                                task={task} /*taskClickedCallback={myFunction1}*/ /*applicationTaskCallback={launchTask}*/ />;  
                            })}
                            {provided.placeholder}
                            </div>
                        
                        )}
                    </Droppable>
                </div>
            )}
        </Draggable>
    )

    return <div className="segment_container" >{segmentElement}</div>;

}

function mouseDownFunc(id, menu) {
    
    document.addEventListener('mousedown', e => {
        if (e.target.id !== menu && e.target.id !== "dropdownButton") {
            // if(typeof document.getElementById(id) !== 'undefined' && typeof document.getElementById(id) !== null){ 
            if(document.getElementById(id) !== null){ 
                let result = document.getElementById(id).classList.contains("show");
                if(result){
                    document.getElementById(id).classList.remove("show");
                }
            }
        }
    });
}

function renderDropDown(isDisabled, data, index, props) {
    
    const onItemClick = (event => {

        // console.log(event);
        const { optionsCallback, layout } = props;

        optionsCallback(event, layout);
        //Do something with the GUID
        document.getElementById(index).classList.toggle("show"); 
    });

    const onMenuItemClick = () => {
        document.getElementById(index).classList.toggle("show"); 
    }
    
    isDisabled = false;

    if(isDisabled){
        return null;
    } else {
        return (
            <div className="segment_header_drop_down_container">
                <div id={`${index}_dropdown`} className="segment_header_menu_icon" onClick={() => onMenuItemClick()} />
                    <SegmentMenu id={index} 
                    data={data}
                    onItemClick={onItemClick}/>
            </div>
        );
    }
}


function renderHint(item) {

    if(isEmpty(item) || item === null){
        return null;
    } else {
        // return <span className="tooltiptext right">{item}</span>
        return <span className="tooltiptext bottom">{item}</span>
    }
}