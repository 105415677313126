import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  updateSelectedApplicationTask
} from "../../actions";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { filterData } from "../../utils/Filter";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { Redirect } from 'react-router-dom';

const localizer = momentLocalizer(moment);

class CertificateCalendar extends Component {
  constructor(props) {
    super(props);

    // let objs = _.map(this.props.certificates, function (value, index) {
    //   return {
    //     ...value,
    //     allDay: true,
    //     start: moment(value.createdOnDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
    //     end: moment(value.createdOnDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
    //     title: value.applicationInstanceName,
    //   };
    // });

    // const objs1 = _.map(this.props.certificates, function (value, index) {
    //   console.log(value)
    //   return {
    //     ...value,
    //     allDay: true,
    //     start: moment(value.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
    //     end: moment(value.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
    //     title: value.applicationInstanceName,
    //   };
    // });

    this.state = {
      events: [],
      selectedOption: "due",
      redirect: false,
      spotliteAddress: '',
      applicationGUID: '',
      viewGUID: '',
    };
  }

  selectApplicationTask = (cert) => {
    this.props.updateSelectedApplicationTask(cert.id);
    
    //console.log(cert.viewGUID, cert.applicationGUID, cert.spotliteAddress);

    this.setState({
      spotliteAddress: cert.spotliteAddress,
      redirect: true,
      applicationGUID: cert.applicationGUID,
      viewGUID: cert.viewGUID,
    });
  };

  handleOptionChange = (changeEvent) => {
    
    const search = [...this.props.searchCriteria];
    
    let objs = [];

    if (changeEvent.target.value === "created") {
      // created date
      objs = Object.values(this.props.ApplicationTasks)
      .filter(task => isEmpty(search) || filterData(task, search))
      .map(task => ({
        ...task,
        allDay: true,
        start: moment(task.taskCreatedDateAndTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
        end: moment(task.taskCreatedDateAndTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
        title: task.applicationInstanceName,
      })); 
      
    } else {
      // due date
      objs = Object.values(this.props.ApplicationTasks)
      .filter(task => isEmpty(search) || filterData(task, search))
      .map(task => ({
        ...task,
        allDay: true,
        start: moment(task.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
        end: moment(task.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
        title: task.applicationInstanceName,
      })); 
    }
    
    this.setState({
      events: objs,
      selectedOption: changeEvent.target.value,
    });
  };

  loadCalendar = () => {

    const search = [...this.props.searchCriteria];

    let objs = Object.values(this.props.ApplicationTasks)
    .filter(task => isEmpty(search) || filterData(task, search))
    .map(task => ({
      ...task,
      allDay: true,
      start: moment(task.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
      end: moment(task.taskDueDateTime, "YYYY-MM-DDThh:mm:ss.SSSZ"),
      title: task.applicationInstanceName,
    })); 

    this.setState({
      events: objs,
      selectedOption: "due"
    });

  }

  componentDidMount() {
    this.loadCalendar()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ApplicationTasks !== this.props.ApplicationTasks) {
      this.loadCalendar()
    }
  }


  render() {
    
    //const { redirect, spotliteAddress } = this.state;

    const { redirect, applicationGUID, viewGUID, spotliteAddress } = this.state;


    if (redirect) {
      
      //let certificateAddress = (spotliteAddress || 'address.not.found').split('.').slice(1).join('.');

      var addressParts = spotliteAddress.split('-');
            
      let formattedSpotliteAddress = "";
      
      if(addressParts.length > 1){
        formattedSpotliteAddress = addressParts[addressParts.length -1];
      } else {
        formattedSpotliteAddress = addressParts[0];
      }

      return <Redirect push to={
        {
            pathname: `${this.props.bookmark.bookmarkPath}/${applicationGUID}`, 
            state:{viewGUID, applicationGUID, spotliteAddress: formattedSpotliteAddress}
        }
      } />;
    }

    return (
      <div className="calendar">
        <h2>
          <strong style={{ display: "flex", margin: 8 }}>Date Selection</strong>
        </h2>
        <form style={{ display: "flex", margin: 8 }}>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="created"
                checked={this.state.selectedOption === "created"}
                onChange={this.handleOptionChange}
              />
              Created Date
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="due"
                checked={this.state.selectedOption === "due"}
                onChange={this.handleOptionChange}
              />
              Due Date
            </label>
          </div>
        </form>
        <div>
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={this.state.events}
            views={["month", "week", "day"]}
            onSelectEvent={(event, e) => {
              this.selectApplicationTask(event);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { searchCriteria } = state.applications;
  const { ApplicationTasks } = state.applications.applications;
  const bookmark = state.bookmark;
  return { searchCriteria, ApplicationTasks, bookmark };
};

export default connect(mapStateToProps, { 
  updateSelectedApplicationTask, 
})(CertificateCalendar);
