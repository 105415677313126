import * as types from './types';

export const fetchApplicationsRequest = () => {
    return {
        type: types.FETCH_APPLICATIONS_REQUEST,
    };
};

export const fetchApplicationsSuccess = (applications) => {
    return {
        type: types.FETCH_APPLICATIONS_SUCCESS,
        payload: applications,
    };
};

export const fetchApplicationsError = (error) => {
    return {
        type: types.FETCH_APPLICATIONS_ERROR,
        payload: error,
    };
};

export const updateApplicationTaskRequest = () => {
    return {
        type: types.UPDATE_APPLICATION_TASK_REQUEST,
    };
}

export const clearApplication_Tasks = (applicationTasks) => {
    return {
        type: types.CLEAR_APPLICATION_TASKS,
        payload: applicationTasks,
    };
};

export const updateApplication_Tasks = (applicationTasks) => {
    return {
        type: types.UPDATE_APPLICATION_TASKS,
        payload: applicationTasks,
    };
};

export const updateApplicationTaskError = (error) => {
    return {
        type: types.UPDATE_APPLICATION_TASK_ERROR,
        payload: error
    };
}

export const updateSelectedApplication_Task = (id) => {
    return {
        type: types.UPDATE_SELECTED_APPLICATION_TASK,
        payload: id,
    };
};

export const updateApplicationTaskColors = (colors) => {
    return {
        type: types.UPDATE_APPLICATION_COLORS,
        payload: colors,
    };
};

export const updateSearchCriteria = (searchCriteria) => {
    return {
        type: types.UPDATE_SEARCH_CRITERIA,
        payload: searchCriteria,
    };
};

export const updateApplicationLayout = (payload) => {
    return {
        type: types.UPDATE_APPLICATION_COMPONENT_TYPE,
        payload: payload,
    }
}
export const changeTeamRequest = () => {
    return {
        type: types.CHANGE_TEAM_REQUEST,
    };
};

export const changeTeamSuccess = (dataConfig) => {
    return {
        type: types.SET_DATA_CONFIG,
        payload: dataConfig
    };
};  

export const changeTeamError = (error) => {
    return {
        type: types.CHANGE_TEAM_ERROR,
        payload: error
    };
};

export const getDataViewRequest = () => {
    return {
        type: types.GET_DATAVIEW_REQUEST
    };
};

export const getDataViewSuccess = (gdvResponse) => {
    return {
        type: types.GET_DATAVIEW_SUCCESS,
        payload: gdvResponse
    };
};

export const getDataViewError = (error) => {
    return {
        type: types.GET_DATAVIEW_ERROR,
        payload: error
    };
};

export const postDataViewRequest = () => {
    return {
        type: types.POST_DATAVIEW_REQUEST,
    };
};

export const postDataViewSuccess = (pdvResponse) => {
    return {
        type: types.POST_DATAVIEW_SUCCESS,
        payload: pdvResponse
    };
};

export const postDataViewError = (error) => {
    return {
        type: types.POST_DATAVIEW_ERROR,
        payload: error
    };
};

export const uploadFilesRequest = () => {
    return {
        type: types.UPLOAD_FILES_REQUEST,
    };
};

export const uploadFilesSuccess = (pdvResponse) => {
    return {
        type: types.UPLOAD_FILES_SUCCESS,
        payload: pdvResponse
    };
};

export const uploadFilesError = (error) => {
    return {
        type: types.UPLOAD_FILES_ERROR,
        payload: error
    };
};

export const setBookmark = (payload) => {
    return {
        type: types.SET_BOOKMARK,
        payload: payload,
    };
};

export const getCatalogueRequest = () => {
    return {
        type: types.GET_CATALOGUE_REQUEST,
    };
};

export const getCatalogueSuccess = (payload) => {
    return {
        type: types.GET_CATALOGUE_SUCCESS,
        payload: payload
    };
};

export const getCatalogueError = (error) => {
    return {
        type: types.GET_CATALOGUE_ERROR,
        payload: error
    };
};